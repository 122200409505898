import { Paper, Box, Typography, Chip, TableContainer, TableHead, TableRow, TableCell, TableBody, Avatar, Stack, Tooltip, useMediaQuery } from "@mui/material";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useEffect, useState } from "react";
import calendarImage from '../../assets/images/calendarIconSchedule.svg';
import CalendarBlueIcon from '../../assets/images/interviewModuleBlueIcon.svg';
import TypeofRTHicon from '../../assets/images/typeOfRTH.svg';
import ExpandArrowIcon from '../../assets/images/expandArrowIcon.svg';
import CopyBlueIcon from '../../assets/images/blueCopyIcon.svg';
import InterviewModeIcon from '../../assets/images/interviewModeIcon.svg';
import InterviewStatus from '../../assets/images/interviewStatusIcon.svg';
import InterviewsIcon from '../../assets/images/interviewersIcon.svg';
import Organiser from '../../assets/images/organiserIcon.svg';
import MsTeamIcon from '../../assets/images/msTeamIcon.svg';
import { getAllInterviewsModuleData, getInterviewMrfDetails } from "../../redux/features/interviewModule";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import React from "react";

export const InterviewCalendar = ({drawerOpen,filterPayload}:any) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [upcomingInterviews, setUpcomingInterviews] = useState<any[]>([]);
    const [expandedRow, setExpandedRow] = useState<string | null>(null);
    const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);
    const [tooltipText, setTooltipText] = useState("Copy");
    const[organizer,setOrganizer]=useState<any>([]);
    const[typeOfRTH,setTypeOfRTH]=useState<any>([]);
    const dispatch=useAppDispatch();
    const interviewModuleData=useAppSelector((state:any)=>state?.interviewModule?.interviewModule);
    const interviewMrfDetails=useAppSelector((state:any)=>state?.interviewModule?.interviewMrfDetails);     
    
        
    const handleExpand = (candidateId:any,mrfId:any) => {   
     setExpandedRow(prev => (prev === candidateId ? null : candidateId)); // Toggle the expanded row
     dispatch(getInterviewMrfDetails(mrfId));
      };   
      
    const getDaysInMonth = (date: Date): number => {
        const year = date.getFullYear();
        const month = date.getMonth();
        return new Date(year, month + 1, 0).getDate();
    };

    const getStartOfMonth = (date: Date): number => {
        const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        return (firstDay.getDay() === 0 ? 6 : firstDay.getDay() - 1); // Adjust so Monday is 0, Sunday is 6

    };

    const daysInMonth = Array.from({ length: getDaysInMonth(currentDate) }, (_, i) => {
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), i + 1);
        const day = new Intl.DateTimeFormat("en-US", { weekday: "short" }).format(date);
        const monthShort = new Intl.DateTimeFormat("en-US", { month: "short" }).format(date);
        return { day: i + 1, weekday: day, month: monthShort, date: date.toISOString().split("T")[0] };
    });

    const handlePreviousMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(currentDate.getMonth() - 1);
        setCurrentDate(newDate);
    };

    const handleNextMonth = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(currentDate.getMonth() + 1);
        setCurrentDate(newDate);
    };

    const getAvatarStyle = (round: number) => {
        switch (round) {
            case 1:
                return { bgcolor: '#F7F1FF', color: '#5708B8' };
            case 2:
                return { bgcolor: '#CAF1D9', color: '#0E8B3E' };
            case 3:
                return { bgcolor: '#C2EEF6', color: '#0D6070' };
            default:
                return { bgcolor: 'unset', color: '#A9A9A9' };
        }
    };

    const handleCopyLink = (link:any) => {
        if (link !== "N/A") {
            navigator.clipboard.writeText(link)
                .then(() => {
                    setTooltipText("Copied");
                    setTimeout(() => setTooltipText("Copy"), 2000); // Reset to "Copy" after 2 seconds
                })
                .catch((err) => {
                    console.error("Failed to copy text: ", err);
                });
        }
    };

    // Get the first day of the current month to align the dates
    const startOfMonth = getStartOfMonth(currentDate); 
   
    const Md = useMediaQuery("(max-width: 1504px)");

    useEffect(() => {
        if (upcomingInterviews && upcomingInterviews.length > 0) {
            const interviewDate = upcomingInterviews[0]?.interviewDetails?.interviewDate;
            if (interviewDate) {
                const formattedDate:any = new Date(interviewDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                });
                setSelectedDate(formattedDate);
            }
        }
    }, [upcomingInterviews]);

    useEffect(() => {
        // Fetch API data
        const fetchInterviewModuleData = async () => {
          try {
            await dispatch(getAllInterviewsModuleData(filterPayload));
          } catch (error) {    
          }
        };
        fetchInterviewModuleData();
      }, [filterPayload, dispatch]);

      useEffect(() => {
        const today = new Date();
        let upcomingInterviewList: any = []; // To handle multiple interviews    
        interviewModuleData?.filter((item: any,itemIndex:any) => {
            // Extract dates from different interview details
            const interviewDates = [
                item.interviewDetL1?.interviewDate,
                item.interviewDetL2?.interviewDate,
                item.interviewDetL3?.interviewDate,
            ].filter(Boolean); // Remove null or undefined values
    
            // Process interview dates for rounds
            interviewDates.forEach((date, roundIndex) => {
                const interviewDate = new Date(date);
                if (interviewDate > today) { // Check if the date is greater than today's date
                    const roundData = {
                        round: roundIndex + 1,
                        candidateName: item?.candidateName,
                        candidateId: item?.candidateId,
                        interviewDate: date,
                        interviewDetails: item?.[`interviewDetL${roundIndex + 1}`],
                        rthId:item?.rthId,
                        index: `${itemIndex}-${roundIndex}`,
                    };
                    upcomingInterviewList.push(roundData);
                }
            });
    
            // Check if at least one interview date is greater than today's date
            return interviewDates.some((date: any) => new Date(date) > today);
        });
    
      setUpcomingInterviews(upcomingInterviewList); // Set the upcoming interviews list
    }, [interviewModuleData]);   
              
    useEffect(()=>{
        if(interviewMrfDetails?.Organizer){
            setOrganizer(interviewMrfDetails?.Organizer[0]);
            setTypeOfRTH(interviewMrfDetails?.TypeOfRth);
         }
    },[interviewMrfDetails])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {/* Calendar UI */}
            <Paper sx={{ width: drawerOpen?(Md ? '49vw' : '55vw'): (Md ? '80vw' : '70vw'), borderRadius: '10px', boxShadow: 'none', margin: "auto", mt: 2, p: 2, ml: drawerOpen? 2:3 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>

                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <Box sx={{ width: "20px", height: '20px', borderRadius: '50%', cursor: 'pointer', pt: '2px' }} onClick={handlePreviousMonth}  onKeyDown={(e) => {
                            if (e.key === 'ArrowLeft') {
                                e.preventDefault(); // Prevent default scrolling behavior
                                handlePreviousMonth();
                            }
                        }}
                        tabIndex={0} // Makes the element focusable
                        role="button" // Adds semantic meaning
                        aria-label="Previous month"
                        >
                            <ArrowBackIosRoundedIcon sx={{ fontSize: '16px', color: '#1A1A1A' }} />
                        </Box>
                        <Typography variant="h6" component="div" sx={{ textAlign: 'center', color: '#1A1A1A', fontSize: '14px', width: '85px' }}>
                            {new Intl.DateTimeFormat("en-GB", {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                            }).format(currentDate)}
                        </Typography>
                        <Box sx={{ width: "20px", height: '20px', borderRadius: '50%', cursor: 'pointer', pl: '5px', pt: '2px' }} onClick={handleNextMonth} 
                        onKeyDown={(e) => {
                            if (e.key === 'ArrowRight') {
                                e.preventDefault(); // Prevent default scrolling behavior
                                handleNextMonth();
                            }
                        }}
                        tabIndex={0} // Makes the element focusable
                        role="button" // Adds semantic meaning
                        aria-label="Next month">
                            <ArrowForwardIosRoundedIcon sx={{ fontSize: '16px', color: '#1A1A1A' }} />
                        </Box>
                    </Box>
                    
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mb: '16px', overflowX:'auto', width:'62%', ml: '50px' }}>
                        <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#FFEBC2'}} />
                        <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A', textWrap:'nowrap' }}>Screening</Typography>
                        <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#F7F1FF' }} />
                        <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A', textWrap:'nowrap'  }}>L1 Interview</Typography>
                        <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#CAF1D9' }} />
                        <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A', textWrap:'nowrap'  }}>L2 Interview</Typography>
                        <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#C2EEF6' }} />
                        <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A', textWrap:'nowrap'  }}>HR Round</Typography>
                        <Chip sx={{ width: '16px', height: '16px', border: '0.5px solid #C6C5C5', borderRadius: '2px', bgcolor: '#FFD7CE' }} />
                        <Typography sx={{ ml: '6px', mr: '20px', fontWeight: 500, fontSize: '14px', color: '#1A1A1A', textWrap:'nowrap'  }}>Other Statuses</Typography>
                    </Box>
                </Box>

                <Box>
                <TableContainer sx={{border:'1px solid #E8E8E8', height: Md? '60vh':495, overflowY:'auto', '&::-webkit-scrollbar': {
                width: '0px', // Set the scrollbar width
                height: '0px', // Set the scrollbar height (if horizontal)
                }}}>

                    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <TableHead sx={{position:'sticky', top:0, zIndex: 1 }}>
                            <TableRow>
                                {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map((day,index) => (
                                    <TableCell key={day} align="center"
                                     sx={{
                                        width:'100px',
                                        backgroundColor: "#f4f5fa", // Adjust to match the header background color from the image
                                        borderRight: index < 6 ? "1px solid #e0e0e0" : "none", // Right border for each day except Sunday
                                        textAlign: "center",
                                        color: "#333",
                                        p:0,
                                     }}>
                                        {day}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {Array.from({ length: 6 }).map((_, rowIndex) => (
                        <TableRow key={rowIndex}>
                        {Array.from({ length: 7 }).map((_, colIndex) => {
                            const dayIndex = rowIndex * 7 + colIndex - startOfMonth;
                            let day:any;

                            if (dayIndex >= 0 && dayIndex < daysInMonth.length) {
                                // If it's within the current month, get the day from daysInMonth
                                day = daysInMonth[dayIndex];
                            } else {
                                // For previous month dates
                                if (dayIndex < 0) {
                                    const prevMonthDate = new Date(currentDate);
                                    prevMonthDate.setMonth(currentDate.getMonth() - 1);
                                    const prevMonthDays = getDaysInMonth(prevMonthDate);
                                    day = { day: prevMonthDays + dayIndex + 1, date: new Date(prevMonthDate.setDate(prevMonthDays + dayIndex + 1)) }; 
                                }
                                // For next month dates
                                else if (dayIndex >= daysInMonth.length) {
                                    const nextMonthDate = new Date(currentDate);
                                    nextMonthDate.setMonth(currentDate.getMonth() + 1);
                                    day = { day: dayIndex - daysInMonth.length + 1, date: new Date(nextMonthDate.setDate(dayIndex - daysInMonth.length + 1)) }; 
                                }
                            }

                            const isCurrentMonth = day && dayIndex >= 0 && dayIndex < daysInMonth.length;
                            // const isWeekend = colIndex === 5 || colIndex === 6; // Saturday or Sunday
                            const isPastMonth = !isCurrentMonth && day && new Date(day.date) < new Date();
                            const isFutureMonth = !isCurrentMonth && day && new Date(day.date) > new Date();

                            return (
                            <>
                            <TableCell
                                key={colIndex}
                                sx={{
                                    overflowX:'scroll',
                                    position: 'relative',
                                    padding: '8px',
                                    backgroundColor: 'white',
                                    color: isPastMonth || isFutureMonth ? '#d3d3d3' : '#1A1A1A',
                                    cursor: isPastMonth || isFutureMonth? 'not-allowed' : 'pointer',
                                    borderRight: colIndex < 6 ? "1px solid #e0e0e0" : "none",
                                    height: '100px',
                                    fontWeight: 500,
                                    '&:hover': {
                                        backgroundColor: '#1677FF05',
                                        border: '1px solid #1677FFCC',
                                    },
                                    ...(new Date(day?.date).toDateString() === new Date(new Date().setDate(new Date().getDate() - 1)).toDateString() && {
                                        color: '#1677FFCC', // Apply violet color to yesterday's date
                                        fontWeight: 'bold', // Optional: make it bold for emphasis
                                    }),
                                }}
                                onClick={() => {
                                    if (isPastMonth || isFutureMonth) {
                                        return; // Prevent clicking on past or future month dates
                                    }
                                    let upcomingInterviewList:any = []; // To handle multiple interviews
                                    let foundCandidate = false;     
                                    const selectedDay = new Date(day?.date);
                                        selectedDay.setDate(selectedDay.getDate() + 1); // Increase the date by 1 day

                                        setSelectedDate(selectedDay.toLocaleDateString("en-GB", {
                                            day: "2-digit",
                                            month: "short",
                                            year: "numeric",
                                        }));
                                                            
                                    interviewModuleData?.forEach((item:any, itemIndex: number) => {
                                                                                                         
                                        const interviewDates = [
                                            item?.interviewDetL1?.interviewDate,
                                            item?.interviewDetL2?.interviewDate,
                                            item?.interviewDetL3?.interviewDate,
                                        ];
                                
                                        interviewDates.forEach((date, roundIndex) => {
                                            const shiftedDate = new Date(date);
                                            shiftedDate.setDate(shiftedDate.getDate() - 1);
                                
                                            if (shiftedDate.toLocaleDateString() === new Date(day.date).toLocaleDateString()) {
                                                const roundData = {
                                                    round: roundIndex + 1,
                                                    candidateName: item?.candidateName,
                                                    candidateId:item?.candidateId,
                                                    interviewDate: date,
                                                    interviewDetails: item?.[`interviewDetL${roundIndex + 1}`],
                                                    index: `${itemIndex}-${roundIndex}`,
                                                    rthId:item?.rthId
                                                };
                                                upcomingInterviewList.push(roundData);
                                                foundCandidate = true;
                                            }
                                        });
                                    });
                                
                                    if (foundCandidate) {                                        
                                        setUpcomingInterviews(upcomingInterviewList); // Set the array of interviews for the clicked date
                                        setExpandedRow(upcomingInterviewList)
                                    } else {
                                        setUpcomingInterviews([]); // Clear the state if no interviews are found
                                    }
                                }}                                
                            >
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '8px',
                                        left: '8px',
                                    }}
                                >
                                    {day ? day.day : ''}
                                </Box>
                                {!(isPastMonth || isFutureMonth) && (
                                <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: '8px',
                                    right: '8px',
                                    fontSize: '12px',
                                }}
                                >
                                <Stack direction="row" spacing={1}>
                                {(() => {
                                    const filteredData = interviewModuleData
                                        ?.flatMap((item: any) => {
                                            const interviewDates = [
                                                item?.interviewDetL1?.interviewDate,
                                                item?.interviewDetL2?.interviewDate,
                                                item?.interviewDetL3?.interviewDate,
                                            ];
                                            return interviewDates.map((date, idx) => ({
                                                date,
                                                candidateNameInitial: item?.candidateName?.charAt(0) || '',
                                                roundIndex: idx + 1,
                                            }));
                                        })
                                        .filter(({ date }: any) => {
                                            const shiftedDate = new Date(date);
                                            shiftedDate.setDate(shiftedDate.getDate() - 1);
                                            return shiftedDate.toLocaleDateString() === new Date(day.date).toLocaleDateString();
                                        });
 
                                    const visibleAvatars = filteredData?.slice(0, 2);
                                    const extraCount = filteredData?.length > 2 ? filteredData?.length - 2 : 0;                                  
                                    return (
                                        <>
                                            {visibleAvatars?.map(({ candidateNameInitial, roundIndex }: any, idx: any) => (
                                                <Avatar
                                                    key={idx}
                                                    sx={{
                                                        bgcolor: getAvatarStyle(roundIndex).bgcolor,
                                                        color: getAvatarStyle(roundIndex).color,
                                                        width: 28,
                                                        height: 28,
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    {candidateNameInitial}
                                                </Avatar>
                                            ))}
                                            {/* Render "+n" avatar if extraCount exists */}
                                            {extraCount > 0 && (
                                                <Avatar
                                                    key="extra"
                                                    sx={{
                                                        color: '#A9A9A9',
                                                        bgcolor:'unset',
                                                        width: 28,
                                                        height: 28,
                                                        fontSize: '12px',
                                                    }}
                                                >
                                                    +{extraCount}
                                                </Avatar>
                                            )}
                                        </>
                                    );
                                })()}
                                </Stack>
                                </Box>
 
                                )}
                            </TableCell>
                            </>
                            );
                        })}
                        </TableRow>
                        ))}
                        </TableBody>
                    </table>
                </TableContainer>
                </Box>
            </Paper>

            {/* Upcoming Interview */}            
            <Paper
            sx={{
                width: drawerOpen? (Md ? '240px' : '290px') : "290px",
                height: Md? '67vh':'73.4vh',
                margin: "auto", mt: 2, ml: 1, p: 2, mr: 1,
                borderRadius: "8px",
                boxShadow: "none", 
                overflow:'hidden'                
            }}
            >            
            <Typography variant="subtitle2" mb={1}>
            Upcoming Interview
            </Typography>
                       
            <Box display="flex" alignItems="center" mb={2}>
            <img style={{ width: "12px", paddingRight:'8px'}} src={CalendarBlueIcon} alt="Calendar" />  
            <Typography variant="body2"  sx={{color:'#1677FF', fontWeight:500}}>
            {selectedDate || "Date not Available"}
            </Typography>
            </Box>
            
            <Box sx={{overflowY:'auto', height: Md? 340 : 485, 
            '&::-webkit-scrollbar': {
            width: '0px', // Set the scrollbar width
            height: '0px', // Set the scrollbar height (if horizontal)
            } }}>
            {upcomingInterviews && upcomingInterviews?.length > 0 ? (
            upcomingInterviews?.map((interview) => { 
                return (  
            <React.Fragment >     
            <>   
            <Paper
            sx={{
            p: "8px 12px",
            backgroundColor: getAvatarStyle(interview?.round)?.bgcolor || "#F7F1FF",
            borderRadius: "4px",
            alignItems: "center",
            mb: 2,
            boxShadow: "none",
            }}
            >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: "6px" }}>
            <Box>
                <Typography
                sx={{
                    color: getAvatarStyle(interview?.round)?.color || "#5708B8",
                    fontWeight: "500",
                    fontSize: "16px",
                }}
                >
                {interview?.candidateName || "N/A"}
                </Typography>
                <Typography
                variant="caption"
                sx={{
                    color: "#888585",
                    display: "flex",
                    alignItems: "center",
                    gap: "4px",
                    fontSize: "12px",
                }}
                >
                <img
                    style={{ width: "12px", paddingRight: "8px" }}
                    src={calendarImage}
                    alt="Calendar"
                />
                {selectedDate &&
                    new Date(selectedDate).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "short",
                    })}
                {" | "}
                {interview?.interviewDetails?.from &&
                !isNaN(new Date(interview?.interviewDetails?.from).getTime())
                    ? new Date(interview?.interviewDetails?.from).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true
                    }).replace(" AM", "").replace(" PM", "")
                    : "N/A"}
                {" - "}
                {interview?.interviewDetails?.to &&
                !isNaN(new Date(interview?.interviewDetails?.to).getTime())
                    ? new Date(interview?.interviewDetails?.to).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                    })
                    : "N/A"}
                </Typography>
            </Box>
            <Box>
                <img
                style={{ width: "16px", paddingRight: "8px", cursor: "pointer" }}
                src={ExpandArrowIcon}
                alt="Expand"
                onClick={() => handleExpand(interview?.index,interview?.rthId)}
                />
            </Box>
            </Box>
            </Paper>   
            {expandedRow === interview?.index && (          
            <>
            <Box mb={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box> <img style={{ width: "16px", paddingRight: '8px' }} src={TypeofRTHicon} alt="Calendar" /></Box>
                        <Box sx={{ ml: '16px' }}>
                            <Typography variant="caption" sx={{ color: '#919EAB', fontWeight: 500, fontSize: '10px' }}>
                                Type of MRF
                            </Typography>
                            <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 500 }}>
                                {typeOfRTH||"N/A"}
                            </Typography>
                        </Box>
            </Box>
                    
            <Box mb={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Box><img style={{ width: "22px" }} src={MsTeamIcon} alt="Calendar" /></Box>

                    <Box sx={{ ml: '16px' }}>
                        <Typography variant="caption" sx={{ color: '#919EAB', fontWeight: 500, fontSize: '10px' }}>
                        Interview Mode
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 500 }}>
                        {interview?.interviewDetails?.interviewMode || "N/A"}
                            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <Typography
                                    sx={{ fontSize: "10px", color: "#001AFF", fontWeight: 500 }}
                                >
                                   {interview?.interviewDetails?.interviewLink?.length > 40 ? (
                                        <Tooltip title={interview?.interviewDetails?.interviewLink || ""}>
                                            <span>
                                                {`${interview?.interviewDetails?.interviewLink.slice(0, 40)}...`}
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        interview?.interviewDetails?.interviewLink || "N/A"
                                    )}
                                 </Typography>
                                <Tooltip title={tooltipText} arrow>
                                {interview?.interviewDetails?.interviewLink &&
                                <img style={{ marginLeft: '10px', width: '12px', cursor: 'pointer' }} src={CopyBlueIcon} alt={"Copy icon"} onClick={()=>handleCopyLink(interview?.interviewDetails?.interviewLink)} />
                                }
                                </Tooltip>
                            </Box>
                        </Typography>
                    </Box>
            </Box>
                        
            <Box mb={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box><img style={{ width: "16px", paddingRight: '8px' }} src={InterviewStatus} alt="Calendar" /></Box>
                <Box sx={{ ml: '16px' }}>
                    <Typography variant="caption" sx={{ color: '#919EAB', fontWeight: 500, fontSize: '10px' }}>
                        Interview Status
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 500 }}>
                      {interview?.interviewDetails?.status  || "N/A" } 
                    </Typography>
                </Box>
            </Box>
                        
            <Box mb={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box><img style={{ width: "18px", paddingRight: '8px' }} src={InterviewModeIcon} alt="Calendar" /></Box>
                <Box sx={{ ml: '16px' }}>
                    <Typography variant="caption" sx={{ color: '#919EAB', fontWeight: 500, fontSize: '10px' }}>
                        Interview Mode
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 500 }}>
                        External
                    </Typography>
                </Box>
            </Box>
                        
            <Box mb={1} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box><img style={{ width: "18px", paddingRight: '8px' }} src={InterviewsIcon} alt="Calendar" /></Box>
                <Box sx={{ ml: '16px' }}>
                    <Typography variant="caption" sx={{ color: '#919EAB', fontWeight: 500, fontSize: '10px' }}>
                        Interviewers
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 500 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Avatar sx={{ width: '16px', height: '16px', mr: '10px' }}></Avatar>
                           {interview?.interviewDetails?.interviewer?.userName  || "N/A"} {"|"} {interview?.interviewDetails?.interviewer?.designation || "N/A"}
                        </Box>
                    </Typography>
                </Box>
            </Box>
                            
            <Box mb={3} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box><img style={{ width: "18px", paddingRight: '8px' }} src={Organiser} alt="Calendar" /></Box>
            <Box sx={{ ml: '16px' }}>
                <Typography variant="caption" sx={{ color: '#919EAB', fontWeight: 500, fontSize: '10px' }}>
                    Organiser
                </Typography>
                <Typography variant="body2" sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {organizer?.userName} {"/"} {organizer?.designation}
                </Typography>
            </Box>
            </Box>
            </> 
            )}
            </>
            </React.Fragment>
            )})
            ) : (
                <Typography
                variant="body2"
                sx={{ textAlign: "center", color: "#888585", marginTop: "20px", fontSize: "14px" }}
                >
                No interview scheduled
                </Typography>
            )}
            </Box> 
            </Paper>
        </Box>
    );
    };