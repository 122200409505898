import axios from "axios";
import { BaseUrl } from "../../constants/Crudurl";
import { createAsyncThunk } from "@reduxjs/toolkit";
// import { CANDIDATE_API_URL } from "../../constants/Crudurl";

export const getCandidateByRthId=async(id:any)=>{    
try{
    const response=await axios.get(
        `${BaseUrl}/candidate/getCandidateByRthId/${id}`
    );
    return response.data;
}
catch(error:any){
    return error.response?.data || { message: 'An unknown error occurred' };
}
}

export const deleteCandidateById=async(id:string)=>{    
    try{
        console.log("into the api");
        const response=await axios.delete(
            `${BaseUrl}/candidate/deleteCandidateById/${id}`
        );
        return response.data;
    }
    catch(error:any){
        return error.response?.data || { message: 'An unknown error occurred' };
    }
}

export const getCandidateByRthIdandRecruiterId=createAsyncThunk('candidate/getCandidateByRthIdandRecruiterId',
    async({rthId,recId,data,searchData}:any)=>{
        const response=await axios.post(`${BaseUrl}/candidate/getCandidateByRctrId/${rthId}/${recId}?search=${searchData}`,data);
        return response.data;
    }
)
