import { createSlice } from "@reduxjs/toolkit"
import { getAllInterviewsModuleData, getAllInterviewsStatusData, getInterviewMrfDetails } from "./interviewModule";

export const initialState={
    interviewModule:[],
    interviewStatus:[],
    interviewMrfDetails:[]
}

const InterviewModuleSlice=createSlice({
    name:'interviewModuleSlice',
    initialState,
    reducers:{},  
    extraReducers:(builder)=>{
        builder.addCase(getAllInterviewsModuleData.fulfilled,(state:any,action:any)=>{
            state.interviewModule=action?.payload?.data;
        })
        builder.addCase(getAllInterviewsModuleData.rejected,(state:any,action:any)=>{
            state.error = action.payload ? action.payload?.error : null;
        })
        builder.addCase(getAllInterviewsStatusData.fulfilled,(state:any,action:any)=>{
            state.interviewStatus=action?.payload?.data;
        })
        builder.addCase(getAllInterviewsStatusData.rejected,(state:any,action:any)=>{
            state.error = action.payload ? action.payload?.error : null;
        })
        builder.addCase(getInterviewMrfDetails.fulfilled,(state:any,action:any)=>{
            state.interviewMrfDetails=action?.payload?.data;
        })
        builder.addCase(getInterviewMrfDetails.rejected,(state:any,action:any)=>{
            state.error = action.payload ? action.payload?.error : null;
        })
    }
    
})

export default InterviewModuleSlice.reducer;