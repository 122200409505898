import { Box, Button, Checkbox, Chip, CircularProgress, ListItemText, MenuItem, Popover, TextField, Typography } from '@mui/material'
import { ArrowDropDownIcon } from '@mui/x-date-pickers'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../redux/store';
import { setPostedInId, setPostedInName } from '../../../redux/features/postedInSlice';
import { useDispatch } from 'react-redux';
import { setFilterState } from '../../../redux/features/teamHeadFilterSlice';

const PostedInDropDown:React.FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
      const [loading, setLoading] = useState(false);
      const dispatch = useDispatch<AppDispatch>();
      // const [searchLoading, setSearchLoading] = useState<boolean>(false);

      const [postedIn,setPostedIn]=useState<any[]>(
        [
            {label:"Naukri",value:"0"},
            {label:"LinkedIn",value:"1"},
            {label:"Indeed",value:"2"}
        ]
    );
    console.log('setLoading', setLoading)
    console.log('setPostedIn', setPostedIn)

    const postedInName = useSelector((state: RootState) => state.postedIn.names);
    const postedInId = useSelector((state: RootState) => state.postedIn.ids);
      const [searchQuery, setSearchQuery] = useState("");

      const isOpen = Boolean(anchorEl);

      const handleClose = () => {
        setAnchorEl(null);
      };
      
      const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };

        const handleToggle = (name: string, id: string) => {
                const updatedPostedInName = postedInName.includes(name)
                  ? postedInName.filter((title) => title !== name)
                  : [...postedInName, name];
                // dispatch(setpostedInName(updatedpostedInNames));
             
                const updatedPostedInIds = postedInId.includes(id)
                  ? postedInId.filter((title) => title !== id)
                  : [...postedInId, id];
                // dispatch(setpostedInId(updatedpostedInIds));

                dispatch(setPostedInName(updatedPostedInName));
                dispatch(setPostedInId(updatedPostedInIds));
        };

          useEffect(() => {
            const formattedPostedInName=postedInName.length ?
            postedInName.map((data)=>data.charAt(0).toLowerCase() + data.slice(1)):[]
            dispatch(
              setFilterState({
                postedIn: postedInName.length ? formattedPostedInName : [],
              })
            );
          }, [postedInName, dispatch]);

  return (
<Box>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          borderColor: "#B0B0B0",
          color: "#000",
          "&:hover": {
            borderColor: "#A0A0A0",
          },
        }}
      >
        {postedInName.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {postedInName.slice(0, 1).map((name) => (
              <Chip
                key={name}
                label={name}
                onDelete={() =>
                  handleToggle(
                    name,
                    postedIn.find((lead) => lead.label === name)?.value || ""
                  )
                }
                sx={{ fontSize: "10px", height: "20px" }}
              />
            ))}
            {postedInName.length > 1 && (
              <Chip
                label={`+${postedInName.length - 1}`}
                sx={{
                  fontSize: "10px",
                  height: "20px",
                  backgroundColor: "#f0f0f0",
                  color: "#8C8C8C",
                }}
              />
            )}
          </Box>
        ) : (
          <Typography sx={{ color: "grey" }}>Choose</Typography>
        )}
        <ArrowDropDownIcon sx={{ fontSize: 18, marginLeft: 1 }} />
      </Button>
 
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            width: anchorEl ? anchorEl.offsetWidth : "auto",
            marginTop: "2px",
          },
        }}
      >
        <Box sx={{ padding: 1 }}>
          <TextField
            size="small"
            placeholder="Search..."
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{ marginBottom: 2, "& .MuiInputBase-input": { fontSize: "12px" } }}
          />
          <Box sx={{ maxHeight: "250px", overflowY: "auto" }}>
            {postedIn.map((lead) => (
              <MenuItem
                key={lead.value}
                onClick={() => handleToggle(lead.label, lead.value)}
                sx={{ fontSize: "10px", padding: "4px 6px", gap: "10px" }}
              >
                <Checkbox
                  checked={postedInName.includes(lead.label)}
                  size="small"
                  sx={{ padding: "2px", "& .MuiSvgIcon-root": { fontSize: "14px" } }}
                />
                <ListItemText primary={lead.label} sx={{ margin: 0, fontSize: "10px" }} />
              </MenuItem>
            ))}
            {loading && (
              <Box sx={{ textAlign: "center", padding: 1 }}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  )
}

export default PostedInDropDown