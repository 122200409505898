import { Box, Grid, SelectChangeEvent, Typography } from '@mui/material'
import React from 'react'
import CommonTextField from '../../../../common/CommonTextField'
import CommonSelect from '../../../../common/CommomSelect';
import { knownOptions } from '../../../../../constants/dummyConst';

export const PersonalInformation = ({formValues,setFormValues,errors,setErrors}:any) => {
  

  const validateField = (name: string, value: string) => {
    const maxLength = 10;
    const maxNoticePeriodLength=3;
    let error = '';
    
    if (name === 'currentCtc' || name === 'expectedCtc') {
      // Check if the value is a valid number
      if (!/^\d*$/.test(value)) {
        error = 'Only numbers are allowed';
      } else if (value.length > maxLength) {
        error = `Maximum of ${maxLength} digits allowed`;
      }
    }
    else if (name === 'noticePeriods') {
      // Check if the notice period is a valid number and within the max length
      if (!/^\d*$/.test(value)) {
        error = 'Only numbers are allowed';
      } else if (value.length > maxNoticePeriodLength) {
        error = `Maximum of ${maxNoticePeriodLength} digits allowed`;
      }
    }

    return error;
  };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
        const { name, value } = event.target;
        const error = validateField(name, value);

        // Set errors if any
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          [name]: error
        }));

     if (!error) {
      setFormValues({
        ...formValues,
        [name]: value
      });
    }
      };
  return (
    <><Typography sx={{ fontSize: '14px', mb: '17px', m: '14px', backgroundColor: '#1677FF1A', color: '#1677FF', width: '159px', p: '3px', borderRadius: '3px', textAlign: 'center', fontWeight: '500' }}>Personal Information</Typography>
    {/* <Grid container spacing={2} sx={{ m: '10px' }}> */}
      <Box sx={{display:'flex',flexDirection:'row',m:'14px',mt:'40px',justifyContent:'space-between'}}>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>How did you heard about us?<span style={{ color: 'red' }}>  *</span></Typography>
                <Box sx={{width:'13vw'}}>
                <CommonSelect
                labelId=""
                label=""
                name="howDidYouHeardAbtUs"
                value={formValues.howDidYouHeardAbtUs || ''}
                onChange={handleInputChange}
                error={!!errors.howDidYouHeardAbtUs}
                helperText={errors.howDidYouHeardAbtUs}
                options={knownOptions} // Your options array
              />
              </Box>
          </Grid>
          <Grid item xs={2} >
            <Box>
              <Typography sx={{ color: '#c2c2c2', mb: '8px',fontSize:'11px', textWrap:'nowrap' }}>Have you previously been employed by HEPL?<span style={{ color: 'red' }}>  *</span></Typography>
              </Box>
              <Box sx={{width:'14vw'}}>
              <CommonTextField
                  label=""
                  name="preEmployed"
                  value={formValues.preEmployed ? 'Yes' : 'No'} 
                  onChange={handleInputChange}
                  error={!!errors.preEmployed}
                  helperText={errors.preEmployed}
                />
                </Box>
          </Grid>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>FirstName<span style={{ color: 'red' }}> *</span></Typography>
              <CommonTextField
                  label=""
                  name="firstName"
                  value={formValues.firstName === 'Not Identified' ? '' : formValues.firstName || ''}
                  onChange={handleInputChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
          </Grid>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>LastName<span style={{ color: 'red' }}>  *</span></Typography>
              <CommonTextField
                  label=""
                  name="lastName"
                  value={formValues.lastName === 'Not Identified' ? '' : formValues.lastName || ''}
                  onChange={handleInputChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
          </Grid>
          </Box>
          <Box sx={{display:'flex',flexDirection:'row',m:'14px',mt:'20px',justifyContent:'space-between'}}>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Email Address<span style={{ color: 'red' }}>  *</span></Typography>
              <CommonTextField
                  label=""
                  name="emailAddress"
                  value={formValues.emailAddress === 'Not Identified' ? '' : formValues.emailAddress || ''}
                  onChange={handleInputChange}
                  error={!!errors.emailAddress}
                  helperText={errors.emailAddress}
                />
          </Grid>
          <Grid item xs={3}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px' }}>Phone Number<span style={{ color: 'red' }}>  *</span></Typography>
              <CommonTextField
                  label=""
                  name="phoneNumber"
                  value={formValues.phoneNumber === 'Not Identified' ? '' : formValues.phoneNumber || ''}
                  onChange={handleInputChange}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                />
          </Grid>
          <Grid item xs={2} sx={{ml:'20px'}}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Current CTC<span style={{ color: 'red' }}> *</span></Typography>
              <CommonTextField
                  label=""
                  name="currentCtc"
                  value={formValues.currentCtc||''}
                  onChange={handleInputChange}
                  error={!!errors.currentCtc}
                  helperText={errors.currentCtc}
                />
          </Grid>
          <Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Expected CTC<span style={{ color: 'red' }}>  *</span></Typography>
              <CommonTextField
                  label=""
                  name="expectedCtc"
                  value={formValues.expectedCtc||''}
                  onChange={handleInputChange}
                  error={!!errors.expectedCtc}
                  helperText={errors.expectedCtc}
                />
          </Grid>
          </Box>
          <Box sx={{display:'flex',flexDirection:'row',gap:'40px',m:'14px',mt:'20px'}}>
<Grid item xs={2}>
              <Typography sx={{ color: '#c2c2c2', mb: '6px',fontSize:'12px'  }}>Notice Period(in days)<span style={{ color: 'red' }}>  *</span></Typography>
              <CommonTextField
                  label=""
                  name="noticePeriods"
                  value={formValues.noticePeriods||''}
                  onChange={handleInputChange}
                  error={!!errors.noticePeriods}
                  helperText={errors.noticePeriods}
                />
          </Grid>
          </Box>
      </> 
  )
}
