import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputAdornment,
  InputBase,
  CircularProgress,
} from "@mui/material";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
import { getOverAllRthAPIData } from "../../../../Crud/card";
import RTHJobCard from "./OverallrthCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import { useAppSelector } from "../../../../redux/hooks";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from '@mui/material/styles';


const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,        // Extra-small: <600px
      sm: 600,      // Small: 600px to 960px
      md: 960,      // Medium: 960px to 1280px
      lg: 1380,     // Large: 1280px to 1920px
      xl: 1920,     // Extra-large: >1920px
    },
  },
});

interface MyRequestsCardProps {
  drawerOpen: boolean;
  userId: any;
  teamHeadFilterDto: any;
  setLowCount: Function;
  setMediumCount: Function;
  setHighCount: Function;
  setL1StatusPending: Function;
  setL1StatusDeclined: Function;
  setL2StatusPending: Function;
  setL2StatusApproved: Function;
  setL2StatusDeclined: Function;
}

const OverallRTH: React.FC<MyRequestsCardProps> = ({
  drawerOpen,
  userId,
  teamHeadFilterDto,
  setLowCount,
  setHighCount,
  setMediumCount,
  setL1StatusPending,
  setL1StatusDeclined,
  setL2StatusPending,
  setL2StatusApproved,
  setL2StatusDeclined,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const approvedStatus = useSelector(
    (state: RootState) => state.overallRth.approved
  );
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const userIds = localStorage.getItem("userId") ?? "";
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }

    const timeoutId = setTimeout(() => {
      setLoading(true);
      getOverAllRthAPIData(userIds, teamHeadFilter, searchQuery)
        .then((res) => {
          setData(res?.data?.listOfRth || []);
          console.log("Overall MRF ", res.data);
          setLowCount(res.data.count.OverAllRthPriorityCount.LowCount);
          setHighCount(res.data.count.OverAllRthPriorityCount.HighCount);
          setMediumCount(res.data.count.OverAllRthPriorityCount.MediumCount);
          setL1StatusPending(
            res.data.count.OverAllRthL1L2Count.L1StatusPending
          );
          setL1StatusDeclined(
            res.data.count.OverAllRthL1L2Count.L1StatusDeclined
          );
          setL2StatusPending(
            res.data.count.OverAllRthL1L2Count.L2StatusPending
          );
          setL2StatusApproved(
            res.data.count.OverAllRthL1L2Count.L2StatusApproved
          );
          setL2StatusDeclined(
            res.data.count.OverAllRthL1L2Count.L2StatusDeclined
          );
        })
        .catch((error) => {
          setData([]);
          console.error("Error fetching MRF data:", error);
        })
        .finally(() => {
          setLoading(false);
          setSearchLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [
    approvedStatus,
    userIds,
    searchQuery,
    teamHeadFilter,
    setHighCount,
    setL1StatusDeclined,
    setL2StatusApproved,
    setL2StatusPending,
    setL1StatusPending,
    setL2StatusDeclined,
    setLowCount,
    setMediumCount,
  ]);

  // if (loading) {
  //   return <Box>Loading...</Box>;
  // }

  return (
    <>
    <ThemeProvider theme={customTheme}>
      <Grid
        item
        sx={{ marginTop: data.length > 0 ? "-54px" : "-58px", paddingLeft:"10px" }}
        position="absolute"
      >
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 0,
            backgroundColor: "white",
            minHeight: "3px",
            fontSize: 14,
            ".MuiInputBase-input": {
              padding: "5px 5px",
            },
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: "absolute",
              right: "10px",
              top: "25%",
              transform: "translateY(-50%)",
              color: "#2F54EB",
            }}
          />
        )}
      </Grid>
      <Box>
        {
          loading ? (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '300px',
              }}
            >
              <CircularProgress size={100} sx={{ color: '#2F54EB' }} />
            </Box>
          ) :
            data?.length > 0 ? (
              <Grid container spacing={1} columns={12}>
                {data.map((job: any, index: number) => (
                  <Grid 
                  item 
                  // xs={12} 
                  // sm={drawerOpen ? 4 : 3}  
                  // md={drawerOpen ? 3 : 2.4}  
                  // lg={drawerOpen ? 3 : 2.4}  
                  // xl={drawerOpen ? 3 : 2.4} 
                  // key={index}
                  xs={12} 
                  sm={6}  
                  md={drawerOpen ? 4 : 3}  
                  lg={drawerOpen ? 3 : 2.4}  
                  xl={drawerOpen ? 3 : 2.4} 
                  key={index}
                >
                    <RTHJobCard
                      job={job}
                      userId={userId}
                      teamHeadFilterDto={teamHeadFilterDto}
                    />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "300px",
                }}
              >
                <img src={NoDataAvail} alt="No Data Available" style={{ maxWidth: "200px" }} />
              </Box>
            )}
      </Box>
      </ThemeProvider>
    </>
  );
};

export default OverallRTH;
