import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

export const useCommentsState = () => {
    const commentState = useSelector((state: RootState) => state.comments);
    return {
      getDataRefresh: commentState.commentGetDataRefresh,
      toggleComment: commentState.openDrawer,
      mainReply: commentState.mainReplyComment,
      child1Reply: commentState.child1ReplyComment,
    };
  };