import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Typography,
  Button,
  IconButton,
  Avatar,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AppDispatch } from "../../../redux/store";
import {
  editRecruiter,
  getMrfByRthId,
  getRecruiter,
  getRthById,
} from "../../../Crud/rth";
import { assignRecruiter } from "../../../redux/features/recruiterSlice";
import { useLocation } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Select from "react-select";

interface Prop {
  numOfPosition?: string;
  numOfOpenPosition?: any;
  setView: Function;
  view: boolean;
}

export interface RowData {
  recruiter: string;
  recruiterId: string;
  assignedPosition: number;
  closedPosition: number;
  assignedDate: string;
}

export interface NewRowData {
  recruiterId: string;
  assignedPosition: number;
  assignedDate: string;
}

const AssignRecruiterNew: React.FC<Prop> = ({
  numOfPosition,
  numOfOpenPosition,
  view,
  setView,
}) => {
  const getFormattedDate = () => {
    const now = new Date();
    return `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(now.getDate()).padStart(2, "0")}`;
  };
  const location = useLocation();
  const { id } = location.state || {};  
  const [apiRows, setApiRows] = useState<NewRowData[]>([]);
  const [rows, setRows] = useState<RowData[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [disable, setIsDisable] = useState<boolean>(false);
  const [reset, setReset] = useState<number>(0);  
  const [remainingPositions, setRemainingPositions] = useState<number>(0);
  const [recruiterData, setRecruiterData] = useState<any[]>([]);
  const [isRecruiterAssigned, setIsRecruiterAssigned] =
    useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [mrfData, setMrfData] = useState<any[]>([]);
  const [mrfIds, setMrfIds] = useState<string[]>([]);
console.log("mrfIds",mrfIds)
  const dispatch = useDispatch<AppDispatch>();

  console.log("isRecruiterAssigned",isRecruiterAssigned);

  useEffect(() => {
    setRemainingPositions(numOfOpenPosition);    
  }, [numOfPosition, numOfOpenPosition]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRthById(id);
        const assigned = response.data.recruiterAssigned;
        setIsRecruiterAssigned(assigned);

        if (assigned) {
          setIsOpen(false);
          setIsDisable(true);          

          getMrfByRthId(id)
            .then((res) => {
              setMrfData(res);
              const mappedRows = res.map((recruiter: any) => ({
                recruiterId: recruiter?.recruiter?.userId,
                recruiter: recruiter?.recruiter?.userName,
                assignedPosition: recruiter?.openPosition,
                assignedDate: getFormattedDate(),
                closedPosition: recruiter.closedPosition,
              }));
              const totalAssignedPositions = res.reduce(
                (total: number, recruiter: any) =>
                  total + parseInt(recruiter.openPosition, 10),
                0
              );

              setRows(mappedRows);
              setRemainingPositions(numOfOpenPosition - totalAssignedPositions);
            })
            .catch((err) => console.error("Error fetching recruiters:", err));
        } else {
          setRows([
            {
              recruiterId: "",
              recruiter: "",
              assignedPosition: numOfOpenPosition,
              assignedDate: getFormattedDate(),
              closedPosition: 0,
            },
          ]);
        }
        setRemainingPositions(0);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    getRecruiter()
      .then((res) => setRecruiterData(res.data || []))
      .catch((err) => console.error("Error fetching recruiters:", err));
  }, [id,reset,isRecruiterAssigned, numOfOpenPosition]);



  const handleAddRowButton = () => {
    if (remainingPositions > 0) {
      // Assuming you're adding the maximum available positions for a new row
      const newAssignedPosition = Math.min(
        remainingPositions,
        numOfOpenPosition
      );

      // Update remainingPositions after adding the new row
      const updatedRemainingPositions =
        remainingPositions - newAssignedPosition;

      // Update the rows with the new row data
      setRows((prevRows) => [
        ...prevRows,
        {
          recruiterId: "",
          recruiter: "",
          assignedPosition: newAssignedPosition,
          assignedDate: getFormattedDate(),
          closedPosition: 0,
        },
      ]);

      // Update the remainingPositions
      setRemainingPositions(updatedRemainingPositions);
    }
  };

  const handleEditButton = () => {
    setIsDisable(false);
    setEdit(true);
  };

  const handleDeleteIconButton = (index: number) => {
    if (rows.length === 1) {
      toast.warning("At least one row is required");
    } else {
      const deletedRow = rows[index];

      if (isRecruiterAssigned) {
        const recruiterId = deletedRow.recruiterId;

        const matchingMrfIds = mrfData
          .filter((mrf) => mrf.recruiter.userId === recruiterId)
          .map((mrf) => mrf.mrfId);        
        setMrfIds((prevMrfIds) => [...prevMrfIds, ...matchingMrfIds]);
      }
      
      setRows((prevRows) => prevRows.filter((_, i) => i !== index));
      setApiRows((prevApiRows) => prevApiRows.filter((_, i) => i !== index));
      setRemainingPositions((prev) => prev + deletedRow.assignedPosition);
    }
  };
  

  const handleInputChange = (index: number, value: number) => {    
    const newRows = [...rows];
    const prevAssigned = newRows[index].assignedPosition;
    const updatedRemaining = remainingPositions - (value - prevAssigned);

    if (updatedRemaining < 0) {
      toast.warning("You can't assign more positions than available!");
      return;
    }
    newRows[index].assignedPosition = value;
    setRows(newRows);
    setRemainingPositions(updatedRemaining);
    
  };


  const handleRecruiterIdChange=(selectedOption:any)=>{
    const newRows = [...rows];
    newRows[selectedOption.index].recruiterId=selectedOption.value;    
    setApiRows(
      newRows.map((row) => ({
        recruiterId: row.recruiterId,
        assignedPosition: row.assignedPosition,
        assignedDate: row.assignedDate,
      }))
    );
  }

  const handleSubmitButton = () => {
    if (isRecruiterAssigned) {            
      editRecruiter(id, rows);
      setReset((prev)=>prev+1);
      setMrfIds([]);
      setView(false);
    } else {      
      if (apiRows.length > 0) {
        console.log("rthId",id)
        console.log("apirows",apiRows)        
        dispatch(assignRecruiter({ rthId: id, apiRows }));
        toast.success("Recruiter Assigned Successfully");
        setReset((prev)=>prev+1);
        setIsRecruiterAssigned(true);
        setView(true);
      } else {
        toast.warning("Assign Atleast 1 Recruiter");
      }
    }
  };

  const handleButtonClick = () => {
    setIsOpen(false);
  };

  const handleCancelButton = () => {
    if (isRecruiterAssigned) {
      setMrfIds([]);
      setReset((prev)=>prev+1);
    } else {
      setIsOpen(true);
      if (view === true) {
        setView(false);
      }
    }
  };

  return (
    <Box>
      {isOpen ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: "16px", color: "#4A4646" }}
              >
                Recruiter Not Yet Assigned
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                sx={{ fontSize: "12px", color: "#8C8C8C" }}
              >
                You’ve got {numOfOpenPosition} positions waiting. Ready to
                assign a recruiter?
              </Typography>
            </Box>
          </Box>

          <Button
            variant="outlined"
            size="small"
            sx={{ border: "1.5px solid" }}
            onClick={handleButtonClick}
          >
            Assign Recruiter
          </Button>
        </Box>
      ) : (
        <Box>
          <Box
            marginBottom={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Box>
              <Typography>
                Total No of Position opened: {numOfOpenPosition}
              </Typography>
            </Box>
            {isRecruiterAssigned && 
            (<Button onClick={handleEditButton}>Edit</Button>)
            }
          </Box>

          <Box
            display={"flex"}
            gap={2}
            p={1}
            sx={{ backgroundColor: "#F9F9F9" }}
          >
            <Typography flexBasis={"20%"} fontSize={15}>
              No. of Open Position
            </Typography>
            <Typography flexBasis={"20%"} fontSize={15}>
              No. of Closed Position
            </Typography>
            <Typography flexBasis={"40%"} fontSize={15}>
              Assign Recruiter
            </Typography>
            <Box
              display={"flex"}
              flexBasis={"30%"}
              alignItems={"center"}
              gap={2}
            >
              <Typography fontSize={15}>Assigned Date</Typography>
              <Typography fontSize={12}>
                assigned date can’t be edited
              </Typography>
            </Box>
          </Box>

          {rows.map((row, index) => (
            <Box
              key={index}
              display="flex"
              alignItems="center"
              gap={2}
              borderBottom="0.5px solid grey"
              pl={1}
              pr={1}
              pt={0}
              pb={0}
              mb={0.8}
            >
              <Box flexBasis={"20%"}>
                <Input
                  type="number"
                  placeholder="Enter No."
                  disableUnderline
                  // disabled={disable}
                  disabled={disable}
                  value={row.assignedPosition || ""}
                  onChange={(e) => {
                    const value = parseInt(e.target.value, 10);
                    handleInputChange(index, value >= 0 ? value : 0);
                  }}
                />
              </Box>
              <Box flexBasis={"20%"}>
                <Input
                  type="number"
                  disableUnderline
                  disabled={true}
                  value={row.closedPosition}
                />
              </Box>
              <Box flexBasis={"40%"}>
                
                <Select
                  options={
                    recruiterData
                    .filter((data) =>                       
                      !rows.some(row => row.recruiterId === data.userId) && data.userId !== row.recruiterId
                    )
                    .map((data) => ({
                    value: data.userId,
                    label: `${data.firstName} ${data.lastName}`,
                    imageUrl: data.imageUrl,
                    designation: data.designation,
                    index:index
                  }))}
                  isDisabled={disable || row.closedPosition > 0}
                  value={
                    row.recruiterId
                      ? recruiterData
                          .map((data) => ({
                            value: data.userId,
                            label: `${data.firstName} ${data.lastName}`,
                            imageUrl: data.imageUrl,
                            designation: data.designation,
                          }))
                          .find((option) => option.value === row.recruiterId)
                      : null
                  }
                  formatOptionLabel={(data) => (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {data.imageUrl ? (
                        <img
                          src={data.imageUrl}
                          alt={data.label}
                          style={{
                            width: 40,
                            height: 40,
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <Avatar
                          alt={data.label}
                          src={data.imageUrl || data.label}
                        />
                      )}
                      <div style={{ marginLeft: 8 }}>
                        <div style={{ fontWeight: "bold" }}>{data.label}</div>
                        <div style={{ fontSize: "0.75rem", color: "#555" }}>
                          {data.designation}
                        </div>
                      </div>
                    </div>
                  )}
                  onChange={handleRecruiterIdChange}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    control: (base) => ({
                      ...base,
                      border: "none", 
                      boxShadow: "none",
                      minHeight: 50,
                      padding: "5px",
                    }),
                    valueContainer: (base) => ({
                      ...base,
                      padding: "0 8px",
                    }),
                  }}
                  menuPosition="absolute"
                />
              </Box>
              <Box
                flexBasis={"30%"}
                display={"flex"}
                alignItems={"center"}
                gap={7}
              >
                <Typography sx={{ color: disable ? "grey" : "black" }}>
                  {row.assignedDate}
                </Typography>
                {(isRecruiterAssigned === false || edit === true) && (
                  <IconButton
                    onClick={() => handleDeleteIconButton(index)}
                    disabled={disable || row.closedPosition > 0}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          ))}
          <Box display={"flex"}>
            {!disable && (
              <>
                <Box flexBasis={"80%"}>
                  <Button
                    onClick={handleAddRowButton}
                    disabled={remainingPositions <= 0}
                  >
                    + Add Row
                  </Button>
                </Box>

                <Box display={"flex"} flexBasis={"20%"} gap={3}>
                  <Button variant="outlined" onClick={handleCancelButton}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleSubmitButton}>
                    Submit
                  </Button>
                </Box>
              </>
            )}
          </Box>
          

        </Box>
      )}
    </Box>
  );
};

export default AssignRecruiterNew;
