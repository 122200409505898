// resumeThunks.ts

import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllResumes } from './resume';


// Define the thunk to fetch all resumes
export const fetchAllResumes = createAsyncThunk(
  'resume/fetchAllResumes', // This is the action type
  async (rthId: any) => {
    try {
      const response = await getAllResumes(rthId); // Call your service function
      const filteredResumes = response?.data?.map((resume: any, index: number) => {
        const fileUrl = resume?.resumeUrl;
        const fileName = fileUrl?.substring(fileUrl?.lastIndexOf('/') + 1, fileUrl?.indexOf('?'));

        return {
          resumeUrl: fileUrl,
          status: resume?.status,
          id: index,
          name: fileName,
          candidateId: resume?.candidateId,
          firstName: resume?.firstName,
          emailAddress: resume?.emailAddress,
          phoneNumber: resume?.phoneNumber,
        };
      });
      return filteredResumes; // Return processed resumes
    } catch (error) {
      return error;
    }
  }
);
