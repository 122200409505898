import { Grid, Typography, Box } from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
// import DropDownIcon from '../../../../../assets/images/dropdown.svg';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { BaseUrl } from '../../../../../constants/Crudurl';

function GaugeChart() {
    const [selectedValue, setSelectedValue] = useState(0);
    const [unselectedValue, setUnselectedValue] = useState(0);
    const [totalValue, setTotalValue] = useState(0);

    const data = useSelector((state: RootState) => state.profile.data);
    const userID = data?.data?.userId;

    useEffect(() => {
        const fetchStatusReport = async () => {
            try {
                const response = await axios.get(
                    `${BaseUrl}/dashboard/selectionRateByRl/${userID}`
                );
                const responseData = response?.data?.data;
                const selected = responseData?.Selected || 0 ;
                const totalCandidates = responseData?.TotalCandidates || 0;
                const unselected = totalCandidates - selected;

                setSelectedValue(selected);
                setUnselectedValue(unselected);
                setTotalValue(totalCandidates);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (userID) {
            fetchStatusReport();
        }
    }, [userID]);

    const statusReport = [
        { label: 'Selected', value: selectedValue, color: '#1677FF' },
        { label: 'Unselected', value: unselectedValue, color: '#D9D9D9' },
    ];

    return (
        <Grid container sx={{ borderRadius: '8px', height: '260px', backgroundColor: '#FFFFFF', padding: '8px', overflow: 'hidden' }}>
            <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: '30px' }}>
                <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: '500', fontSize: '10px' }}>Selection Rate</Typography>
                </Grid>
                {/* <Grid item xs={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '12px' }}>User</Typography>
                    <img src={DropDownIcon} alt="dropdownIcon" style={{ height: '18px', width: '18px', marginLeft: '4px' }} />
                </Grid> */}
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '170px', position: 'relative' }}>
                <Gauge
                    value={selectedValue}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius="60%"
                    outerRadius="100%"
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontSize: 20,
                            transform: 'translate(2px, -8px)',
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: '#1677FF',
                        },
                    }}
                    text={({ value }) => `${value}% `}
                    valueMax={totalValue}
                />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
                {statusReport.map((item, index) => (
                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', margin: '4px', backgroundColor: '#FAFAFA', borderRadius: '8px', padding: '2px 4px' }}>
                        <Box sx={{ width: 6, height: 6, borderRadius: '1px', backgroundColor: item.color, marginRight: '8px' }}></Box>
                        <Typography sx={{ color: '#6B7280', fontSize: '10px', padding: '2px' }}>{item.label}</Typography>
                        <Typography sx={{ color: '#6B7280', fontSize: '10px', fontWeight: 'bold' }}>{item.value}</Typography>
                    </Box>
                ))}
            </Grid>
        </Grid>
    );
}

export default GaugeChart;
