import { Box, Button, Grid, InputAdornment, InputBase, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import FilterSidebar from "../dashboard/commoncomponent/FilterSidebar"
import { useCallback, useEffect, useRef, useState } from "react";
import SearchBar from "../dashboard/commoncomponent/SearchBar";
import SearchIcon from '@mui/icons-material/Search';
import CandidateCard from "../common/CandidateCard";
import { StyledTableCell } from "../dashboard/Rth/rthtable/RthClosedTable";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteCandidate, getResumeRepo } from "../../redux/features/resume";
import pdfImage from '../../assets/images/pdf 1.svg';
import docImage from '../../assets/images/doc 1.svg';
import copyIcon from '../../assets/images/interviewLinkCopyIcon.svg';
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
 

export const ResumeRepo = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [viewType, setViewType] = useState<'grid' | 'list'>('grid');
    const [searchQuery, setSearchQuery] = useState<string>('');
    const[debounceQuery,setDebounceQuery]=useState<string>('');
    // const [candidate, setCandidate] = useState<any>([]);
    const [filteredData, setFilteredData] = useState<any>([]);

    // const hasListener = useRef(false);
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null); // Debounce timeout initialized correctly

    const dispatch=useAppDispatch();
    const [page, setPage] = useState(0); // Current page
    const hasMore = true;
    const size=12; // Items per page 
    const scrollPosition = useRef(0);    
    const scrollContainerRef = useRef<HTMLDivElement>(null); 
    const [tooltipText, setTooltipText] = useState<string>("Copy");
    const [searchState, setSearchState] = useState(false); // New state to track search state
    const resumeRepoData=useAppSelector((state)=>state.resume.resumeRepo);
    const [hoveredRow, setHoveredRow] = useState<number | null>(null);
    const[tabName,setTabName]=useState('');
    const [teamHeadFilterDto, setTeamHeadFilterDto] = useState({
        l1StatusPending: false,
        l1StatusDeclined: false,
        l2StatusApproved: false,
        internalOnRole: false,
        internalContract: false,
        externalOneTime: false,
        externalContract: false,
        priorityLow: false,
        priorityMedium: false,
        priorityHigh: false,
        rthStages: null,
        createdBy: null,
        recruiterLead: null,
        PositionTitle: null,
        fromYear: 0,
        fromMonth: 0,
        fromDay: 0,
        toYear: 0,
        toMonth: 0,
        toDay: 0,
        page: 0,
        size: 10
      });
   const navigate=useNavigate();
      
const handleViewChange = (view: 'grid' | 'list') => {
    setViewType(view);
    setSearchQuery('');
    setPage(0);
    setDebounceQuery('');
  };
    
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setSearchQuery(event.target.value);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current); // Clear previous timeout
    }

    debounceTimeout.current = setTimeout(() => {
      setDebounceQuery(event.target.value);
    }, 5000);
  };
const filterDate=useAppSelector((state)=>state.teamHeadFilter)
const{fromDate,toDate}=filterDate;
const filterData=useAppSelector((state)=>state.filter);
const{resumeEducation,resumeExperience
  ,resumeInterviewStatus,resumeLanguage,resumePositionTitle
  ,resumeskillSets,resumeCurrentSalary,resumeExpectedSalary,
  resumeSource,resumeNoticePeriod,resumeExpectedCtc
}=filterData;

const handleScroll = useCallback(() => {
  if (!scrollContainerRef.current) return;

  const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;

  // Trigger the API call only if scrolling downward and near the bottom
  if (scrollTop > scrollPosition.current) {
    if (scrollTop + clientHeight >= scrollHeight - 100 && hasMore && page >= 0) {
      setPage((prevPage) => prevPage + 1);
    }
  }

  // Update the previous scroll position
  scrollPosition.current = scrollTop;
},[hasMore, page, scrollContainerRef, scrollPosition]);
  
const normalizePayloadValue = (value: any) => {
  if (Array.isArray(value)) {
    return value.length > 0 ? value : null;
  }
  if (value instanceof Date) {
    // Return the date in ISO string format (YYYY-MM-DDTHH:mm:ss)
    return value.toISOString().split('T')[0] + 'T00:00:00' || null; // Adjust to 00:00:00 for time
  }
  return value || null;
};


const normalizedPayload = {
  from: normalizePayloadValue(fromDate) || null,
  to: normalizePayloadValue(toDate) || null,
};

console.log(normalizedPayload);


const normalizeSalaryValue = (value: any) => {
  // If the value is a string and can be converted to a number, convert it to an array with that number
      
  if (value == null || value === '') {
    return null;
  }

  if (typeof value === 'string' && !isNaN(Number(value))) {
      return [Number(value)];
  }
  // Otherwise, return the value as is (or null if the value is not valid)
  return value||null;
};

const tableHead = [
    "SI.No",
    "Profile Details",
    "Email ID",
    "Mobile Number",
    "Resume",
    "Arrived Status",
    "Interview Status",
    "Action",
  ];


const getFileName = (url: any) => {
    const fileNameWithParams = url?.split("/").pop(); // Get the last part of the URL
    const fileName = fileNameWithParams?.split("?")[0]; // Remove query params
    return fileName;
  };
  
  const getFileIcon = (fileName: string) => {
    const extension = fileName?.split('.').pop()?.toLowerCase();
    if (extension === 'pdf') {
      return <img src={pdfImage} alt="PDF Icon" style={{ width: '12px', height: '12px' }} />;
    }
    if(extension==='doc'||extension==='docx'){
      return <img src ={docImage} alt="DOC Icon" style={{width:'16px',height:'16px'}}/>;
    }
    return null;
  };

  const getTruncatedFileName = (fileName: string) => {
    const extension = fileName?.split('.').pop(); // Get the file extension
    const nameWithoutExtension = fileName?.substring(0, 4); // Get the first 4 characters of the file name
    return `${nameWithoutExtension}.${extension}`; // Return the truncated file name with extension
  };

//   const getSalaryValue = (view: string, resumeExpectedSalary: any, resumeExpectedCtc: any) => {
//     // Based on the view type, return either resumeExpectedSalary or resumeExpectedCtc
//     if (view === "grid") {
//         return normalizeSalaryValue(resumeExpectedSalary) || null;
//     } else if (view === "list") {
//         return normalizeSalaryValue(resumeExpectedCtc) || null;
//     }
//     return null; // Return null if view is not "grid" or "list"
// };

  const handleDeleteCandidate = (id: any) => {
    // Dispatch the delete candidate action
    dispatch(deleteCandidate(id))
      .then((response: any) => {
        if (response?.payload?.status === true) {
          // Show the success toast message
          toast.success('Candidate deleted successfully');

          const datapayload = {
            from: null,
            to: null,
            experienceLevel: null,
            skillsSet: null,
            education: null,
            language: null,
            interviewStatus: null,
            positionTitle: null,
            expectedSalary: null,
            currentSalary: null,
            page: page,
            size: size
          };
  
          dispatch(getResumeRepo({ search: searchQuery, data: datapayload }));
        }
      })
      .catch((error: any) => {
        toast.error('Failed to delete candidate');
      });
  };
 
 
  const handleCopyEmail = (email: string) => {
    navigator.clipboard.writeText(email)
      .then(() => {
        setTooltipText("Copied");
        setTimeout(() => setTooltipText("Copy"), 2000); // Reset to "Copy" after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy email address: ", err);
      });
  };

  const handleRowClick=(candidateId:any,firstName:any,lastName:any,rthName:any,rthId:any)=>{
    const fullName = lastName ? `${firstName} ${lastName}` : firstName;
    console.log("elakkia or sughanthan pannidunga")
    navigate(`/newRequestToHire/${rthName}/${rthId}/candidate/${fullName}/${candidateId}`)
  }

  const formatDate = (date: string | null): string | null => {
    if (date) {
      const formattedDate = new Date(date);
      if (!isNaN(formattedDate.getTime())) {
        return formattedDate.toISOString().split('T')[0] + 'T00:00:00'; // Format as "YYYY-MM-DDT00:00:00"
      }
    }
    return null;
  };
  
useEffect(()=>{
  const datapayload={
    "from": null,
    "to": null,
    "experienceLevel": null,
    "skillsSet": null,
    "education": null,
    "language": null,
    "interviewStatus": null,
    "positionTitle": null,
    "expectedSalary":null,
    "currentSalary": null,
    "page":page,
    "size":size
  }
   dispatch(getResumeRepo({ search: searchQuery, data: datapayload }));
},[dispatch,page,size,searchQuery,viewType])

useEffect(() => {
  if (resumeRepoData?.length > 0) {
    if (searchState) {
      setFilteredData(resumeRepoData);
    } else {
      setFilteredData((prevData:any) => [
        ...prevData,
        ...resumeRepoData.filter(
          (item:any) =>
            !prevData.some(
              (prevItem:any) =>
                prevItem.candidateLogInList?.candidateId ===
                item.candidateLogInList?.candidateId
            )
        ),
      ]);
    }
  } else if (page === 0) {
    setFilteredData([]);
  }
}, [resumeRepoData, searchState,page]);

useEffect(()=>{
  const getSalaryValue = (view: string, resumeExpectedSalary: any, resumeExpectedCtc: any) => {
    // Based on the view type, return either resumeExpectedSalary or resumeExpectedCtc
    if (view === "grid") {
        return normalizeSalaryValue(resumeExpectedSalary) || null;
    } else if (view === "list") {
        return normalizeSalaryValue(resumeExpectedCtc) || null;
    }
    return null; // Return null if view is not "grid" or "list"
};
  const datapayload={
    "from": formatDate(fromDate)||null,
    "to": formatDate(toDate)||null,
    "experienceLevel": normalizePayloadValue(resumeExperience) ||null,
    "skillsSet":normalizePayloadValue(resumeskillSets)||null,
    "education": normalizePayloadValue(resumeEducation)||null,
    "language": normalizePayloadValue(resumeLanguage)||null,
    "interviewStatus": normalizePayloadValue(resumeInterviewStatus)||null,
    "positionTitle": normalizePayloadValue(resumePositionTitle)||null,
    "expectedSalary": getSalaryValue(viewType, resumeExpectedSalary, resumeExpectedCtc),
    "currentSalary": normalizeSalaryValue(resumeCurrentSalary)||null,
    "source":normalizePayloadValue(resumeSource)||null,
    "noticePeriod":normalizePayloadValue(resumeNoticePeriod)||null,
    "page":page,
    "size":size
  }
   dispatch(getResumeRepo({ search: searchQuery, data: datapayload }));
},[fromDate,toDate,resumeExperience,resumeSource,resumeNoticePeriod,viewType,
  resumeEducation,resumeLanguage,resumeInterviewStatus,resumePositionTitle,resumeExpectedSalary,resumeCurrentSalary,dispatch,searchQuery,page,resumeskillSets,resumeExpectedCtc])

useEffect(() => {
  const scrollContainer = scrollContainerRef.current;
  if (scrollContainer) {
    scrollContainer.addEventListener("scroll", handleScroll);
  }
  return () => {
    if (scrollContainer) {
      scrollContainer.removeEventListener("scroll", handleScroll);
    }
  };
}, [hasMore,handleScroll]);

useEffect(()=>{
if(viewType==='grid'){
setTabName('gridResumeRepo')
}
else if(viewType==='list'){
  setTabName('listResumeRepo')
}
},[viewType,tabName])

useEffect(() => {
  // Update `searchState` based on the presence of values in the specified fields
  if (
    searchQuery || 
    fromDate || 
    toDate || 
    resumeExperience.length > 0 || 
    resumeEducation.length > 0 || 
    resumeLanguage.length > 0 || 
    resumeInterviewStatus.length > 0 || 
    resumePositionTitle.length > 0 || 
    resumeExpectedSalary.length > 0 || 
    resumeCurrentSalary.length > 0 || 
    resumeskillSets.length > 0
  ) {
    setSearchState(true); // Set to true if any of the fields have values
  } else {
    setSearchState(false); // Otherwise, set to false
  }
}, [
  searchQuery, 
  fromDate, 
  toDate, 
  resumeExperience, 
  resumeEducation, 
  resumeLanguage, 
  resumeInterviewStatus, 
  resumePositionTitle, 
  resumeExpectedSalary, 
  resumeCurrentSalary, 
  resumeskillSets
]);

console.log(debounceQuery,'debounceQuery');

  return (
    <Box sx={{ width: '100%', backgroundColor: '#f0f4ff', display: 'flex', position: 'relative', flexDirection:'row',
      "&::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
              display:'none'
            },
     }} >    
    <Box sx={{height:'80vh'}}>    
    <FilterSidebar onChangeFilter={setDrawerOpen} drawerOpen={drawerOpen} tabName={tabName} teamHeadFilterDto={teamHeadFilterDto} setTeamHeadFilterDto={setTeamHeadFilterDto}/>
    </Box>
    <Box sx={{ width: '100%', transition: 'margin-left 0.3s' }}>
    <Box sx={{ position:'sticky'}}>
        <Box sx={{mt:'20px',ml:'90px'}}>
    <Grid item position="absolute">
                <InputBase
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search Profile..."
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                        </InputAdornment>
                    }
                    sx={{
                        border: '1px solid #ccc', 
                        borderRadius: 1,
                        padding: 0,
                        backgroundColor: 'white',
                        minHeight: '3px',
                        fontSize: 14,
                        '.MuiInputBase-input': {
                            padding: '5px 5px',
                        },
                    }}
                />
            </Grid>
            </Box>
    <SearchBar viewType={viewType} handleViewChange={handleViewChange} pageType={'Resume'} />
    {/* <Box sx={{display:'flex',flexDirection:'row',justifyContent:'flex-end'}}>
    <img src={chatIcon} alt="chat icon" style={{cursor:'pointer'}} onClick={handleChat}/>
    </Box> */}
    </Box>
    {viewType === "grid" ? (
        <Box
         ref={scrollContainerRef}
          sx={{
            ml: "20px",
            mt: "0",
            mb: 3,
            height: 510,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
              display:'none'
            },
          }}
        >
          {filteredData?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}
            >
              <Typography sx={{fontWeight:600}}>Candidate not Assigned</Typography>
            </Box>
          ) : (
            <Box
            sx={{
              p: 2,
              height: {
                xs: '60vh',  // Small screens, including mobile
                sm: '50vh',  // Small laptops
                 md: '68vh',  // Medium to large screens
                //  lg: '70vh',  // Extra-large screens
              },
              overflowY: 'auto',
            }}
          >
            <Grid container>
              {filteredData?.map((candidate: any) => {
                    const candidateData = candidate?.candidateLogInList; // Extract candidateLogInList
                return(
                <Grid item key={candidateData?.id} sx={{"&::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
              display:'none'
            },}}>
                  <CandidateCard
                    name={candidateData?.firstName}
                    lastName={candidateData?.lastName}
                    status={candidateData?.status}
                    daysAgo={candidateData?.daysAgo}
                    phone={candidateData?.phoneNumber}
                    email={candidateData?.emailAddress}
                    pdfLink={candidateData?.resumeUrl || ""}
                    schedule={candidateData?.interviewStatus}
                    candidate={candidate}
                    drawerOpen={drawerOpen}
                  />
                </Grid>
              )
            })}
            </Grid>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }} p={1}
        ref={scrollContainerRef}
        >
          {filteredData?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}
            >
              <Typography sx={{fontWeight:600}}>Table not Assigned</Typography>
            </Box>
          ) : (
            <Box sx={{ width: "100%", overflowX: "auto" ,height:'60vh'}}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="MRF table">
                  <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                    <TableRow>
                      {tableHead?.map((data, index) => (
                        <StyledTableCell key={index}>{data}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData?.map((data: any, index: number) => {
                       const candidateData = data?.candidateLogInList; // Extract candidateLogInList
                    return(
                      <TableRow key={index} sx={{cursor:'pointer'}} onClick={()=>handleRowClick(candidateData?.candidateId,candidateData.firstName,candidateData?.lastName,data?.rthName,candidateData?.rthId)} >
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{candidateData?.firstName}</StyledTableCell>
                        <StyledTableCell>
          <Box
            sx={{ position: 'relative' }}
            onMouseEnter={() =>setHoveredRow(index)} // When mouse enters the email area
            onMouseLeave={() =>setHoveredRow(null) } // When mouse leaves
          >
            <span>{candidateData?.emailAddress}</span>
            {hoveredRow===index && (
                <Tooltip title={tooltipText} arrow>
              <img
                src={copyIcon}
                alt="Copy Icon"
                style={{
                  position: 'absolute',
                  right: 0,
                  cursor: 'pointer', 
                }}
                onClick={() => handleCopyEmail(candidateData?.emailAddress)}
              />
              </Tooltip>
            )}
          </Box>
        </StyledTableCell>
                        <StyledTableCell>{candidateData?.phoneNumber}</StyledTableCell>
                        <StyledTableCell>
                        <Button
                          variant="outlined"
                          disabled
                          startIcon={getFileIcon(getFileName(candidateData?.resumeUrl))}
                          sx={{
                            width: '100px',
                            height: '24px',                                                              
                            color: '#888888',                                  
                            fontSize: '11px',                                                                                                 
                            border: '1px solid #DEDEDE',                                                                                                  
                          }}
                        >
                          {getTruncatedFileName(getFileName(candidateData?.resumeUrl))}
                        </Button>
                        </StyledTableCell>
                        <StyledTableCell>{candidateData?.createdAt}</StyledTableCell>
                        <StyledTableCell>{candidateData?.interviewStatus}</StyledTableCell>
                        <StyledTableCell>
                          <GridDeleteIcon sx={{cursor:'pointer'}} onClick={()=>handleDeleteCandidate(candidateData?.candidateId)} />
                        </StyledTableCell>
                      </TableRow>
                    )})}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </Box>
    </Box>
  )
}
