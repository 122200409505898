import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BaseUrl } from '../../constants/Crudurl';

export const getFileUrl=createAsyncThunk('resume/getFileUrl',
    async(data:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.post(`${BaseUrl}/rth/addAttachment`,data,
                {
                    headers:{
                        Authorization:`Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch(error:any){
            return error.response.data
        }
    }
);

export const fileBulkUpload=createAsyncThunk('resume/fileBulkUpload',
    async({ data, rthId,recId}: { data: FormData; rthId: any,recId:any })=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.post(`${BaseUrl}/candidate/addBulkCandidates/${rthId}/${recId}`,data,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        } catch(error:any){
            return error.response.data
        }
    }
);

export const getAllResumes=
    async(rthId:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/candidate/getCandidateByRthId/${rthId}`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        } catch(error:any){
            return error.response.data
        }
    }
export const addCandidate=createAsyncThunk('resume/addCandidate',
    async(data:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.post(`${BaseUrl}/candidate/addCandidate`,data,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        } catch(error:any){
            return error.response.data;
        }
    }
)

export const getNationality=createAsyncThunk('resume/getNationality',
    async()=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/master/getNationality`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const getCitizenship=createAsyncThunk('resume/getNationality',
    async()=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/master/getCitizenship`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const getCountry=createAsyncThunk('resume/getCountry',
    async()=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/master/getCountries`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const getCity=createAsyncThunk('resume/getCity',
    async()=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/master/getCities`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );            
            return response?.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const getState=createAsyncThunk('resume/getState',
    async()=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/master/getStates`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );            
            return response?.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const getSkill=createAsyncThunk('resume/getSkill',
    async ({ search = '', page = 0, size = 10 }: { search?: string; page?: number; size?: number }) => {
        try{
            
            const response=await axios.get(`${BaseUrl}/master/getSkillList?page=${page}&size=${size}&search=${search}`,
                
            );            
            return response?.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const getCandidateByCandidateId=createAsyncThunk('resume/getCandidateByCandidateId',
    async(candidateId:any)=>{
        try{
            const response=await axios.get(`${BaseUrl}/candidate/getCandidateByCandidateId/${candidateId}`,               
            );
            return response?.data;
        }catch(error:any){
          return error.response.data;
        }
    }
)

export const editCandidate=createAsyncThunk('resume/editCandidate',
    async({candidateId,data}:any)=>{
        
        try{
            const token=localStorage.getItem('token');
            const response=await axios.put(`${BaseUrl}/candidate/editCandidate/${candidateId}`,data,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response?.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const getL1Approvers=createAsyncThunk('resume/getL1Approvers',
    async(search:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/master/getL1Approvers?search=${search}`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const getRthDetailsByRthId=createAsyncThunk('resume/getRthDetailsByRthId',
    async(rthId:any)=>{
        const response=await axios.get(
            `${BaseUrl}/rth/viewRthByRthId/${rthId}`,
          ); 
        return response.data;
    }
)

export const setInterviewStatus=createAsyncThunk('resume/setInterviewStatus',
    async({candidateId,recruiterId,status}:any)=>{
        const response=await axios.put(`${BaseUrl}/candidate/setInterviewStatus/${recruiterId}/${candidateId}?status=${status}`);
        return response.data;
    }
)

export const getInterviewStatus=createAsyncThunk('resume/getInterviewStatus',
    async()=>{
        const response=await axios.get(`${BaseUrl}/master/getInterviewStatus`);
        return response.data;
    }
)

export const getResumeRepo=createAsyncThunk('resume/getResumeRepo',
    async({search,data}:any)=>{
        const response=await axios.post(`${BaseUrl}/resumeRepo/getOverAllResumes?search=${search}`,data);
        return response.data;
    }
)

export const getResumeUrlAndData=createAsyncThunk('resume/getResumeUrlAndData',
    async({data,rthId}:any)=>{
        try{
          
            const response=await axios.post(`${BaseUrl}/candidate/fetchData?rthId=${rthId}`,data
            );
            return response?.data;
        } catch(error:any){
            return error.response.data;
        }
    }
);

export const deleteCandidate=createAsyncThunk('resume/deleteCandidate',
    async(candidateId:any)=>{
        const response=await axios.delete(`${BaseUrl}/candidate/deleteCandidateById/${candidateId}`);
        return response.data;
    }
);

export const getQualification=createAsyncThunk('resume/getQualification',
    async()=>{
        const response=await axios.get(`${BaseUrl}/master/getQualificationList`);
        return response.data;
    }
)