import {
  Box,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import CandidateCard from "../../../common/CandidateCard";
import pdfImage from "../../../../assets/images/pdf 1.svg"
import {
  deleteCandidateById,
  getCandidateByRthIdandRecruiterId,
} from "../../../../redux/features/candidate";
import {  useNavigate, useParams } from "react-router-dom";
import { StyledTableCell } from "./RthClosedTable";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import SearchIcon from '@mui/icons-material/Search';
import { calculateDaysForCandidateCard } from "../../../../helpers/calculateDays";

export const CandidateDetails = ({ viewType, drawerOpen, handleFirstStep }: any) => {
  const [candidate, setCandidate] = useState<any>([]);
  const { id,jobTitle } = useParams();
  // const [loading, setLoading] = useState<boolean>(false);
  // const [candidateIdd, setCandidateId] = useState<string>("");
  const [searchQuery, setSearchQuery] = useState<string>('');
  const[page,setpage]=useState(0);
  const filterDate=useAppSelector((state)=>state.teamHeadFilter);
  const filterData=useAppSelector((state)=>state.resumeInfo);
  const{candidateExpectedCtc,candidateInterviewStage,candidateNoticePeriod,candidateSource,selectedStatuses}=filterData;
  const{fromDate,toDate}=filterDate;
  const candidateDetails=useAppSelector((state)=>state.resume.allCandidates);
  console.log("candidateDetailsss",candidateDetails);
  const dispatch=useAppDispatch();
  const recruiterId=localStorage.getItem('userId');
  const scrollPosition = useRef(0);    
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const hasMore = true; 
  const [searchState, setSearchState] = useState(false);
  const navigate=useNavigate();
  
  const extractValues = (items: { label: string; value: string }[] | null | undefined) => {
    if (!items || items.length === 0) {
      return null;
    }
    return items.map(item => item.value);
  };
  
  const normalizePayloadValue = (value:any) => {
    if (Array.isArray(value)) {
      return value.length > 0 ? value : null;
    }
    return value || null;
  };

  const normalizeSalaryValue = (value: any) => {
    // If the value is a string and can be converted to a number, convert it to an array with that number
        
    if (value == null || value === '') {
      return null;
    }
  
    if (typeof value === 'string' && !isNaN(Number(value))) {
        return [Number(value)];
    }
    // Otherwise, return the value as is (or null if the value is not valid)
    return value||null;
  };

  const Md = useMediaQuery("(max-width: 1504px)");
  
  const handleSearchChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
    setpage(0);
     setSearchQuery(event.target.value);
}
    
const handleScroll = useCallback(() => {
  if (!scrollContainerRef.current) return;

  const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;

  // Trigger the API call only if scrolling downward and near the bottom
  if (scrollTop > scrollPosition.current) {
    if (scrollTop + clientHeight >= scrollHeight - 100 && hasMore && page >= 0) {
      setpage((prevPage) => prevPage + 1);
    }
  }

  // Update the previous scroll position
  scrollPosition.current = scrollTop;
},[hasMore, page, scrollPosition, scrollContainerRef]);
  
  const handleDeleteButton = async (candidateId: string) => {
    // await setCandidateId(candidateId);

    await deleteCandidateById(candidateId);

    // setCandidateId("");
    toast.success("Deleted Sucessfully");
    const filterPayload={     
      "resumesPostedInFrom":normalizePayloadValue(fromDate)||null,      
      "resumesPostedInTo":normalizePayloadValue(toDate)||null,      
      "interviewStages":normalizePayloadValue(candidateInterviewStage)||null,      
      "source":normalizePayloadValue(candidateSource)||null,      
      "expectedCtc":normalizeSalaryValue(candidateExpectedCtc)||null,      
      "noticePeriod": normalizePayloadValue(candidateNoticePeriod)||null,      
      "status":extractValues(selectedStatuses)||null,
      "page":page,
      "size":20
    }
  dispatch(getCandidateByRthIdandRecruiterId({rthId:id,recId:recruiterId,data:filterPayload,searchData:searchQuery}))
  };

  const tableHead = [
    "SI.No",
    "Profile Details",
    "Email ID",
    "Mobile Number",
    "Resume",
    "Arrived Status",
    "Interview Status",
    "Action",
  ];

  const getFileName = (url: string): string => {
    const fileNameWithParams = url?.split("/").pop() || ""; // Get the last part of the URL
    const fileName = fileNameWithParams?.split("?")[0]; // Remove query params

    // Truncate if the name exceeds 32 characters
    return fileName?.length > 32 ? `${fileName?.slice(0, 29)}...` : fileName;
  };
    

  const handleRowClick=(candidateId:any,firstName:any,lastName:any)=>{
    const fullName = lastName ? `${firstName} ${lastName}` : firstName;
    // console.log("elakkia or sughanthan pannidunga")
    navigate(`/newRequestToHire/${jobTitle}/${id}/candidate/${fullName}/${candidateId}`)
  }
  
  useEffect(()=>{
    if(searchQuery?.length>0||fromDate||toDate||candidateExpectedCtc.length>0||
      candidateInterviewStage.length>0||candidateSource.length>0||candidateNoticePeriod.length>0||
      selectedStatuses.length>0
    ){
      setSearchState(true);
    }
    else{
      setSearchState(false);
    }
  },[searchQuery,fromDate,toDate,candidateInterviewStage,candidateSource,
    candidateExpectedCtc,candidateNoticePeriod,selectedStatuses
  ]);

  useEffect(()=>{
    // setLoading(true);
    const filterPayload={     
        "resumesPostedInFrom":normalizePayloadValue(fromDate)||null,      
        "resumesPostedInTo":normalizePayloadValue(toDate)||null,      
        "interviewStages":normalizePayloadValue(candidateInterviewStage)||null,      
        "source":normalizePayloadValue(candidateSource)||null,      
        "expectedCtc":normalizeSalaryValue(candidateExpectedCtc)||null,      
        "noticePeriod": normalizePayloadValue(candidateNoticePeriod)||null,      
        "status":extractValues(selectedStatuses)||null,
        "page":page,
        "size":20
      }
    dispatch(getCandidateByRthIdandRecruiterId({rthId:id,recId:recruiterId,data:filterPayload,searchData:searchQuery}))
  },[fromDate,toDate,candidateInterviewStage,candidateSource,candidateExpectedCtc,candidateNoticePeriod,selectedStatuses,page,searchQuery,dispatch,id,recruiterId])

  useEffect(()=>{
    if(candidateDetails?.length>0){
      if(searchState){
      setCandidate(candidateDetails)
      } else{
        setCandidate((prevData:any)=>[
          ...prevData,
          ...candidateDetails.filter(
           (item:any)=>
            !prevData.some(
              (prevItem:any)=>
              prevItem.candidateId===item.candidateId
            ),
          ),
        ]);
      }
    }
    if(searchState){
      setCandidate(candidateDetails);
    }
   },[candidateDetails,searchState])
  

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [hasMore,handleScroll]);

  useEffect(()=>{
    setSearchQuery('');
  },[viewType])

  useEffect(()=>{
    const filterPayload={     
      "resumesPostedInFrom":null,      
      "resumesPostedInTo":null,      
      "interviewStages":null,      
      "source":null,      
      "expectedCtc":null,      
      "noticePeriod":null,      
      "status":null,
      "page":0,
      "size":20
    }
    // setLoading(true);
  dispatch(getCandidateByRthIdandRecruiterId({rthId:id,recId:recruiterId,data:filterPayload,searchData:''}))
  // setLoading(false);

  },[dispatch,id,recruiterId])
 
  return (
    <>
      {viewType === "grid" ? (
        <Box
        ref={scrollContainerRef}
          sx={{
            ml: "20px",
            mt: "0",
            mb: 3,
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
            },
          }}
        >           
              <>
           {candidate?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
             No Candidate Available
              </Typography>
            </Box>
          ) : (
            <>
            <Box sx={{ mt:'-55px', ml: '20px'}}>
                <Grid item position="absolute">
                  <InputBase
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search Profile..."
                    startAdornment={<InputAdornment position="start">
                      <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                    </InputAdornment>}
                    sx={{
                      border: '1px solid #ccc',
                      borderRadius: 1,
                      padding: 0,
                      backgroundColor: 'white',
                      minHeight: '3px',
                      fontSize: 14,
                      '.MuiInputBase-input': {
                        padding: '5px 5px',
                      },
                    }} />
                </Grid>
              </Box>  
            <Grid container sx={{mt:'70px', height: Md? "260px": "400px "}}>
                  {candidate.map((candidate: any) => (
                    <Grid item key={candidate.id}>
                      <CandidateCard
                        name={candidate?.firstName}
                        lastName={candidate?.lastName}
                        status={calculateDaysForCandidateCard(candidate?.createdAt)}
                        daysAgo={candidate?.daysAgo}
                        phone={candidate?.phoneNumber}
                        email={candidate?.emailAddress}
                        pdfLink={candidate?.resumeUrl || ""}
                        schedule={candidate?.interviewStatus}
                        candidate={candidate}
                        drawerOpen={drawerOpen} />
                    </Grid>
                  ))}
                </Grid></>
          )}
          </>
        </Box>
      ) : (
        <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }} p={1}>
          {candidate.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50%",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                Candidate not Assigned
              </Typography>
            </Box>
          ) : (
            <Box sx={{ width: "100%", overflowX: "auto" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="MRF table">
                  <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                    <TableRow
                      sx={{
                        "& th": {
                          padding: "4px 8px",
                          fontSize: "14px",
                        },
                      }}
                    >
                      {tableHead.map((data, index) => (
                        <StyledTableCell key={index}>{data}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody                 sx={{
                  "& td, & th": {
                    padding: "4px 8px", // Adjust padding here
                  },
                }}>
                    {candidate?.map((data: any, index: number) => (
                      <TableRow
                      onClick={()=>handleRowClick(data?.candidateId,data?.firstName,data?.lastName)}
                        key={index}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: "rgba(0, 0, 0, 0.1)", // Example color
                          },
                        }}
                      >
                        <StyledTableCell>{index + 1}</StyledTableCell>
                        <StyledTableCell>{data?.firstName}</StyledTableCell>
                        <StyledTableCell>{data?.emailAddress}</StyledTableCell>
                        <StyledTableCell>
                          
                          {data.phoneNumber}
                          </StyledTableCell>
                        <StyledTableCell>
                        <img src={pdfImage} alt="" />
                          {getFileName(data?.resumeUrl)}
                        </StyledTableCell>
                        <StyledTableCell>{data.createdAt}</StyledTableCell>
                        <StyledTableCell>
                          <Chip
                            label={data?.interviewStatus}
                            size="small"
                            sx={{
                              fontSize: "12px",
                              height: "px",
                              borderRadius: "1px",
                              backgroundColor: "#1677FF1A",
                              color: "#1677FF",
                            }}
                          />
                        </StyledTableCell>
                        <StyledTableCell>
                          <IconButton
                          size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteButton(data?.candidateId)
                            }}
                          >
                            <GridDeleteIcon sx={{ color: "grey" }} />
                          </IconButton>
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
