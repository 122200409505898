import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableChart from "./table";
import Filter from "./filter";
import { useNavigate } from 'react-router-dom';
 
const OfferApproval: React.FC = () => {
  const [filters, setFilters] = useState<string[]>([]);
 
  const navigate = useNavigate();
 
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/', { replace: true });
        }
    }, [navigate]);
 
  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
      <Box>
      <Filter onFilterChange={setFilters} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", mr:2 }}>
      <TableChart filters={filters} />
      </Box>
    </Box>
  );
};
 
export default OfferApproval;