// jobcardSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import {BaseUrl} from "../../constants/Crudurl"

interface TeamHeadFilterDto {
  l1StatusPending: boolean;
  l1StatusDeclined: boolean;
  l2StatusApproved: boolean;
  internalOnRole: boolean;
  internalContract: boolean;
  externalOneTime: boolean;
  externalContract: boolean;
  priorityLow: boolean;
  priorityMedium: boolean;
  priorityHigh: boolean;
  rthStages: null;
  createdBy: null;
  recruiterLead: null;
  PositionTitle: null;
  fromYear: number;
  fromMonth: number;
  fromDay: number;
  toYear: number;
  toMonth: number;
  toDay: number;
  page: number;
  size: number;
}

interface FetchParams {
  userId: string;
  teamHeadFilterDto: TeamHeadFilterDto;
}

export const getMyRequests = createAsyncThunk(
    'jobs/fetchMyRequests',
    async (_, { rejectWithValue }) => {
      try {
        const token = localStorage.getItem('token');
        const userId = localStorage.getItem('userId'); // Ensure user ID is stored

        const response = await axios.get(
          `${BaseUrl}/homePage/myRequest/${userId}`, 
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.data;
        
      } catch (error: any) {
        return rejectWithValue(
          error.response?.data || 
          { message: 'Failed to fetch requests' }
        );
      }
    }
);

export const fetchOverallRTH = createAsyncThunk(
  'jobs/fetchOverallRTH',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/overall/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

export const fetchMyRequests = createAsyncThunk(
  'jobs/fetchMyRequests',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/my-requests/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

export const fetchInProgress = createAsyncThunk(
  'jobs/fetchInProgress',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/in-progress/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

export const fetchClosed = createAsyncThunk(
  'jobs/fetchClosed',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/closed/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

export const fetchToBeAssigned = createAsyncThunk(
  'jobs/fetchToBeAssigned',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/to-be-assigned/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

export const fetchAssignedRTH = createAsyncThunk(
  'jobs/fetchAssignedRTH',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/assigned/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

export const fetchWaitingToMoveToJob = createAsyncThunk(
  'jobs/fetchWaitingToMoveToJob',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/waiting-to-move/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

export const fetchReadyForJDCreation = createAsyncThunk(
  'jobs/fetchReadyForJDCreation',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/ready-for-jd/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

export const fetchJobPost = createAsyncThunk(
  'jobs/fetchJobPost',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/job-post/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

export const fetchResume = createAsyncThunk(
  'jobs/fetchResume',
  async ({ userId, teamHeadFilterDto }: FetchParams) => {
    const response = await axios.post(`${BaseUrl}/rth/resume/${userId}`, teamHeadFilterDto);
    return response.data;
  }
);

interface JobState {
  overAllRth: any[];
  myRequest: any[];
  inProgress: any[];
  closed: any[];
  tobeAssigned: any[];
  asignedRTh: any[];
  WaitingToMoveToJob: any[];
  ReadyForJDCreation: any[];
  JobPost: any[];
  Resume: any[];
  loading: boolean;
  error: string | null;
  myRquestCounts: any;
}

const initialState: JobState = {
  overAllRth: [],
  myRequest: [],
  inProgress: [],
  closed: [],
  tobeAssigned: [],
  asignedRTh: [],
  WaitingToMoveToJob: [],
  ReadyForJDCreation: [],
  JobPost: [],
  Resume: [],
  loading: false,
  error: null,
  myRquestCounts: null,
};

const cardSlice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Overall MRF
      .addCase(fetchOverallRTH.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOverallRTH.fulfilled, (state, action) => {
        state.loading = false;
        state.overAllRth = action.payload;
      })
      .addCase(fetchOverallRTH.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch overall MRF';
      })

      // My Requests
      .addCase(fetchMyRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.myRequest = action.payload;
      })
      .addCase(fetchMyRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch my requests';
      })

      // In Progress
      .addCase(fetchInProgress.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchInProgress.fulfilled, (state, action) => {
        state.loading = false;
        state.inProgress = action.payload;
      })
      .addCase(fetchInProgress.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch in progress';
      })

      // Closed
      .addCase(fetchClosed.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchClosed.fulfilled, (state, action) => {
        state.loading = false;
        state.closed = action.payload;
      })
      .addCase(fetchClosed.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch closed';
      })

      // To Be Assigned
      .addCase(fetchToBeAssigned.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchToBeAssigned.fulfilled, (state, action) => {
        state.loading = false;
        state.tobeAssigned = action.payload;
      })
      .addCase(fetchToBeAssigned.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch to be assigned';
      })

      // Assigned MRF
      .addCase(fetchAssignedRTH.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAssignedRTH.fulfilled, (state, action) => {
        state.loading = false;
        state.asignedRTh = action.payload;
      })
      .addCase(fetchAssignedRTH.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch assigned MRF';
      })

      // Waiting To Move To Job
      .addCase(fetchWaitingToMoveToJob.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchWaitingToMoveToJob.fulfilled, (state, action) => {
        state.loading = false;
        state.WaitingToMoveToJob = action.payload;
      })
      .addCase(fetchWaitingToMoveToJob.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch waiting to move to job';
      })

      // Ready For JD Creation
      .addCase(fetchReadyForJDCreation.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchReadyForJDCreation.fulfilled, (state, action) => {
        state.loading = false;
        state.ReadyForJDCreation = action.payload;
      })
      .addCase(fetchReadyForJDCreation.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch ready for JD creation';
      })

      // Job Post
      .addCase(fetchJobPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobPost.fulfilled, (state, action) => {
        state.loading = false;
        state.JobPost = action.payload;
      })
      .addCase(fetchJobPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch job post';
      })

      // Resume
      .addCase(fetchResume.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchResume.fulfilled, (state, action) => {
        state.loading = false;
        state.Resume = action.payload;
      })
      .addCase(fetchResume.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch resume';
      });
  },
});

export default cardSlice.reducer;