import React, { useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getCandStatusByRth } from "../../Crud/report";
import { getRthById } from "../../Crud/rth";

interface prop {
  openn: boolean;
  setOpenn: Function;
  status: string;
  seqId: string;
  currentReportData: any[];
  selectedTab: string;
  roleName: string;
}

const RightDrawer: React.FC<prop> = ({
  openn,
  setOpenn,
  status,
  seqId,
  currentReportData,
}) => {
  const [open, setOpen] = useState(false);
  const [popOverData, setPopOverData] = useState<any[]>();  

  const [mrfTitle, setMrfTitle] = useState<string | null | undefined>(
    undefined
  );
  const [mrfCategory, setMrfCategory] = useState<string | null | undefined>(
    undefined
  );
  const [mrfSubcategory, setMrfSubcategory] = useState<
    string | null | undefined
  >(undefined);
  const [position, setPosition] = useState<string | null | undefined>(
    undefined
  );
  const [recruiter, setRecruiter] = useState<string | null | undefined>(
    undefined
  );
  const [recruiterLead, setRecruiterLead] = useState<string | null | undefined>(
    undefined
  );

  // const [searchQuery, setSearchQuery] = useState<string>("");
  // console.log(searchQuery);

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setSearchQuery(event.target.value);
  // };

  const tableData = [
    "S no",
    "Position",
    "Recruiter",
    "Recruiter Lead",
    "Candidate",
    "Interview Stage",
    "Interview Status",
    "L1 Interviewer Name",
    "L2 Interviewer Name",
    "Interview Details",
  ];

  const toggleDrawer = (isOpen: boolean) => {
    setOpen(isOpen);
    setOpenn(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const foundRthId =
          currentReportData.find((item) => item.rthRequest.seqId === seqId)
            ?.rthRequest.rthId || "";

        setOpen(openn);

        if (foundRthId.length > 0) {
          console.log("id da dei", foundRthId);
          const candStatusResponse = await getCandStatusByRth(
            foundRthId,
            status
          );
          setPopOverData(candStatusResponse?.data);
          console.log("see this", candStatusResponse.data);

          const rthResponse = await getRthById(foundRthId);
          console.log(rthResponse);
          setMrfTitle(rthResponse.data.positionTitle);
          setMrfCategory(rthResponse.data.typeOfRth);
          setMrfSubcategory(rthResponse.data.modeOfRth);
          setPosition(rthResponse.data.numOfOpenPosition);
          setRecruiterLead(
            rthResponse?.data?.assignedRecruiterLead
              ?.map((recruiter: any) => recruiter?.userName)
              .join(",") || "-"
          );
          setRecruiter(
            rthResponse?.data?.assignedRecruiter
              ?.map((recruiter: any) => recruiter?.userName)
              .join(", ") || "-"
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [seqId, openn,currentReportData,status]);

  return (
    <div>
      {/* Drawer Component */}
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        {/* Drawer Content */}
        <Box
          sx={{
            width: 1100, // Adjust width as needed
            paddingLeft: 2,
            paddingRight: 2,
            paddingTop: 1,
          }}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} alignItems={"center"} sx={{backgroundColor:"#F0F5FF"}} gap={1} border={"1px solid #F0F5FF"} borderRadius={1} p={1}>
              <Typography fontSize={15}  textTransform={"uppercase"}>{mrfTitle}</Typography>
              <Typography >-</Typography>
              <Typography
                p={0.5}
                fontSize={12}                                
                textTransform={"uppercase"}
              >
                {mrfCategory}
              </Typography>
              <Typography >|</Typography>
              
              <Typography
                p={0.5}
                fontSize={12}                
                textTransform={"uppercase"}
              >
                {mrfSubcategory}
              </Typography>
            </Box>
            <IconButton onClick={() => toggleDrawer(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ padding: 1, width: 1100 }}>
          <TableContainer
            component={Paper}
            sx={{ border: "15px solid #F0F5FF" }}
          >
            <Table aria-label="MRF table">
              <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                <TableRow
                  sx={{
                    "& th": {
                      padding: "4px 8px",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    },
                  }}
                >
                  {tableData.map((data) => (
                    <TableCell>{data}</TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody
                sx={{
                  "& td, & th": {
                    padding: "4px 8px",
                    whiteSpace: "nowrap",
                  },
                }}
              >
                {popOverData?.map((data, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{position}</TableCell>
                    <TableCell>{recruiter}</TableCell>
                    <TableCell>{recruiterLead}</TableCell>
                    <TableCell>{data.candidateLogIn.firstName}</TableCell>
                    <TableCell>{"-"}</TableCell>
                    <TableCell>{"-"}</TableCell>
                    <TableCell>{"-"}</TableCell>
                    <TableCell>{"-"}</TableCell>
                    <TableCell>{"-"}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Drawer>
    </div>
  );
};

export default RightDrawer;
