import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PositionTitleState {
  positionTitleName: string[];
  positionTitleId: string[];
}

const initialState: PositionTitleState = {
  positionTitleName: [],
  positionTitleId: [],
};

const positionTitleSlice = createSlice({
  name: "positionTitle",
  initialState,
  reducers: {
    setPositionTitleName: (state, action: PayloadAction<string[]>) => {
      state.positionTitleName = action.payload;
    },
    setPositionTitleId: (state, action: PayloadAction<string[]>) => {
      state.positionTitleId = action.payload;
    },
  },
});

export const { setPositionTitleName, setPositionTitleId } = positionTitleSlice.actions;

export default positionTitleSlice.reducer;
