import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface RthStagesState {
  rthStagesName: string[];
  rthStagesId: string[];
}

const initialState: RthStagesState = {
  rthStagesName: [],
  rthStagesId: [],
};

const rthStagesSlice = createSlice({
  name: "rthStages",
  initialState,
  reducers: {
    setRthStagesName(state, action: PayloadAction<string[]>) {
      state.rthStagesName = action.payload;
    },
    setRthStagesId(state, action: PayloadAction<string[]>) {
      state.rthStagesId = action.payload;
    },
    resetRthStages(state) {
      state.rthStagesName = [];
      state.rthStagesId = [];
    },
  },
});

export const { setRthStagesName, setRthStagesId, resetRthStages } = rthStagesSlice.actions;
export default rthStagesSlice.reducer;
