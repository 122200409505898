import React from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";

interface RthTabProps {
  currentTab: number;
  onChangeTab: (type: number) => void;
}

const INTERNAL_ON_ROLE = 1;
const INTERNAL_CONTRACT = 2;
const EXTERNAL_CONTRACT = 3;
const EXTERNAL_ONTIME = 4;

const RthTab: React.FC<RthTabProps> = ({ currentTab, onChangeTab }) => {
  const handleType = (event: React.SyntheticEvent, value: number) => {
    onChangeTab(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: { xs: "flex-start", md: "center" },
        padding: "8px",
        gap: { xs: "10px", md: "20px" },
        width: "99%",
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.01)",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Typography
        variant="body2"
        sx={{
          alignSelf: { xs: "flex-start", md: "center" },
          fontWeight: "bold",
          marginBottom: { xs: "4px", md: "0px" },
        }}
        gutterBottom
      >
        Type of MRF
      </Typography>

      {/* Internal MRF Tabs */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "2px",
          gap: "12px",
          width: "fit-content",
          backgroundColor: "#FFFFFF",
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{ alignSelf: "center", paddingLeft: "5px" }}
          gutterBottom
        >
          Internal -
        </Typography>
        <Tabs
          value={currentTab}
          onChange={handleType}
          aria-label="internal tabs"
          sx={{ "& .MuiTabs-indicator": { display: "none" } }}
        >
          <Tab
            label="On-role"
            value={1}
            sx={{
              minWidth: "80px",
              fontSize: "0.75rem",
              padding: "2px 4px",
              minHeight: "40px",
              border:
                currentTab === INTERNAL_ON_ROLE ? "1px solid" : "1px solid",
              borderColor:
                currentTab === INTERNAL_ON_ROLE ? "#1677FF" : "divider",
              borderRadius: "4px",
              margin: "4px",
              backgroundColor:
                currentTab === INTERNAL_ON_ROLE ? "#F0F5FF" : "transparent",
            }}
          />
          <Tab
            label="Contract"
            value={2}
            sx={{
              minWidth: "80px",
              fontSize: "0.75rem",
              padding: "2px 4px",
              minHeight: "40px",
              border:
                currentTab === INTERNAL_CONTRACT ? "1px solid" : "1px solid",
              borderColor:
                currentTab === INTERNAL_CONTRACT ? "#1677FF" : "divider",
              borderRadius: "4px",
              margin: "4px",
              backgroundColor:
                currentTab === INTERNAL_CONTRACT ? "#F0F5FF" : "transparent",
            }}
          />
        </Tabs>
      </Box>

      {/* External MRF Tabs */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "2px",
          gap: "12px",
          width: "fit-content", 
          backgroundColor: "#FFFFFF",
          border: 1,
          borderColor: "divider",
          borderRadius: 2,
        }}
      >
        <Typography
          variant="body2"
          sx={{ alignSelf: "center", paddingLeft: "5px" }}
          gutterBottom
        >
          External -
        </Typography>
        <Tabs
          value={currentTab}
          onChange={handleType}
          aria-label="external tabs"
          sx={{ "& .MuiTabs-indicator": { display: "none" } }}
        >
          <Tab
            label="Contract"
            value={3}
            sx={{
              minWidth: "80px",
              fontSize: "0.75rem",
              padding: "2px 4px",
              minHeight: "40px",
              border:
                currentTab === EXTERNAL_CONTRACT ? "1px solid" : "1px solid",
              borderColor:
                currentTab === EXTERNAL_CONTRACT ? "#1677FF" : "divider",
              borderRadius: "4px",
              margin: "4px",
              backgroundColor:
                currentTab === EXTERNAL_CONTRACT ? "#F0F5FF" : "transparent",
            }}
          />
          <Tab
            label="One Time"
            value={4}
            sx={{
              minWidth: "80px",
              fontSize: "0.75rem",
              padding: "2px 4px",
              minHeight: "40px",
              border:
                currentTab === EXTERNAL_ONTIME ? "1px solid" : "1px solid",
              borderColor:
                currentTab === EXTERNAL_ONTIME ? "#1677FF" : "divider",
              borderRadius: "6px",
              margin: "4px",
              backgroundColor:
                currentTab === EXTERNAL_ONTIME ? "#F0F5FF" : "transparent",
            }}
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default RthTab;
