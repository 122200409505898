import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReportTableView from "./ReportTableView";
import ReportTabs from "./ReportTabs";
import { useNavigate } from 'react-router-dom';
import {
  getCandidateReportR,
  getCandidateReportRl,
  getCandidateReportTa,
  getMrfApprovalStatusAh,
  getMrfApprovalStatusTh,
  getMrfApprovedReportAh,
  getMrfApprovedReportTh,
  getMrfReportTa,
  getMrfStageReportAh,
  getMrfStageReportR,
  getMrfStageReportRl,
  getMrfStageReportTh,
  getOverallMrfReportAh,
  getOverallMrfReportTa,
  getOverallMrfReportTh,
  getOverAllReportR,
  getOverAllReportRl,
  getSourceReportR,
  getSourceReportRl,
  getSourceReportTa,
} from "../../Crud/report";
import SearchAndExport from "./SearchAndExport";
// import ReportSidebar from "./ReportSidebar";
import RightDrawer from "./TableDrawer";
import Filter from "./ReportSidebar";
 
const ReportMain: React.FC = () => {
  const [tabsData, setTabsData] = useState<string[]>([]);
 
  const [filterData, setFilterData] = useState({
    from: "",
    to: "",
    positions: [],
    interviewStatus: [],
    page: 0,
    size: 1000,
  });
  console.log("setFilterData", setFilterData);
  const [filterData2, setFilterData2] = useState({
    l1StatusPending: false,
    l1StatusDeclined: false,
    l2StatusPending: false,
    l2StatusApproved: false,
    internalOnRole: false,
    internalContract: false,
    externalOneTime: false,
    externalContract: false,
    priorityLow: false,
    priorityMedium: false,
    priorityHigh: false,
    rthStages: [],
    postedIn: [],
    createdBy: [],
    recruiterLead: [],
    positionTitle: [],
    business: [],
    location: [],
    recruiter: [],
    fromDate: null,
    toDate: null,
    page: 0,
    size: 1000,
  });
  console.log("setFilterData2", setFilterData2);
 
  const [currentReportData, setCurrentReportData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>(tabsData[0]);
  const userId = localStorage.getItem("userId") ?? "";
  const userString = localStorage.getItem("user");
  const userData = userString ? JSON.parse(userString) : null;
  const roleName = userData?.roleName || null;

  const [rthId, setRthId] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [openn, setOpenn] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sideBarOpen, setSideBarOpen] = useState<boolean>(true);
  const [filterCount, setFilterCount] = useState<any>(null);
  console.log("filterCount", filterCount);
 
    const [exportHeaderTitle, setExportHeaderTitle] = useState<string[]>([]);
    const [exportRows, setExportRows] = useState<
      { [key: string]: string | number | null | undefined }[]
    >([]);
 

  const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/', { replace: true });
        }
    }, [navigate]);

  useEffect(() => {
    const fetchTabsData = async () => {
      if (roleName === "TEAMHEAD" || roleName === "APPROVERHEAD") {
        await setTabsData([
          "MRF Approval Status",
          "MRF Stage Report",
          "Approved MRF Report",
          "Overall MRF Report",
        ]);
        await setSelectedTab("MRF Approval Status");
      } else if (
        roleName === "TALENTACQUISITIONHEAD" ||
        roleName === "RECRUITERLEAD" ||
        roleName === "RECRUITER"
      ) {
        await setTabsData([
          "Candidate Interview Status",
          "Source Report",
          "MRF Stage Report",
          "Department wise MRF",
          "Client wise MRF",
          "Selection Rate",
          "MRF Category Report",
          "Overall report",
        ]);
        await setSelectedTab("Candidate Interview Status");
      }
    };
    fetchTabsData();
  }, [roleName]);
 
  useEffect(() => {
    setCurrentReportData([]);
    const fetchReportData = async () => {
      setIsLoading(true);
      try {
        if (selectedTab === "MRF Approval Status" && roleName === "TEAMHEAD") {
          await getMrfApprovalStatusTh(userId, filterData).then((res) => {
            setCurrentReportData(res.data.mrfApprovalStatus);
            console.log("respone of api", res.data);
            console.log("filterDataa", filterData);
          });
        } else if (
          selectedTab === "MRF Stage Report" &&
          roleName === "TEAMHEAD"
        ) {
          await getMrfStageReportTh(userId, filterData).then((res) => {
            console.log("MRF Stage Report th", res);
            setCurrentReportData(res?.data.MrfReport);
          });
        } else if (
          selectedTab === "Approved MRF Report" &&
          roleName === "TEAMHEAD"
        ) {
          await getMrfApprovedReportTh(userId, filterData).then((res) => {
            setCurrentReportData(res?.data.MrfApprovedReport);
            console.log(res);
          });
        } else if (
          selectedTab === "Overall MRF Report" &&
          roleName === "TEAMHEAD"
        ) {
          await getOverallMrfReportTh(userId, filterData).then((res) => {
            setCurrentReportData(res?.data.OverAllRth);
            console.log("res", res);
          });
        } else if (
          selectedTab === "MRF Approval Status" &&
          roleName === "APPROVERHEAD"
        ) {
          await getMrfApprovalStatusAh(userId, filterData).then((res) => {
            setCurrentReportData(res.data.mrfApprovalStatus);
            console.log("respone of api", res);
          });
        } else if (
          selectedTab === "MRF Stage Report" &&
          roleName === "APPROVERHEAD"
        ) {
          await getMrfStageReportAh(userId, filterData).then((res) => {
            setCurrentReportData(res?.data.MrfReport);
            console.log(res);
          });
        } else if (
          selectedTab === "Approved MRF Report" &&
          roleName === "APPROVERHEAD"
        ) {
          await getMrfApprovedReportAh(userId, filterData).then((res) => {
            setCurrentReportData(res?.data.MrfApprovedReport);
            console.log(res);
          });
        } else if (
          selectedTab === "Overall MRF Report" &&
          roleName === "APPROVERHEAD"
        ) {
          await getOverallMrfReportAh(userId, filterData).then((res) => {
            setCurrentReportData(res?.data.Candidate_Interview_Status);
            console.log("res", res);
          });
        } else if (
          selectedTab === "Source Report" &&
          roleName === "TALENTACQUISITIONHEAD"
        ) {
          await getSourceReportTa(userId, filterData, searchQuery).then(
            (res) => {
              setCurrentReportData(res.source_Report);
              setFilterCount(res.interviewStatusCount);
            }
          );
        } else if (
          selectedTab === "Source Report" &&
          roleName === "RECRUITERLEAD"
        ) {
          await getSourceReportRl(userId, filterData, searchQuery).then(
            (res) => {
              setCurrentReportData(res.source_Report);
              console.log("res", res);
            }
          );
        } else if (
          selectedTab === "Source Report" &&
          roleName === "RECRUITER"
        ) {
          await getSourceReportR(userId, filterData, searchQuery).then(
            (res) => {
              setCurrentReportData(res.source_Report);
              console.log("res", res);
            }
          );
        } else if (
          (selectedTab === "MRF Stage Report" ||
            selectedTab === "Department wise MRF" ||
            selectedTab === "Client wise MRF" ||
            selectedTab === "Selection Rate" ||
            selectedTab === "MRF Category Report") &&
          roleName === "TALENTACQUISITIONHEAD"
        ) {
          await getMrfReportTa(userId, filterData2).then((res) => {
            setCurrentReportData(res?.data?.MrfReport);
            console.log("hey see filterData2", filterData2);
            console.log("MRF Stage Report res", res?.data);
          });
        } else if (
          (selectedTab === "MRF Stage Report" ||
            selectedTab === "Department wise MRF" ||
            selectedTab === "Client wise MRF" ||
            selectedTab === "Selection Rate" ||
            selectedTab === "MRF Category Report") &&
          roleName === "RECRUITERLEAD"
        ) {
          await getMrfStageReportRl(userId, filterData2).then((res) => {
            setCurrentReportData(res?.data?.MrfReport);
            console.log("res", res);
            // console.log("currentReportData", currentReportData);
          });
        } else if (
          (selectedTab === "MRF Stage Report" ||
            selectedTab === "Department wise MRF" ||
            selectedTab === "Client wise MRF" ||
            selectedTab === "Selection Rate" ||
            selectedTab === "MRF Category Report") &&
          roleName === "RECRUITER"
        ) {
          await getMrfStageReportR(userId, filterData2).then((res) => {
            setCurrentReportData(res?.data?.MrfReport);
            console.log("res", res);
          });
        } else if (
          selectedTab === "Overall report" &&
          roleName === "TALENTACQUISITIONHEAD"
        ) {
          await getOverallMrfReportTa(userId, filterData, searchQuery).then(
            (res) => {
              setCurrentReportData(res?.data?.Candidate_Interview_Status);
              console.log("res", res);
            }
          );
        } else if (
          selectedTab === "Overall report" &&
          roleName === "RECRUITERLEAD"
        ) {
          await getOverAllReportRl(userId, filterData, searchQuery).then(
            (res) => {
              setCurrentReportData(res?.data?.Candidate_Interview_Status);
              console.log("res", res);
            }
          );
        } else if (
          selectedTab === "Overall report" &&
          roleName === "RECRUITER"
        ) {
          await getOverAllReportR(userId, filterData, searchQuery).then(
            (res) => {
              setCurrentReportData(res?.data?.Candidate_Interview_Status);
              console.log("res", res);
            }
          );
        } else if (
          selectedTab === "Candidate Interview Status" &&
          roleName === "TALENTACQUISITIONHEAD"
        ) {
          await getCandidateReportTa(userId, filterData, searchQuery).then(
            (res) => {
              setCurrentReportData(res);
              setFilterCount(null);
              console.log("TALENTACQUISITIONHEAD res", res);
            }
          );
        } else if (
          selectedTab === "Candidate Interview Status" &&
          roleName === "RECRUITERLEAD"
        ) {
          await getCandidateReportRl(userId, filterData, searchQuery).then(
            (res) => {
              setCurrentReportData(res);
              console.log("ress", res);
            }
          );
        } else if (
          selectedTab === "Candidate Interview Status" &&
          roleName === "RECRUITER"
        ) {
          await getCandidateReportR(userId, filterData, searchQuery).then(
            (res) => {
              setCurrentReportData(res);
              console.log("res", res);
            }
          );
        }
      } catch (error) {
        console.error("Error fetching report data", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchReportData();
  }, [selectedTab, userId, roleName, filterData, searchQuery, filterData2]);
 
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", marginBottom: "30px" }}
      gap={4}
    >
      {sideBarOpen && (
        <Box sx={{ flexBasis: "20%", width: "100%" }}>
          <Filter
            setFilterData={setFilterData}
            setSideBarOpen={setSideBarOpen}
            selectedTab={selectedTab}
            filterCount={filterCount}
            setFilterData2={setFilterData2}
            roleName={roleName}
          />
        </Box>
      )}
      <Box
        sx={{
          maxWidth: "100%",
          flexBasis: "100%",
          display: "flex",
          flexDirection: "column",
          overflow: "scroll",
        }}
        pl={1}
        pr={1}
      >
        <Box mb={1}>
          <ReportTabs
            setSelectedTab={setSelectedTab}
            tabsData={tabsData}
            sideBarOpen={sideBarOpen}
            setSideBarOpen={setSideBarOpen}
          />
        </Box>
        <Box mb={1}>
          <SearchAndExport
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            exportHeaderTitle={exportHeaderTitle}
            exportRows={exportRows}
            selectedTab={selectedTab}
          />
        </Box>
        <Box>
          <RightDrawer
            roleName={roleName}
            selectedTab={selectedTab}
            openn={openn}
            setOpenn={setOpenn}
            seqId={rthId}
            status={status}
            currentReportData={currentReportData}
          />
          <ReportTableView
            currentReportData={currentReportData}
            selectedTab={selectedTab}
            isLoading={isLoading}
            roleName={roleName}
            setRthId={setRthId}
            setStatus={setStatus}
            setOpenn={setOpenn}
            setExportHeaderTitle={setExportHeaderTitle}
            setExportRows={setExportRows}
          />
        </Box>
      </Box>
    </Box>
  );
};
 
export default ReportMain;