export const cardResume=[
    {
        "label": "Waiting for JD Creation",
        "component": {
            "key": null,
            "ref": null,
            "props": {
                "data": [
                    {
                        "rthId": "66f3db12ff9b686dfd18b235",
                        "seqId": "RTH0002",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:12 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    },
                    {
                        "rthId": "66f3dc4823a4a806694a17e0",
                        "seqId": "RTH0004",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:17 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    },
                    {
                        "rthId": "66f3dc8c23a4a806694a17e1",
                        "seqId": "RTH0001",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    },
                    {
                        "rthId": "66f3dc9823a4a806694a17e2",
                        "seqId": "RTH0002",
                        "userId": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "typeOfRth": "Internal",
                        "modeOfRth": "OnRole",
                        "modesofRth":"JD Created",
                        "business": {
                            "businessId": "66c6d176eb1bf1110f1ca605",
                            "businessName": "CITPL",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "positionTitle": "BackEnd",
                        "numOfPosition": "5",
                        "internalSpoc": null,
                        "posReportsTo": {
                            "id": "66ddad2bcc7a4404054954c7",
                            "email": "aanand@gmail.com",
                            "firstName": "Aanand",
                            "lastName": "MyTheron",
                            "role": "TEAM HEAD",
                            "mobNumb": "9999999999",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l1Approval": {
                            "id": "66ddb14a9a657e68d477651a",
                            "email": "saravanan@gmail.com",
                            "firstName": "Saravanan",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "8888888888",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "l2Approval": {
                            "id": "66ddb1959a657e68d477651b",
                            "email": "aishwarya@gmail.com",
                            "firstName": "Aishwarya",
                            "lastName": "MyTheron",
                            "role": "APPROVER HEAD",
                            "mobNumb": "7777777777",
                            "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                            "enabled": true,
                            "accountNonExpired": true,
                            "accountNonLocked": true,
                            "credentialsNonExpired": true
                        },
                        "reasonForHire": "Replacement",
                        "location": "Chennai",
                        "department": {
                            "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                            "departmentName": "IT - Software Development",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "band": {
                            "bandId": "66cd65fb90aacc60d698303b",
                            "bandName": "1A",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "qualNeeded": {
                            "qualificationId": "66c6d283eb1bf1110f1ca607",
                            "degree": "B.E - Computer Science",
                            "status": "Active",
                            "createdAt": "9/25/24, 3:19 PM"
                        },
                        "minExperience": "2",
                        "maxExperience": "5",
                        "ctc": "5LPA",
                        "primarySkills": [
                            {
                                "id": "66f25b19e403cd57cef6951b",
                                "skillName": "Java",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "secondarySkills": [
                            {
                                "id": "66f25b20e403cd57cef6951c",
                                "skillName": "Python",
                                "status": "Active",
                                "createdAt": "9/24/24, 11:54 AM"
                            }
                        ],
                        "prefStartDate": "2024-09-25",
                        "workMode": "OnSite",
                        "priority": "High",
                        "comments": "xxxxxxxxxx",
                        "contractDur": null,
                        "contractType": null,
                        "clientName": null,
                        "clientPoc": null,
                        "approvalAttachment": null,
                        "clientRateCard": null,
                        "oneTimeCharge": null,
                        "oneTimeAmount": null,
                        "clientMarginInAmount": null,
                        "clientMarginInPer": null,
                        "l1Status": "Approved",
                        "l2Status": "Approved",
                        "assignedRecruiterLead": null,
                        "recruiterLeadAssigned": false,
                        "assignedRecruiter": null,
                        "recruiterAssigned": false,
                        "status": "TBA",
                        "rthProcess": null,
                        "rthModule": null,
                        "createdAt": "2024-09-25",
                        "updatedAt": null
                    }
                ]
            },
            "_owner": {
                "tag": 0,
                "key": null,
                "stateNode": null,
                "return": null,
                "child": null,
                "sibling": null,
                "index": 0,
                "ref": null,
                "pendingProps": null,
                "memoizedProps": null,
                "updateQueue": null,
                "memoizedState": null,
                "dependencies": null,
                "mode": 27,
                "flags": 12617749,
                "subtreeFlags": 0,
                "deletions": null,
                "lanes": 0,
                "childLanes": 0,
                "alternate": null,
                "actualDuration": 34.80000019073486,
                "actualStartTime": 1419.7999999523163,
                "selfBaseDuration": 4.300000071525574,
                "treeBaseDuration": 4.299999952316284,
                "_debugSource": {
                    "fileName": "D:\\MYTHERON\\mytheron_frontend_react\\src\\components\\dashboard\\Rth\\resume\\RTHComponent.tsx",
                    "lineNumber": 16,
                    "columnNumber": 13
                },
                "_debugOwner": null,
                "_debugNeedsRemount": false,
                "_debugHookTypes": [
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useState",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useContext",
                    "useRef",
                    "useContext",
                    "useLayoutEffect",
                    "useCallback",
                    "useState",
                    "useContext",
                    "useRef",
                    "useCallback",
                    "useRef",
                    "useMemo",
                    "useSyncExternalStore",
                    "useEffect",
                    "useDebugValue",
                    "useDebugValue",
                    "useEffect",
                    "useEffect",
                    "useEffect"
                ]
            },
            "_store": {}
        },
        "data": [
            {
                "rthId": "66f3db12ff9b686dfd18b235",
                "seqId": "RTH0002",
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "modesofRth":"JD Created",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:12 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Approved",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "TBA",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            },
            {
                "rthId": "66f3dc4823a4a806694a17e0",
                "seqId": "RTH0004",
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "modesofRth":"JD Created",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:17 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:17 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:17 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:17 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Approved",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "TBA",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            },
            {
                "rthId": "66f3dc8c23a4a806694a17e1",
                "seqId": "RTH0001",
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "modesofRth":"JD Created",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Approved",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "TBA",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            },
            {
                "rthId": "66f3dc9823a4a806694a17e2",
                "seqId": "RTH0002",
                "userId": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "typeOfRth": "Internal",
                "modeOfRth": "OnRole",
                "modesofRth":"JD Created",
                "business": {
                    "businessId": "66c6d176eb1bf1110f1ca605",
                    "businessName": "CITPL",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "positionTitle": "BackEnd",
                "numOfPosition": "5",
                "internalSpoc": null,
                "posReportsTo": {
                    "id": "66ddad2bcc7a4404054954c7",
                    "email": "aanand@gmail.com",
                    "firstName": "Aanand",
                    "lastName": "MyTheron",
                    "role": "TEAM HEAD",
                    "mobNumb": "9999999999",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908193549_aanand.jfif?sig=VMtZkDtPIVTU82T8N7JIdL9nf%2FXY%2Fw2w%2BUx4P%2BogdCE%3D&se=2034-09-06T14%3A05%3A49Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l1Approval": {
                    "id": "66ddb14a9a657e68d477651a",
                    "email": "saravanan@gmail.com",
                    "firstName": "Saravanan",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "8888888888",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908210417_Image%20(1).jfif?sig=%2BBC6MYuhuZpNQVipBPK8fRyBqIQkCMKK%2FB8eCsSD8zk%3D&se=2034-09-06T15%3A34%3A18Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "l2Approval": {
                    "id": "66ddb1959a657e68d477651b",
                    "email": "aishwarya@gmail.com",
                    "firstName": "Aishwarya",
                    "lastName": "MyTheron",
                    "role": "APPROVER HEAD",
                    "mobNumb": "7777777777",
                    "profilePicture": "https://packagingapp.blob.core.windows.net/trove/20240908202041_aishwarya.jfif?sig=JtkoLvoAPLgh4ymAwrhwhXpGdsATYQ9yrRJCvMIdXyQ%3D&se=2034-09-06T14%3A50%3A41Z&sv=2019-02-02&sp=rl&sr=c",
                    "enabled": true,
                    "accountNonExpired": true,
                    "accountNonLocked": true,
                    "credentialsNonExpired": true
                },
                "reasonForHire": "Replacement",
                "location": "Chennai",
                "department": {
                    "departmentId": "66c6ce4beb1bf1110f1ca5e8",
                    "departmentName": "IT - Software Development",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "band": {
                    "bandId": "66cd65fb90aacc60d698303b",
                    "bandName": "1A",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "qualNeeded": {
                    "qualificationId": "66c6d283eb1bf1110f1ca607",
                    "degree": "B.E - Computer Science",
                    "status": "Active",
                    "createdAt": "9/25/24, 3:19 PM"
                },
                "minExperience": "2",
                "maxExperience": "5",
                "ctc": "5LPA",
                "primarySkills": [
                    {
                        "id": "66f25b19e403cd57cef6951b",
                        "skillName": "Java",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "secondarySkills": [
                    {
                        "id": "66f25b20e403cd57cef6951c",
                        "skillName": "Python",
                        "status": "Active",
                        "createdAt": "9/24/24, 11:54 AM"
                    }
                ],
                "prefStartDate": "2024-09-25",
                "workMode": "OnSite",
                "priority": "High",
                "comments": "xxxxxxxxxx",
                "contractDur": null,
                "contractType": null,
                "clientName": null,
                "clientPoc": null,
                "approvalAttachment": null,
                "clientRateCard": null,
                "oneTimeCharge": null,
                "oneTimeAmount": null,
                "clientMarginInAmount": null,
                "clientMarginInPer": null,
                "l1Status": "Approved",
                "l2Status": "Approved",
                "assignedRecruiterLead": null,
                "recruiterLeadAssigned": false,
                "assignedRecruiter": null,
                "recruiterAssigned": false,
                "status": "TBA",
                "rthProcess": null,
                "rthModule": null,
                "createdAt": "2024-09-25",
                "updatedAt": null
            }
        ]
    },
]


  
    
export const SelectDataOptions=[
    {
        "id": "66d6ff54d6d855787104e4cd",
        "name": "High"
    },
    {
        "id": "66d6ff54d6d855787104e4ce",
        "name": "Medium"
    },
    {
        "id": "66d6ff54d6d855787104e4cf",
        "name": "Low"
    },
    {
        "id": "66d6ff54d6d855787104e4d0",
        "name": "None"
    }
]
export const gender=[
    {
        label:'Male',
        value:'Male'
    },
    {
        label:'Female',
        value:'Female'
    }
]

export const knownOptions=[
    {
        label:'Social Media',
        value:'Social Media'
    },
    {
        label:'LinkedIn',
        value:'LinkedIn'
    },
    {
        label:'Google',
        value:'Google'
    },
    {
        label:'Bing',
        value:'Bing'
    },
    {
        label:'Yahoo',
        value:'Yahoo'
    },
    {
        label:'Friend/Family',
        value:'Friend/Family'
    },{
        label:'Colleague/Acquaintance',
        value:'Colleague/Acquaintance'
    },{
        label:'Naukri',
        value:'Naukri'
    },
    {
        label:'Indeed',
        value:'Indeed'
    },
    {
        label:'Shine',
        value:'Shine'
    },
    {
        label:'Monster India',
        value:'Monster India'
    },
    {
        label:'Monster India',
        value:'Monster India'
    },{
        label:'TimesJobs',
        value:'TimesJobs'
    },{
        label:'Freshersworld',
        value:'Freshersworld'
    },
    {
        label:'Monster India',
        value:'Monster India'
    },
    {
        label:'Hirist',
        value:'Hirist'
    },
    {
        label:'Updazz',
        value:'Updazz'
    },
    {
        label:'others',
        value:'others'
    }

]
export const Nationality=[
    {
        label:"Indian",
        value:"Indian",
    },
    {
        label:"American",
        value:"American",
    },
    {
        label:"others",
        value:"others",
    }
]
export const Citizenship=[
    {
        label:"Permanent Resident",
        value:"Permanent Resident",
    },
    {
        label:"others",
        value:"others",
    },
    
]
export const Country=[
    {
        label:"India",
        value:"India",
    },
    {
        label:'Germany',
        value:'Germany',
    }
]
export const State=[
    {
        label:'TamilNadu',
        value:'TamilNadu'
    },
    {
        label:'Maharashtra',
        value:'Maharashtra'
    },
    {
        label:'karnataka',
        value:'karnataka'
    },
]
export const Cities=[
    {
        label:'Chennai',
        value:'Chennai'
    },
    {
        label:'Coimbatore',
        value:'Coimbatore'
    },
    {
        label:'Madurai',
        value:'Madurai'
    },
]
export const Skills=[
    {
        label:'Java',
        value:'Java'
    },
    {
        label:'Python',
        value:'Python'
    },
    {
        label:'SQL',
        value:'SQL'
    },
    {
        label:'HTML',
        value:'HTML',
    },
    {
        label:'CSS',
        value:'CSS',
    },
    {
        label:'JavaScript',
        value:'JavaScript',
    },
    {
        label:'React JS',
        value:'React JS',
    },
]
export const YesNo=[
    {
        label:'Yes',
        value:'Yes',
    },
    {
        label:'No',
        value:'No',
    },
]
export const YearsInExp=[
    {
        label:'Fresher(0-years)',
        value:'Fresher(0-years)',
    },
    {
        label:'0-1 year',
        value:'0-1 year',
    },
    {
        label:'1-2 Years',
        value:'1-2 Years',
    },
    {
        label:'2-3 Years',
        value:'2-3 Years',
    },
    {
        label:'3-5 Years',
        value:'3-5 Years',
    },
    {
        label:'5-7 Years',
        value:'5-7 Years',
    },
    {
        label:'7-10 Years',
        value:'7-10 Years',
    },
    {
        label:'10-15 Years',
        value:'10-15 Years',
    },
    {
        label:'15+ years',
        value:'15+ years',
    }
]
export const TrueFalse=[
    {
        label:'Yes',
        value:true,
    },
    {
        label:'No',
        value:false,

    }
]  

export const InterviewMode=[
    {
        label:'Google Meet',
        value:'Google Meet',
    },
    {
        label:"Teams",
        value:'Teams',
    },
    {
        label:"Zoom",
        value:"Zoom"
    }
]

export const sourceOptions=[
    {
        label:'LinkedIn',
        value:'LinkedIn'
    },
    {
        label:'Indeed',
        value:'Indeed'
    },
    {
       label:'Naukri',
       value:'Naukri'
    },
]

export const interviewNameOptions=[
    {
        label:"L1 Round",
        value:"L1 Round"
    },
    {
        label:"L2 Round",
        value:"L2 Round"
    },
    {
        label:"L3 Round",
        value:"L3 Round"
    },
    {
        label:"HR Round",
        value:"HR Round"
    }
]

export const noticePeriod=[
    {
        label:'15 days or less',
        value:'15 days or less',
    },
    {
        label:'1 Month',
        value:'1 Month',
    },
    {
        label:'2 Months',
        value:'2 Months'
    },
    {
        label:'More than 2 Months',
        value:'More than 2 Months'
    }
]