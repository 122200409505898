import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Paper } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import L1approver from "./L1approver";
import L2approver from "./L2Approver";

const TableView = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
        navigate('/', { replace: true });
    }
}, [navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabParam = queryParams.get("tab");
    if (tabParam === "l2-approver") {
      setActiveTab(1);
    } else {
      setActiveTab(0);
    }
  }, [location.search]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    const newQueryParam = newValue === 0 ? "l1-approver" : "l2-approver";
    navigate(`?tab=${newQueryParam}`);
  };

  function a11yProps(index: number) {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  }

  return (
    <Paper elevation={0} sx={{ backgroundColor: "transparent" }}>
      <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" aria-label="job tabs">
        <Tab label="L1 Offer Approval" {...a11yProps(0)}
          sx={{
            textTransform: "none",
            fontSize: "12px",
            minWidth: "100px",
            textAlign: "center",
            color: "#A9A9A9",
            "&:hover": {
              color: "#1677FF",
              backgroundColor: "rgba(30, 136, 229, 0.1)",
              borderRadius: "8px",
            },
          }}
        />
        <Tab label="L2 Offer Approval" {...a11yProps(1)}
          sx={{
            textTransform: "none",
            fontSize: "12px",
            minWidth: "100px",
            textAlign: "center",
            color: "#A9A9A9",
            "&:hover": {
              color: "#1677FF",
              backgroundColor: "rgba(30, 136, 229, 0.1)",
              borderRadius: "8px",
            },
          }}
        />
      </Tabs>
      <Box sx={{ padding: 2 }}>
        {activeTab === 0 && <L1approver />}
        {activeTab === 1 && <L2approver />}
      </Box>
    </Paper>
  );
};

export default TableView;
