import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getPositionTitle } from "../../../Crud/rth";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setPositionTitleName,
  setPositionTitleId,
} from "../../../redux/features/positionTitleSlice";
import { setFilterState } from "../../../redux/features/teamHeadFilterSlice";

const PositionTitleDropDown = () => {
  const dispatch = useDispatch();
  const { positionTitleName, positionTitleId } = useSelector(
    (state: RootState) => state.positionTitle
  );

  const [positionTitle, setPositionTitle] = useState<any[]>([]);
  console.log("positionTitle",positionTitle);
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  console.log('searchLoading', searchLoading)


  // Handle opening the dropdown menu
  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPage(0); // Reset page when opening the dropdown
    setPositionTitle([]);
    setSearchQuery("")
    fetchPositionTitles("", 0); // Fetch first page on open
    setHasMore(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Fetch position titles with debouncing logic and pagination
  const fetchPositionTitles = useCallback(
    async (query: string, currentPage: number) => {
      setLoading(true);

      try {
        const response = await getPositionTitle(query, currentPage, 10);
        const fetchedData = response?.map((item: any, index: any) => ({
          value: `${currentPage}-${index}`, // Unique value for pagination
          label: item,
        }));

        // Append new data if the response length is 10 (page size)
        setPositionTitle((prev) => [
          ...prev,
          ...fetchedData.filter(
            (newItem:any) => !prev.some((item) => item.value === newItem.value)
          ),
        ]);

        setHasMore(fetchedData.length === 10); // Assume 10 items per page
      } catch (error) {
        console.error("Error fetching position titles:", error);
      } finally {
        setLoading(false);
      }
    },[]
  );


  // const debounce = (func: (...args: any[]) => void, delay: number) => {
  //   let timer: NodeJS.Timeout;
  //   return (...args: any[]) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func(...args);
  //     }, delay);
  //   };
  // };

  
  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
      setPositionTitle([]); 
      setPage(0); 
    } else {
      setPositionTitle([]);
      setSearchLoading(false);
    }

    const timeoutId = setTimeout(() => {
      fetchPositionTitles(searchQuery, 0); 
    }, 300); 

    return () => {
      clearTimeout(timeoutId); 
    };
  }, [searchQuery, fetchPositionTitles]);
  
  useEffect(() => {
    dispatch(
      setFilterState({
        positionTitle: positionTitleName.length ? positionTitleName : [],
      })
    );
  }, [positionTitleName, dispatch]);

  // Handle scrolling to load more data
  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const listboxNode = event.currentTarget;
    if (
      listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 10 &&
      hasMore &&
      !loading
    ) {
      const nextPage = page + 1;
      setPage(nextPage); // Increment page
      fetchPositionTitles(searchQuery, nextPage); // Fetch the next page
    }
  };

  // Toggle selection for position title
  const handleToggle = (name: string, id: string) => {
    const updatedPositionTitleNames = positionTitleName.includes(name)
      ? positionTitleName.filter((title:any) => title !== name)
      : [...positionTitleName, name];
    dispatch(setPositionTitleName(updatedPositionTitleNames));

    const updatedPositionTitleIds = positionTitleId.includes(id)
      ? positionTitleId.filter((title:any) => title !== id)
      : [...positionTitleId, id];
    dispatch(setPositionTitleId(updatedPositionTitleIds));
  };

  const isOpen = Boolean(anchorEl);
  console.log("isOpen",isOpen);

  const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);     
  };


  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          borderColor: "#B0B0B0",
          color: "#000",
          "&:hover": {
            borderColor: "#A0A0A0",
          },
        }}
      >
        {positionTitleName.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {positionTitleName.slice(0, 1).map((name) => (
              <Chip
                key={name}
                label={name}
                onDelete={() =>
                  handleToggle(
                    name,
                    positionTitle.find((lead) => lead.label === name)?.value || ""
                  )
                }
                sx={{ fontSize: "10px", height: "20px" }}
              />
            ))}
            {positionTitleName.length > 1 && (
              <Chip
                label={`+${positionTitleName.length - 1}`}
                sx={{
                  fontSize: "10px",
                  height: "20px",
                  backgroundColor: "#f0f0f0",
                  color: "#8C8C8C",
                }}
              />
            )}
          </Box>
        ) : (
          <Typography sx={{ color: "grey" }}>Choose</Typography>
        )}
        <ArrowDropDownIcon sx={{ fontSize: 18, marginLeft: 1 }} />
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            width: anchorEl ? anchorEl.offsetWidth : "auto",
            marginTop: "2px",
          },
        }}
      >
        <Box sx={{ padding: 1 }}>
          <TextField
            size="small"
            placeholder="Search..."
            fullWidth
            value={searchQuery}
            onChange={handleSearchQuery}
            sx={{ marginBottom: 2, "& .MuiInputBase-input": { fontSize: "12px" } }}
          />
          <Box onScroll={handleScroll} sx={{ maxHeight: "250px", overflowY: "auto" }}>
            {positionTitle.map((lead) => (
              <MenuItem
                key={lead.value}
                onClick={() => handleToggle(lead.label, lead.value)}
                sx={{ fontSize: "10px", padding: "4px 6px", gap: "10px" }}
              >
                <Checkbox
                  checked={positionTitleName.includes(lead.label)}
                  size="small"
                  sx={{ padding: "2px", "& .MuiSvgIcon-root": { fontSize: "14px" } }}
                />
                <ListItemText primary={lead.label} sx={{ margin: 0, fontSize: "10px" }} />
              </MenuItem>
            ))}
            {loading && (
              <Box sx={{ textAlign: "center", padding: 1 }}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default PositionTitleDropDown;
