import React, { useState, useEffect, useCallback } from "react";
import { TextField, CircularProgress, MenuItem } from "@mui/material";
import { Autocomplete } from "@mui/material";

interface Option {
  id: string | number;
  label: string;
}

interface SearchableDropdownProps {
  searchAPICall: (query?: string, page?: number, size?: number) => Promise<any>;
  label: string;
  onOptionSelect: (selectedOption: Option | null) => void;
  value?: Option | null;
  error?: boolean;
  helperText?: string;
  name?: string;
  l2IdApproval?: string;
}

const SearchableDropdown: React.FC<SearchableDropdownProps> = ({
  searchAPICall,
  label,
  onOptionSelect,
  value,
  helperText,
  error,
  name,
  l2IdApproval,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchOptions = useCallback(
    async (query = "", currentPage = 0) => {
      if (!hasMore && currentPage > 0) return;

      setIsFetching(true);
      try {
        const response = await searchAPICall(query, currentPage, 10);
        const fetchedOptions = response.data?.map((item: any) => ({
          id: item.userId,
          label: `${item.firstName} ${item.lastName} | ${item.designation}`,
          firstName: item.firstName,
          designation: item.designation,
        })) || [];

        // Filter out the L1 approver from L2 options list
        const filteredOptions = l2IdApproval 
          ? fetchedOptions.filter((option: any) => option.id !== l2IdApproval)
          : fetchedOptions;

        setOptions((prevOptions) => {
          const uniqueOptions = [
            ...prevOptions,
            ...filteredOptions.filter(
              (newOption: any) => !prevOptions.some((option) => option.id === newOption.id)
            ),
          ];
          return uniqueOptions;
        });

        setHasMore(fetchedOptions.length === 10);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    },
    [hasMore, searchAPICall, l2IdApproval]
  );

  const handleScroll = useCallback(
    (event: React.SyntheticEvent) => {
      const listboxNode = event.currentTarget;
      if (
        listboxNode &&
        listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 50 &&
        hasMore &&
        !loading &&
        !isFetching
      ) {
        fetchOptions(searchTerm, page + 1);
        setPage((prevPage) => prevPage + 1);
      }
    },
    [hasMore, loading, isFetching, searchTerm, page, fetchOptions]
  );  

  useEffect(() => {
    // Reset options and page when l2IdApproval changes
    setOptions([]);
    setPage(0);
    fetchOptions(searchTerm, 0);
  }, [searchTerm, l2IdApproval, fetchOptions]);

  return (
    <Autocomplete
      fullWidth
      size="small"
      options={options}
      getOptionLabel={(option) => option.label}
      loading={loading}
      onInputChange={(event, value) => setSearchTerm(value)}
      onChange={(event, newValue) => onOptionSelect(newValue)}
      onOpen={() => {
        setOptions([]);
        setPage(0);
        fetchOptions("", 0);
      }}
      ListboxProps={{
        onScroll: handleScroll,
        sx: {
          maxWidth: 500,
          "& .MuiAutocomplete-option": {
            fontSize: "0.8rem",
          },
        },
      }}
      sx={{
        "& .MuiAutocomplete-root": {
          width: "100%",
        },
      }}
      value={value || null}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          name={name}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            "& .MuiInputLabel-root": {
              fontSize: "0.8rem",
              fontWeight: 400,
              color: "#808080",
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem sx={{ minWidth: '350px', fontSize: '0.7rem', overflow: 'auto' }} {...props}>
          {option.label}
        </MenuItem>
      )}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
    />
  );
};

export default SearchableDropdown;