import {
  Avatar,
  Box,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import feedbackIcon from "../../assets/images/feedbackIcon.svg";
import searchIcon from "../../assets/images/searchIcon.svg";
// import locationIcon from "../../assets/images/locationIcon.svg";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getSearchSuggestions } from "../../redux/features/allUserSlice";
import { useSelector } from "react-redux";
import citplLogo from "../../assets/images/citplLogo.svg";
import { Job } from "../../redux/features/jobcardSlice";
import { ProfileMenu } from "../dashboard/commoncomponent/HeaderBar";
import { stringAvatar } from "../../helpers/stringAvatar";
import { fetchProfile } from "../../redux/features/profileSlice";
import { jwtDecode } from "jwt-decode";

interface prop {
  setClickedIndex: Function;
  clickedIndex: number;
  allPostedJobs: Job[];
  setProfileView:Function;
}

const AjHeader: React.FC<prop> = ({
  setClickedIndex,
  clickedIndex,
  allPostedJobs,
  setProfileView
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const profile = useSelector((state: RootState) => state.profile.data?.data);
  const profileUpdateStatus = useSelector(
    (state: RootState) => state.common.profileUpdate
  );

  const dispatch = useDispatch<AppDispatch>();
    const token: any = localStorage.getItem("token");
    const decoded: any = jwtDecode(token);
    const userId = decoded.userId;    

    useEffect(() => {
      if (userId) {
        dispatch(fetchProfile(userId));
      }
    }, [dispatch, userId]);


  console.log("profile",profile);
  console.log("profileUpdateStatus",profileUpdateStatus);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [searchQuery, setSearchQuery] = useState<string>("");

  const [lenOfSearchResults, setLenOfSearchResults] = useState(0);

  const searchInputRef = useRef<HTMLInputElement | null>(null);

  

  const searchSuggestions = useSelector(
    (state: RootState) => state.allUserLogin.searchSuggestionsData
  );

  console.log("len -> ", lenOfSearchResults);

  useEffect(() => {
    if (searchSuggestions) {
      setLenOfSearchResults(searchSuggestions.length);
    }
  }, [searchSuggestions]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  console.log(searchSuggestions.length);

  const handleSearchIconButtonClick = () => {
    searchInputRef.current?.focus();
  };

  useEffect(() => {
    if (searchQuery !== "") {
      dispatch(getSearchSuggestions({ searchString: searchQuery }));
    }
  }, [searchQuery, dispatch]);

  const handleSuggestionClick = (rthId: string | undefined) => {
    // console.log("selected Rth Id is "+rthId);
    setSearchQuery("");

    const index = allPostedJobs.findIndex((job) => job.rthId === rthId);
    setClickedIndex(index);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ backgroundColor: "white" }} p={1}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={"5px"}      
      >
        <Box sx={{ display: "contents" }}>
          <img src={feedbackIcon} alt="" width="40px" height="40px" />
        </Box>

        <Box
          sx={{
            p: "2px 4px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            alignContent: "center",
            width: 350,
            height: 30,
            borderRadius: "2px",
            border: "1px solid #00000008",
            backgroundColor: "#F8F8F8",
            position: "relative",
          }}
        >
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={handleSearchIconButtonClick}
          >
            <img src={searchIcon} alt="" />
          </IconButton>
          <InputBase
            inputRef={searchInputRef}
            sx={{ ml: 1, flex: 1, color: "grey" }}
            placeholder="Search Jobs, Location..."
            onChange={handleSearchChange}
          />
          {searchQuery.length > 0 && (
            <Paper
              sx={{
                position: "absolute",
                top: 40,
                left: 0,
                right: 0,
                zIndex: 10,
                maxHeight: 200,
                overflowY: "auto",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              }}
            >
              <List>
                {searchSuggestions.map((suggestion, index) => (
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      onClick={() => handleSuggestionClick(suggestion.rthId)}
                    >
                      {/* <Typography variant="body2">{suggestion.positionTitle}</Typography> */}
                      <Box
                        width={"100%"}
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                      >
                        <Box flexBasis={"10%"}>
                          <img
                            src={citplLogo}
                            alt="B Icon"
                            width={"60px"}
                            height={"60px"}
                          />
                        </Box>

                        <Box flexBasis={"90%"} color={"#384250"}>
                          <Typography fontSize={13}>
                            {suggestion.positionTitle}
                          </Typography>

                          <Typography fontSize={11} color={"#0000004D"}>
                            {suggestion.business.businessName} |{" "}
                            {suggestion.createdAt}
                          </Typography>
                        </Box>
                      </Box>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </Box>

        <Box sx={{ display: "contents" }}>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            color="inherit"
            onClick={handleProfileMenuOpen}
          >
            <Avatar
              alt="Profile"
              {...stringAvatar(`${profile?.firstName} ${profile?.lastName}`,"headerProfile")}
              src={profileUpdateStatus || undefined}
            />            
          </IconButton>
          <ProfileMenu anchorEl={anchorEl} setProfileView={setProfileView} onClose={handleProfileMenuClose} />
        </Box>
      </Box>
    </Box>
  );
};

export default AjHeader;