import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileUpdate: "",
  interviewRounds:"",
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    profileUpdateGetData: (state, action) => {
      state.profileUpdate = action.payload;
    },
    setInterviewRound: (state, action) => {
      state.interviewRounds = action.payload;
    },
  },
});

export const { profileUpdateGetData,setInterviewRound } = commonSlice.actions;
export default commonSlice.reducer;
