import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { useLocation } from "react-router-dom";
import legendLogo from "../../../assets/images/legendLogo.svg";
import { getRecruiterLead, getRthById } from "../../../Crud/rth";
import {
  assignRecruiterLead,
  resetStatus,
  setSelectedRecruiter,
} from "../../../redux/features/recruiterSlice";
import { AppDispatch, RootState } from "../../../redux/store";
import { toast } from "react-toastify";
import editIcon2 from "../../../assets/images/editIcon2.svg";
import Select from "react-select";

export interface AssignRecruiterProps {
  numOfPosition?: string;
  numOfOpenPosition?: number;
}

const AssignRecruiterLead: React.FC<AssignRecruiterProps> = ({
  numOfOpenPosition,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const status = useSelector((state: RootState) => state.recruiter.status);
  const [recruiterIds, setRecruiterIds] = useState<string>("");  
  const [load, setLoad] = useState<number>(0);  

  const [isButtonClicked, setButtonClicked] = useState(false);
  const [recruiterData, setRecruiterData] = useState<any[]>([]);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);  
  const [isAssigned, setIsAssigned] = useState<boolean>();
  const [isRecruiterAssigned, setIsRecruiterAssigned] = useState<boolean>();  
  const userId = localStorage.getItem("userId") ?? "";
  const location = useLocation();
  const { id } = location.state || {};
  const [view, setView] = useState<boolean>(false);  

  console.log("r data", recruiterData);

  useEffect(() => {    
    setSelectedDate(null);
    dispatch(setSelectedRecruiter(""));
    dispatch(resetStatus());
  }, [id, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const recruiterLeadResponse = await getRecruiterLead();
        const recruiterData = recruiterLeadResponse.data;
        setRecruiterData(recruiterData || []);

        const response = await getRthById(id);
        const assigned = response.data.recruiterLeadAssigned;
        const recruiterAssigned = response.data.recruiterAssigned;
        setIsAssigned(assigned);
        setIsRecruiterAssigned(recruiterAssigned);

        if (assigned) {
          setButtonClicked(true);
          setView(true);
          const response = await getRthById(id);          
          setRecruiterIds(response?.data?.assignedRecruiterLead[0]?.userId);
          setSelectedDate(response.data.recruiterLeadAssignedDate);             
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function
  }, [id, load]);

  const handleRecruiterIdChange = (selectedOption: any) => {
    if (selectedOption) {
      setRecruiterIds(selectedOption.value);
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().split("T")[0];
      setSelectedDate(formattedDate);
    } else {
      setRecruiterIds("");
    }
  };
  



  const handleButtonClick = () => {
    setButtonClicked(true);
  };

  const handleButtonCancel = () => {
    setButtonClicked(false);    
    setSelectedDate(null);
    dispatch(setSelectedRecruiter(""));
    dispatch(resetStatus());
    setLoad((prev:any)=>prev+1);
  };

  const handleSubmit = () => {
    console.log(recruiterIds,selectedDate)
    if (recruiterIds && selectedDate) {
      dispatch(
        assignRecruiterLead({
          rthId: id,
          recruiterLeadId: recruiterIds,
          assignedDate: selectedDate,
          taId: userId,
        })
      );
      toast.success("Recruiter Lead assigned Sucessfully");
      setLoad(1);
      setView(true);
    } else if (!recruiterIds && !selectedDate) {
      toast.warn("Select Recruiter Lead");
    }
  };

  const handleEditButton = () => {
    console.log("isRecruiterAssigned", isRecruiterAssigned);
    if (isRecruiterAssigned) {
      toast.warning("You can't edit since the recruiter has been assigned.");
    } else {
      setView(false);
    }
  };


  

  const formatDateForDisplay = (dateString: string) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = date.toLocaleString("en-GB", {
      month: "long",
      timeZone: "UTC",
    });
    const year = date.getUTCFullYear();
    return `${day} ${month} ${year}`;
  };

  return (
    <Box>
      {!isButtonClicked && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          flexDirection={"row"}
        >
          <Typography variant="h6" sx={{ fontSize: "12px", color: "#8C8C8C" }}>
            Click "Assign Recruiter Lead" to allocate the {numOfOpenPosition}{" "}
            available positions.
          </Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ border: "1.5px solid" }}
            onClick={handleButtonClick}
          >
            Assign Recruiter Lead
          </Button>
        </Box>
      )}

      {isButtonClicked && (
        <Box>
          {isAssigned && (
            <Box justifyContent={"end"} display={"flex"}>
              <Box
                component={ButtonBase}
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                onClick={handleEditButton}
                sx={{
                  padding: 1,
                  borderRadius: 1,
                  transition: "background-color 0.3s",
                  "&:hover": { backgroundColor: "rgba(22, 119, 255, 0.1)" },
                }}
              >
                <IconButton sx={{ padding: 0 }}>
                  <img src={editIcon2} alt="edit Icon" />
                </IconButton>
                <Typography color={"#1677FF"} sx={{ marginLeft: 0.5 }}>
                  Edit
                </Typography>
              </Box>
            </Box>
          )}

          <TableContainer
            component={Paper}
            sx={{
              marginBottom: "1.5%",
              borderRadius: "0px",
              boxShadow: "none",
            }}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              size="small"
            >
              <TableHead>
                <TableRow sx={{ backgroundColor: "#F9F9F9" }}>
                  <TableCell align="left">No. of Open Position</TableCell>
                  <TableCell align="left">Assign Recruiter Lead</TableCell>
                  <TableCell
                    sx={{ alignContent: "center", alignItems: "center" }}
                    align="center"
                  >
                    <span>Assigned Date</span>
                    <img src={legendLogo} alt="" />{" "}
                    <span style={{ color: "grey", fontSize: "10px" }}>
                      assigned date can't be edited
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="left">{numOfOpenPosition}</TableCell>
                  <TableCell align="left" sx={{ padding: "0px" }} width={"50%"}>
                  <Select
  isClearable
  isDisabled={view}
  options={recruiterData
    .filter((data) => data.userId !== recruiterIds)
    .map((data) => ({
      value: data.userId,
      label: `${data.firstName} ${data.lastName}`,
      imageUrl: data.imageUrl,
      designation: data.designation,
    }))}
  value={
    recruiterIds
      ? recruiterData
          .map((data) => ({
            value: data.userId,
            label: `${data.firstName} ${data.lastName}`,
            imageUrl: data.imageUrl,
            designation: data.designation,
          }))
          .find((option) => option.value === recruiterIds)
      : null
  }
  onChange={handleRecruiterIdChange}
  formatOptionLabel={(data) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {data.imageUrl ? (
        <img
          src={data.imageUrl}
          alt={data.label}
          style={{
            width: 40,
            height: 40,
            borderRadius: "50%",
          }}
        />
      ) : (
        <Avatar
          alt={data.label}
          src={data.imageUrl || data.label}
        />
      )}
      <div style={{ marginLeft: 8 }}>
        <div style={{ fontWeight: "bold" }}>{data.label}</div>
        <div style={{ fontSize: "0.75rem", color: "#555" }}>
          {data.designation}
        </div>
      </div>
    </div>
  )}
  menuPortalTarget={document.body}
  styles={{
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (base) => ({
      ...base,
      minHeight: 50,
      padding: "5px",
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 8px",
    }),
  }}
  menuPosition="absolute"
/>

                  </TableCell>
                  <TableCell align="center">
                    {selectedDate ? formatDateForDisplay(selectedDate) : "-"}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {isAssigned === false && (
            <Box display={"flex"} justifyContent={"end"} gap={3}>
              <Button
                variant="text"
                sx={{ color: "grey" }}
                onClick={handleButtonCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={status === "loading"}
              >
                {status === "loading" ? "Submitting..." : "Submit"}
              </Button>
            </Box>
          )}

          {isAssigned === true && view === false && (
            <Box display={"flex"} justifyContent={"end"} gap={3}>
              <Button
                variant="text"
                sx={{ color: "grey" }}
                onClick={handleButtonCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={status === "loading"}
              >
                {status === "loading" ? "Submitting..." : "Save Changes"}
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AssignRecruiterLead;
