import React, { useEffect, useState } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Button, Breadcrumbs, Link, useMediaQuery } from '@mui/material';
import InterviewEvaluationFormLogo from "../../../../assets/images/InterviewEvaluationFormLogo.svg";
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BulkUploadForm from './forms';
import AdditionalInformation from './AdditionalInformation';
import Address from './AddressForm';
import Experience from './ExperienceForm';
import Education from './EducationForm';
import Review from './ReviewForm';
// import backgroundImage from '../../../../assets/images/feedbackBacgroundImage.svg';
import UploadResume from './UploadResume';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { addCandidate, getRthDetailsByRthId } from '../../../../redux/features/resume';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import {Link as RouterLink} from 'react-router-dom'; 
 
const steps = ['Upload Resume', 'Personal Information', 'Additional Information', 'Address', 'Experience', 'Education', 'Review'];
 
const CustomStepIcon = styled('div')<{ active?: boolean; completed?: boolean }>(
  ({ theme, active, completed }) => ({
    width: 30,
    height: 30,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: active
      ? 'linear-gradient(135deg, #36CFC9 0%, #2F54EB 100%)' // Gradient for active step
      : completed
        ? 'linear-gradient(135deg, #36CFC9 0%, #2F54EB 100%)' // Same gradient for completed
        : 'transparent',
    border: completed || active
      ? 'none'
      : `2px solid ${theme.palette.grey[400]}`,
    color: active || completed ? '#fff' : theme.palette.grey[500], // White text for active/completed
    fontWeight: 500,
    fontSize: 16,
    transition: 'all 0.3s ease',
    position: 'relative',
    boxSizing: 'border-box',
    boxShadow: active || completed ? '0px 0px 6px rgba(0, 0, 0, 0.2)' : 'none',
 
    '& svg': {
      fontSize: 20,
      display: completed ? 'block' : 'none', // Tick icon for completed step
      padding: 0, // Remove extra padding
      margin: 0,
      // color:'#fff',
    },
 
  })
 
);
 
 
const CustomStepIconComponent = (props: { active?: boolean; completed?: boolean; icon: React.ReactNode }) => {
  const { active, completed, icon } = props;
  
  return (
    <CustomStepIcon active={active} completed={completed}>
      {completed ? <CheckCircleIcon /> : icon}
    </CustomStepIcon>
  );
};
 
export const CandidateFormsStepper = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [formValues, setFormValues] = useState<any>({});
  const[selectedFile,setSelectedFile]=useState<File[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const[otherErrors,setOtherErrors]=useState<{[key:string]:string}>({});
  const [aadharError,setAadharError]=useState<string | null>(null);
  const [aadharFile,setAadharFile]=useState<File[]>([]);
  const dispatch=useAppDispatch();
  const details=useAppSelector((state:any)=>state.resume.rthDetails);
  const[selectedState,setSelectedState]=useState<any>([]);
  const[selectedCountry,setSelectedCountry]=useState<any>([]);
  const[selectedCity,setSelectedCity]=useState<any>([]);
  const {id}=useParams();
  const navigate=useNavigate();
 
  const handleNext = () => {
    const isValid = validateForm();
    const hasOtherErrors = Object.values(otherErrors).some((error) => {
      if (Array.isArray(error)) {
        return error.some((item) => Object.values(item).some((val) => val !== ""));
      } else if (typeof error === 'object') {
        return Object.values(error).some((val) => val !== "");
      }
      return error !== "";
    });
    if(isValid && !hasOtherErrors){
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      handleSubmit();
    }
  }
  };

  const Md = useMediaQuery("(max-width: 1504px)");
 
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
 
  const handleSuccesfullySubmitted=()=>{
    navigate('/SuccessfullySubmitted')
  };
  const handleSubmit=async()=>{
   
    const payload={
      ...formValues,
      rthId:id,
      workExperience:formValues.workExperience==='Yes',
      status:'Completed',
      candidateType:'Uploaded'
    }
    const resultAction=await dispatch(addCandidate(payload));
    if (addCandidate.fulfilled.match(resultAction)) {
      const { status,message } = resultAction.payload || {};
      console.log(message,'oytrdc');
      
      if(status===true){
        setSelectedFile([]);
        setFormValues([]);
        setAadharFile([]);
        handleSuccesfullySubmitted();
      }
      else if(status===false){
        setSelectedFile([]);
        setFormValues([]);
        setAadharFile([]);
        toast.error(message)
      }
    } else {
      toast.error('Failed to submit candidate form');
    } 
    setActiveStep(0);
  }

  const validateAddress = (address:any) => {
    return address?.trim() === '' ? 'Address cannot be empty or contain only spaces.' : '';
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if(activeStep===1){
    if (!formValues.howDidYouHeardAbtUs) {
      newErrors.howDidYouHeardAbtUs = 'This is required';
    }
    const namePattern = /^[A-Za-z]+$/;
  
  if (!formValues.firstName) {
    newErrors.firstName = 'First Name is required';
  } else if (formValues.firstName.length > 100) {
    newErrors.firstName = 'First Name cannot exceed 100 characters';
  } else if (!namePattern.test(formValues.firstName)) {
    newErrors.firstName = 'First Name should contain only alphabets';
  }
  
  if (!formValues.lastName) {
    newErrors.lastName = 'Last Name is required';
  } else if (formValues.lastName.length > 100) {
    newErrors.lastName = 'Last Name cannot exceed 100 characters';
  } else if (!namePattern.test(formValues.lastName)) {
    newErrors.lastName = 'Last Name should contain only alphabets';
  }
  
    
    if (!formValues.expectedCtc) {
      newErrors.expectedCtc = 'Expected CTC is required';
    } else if (!/^\d{1,10}$/.test(formValues.expectedCtc)) {
      newErrors.expectedCtc = 'Expected CTC must be a numeric value up to 10 digits';
    }
    
    if (!formValues.noticePeriods) {
      newErrors.noticePeriods = 'Required field';
    } else if (!/^\d{1,3}$/.test(formValues.noticePeriods)) {
      newErrors.noticePeriods = 'Notice period must be a numeric value up to 3 digits';
    }
    
    if (!formValues.currentCtc) {
      newErrors.currentCtc = 'Current CTC is required';
    } else if (!/^\d{1,10}$/.test(formValues.currentCtc)) {
      newErrors.currentCtc = 'Current CTC must be a numeric value up to 10 digits';
    }
     
    if (!formValues.emailAddress) {
      newErrors.emailAddress = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.emailAddress)) {
      newErrors.emailAddress = 'Email Address is invalid';
    }
    if (!formValues.phoneNumber) {
      newErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      newErrors.phoneNumber = 'Phone Number must be 10 digits';
    }
  
    // if (!formValues.aadharNumber) {
    //   newErrors.aadharNumber = 'Aadhar Number is required';
    // } else if (!/^\d{12}$/.test(formValues.aadharNumber)) {
    //   newErrors.aadharNumber = 'Aadhar Number must be 12 digits';
    // }
    if (!formValues.dateOfBirth) {
      newErrors.dateOfBirth = 'Date of Birth is required';
    }
    if (!formValues.gender) {
      newErrors.gender = 'Gender is required';
    }
    // if(aadharFile.length <=0){
    //   setAadharError('Aadhar card is required');
    // }
    if (formValues.preEmployed === undefined || formValues.preEmployed === null) {
      newErrors.preEmployed = 'This field is required';
    }
  }
  if(activeStep===2){
    if (!formValues.nationality) {
      newErrors.nationality = 'Nationality is required';  
    }
    if (!formValues.citizenship) {
      newErrors.citizenship = 'Citizenship is required';
     }
  }
  if(activeStep===3){
    if (!formValues?.address?.country) {
      newErrors.country = 'Country is required';  
    }
    if (!formValues?.address?.state) {
      newErrors.state = 'State is required';
     }
     if (!formValues?.address?.addressLine1) {
      newErrors.addressLine1 = 'Address Line 1 is required';  
    }
    if (!formValues?.address?.addressLine2) {
      newErrors.addressLine2 = 'Address Line 2 is required';
     }
     if (!formValues?.address?.postalCode) {
      newErrors.postalCode = 'Postal Code is required';  
    }
    // if (!formValues?.address?.city) {
    //   newErrors.city = 'City is required';
    //  }
    const addressLine1Error = validateAddress(formValues?.address?.addressLine1);
    const addressLine2Error = validateAddress(formValues?.address?.addressLine2);
    if (addressLine1Error || addressLine2Error) {
      setErrors({
        ...errors,
          addressLine1: addressLine1Error,
          addressLine2: addressLine2Error,
      });
      return; // Stop submission if there are errors
    }
  }
  if(activeStep===5){

    if(formValues?.educations[0]){
    if(!formValues?.educations?.[0]?.university){
      newErrors.university='This Field is Required'
    }
    if(!formValues?.educations?.[0]?.degree){
      newErrors.degree='This Field is Required'
    }
    if(!formValues?.educations?.[0]?.fieldOfStudy){
      newErrors.fieldOfStudy='This Field is Required'
    }
    if(!formValues?.educations?.[0]?.from){
      newErrors.from='This Field is Required'
    }
    if(!formValues?.educations?.[0]?.to){
      newErrors.to='This Field is Required'
    }
  }
  }
  if(activeStep===4){
    if(formValues?.experience[0]&&(formValues.workExperience?.toLowerCase()==='yes')){
      // if(!formValues?.skills){
      //   newErrors.skills='This Field is Required'
      // }
      if(!formValues?.workExperience){
        newErrors.workExperience='This Field is Required'
      }
      if(!formValues?.totYearsOfExp){
        newErrors.totYearsOfExp='This Field is Required'
      }
      if(!formValues?.experience?.[0]?.jobTitle){
        newErrors.jobTitle='This Field is Required'
      }
      if(!formValues?.experience?.[0]?.company){
        newErrors.company='This Field is Required'
      }
      if(!formValues?.experience?.[0]?.from){
        newErrors.from='This Field is Required'
      }
      if(!formValues?.experience?.[0]?.to){
        newErrors.to='This Field is Required'
      }
    }
  }

    setErrors(newErrors);
    setOtherErrors(newErrors);
  
    return Object.keys(newErrors).length === 0;
  };

  const stepComponents = [
    
    <UploadResume selectedFile={selectedFile} setSelectedFile={setSelectedFile} handleNext={handleNext} formValues={formValues} setFormValues={setFormValues}/>,
    <BulkUploadForm formValues={formValues} setFormValues={setFormValues} handleNextPage={handleNext} handleBack={handleBack} validateForm={validateForm} 
    errors={errors} aadharError={aadharError} setAadharFile={setAadharFile} aadharFile={aadharFile} setErrors={setErrors} setAadharError={setAadharError} />,
    <AdditionalInformation formValues={formValues} setFormValues={setFormValues} errors={otherErrors} setErrors={setOtherErrors} />,
    <Address formValues={formValues} setFormValues={setFormValues} errors={otherErrors} setErrors={setOtherErrors}selectedState={selectedState} setSelectedState={setSelectedState}
    selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} selectedCity={selectedCity} setSelectedCity={setSelectedCity}/>,    
      <Experience formValues={formValues} setFormValues={setFormValues} errors={otherErrors} setErrors={setOtherErrors} />,
      <Education formValues={formValues} setFormValues={setFormValues} errors={otherErrors} setErrors={setOtherErrors} />,
    <Review formValues={formValues} setFormValues={setFormValues} selectedFile={formValues} />,
  ];

 useEffect(()=>{
    dispatch(getRthDetailsByRthId(id));
  },[id,dispatch]);
  return (
    <Box sx={{ backgroundColor: 'white', height: '100vh' }}>
      {/* Header */}
      <Box sx={{ background: 'linear-gradient(90deg, #36CFC9 0%, #2F54EB 100%)', p: 2 ,height:'40px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <img
            src={InterviewEvaluationFormLogo}
            alt="Theron logo"
            style={{ width: '50px', height: '50px', marginRight: '10px' }}
          />
          <Typography sx={{ color: 'white', fontWeight: 500, fontSize: '20px' }}>
            Theron-Cavin Infotech
          </Typography>
        </Box>
      </Box>
 
      {/* Job Position */}
      <Box
        sx={{
          // backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'right',
          backgroundRepeat: 'no-repeat',
        }}
      >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1,mt:'20px',ml:'20px' }}>     
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>                       
          <Typography sx={{ml:'50px',fontSize:'22px'}} fontWeight="bold">
            {details?.positionTitle}
          </Typography>
        </Box>
        
        <Box sx={{ml:'90px'}}>
        <Breadcrumbs separator="•" aria-label="breadcrumb">
          <Typography color="inherit">
            Home
          </Typography>
          <Typography color="inherit">
            Job Listing
          </Typography>
          <Link underline="hover" color="text.primary" component={RouterLink} to={`/candidateForm/${id}`}>
            Job Details
          </Link>
          <Typography color="inherit">{details?.positionTitle}</Typography>
        </Breadcrumbs>
        </Box>
      </Box>
 
 
        {/* Stepper */}
        <Box sx={{ mt: '20px', ml: '80px', width: '80vw' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={CustomStepIconComponent}
                  sx={{ '& .MuiStepLabel-label': { fontWeight: activeStep === steps.indexOf(label) ? 'bold' : 'normal' } }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
 

        <Box sx={{ mt:'20px', ml: '90px', overflowY:'scroll', height: Md? ( activeStep === 0 ? "auto" : activeStep === 1? '280px':'220px' ) : ( activeStep === 0 ? "auto" : activeStep === 1? '450px' : '380px' ), OverflowY:'scroll'}}>
          {/* <Paper sx={{overflowY:'scroll',height:'30vh'}}> */}
          {stepComponents[activeStep]}
          {/* </Paper> */}  
        </Box>
 
        {activeStep !== 0 && activeStep !== 1 && ( // Conditionally render the box
        <Box
          sx={{
            position: 'absolute',
            bottom: Md?'25px':'35px',
            left: '120px',
            display: 'flex',
            gap: 2,
          }}
        >
          {activeStep !== 0 && (
            <Button
              onClick={handleBack}
              sx={{border:'InactiveBorder',color:'#848484',textTransform:'none'}}
            >
              Previous
            </Button>
          )}
 
          <Button
            variant="contained"
            onClick={handleNext}
            sx={{
              minWidth: '100px',
              textTransform:'none',
              backgroundColor:'#1677FF',
              color:'#fff',
            }}
          >
            {activeStep === steps.length - 1 ? 'Submit Application' : 'Continue'}
          </Button>
        </Box>
        )}
      </Box>
    </Box>
  );
};
