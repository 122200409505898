 import React, { useEffect, useState } from "react";
import { Box, Grid, InputAdornment, InputBase, CircularProgress } from "@mui/material";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
import { getToBeAssignedAPIData } from "../../../../Crud/card";
import ToBeAssignedCard from "./TobeAssignedCard";
import { useAppSelector } from "../../../../redux/hooks";
import SearchIcon from "@mui/icons-material/Search";
// import { jwtDecode } from "jwt-decode";
import { createTheme, ThemeProvider } from '@mui/material/styles';


const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,        // Extra-small: <600px
      sm: 600,      // Small: 600px to 960px
      md: 960,      // Medium: 960px to 1280px
      lg: 1380,     // Large: 1280px to 1920px
      xl: 1920,     // Extra-large: >1920px
    },
  },
});

interface MyRequestsCardProps {
  drawerOpen: boolean;
  setLowCount: Function;
  setMediumCount: Function
  setHighCount: Function
}

const ToBeAssigned: React.FC<MyRequestsCardProps> = ({ drawerOpen,setLowCount, setHighCount, setMediumCount }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const userId = localStorage.getItem("userId") ?? "";
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const role = localStorage.getItem("user")?JSON.parse(localStorage.user)?.roleName:"";

  const { roleName } = localStorage.getItem("user")
  ? JSON.parse(localStorage.user)
  : null;

  const userData = localStorage.getItem("user") 
    ? JSON.parse(localStorage.getItem("user") || '{}')
    : { 
        permissionsList: {}, 
        roleName: '' 
      };

  const permissionsList = userData.permissionsList;


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }

    // assignAndEditRecruiter
    // permissionsList.assignAndEditRecruiter
    const timeoutId = setTimeout(() => {
      setLoading(true);
      getToBeAssignedAPIData(userId, teamHeadFilter, searchQuery)      
        .then((res) => {          
          console.log("permissionsList.assignAndEditRecruiterLead",permissionsList.assignAndEditRecruiterLead)
          console.log("permissionsList.assignAndEditRecruiter",permissionsList.assignAndEditRecruiter);
          if(permissionsList.assignAndEditRecruiterLead)
          {            
            const filteredData=res?.data?.listOfRth?.filter((item:any)=>
              item.recruiterAssigned === false && item.recruiterLeadAssigned === false            
            ) || [];
            setData(filteredData);            
          }          
          if(permissionsList.assignAndEditRecruiter)
          {
            const filteredData=res?.data?.listOfRth?.filter((item:any)=>
              item.recruiterAssigned === false && item.recruiterLeadAssigned === true
            ) || [];
            console.log("filteredData",filteredData);
            setData(filteredData);
          }
          setLowCount(res?.data?.count?.ToBeAssignedPriorityCount?.LowCount);
          setMediumCount(res?.data?.count?.ToBeAssignedPriorityCount?.MediumCount);
          setHighCount(res?.data?.count?.ToBeAssignedPriorityCount?.HighCount);          
        })
        .catch((error) => {
          setData([]);
          console.error("Error fetching ToBeAssigned data:", error);
        })
        .finally(() => {
          setLoading(false);
          setSearchLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [userId, searchQuery, teamHeadFilter,role,permissionsList.assignAndEditRecruiter,permissionsList.assignMrf,roleName,permissionsList.assignAndEditRecruiterLead,setMediumCount,setLowCount,setHighCount]);

  // if (loading) {
  //   return <Box>Loading...</Box>;
  // }

  return (
    <>
    <ThemeProvider theme={customTheme}>
      <Grid
        item
        sx={{ marginTop: data.length > 0 ? "-54px" : "-58px", paddingLeft:"10px" }}
        position="absolute"
      >
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 0,
            backgroundColor: "white",
            minHeight: "3px",
            fontSize: 14,
            ".MuiInputBase-input": {
              padding: "5px 5px",
            },
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '25%',
              transform: 'translateY(-50%)',
              color: '#2F54EB',
            }}
          />
        )}
      </Grid>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
            }}
          >
            <CircularProgress size={100} sx={{ color: '#2F54EB' }} />
          </Box>
        ) :

          data.length > 0 ? (
            <Grid
              container
              spacing={0}
              sx={{
                display: "flex",
                flexWrap: "wrap",
              }}

            >
              {data.map((job: any, index: number) => (
                <Grid
                  item
                  xs={12} 
                  sm={6}  
                  md={drawerOpen ? 4 : 3}  
                  lg={drawerOpen ? 3 : 2.4}  
                  xl={drawerOpen ? 3 : 2.4} 
                  key={index} // Conditional layout for large screens
                  sx={{
                    minWidth: "100px", // Ensure a minimum width for cards
                    maxWidth: "300px", // Limit max width for uniform appearance
                    flexGrow: 3, // Allow flexibility in layout
                  }}
                >
                  <ToBeAssignedCard job={job} role={role}/>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "300px",
              }}
            >
              <img
                src={NoDataAvail}
                alt="No Data Available"
                style={{ maxWidth: "200px" }}
              />
            </Box>
          )}
      </Box>
      </ThemeProvider>
    </>
  );
};

export default ToBeAssigned;
