import React from 'react';
import { Box } from '@mui/material';
import BaseJobCard from '../BaseJobCard';
import ToBeAssigned from '../../commoncard/TobeAssigned';
import { Job } from '../../commoncard/types';

interface ToBeAssignedCardProps {
  job?: Job;
  role:string;
}

const ToBeAssignedCard: React.FC<ToBeAssignedCardProps> = ({ job,role }) => {
  return (
    <BaseJobCard job={job} label="To Be Assigned" queryParam= "tobe-assigned">
      <Box sx={{ width: "100%" }}>        
        <ToBeAssigned role={role} job={job}/>
      </Box>
    </BaseJobCard>
  );
};

export default ToBeAssignedCard;