import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Switch, Typography, IconButton, TextField, Collapse, styled, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../redux/store';
import { editRole, fetchRoles } from '../../../../redux/features/roleSlice';
import { RolesData } from './AddRole';
import { toast } from 'react-toastify';

const RightDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    justifyContent: 'flex-end',
  },
  '& .MuiDialog-paper': {
    margin: 0,
    maxWidth: '400px',
    width: '100%',
    height: '100%',
    maxHeight: 'none',
    borderRadius: 0,
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 32,
  height: 16,
  padding: 0,
  marginTop: '12px',
  display: 'flex',
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc',
        }),
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 8,
  },
  '& .MuiSwitch-track': {
    borderRadius: 12,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)',
    }),
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
  children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
}

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  defaultExpanded?: boolean;
  sectionKey: keyof Permissions;
  onSectionToggle?: (key: keyof Permissions, value: boolean) => void;
  checked?: boolean
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  children,
  defaultExpanded = false,
  sectionKey,
  onSectionToggle,
  checked = false
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  const [sectionSwitch, setSectionSwitch] = useState(false);

  const handleExpandClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const handleSwitchClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    const newSwitchState = !sectionSwitch;
    setSectionSwitch(newSwitchState);
    if (onSectionToggle) {
      onSectionToggle(sectionKey, newSwitchState);
    }
  };

  return (
    <Box sx={{ borderBottom: '1px solid #f0f0f0' }}>
      <Box
        onClick={handleExpandClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1,
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {isExpanded ? (
            <ExpandMoreIcon sx={{ fontSize: 20, color: 'text.secondary', mr: 1 }} />
          ) : (
            <ChevronRightIcon sx={{ fontSize: 20, color: 'text.secondary', mr: 1 }} />
          )}
          <Typography variant="caption">{title}</Typography>
        </Box>
        <AntSwitch
          size="small"
          color="primary"
          checked={checked}
          onClick={handleSwitchClick}
        />
      </Box>
      <Collapse in={isExpanded}>
        <Box sx={{ ml: 4, mb: 2 }}>{children}</Box>
      </Collapse>
    </Box>
  );
};

interface AddRoleModalProps {
  open: boolean;
  onClose: () => void;
  user: RolesData
}

interface Permissions {
  recruiterAccess?: boolean;
  assignAndEditRecruiter?: boolean;
  assignAndEditRecruiterLead?: boolean;
  mrfUsers?: boolean;
  addInterviewer?: boolean;
  assignMrf?: boolean;
  viewMrfStage?: boolean;
  editMrfStage?: boolean;
  viewAddComments?: boolean;
  viewResumes?: boolean;
  viewInterviewFeedback?: boolean;
  receiveAlerts?: boolean;
  scheduleRemindersForInterviews?: boolean;
  manageUserSettings?: boolean;
  viewAndManageRoles?: boolean;
  assignRolesToUsers?: boolean;
  recruitment?: boolean;
  mrfUsersApprovers?: boolean;
  useFiltersForLeadsApprovers?: boolean;
  overAllReport?: boolean;
  allPersonLogin?: boolean;
  approveDeclineMrf?: boolean;
  interviewCalenderStatus?: boolean;
  mrfHub?: boolean;
  resumeVault?: boolean;
  interviewSuite?: boolean;
  filters?: boolean;
  notification?: boolean;
  settings?: boolean;
  focusBoard?: boolean;
  reportsAndMetrics?: boolean;
  commonUser?: boolean;
  resumeOwner?: boolean;
  offerApprover?: boolean;
}

const EditRole: React.FC<AddRoleModalProps> = ({ open, onClose, user }) => {
  const dispatch = useDispatch<AppDispatch>();
  const [formData, setFormData] = React.useState<RolesData>(user);
  const ALPHABETIC_REGEX = /^[A-Za-z]$/;

  useEffect(() => {
    setFormData(user);
  }, [user]);

  const isSectionToggleChecked = (section: keyof Permissions, permissionsList: Permissions): boolean => {
    if (section === "mrfHub") {
      return (
        !!permissionsList.mrfUsers &&
        !!permissionsList.approveDeclineMrf &&
        !!permissionsList.addInterviewer &&
        !!permissionsList.assignMrf &&
        !!permissionsList.viewMrfStage &&
        !!permissionsList.editMrfStage &&
        !!permissionsList.viewAddComments
      );
    } else if (section === "resumeVault") {
      return !!permissionsList.viewResumes;
    } else if (section === "interviewSuite") {
      return (
        !!permissionsList.interviewCalenderStatus &&
        !!permissionsList.assignAndEditRecruiter &&
        !!permissionsList.assignAndEditRecruiterLead &&
        !!permissionsList.recruiterAccess &&
        !!permissionsList.viewInterviewFeedback
      );
    } else if (section === 'notification') {
      return (
        !!permissionsList.receiveAlerts &&
        !!permissionsList.scheduleRemindersForInterviews
      )
    }
    else if (section === 'settings') {
      return (
        !!permissionsList.manageUserSettings &&
        !!permissionsList.viewAndManageRoles &&
        !!permissionsList.assignRolesToUsers
      )
    }
    else if (section === 'focusBoard') {
      return (
        !!permissionsList.recruitment &&
        !!permissionsList.mrfUsersApprovers &&
        !!permissionsList.useFiltersForLeadsApprovers
      )
    } else if (section === 'reportsAndMetrics') {
      return (
        !!permissionsList.overAllReport
      )
    } else if (section === 'commonUser') {
      return (
        !!permissionsList.allPersonLogin
      )
    } else if (section === 'resumeOwner') {
      return (
        !!permissionsList.resumeOwner
      )
    } else if (section === 'offerApprover') {
      return (
        !!permissionsList.offerApprover
      )
    }
    return false;
  };

  const handleSectionToggle = (section: keyof Permissions, value: boolean) => {
    const sectionPermissions: Partial<Permissions> = {};
    if (section === 'mrfHub') {
      sectionPermissions.mrfUsers = value;
      sectionPermissions.approveDeclineMrf = value;
      sectionPermissions.addInterviewer = value;
      sectionPermissions.assignMrf = value;
      sectionPermissions.viewMrfStage = value;
      sectionPermissions.editMrfStage = value;
      sectionPermissions.viewAddComments = value;

    } else if (section === 'resumeVault') {
      sectionPermissions.viewResumes = value;
    } else if (section === 'interviewSuite') {
      sectionPermissions.interviewCalenderStatus = value;
      sectionPermissions.assignAndEditRecruiter = value
      sectionPermissions.assignAndEditRecruiterLead = value;
      sectionPermissions.recruiterAccess = value;
      sectionPermissions.viewInterviewFeedback = value;
    } else if (section === 'notification') {
      sectionPermissions.receiveAlerts = value;
      sectionPermissions.scheduleRemindersForInterviews = value;
    } else if (section === 'settings') {
      sectionPermissions.manageUserSettings = value;
      sectionPermissions.viewAndManageRoles = value;
      sectionPermissions.assignRolesToUsers = value;
    } else if (section === 'focusBoard') {
      sectionPermissions.recruitment = value;
      sectionPermissions.mrfUsersApprovers = value;
      sectionPermissions.useFiltersForLeadsApprovers = value;
    } else if (section === 'reportsAndMetrics') {
      sectionPermissions.overAllReport = value;
    } else if (section === 'commonUser') {
      sectionPermissions.allPersonLogin = value;
    } else if (section === 'resumeOwner') {
      sectionPermissions.resumeOwner = value;
    } else if (section === 'offerApprover') {
      sectionPermissions.offerApprover = value;
    }
    setFormData((prev) => ({
      ...prev,
      permissionsList: {
        ...prev.permissionsList,
        ...sectionPermissions,
      },
    }));
  };

  const handleSubmit = async () => {
    const hasPermission = Object.values(formData.permissionsList).includes(true);
    if (!hasPermission) {
      toast.error("Please select at least one permission.");
      return;
    }
    try {
      await dispatch(editRole(formData)).unwrap();
      await dispatch(fetchRoles());
      // toast.success("Role Name Updated");
      onClose();
    } catch (error) {
      toast.error("Role already exists");
    }
  };

  const handleSwitchChange = (permission: keyof Permissions) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      permissionsList: {
        ...prev.permissionsList,
        [permission]: event.target.checked,
      },
    }));
  };

  const renderMrfHubSection = () => {
    const isMrfHubChecked = isSectionToggleChecked("mrfHub", formData.permissionsList);
    return (
      <CollapsibleSection title="MRF Hub" sectionKey="mrfHub" checked={isMrfHubChecked} onSectionToggle={handleSectionToggle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">MRF User</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.mrfUsers ?? false} onChange={handleSwitchChange("mrfUsers" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Approve/Decline MRF</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.approveDeclineMrf ?? false} onChange={handleSwitchChange("approveDeclineMrf" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Add Interviewer</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.addInterviewer ?? false} onChange={handleSwitchChange("addInterviewer" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box> */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Assign MRF</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.assignMrf ?? false} onChange={handleSwitchChange("assignMrf" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">View MRF Stage</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.viewMrfStage ?? false} onChange={handleSwitchChange("viewMrfStage" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Edit MRF Stage</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.editMrfStage ?? false} onChange={handleSwitchChange("editMrfStage" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">View/Add Comments</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.viewAddComments ?? false} onChange={handleSwitchChange("viewAddComments" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
        </Box>
      </CollapsibleSection>
    )
  };

  const renderResumeSection = () => {
    const isResumeVaultChecked = isSectionToggleChecked("resumeVault", formData.permissionsList);
    return (
      <CollapsibleSection title="Resume Vault" sectionKey="resumeVault" checked={isResumeVaultChecked} onSectionToggle={handleSectionToggle}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="caption">View Resumes</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.viewResumes ?? false} onChange={handleSwitchChange("viewResumes" as keyof Permissions)} inputProps={{ "aria-label": "status switch" }} />
          </Box>
        </Box>
      </CollapsibleSection>
    );
  };

  const renderInterviewSuiteSection = () => {
    const isInterviewSuiteChecked = isSectionToggleChecked("interviewSuite", formData.permissionsList);
    return (
      <CollapsibleSection title="Interview Suite" sectionKey='interviewSuite' checked={isInterviewSuiteChecked} onSectionToggle={handleSectionToggle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Interview Calendar & Status</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.interviewCalenderStatus ?? false} onChange={handleSwitchChange("interviewCalenderStatus" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Assign & Edit recruiter Lead</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.assignAndEditRecruiterLead ?? false} onChange={handleSwitchChange("assignAndEditRecruiterLead" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Assign & Edit recruiter</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.assignAndEditRecruiter ?? false} onChange={handleSwitchChange("assignAndEditRecruiter" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Recruiter access</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.recruiterAccess ?? false} onChange={handleSwitchChange("recruiterAccess" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">View Interview Feedback</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.viewInterviewFeedback ?? false} onChange={handleSwitchChange("viewInterviewFeedback" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
        </Box>
      </CollapsibleSection>
    );
  }

  const renderNotificationSection = () => {
    const isNotificationsChecked = isSectionToggleChecked("notification", formData.permissionsList);
    return (<CollapsibleSection title="Notification" sectionKey='notification' checked={isNotificationsChecked} onSectionToggle={handleSectionToggle}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption"> Receive Alerts for MRF/Interviews/MRF stages changes/ interview Status changes/ Approvals based on the permissions accessed </Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.receiveAlerts ?? false} onChange={handleSwitchChange("receiveAlerts" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Schedule Reminders for Interviews</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.scheduleRemindersForInterviews ?? false} onChange={handleSwitchChange("scheduleRemindersForInterviews" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>
    );
  }

  const renderSettingsSection = () => {
    const isSettingsChecked = isSectionToggleChecked("settings", formData.permissionsList);
    return (
      <CollapsibleSection title="Settings" sectionKey='settings' checked={isSettingsChecked} onSectionToggle={handleSectionToggle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Manage User Settings (Profile, Notifications)</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.manageUserSettings ?? false} onChange={handleSwitchChange("manageUserSettings" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">View and Manage Roles</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.viewAndManageRoles ?? false} onChange={handleSwitchChange("viewAndManageRoles" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Assign Roles to Users</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.assignRolesToUsers ?? false} onChange={handleSwitchChange("assignRolesToUsers" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
        </Box>
      </CollapsibleSection>
    )
  };

  const renderFocusBoardSection = () => {
    const isFocusBoardChecked = isSectionToggleChecked("focusBoard", formData.permissionsList);
    return (<CollapsibleSection title="FocusBoard" sectionKey='focusBoard' checked={isFocusBoardChecked} onSectionToggle={handleSectionToggle}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">Recruitment</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.recruitment ?? false} onChange={handleSwitchChange("recruitment" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">MRF Users Approvers</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.mrfUsersApprovers ?? false} onChange={handleSwitchChange("mrfUsersApprovers" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="caption">User Filters For Leads & Approvers</Typography>
          <AntSwitch color="primary" checked={formData.permissionsList?.useFiltersForLeadsApprovers ?? false} onChange={handleSwitchChange("useFiltersForLeadsApprovers" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
        </Box>
      </Box>
    </CollapsibleSection>)
  }

  const renderReportsAndMetricsSection = () => {
    const isReportsAndMetricsChecked = isSectionToggleChecked("reportsAndMetrics", formData.permissionsList);
    return (
      <CollapsibleSection title="Reports & Metrics" sectionKey='reportsAndMetrics' checked={isReportsAndMetricsChecked} onSectionToggle={handleSectionToggle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Overall Reports</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.overAllReport ?? false} onChange={handleSwitchChange("overAllReport" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
        </Box>
      </CollapsibleSection>
    )
  }

  const renderCommonUserSection = () => {
    const isCommonUserChecked = isSectionToggleChecked("commonUser", formData.permissionsList);
    return (
      <CollapsibleSection title="Common User" sectionKey='commonUser' checked={isCommonUserChecked} onSectionToggle={handleSectionToggle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">All Person comman Login</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.allPersonLogin ?? false} onChange={handleSwitchChange("allPersonLogin" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
        </Box>
      </CollapsibleSection>
    )
  }

  const renderResumeOwnerSection = () => {
    const isResumeOwnerChecked = isSectionToggleChecked("resumeOwner", formData.permissionsList);
    return (
      <CollapsibleSection title="Resume Owner" sectionKey='resumeOwner' checked={isResumeOwnerChecked} onSectionToggle={handleSectionToggle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Change candidate status, and schedule & edit the Interview</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.resumeOwner ?? false} onChange={handleSwitchChange("resumeOwner" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
        </Box>
      </CollapsibleSection>
    )
  }

  const renderOfferApproverSection = () => {
    const isOfferApproverChecked = isSectionToggleChecked("offerApprover", formData.permissionsList);
    return (
      <CollapsibleSection title="Offer Approver" sectionKey='offerApprover' checked={isOfferApproverChecked} onSectionToggle={handleSectionToggle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="caption">Offer Approver</Typography>
            <AntSwitch color="primary" checked={formData.permissionsList?.offerApprover ?? false} onChange={handleSwitchChange("offerApprover" as keyof Permissions)} inputProps={{ 'aria-label': 'status switch' }} />
          </Box>
        </Box>
      </CollapsibleSection>
    )
  }

  return (
    <RightDialog open={open} onClose={onClose} TransitionComponent={Transition} hideBackdrop={false} >
      <DialogTitle sx={{ p: 2, borderBottom: '1px solid #f0f0f0' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" sx={{ fontSize: '16px', fontWeight: 500 }}>Edit Role</Typography>
          <IconButton aria-label="close" onClick={onClose} sx={{ position: "absolute", right: 8, top: 8, backgroundColor: "#ECECEC", }}>
            <CloseIcon sx={{ fontSize: '12px' }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: 2 }}>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle2" sx={{ mb: 1, fontSize: '12px' }}> Role Name </Typography>
          <TextField
            name="roleName"
            placeholder="Enter Role Name"
            fullWidth
            value={formData.roleName || ''}
            inputProps={{ maxLength: 100 }}
            onChange={(e) => {
              const sanitizedValue = e.target.value.replace(/[^A-Za-z]/g, "").toUpperCase();
              setFormData((prev) => ({ ...prev, roleName: sanitizedValue }));
            }}
            onKeyDown={(event) => {
              const allowedKeys = ["Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"];
              if (!ALPHABETIC_REGEX.test(event.key) && !allowedKeys.includes(event.key)) {
                event.preventDefault();
              }
            }}
            size="small"
            sx={{
              mb: 3,
              "& .MuiInputBase-input": {
                fontSize: "12px",
              },
              "& .MuiInputLabel-root": {
                fontSize: "12px",
              },
            }}
          />
        </Box>
        {renderMrfHubSection()}
        {renderResumeSection()}
        {renderInterviewSuiteSection()}
        {renderNotificationSection()}
        {renderSettingsSection()}
        {renderFocusBoardSection()}
        {renderReportsAndMetricsSection()}
        {renderCommonUserSection()}
        {renderResumeOwnerSection()}
        {renderOfferApproverSection()}
      </DialogContent>
      <DialogActions sx={{ p: 2.5, borderTop: '1px solid #f0f0f0', position: 'sticky', bottom: 0, bgcolor: 'background.paper' }}>
        <Button onClick={handleSubmit} disabled={!formData.roleName} variant="contained" sx={{ textTransform: 'none', fontSize: '12px', borderRadius: '8px', backgroundColor: '#1677FF' }} > Update </Button>
      </DialogActions>
    </RightDialog>
  );
};

export default EditRole;