import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { getJobPostedInAPIData } from "../../Crud/card";
import { useAppSelector } from "../../redux/hooks";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setInterviewRound } from "../../redux/features/commonSlice";

function InterviewRounds() {
  const { jobData } = useAppSelector((state) => state.jobs);
    // const [activeRounds, setActiveRounds] = useState([false, false, false]);
  const [activeRounds, setActiveRounds] = useState<boolean[]>([]);

  console.log('jobData',jobData?.numOfRounds)
  const [switchStates, setSwitchStates] = useState({
    indeed: false,
    naukri: false,
    linkedIn: false,
  });
  console.log("switchStates",switchStates)
  const [switchDate, setSwitchDate] = useState({
      indeed: "",
      naukri: "",
      linkedIn: "",
    });
  const dispatch = useDispatch();
  useEffect(() => {
      const numOfRounds = Number(jobData?.numOfRounds) || 0; 
      const initialRounds = Array(3)
        .fill(false)
        .map((_, index) => index < numOfRounds);
      setActiveRounds(initialRounds);

    }, [jobData?.numOfRounds]);

    useEffect(() => {
      if (jobData?.postedIn) {
        setSwitchStates((prevState) => {
          const updatedStates = Object.keys(prevState).reduce((acc, key) => {
            acc[key] = Object.hasOwn(jobData.postedIn, key); 
            return acc;
          }, {} as Record<string, boolean>);
    
          return {
            ...prevState,
            ...updatedStates,
          };
        });
      }
    }, [jobData?.postedIn]);
    
  // const handleSubmit = async (index:number) => {
  //   const updatedRounds = activeRounds.map((_, idx) => idx <= index);
  //   console.log("updatedRounds",updatedRounds)
  //   dispatch(setInterviewRound(index+1))
  //   setActiveRounds(updatedRounds);
  //   await getJobPostedInAPIData(jobData?.rthId, switchStates,index+1)
  //     .then(() => toast.success("Interview round added successfully"))
  //     .catch(() => toast.error("Interview round add failed"));
  // };
    useEffect(()=>{
        setSwitchDate({
          indeed: jobData?.postedIn?.indeed,
          naukri: jobData?.postedIn?.naukri,
          linkedIn: jobData?.postedIn?.linkedIn,
        })
      },[jobData?.postedIn,setSwitchDate])

const handleSubmit = async (index:number) => {
      const filteredPostedIn = Object.fromEntries(
        Object.entries(switchDate).filter(([key, value]) => value !== "")
      );
      const updatedRounds = activeRounds.map((_, idx) => idx <= index);
      console.log("updatedRounds",updatedRounds)
      dispatch(setInterviewRound(index+1))
      setActiveRounds(updatedRounds);
      const reqBody = {
        rthId: jobData?.rthId,
        postedIn: filteredPostedIn,  // Pass only non-empty key-value pairs
        noOfRounds: index+1,
      };
    
      try {
        await getJobPostedInAPIData(reqBody);
        toast.success("Interview round added successfully")
      } catch (error) {
        toast.error("Interview round add failed")
      }
    };
  return (
    <Box
      sx={{
        p: 3,
        backgroundColor: "background.default",
        borderRadius: 2,
        ml: "16px",
        mr: "16px",
        mb: "20px",
        position: "relative",
      }}
    >
      <Typography
        sx={{ fontSize: "12px", color: "#8C8C8C", fontWeight: "700px" }}
      >
        Interview Rounds
      </Typography>

      {["Round 1", "Round 2", "Round 3"].map((round, index) => (
        <Button
          key={index}
          onClick={()=>handleSubmit(index)}
          sx={{
            border: `1px solid ${activeRounds[index] ? "#1677FF" : "#9A9A9A"}`,
            backgroundColor: activeRounds[index] ? "#F0F5FF" : "white",
            color: activeRounds[index] ? "#1677FF" : "#9A9A9A",
            margin: "8px",
            fontSize: 10,
          }}
        >
          {round}
        </Button>
      ))}
    </Box>
  );
}

export default InterviewRounds;
