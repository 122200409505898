import React, { useState } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  ListItemText,
  MenuProps,
  Chip,
  Box,
  TextField,
  SelectChangeEvent,
} from '@mui/material';

interface Option {
  label: string;
  value: string;
}

interface CommonMultiSelectProps {
  labelId: string;
  label: string;
  name: string;
  value: string[];
  onChange: (event: any) => void;
  options: Option[];
  error?: boolean;
  helperText?: string;
  fetchMoreOptions?: (searchTerm: string, page: number) => Promise<void>;
  pageType?: string;
}

const CommonMultiSelect: React.FC<CommonMultiSelectProps> = ({
  labelId,
  label,
  name,
  value,
  onChange,
  options,
  error,
  helperText,
  fetchMoreOptions,
  pageType,
}) => {
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false); // To prevent multiple API calls
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    // Prevent default behavior (which could trigger search)
    event.preventDefault();

    // Handle your custom onChange logic
    onChange(event);
  };


  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.currentTarget;
    const scrollBuffer = 20; // Buffer in pixels to handle near-bottom scenarios
    const bottomReached =
      target.scrollHeight - target.scrollTop - target.clientHeight <= scrollBuffer;
  
    if (bottomReached && fetchMoreOptions && !loading && !isFetching) {
      try {
        setIsFetching(true); // Prevent subsequent API calls
        const nextPage = page + 1;
        setPage(nextPage);
        await fetchMoreOptions(searchTerm, nextPage);
      } catch (error) {
        console.error('Error fetching more options:', error);
      } finally {
        setIsFetching(false); // Allow fetching again after completion
      }
    }
  };
  

  const customMenuProps: Partial<MenuProps> = {
    PaperProps: {
      style: {
        width: '150px',
        maxHeight: 200,
        overflowY: 'auto',
        overflowX: 'auto',
      },
      onScroll: handleScroll, 
    },
  };

  const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    setPage(0); // Reset page to 1 for new search term
    
    if (fetchMoreOptions) {    
      try {
        setLoading(true);
        await fetchMoreOptions(newSearchTerm, page); // Trigger API call with search term and page 1
      } catch (error) {
        console.error('Error fetching options for search:', error);
      } finally {
        setLoading(false);
      }
    }
     };
  

  const renderSelectedValues = (selected: string[]) => {
    const maxVisible = 1;
    const visibleSelected = selected.slice(0, maxVisible);
    const remainingCount = selected.length - maxVisible;

    if (pageType === 'resumeRepo') {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {visibleSelected.map((value) => (
            <Chip
              key={value}
              label={options.find((opt) => opt.value === value)?.label || value}
              sx={{ fontSize: '0.75rem', height: '24px' }}
            />
          ))}
          {remainingCount > 0 && (
            <Chip
              label={`+${remainingCount}`}
              sx={{
                fontSize: '0.75rem',
                height: '24px',
                backgroundColor: '#f0f0f0',
                color: '#8C8C8C',
              }}
            />
          )}
        </Box>
      );
    }
    return selected
      .map((value) => options.find((opt) => opt.value === value)?.label || value)
      .join(', ');
  };

  return (
    <FormControl fullWidth size="small" error={error} sx={{ borderColor: 'divider' }}>
      <InputLabel id={labelId} sx={{ fontSize: '0.9rem' }}>
        {label}
      </InputLabel>
      
      <Select
        labelId={labelId}
        label={label}
        multiple
        value={value}
        onChange={handleSelectChange}
        name={name}
        renderValue={renderSelectedValues}
        MenuProps={customMenuProps}
      >
        {pageType === 'skillsSearch' && (
        <MenuItem>
        <TextField
            fullWidth
            size="small"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
            autoComplete='off'
          />
        </MenuItem>
        )}
        {options.length === 0 && !loading && (
          <MenuItem disabled>No options available</MenuItem>
        )}
        {options.map((option) => {
          const isChecked = value.includes(option.value);
          return (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={isChecked} />
              <ListItemText primary={option.label} />
            </MenuItem>
          );
        })}
        {loading && <MenuItem disabled>Loading...</MenuItem>}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default CommonMultiSelect;
