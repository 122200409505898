import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Popover,
  TextField,
  Checkbox,
  ListItemText,
  MenuItem,
  Typography,
  Chip,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; // Import the dropdown icon
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { AppDispatch } from "../../../redux/store";
import { getRecruiterLead } from "../../../Crud/rth";
import { setFilterState } from "../../../redux/features/teamHeadFilterSlice";
import { setRecruiterLeadState } from "../../../redux/features/recruiterLead";
// Import action

const RecruiterLeadDropDown: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();  
  const recruiterLeadState = useAppSelector((state) => state.recruiterLead); // Get state from Redux

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [recruiterLeadName, setRecruiterLeadName] = useState<string[]>(
    recruiterLeadState.recruiterLeadName
  );
  const [recruiterLeadId, setRecruiterLeadId] = useState<string[]>(
    recruiterLeadState.recruiterLeadId
  );
  const [recruiterLead, setRecruiterLead] = useState<any[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  console.log(searchLoading);

  useEffect(() => {
    dispatch(
      setFilterState({
        recruiterLead: recruiterLeadId.length ? recruiterLeadId : [],
      })
    );
  }, [recruiterLeadId,dispatch]);

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }
    const timeoutId = setTimeout(() => {
      getRecruiterLead(searchQuery).then((res) => {
        const recruiterLeadData = res.data?.map((user: any) => ({
          value: user.userId,
          label: user.firstName,
        }));
        setRecruiterLead(recruiterLeadData);
      });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchQuery]);

  useEffect(() => {
    // Dispatch to Redux when state changes
    dispatch(
      setRecruiterLeadState({
        recruiterLeadId,
        recruiterLeadName,
      })
    );
  }, [recruiterLeadId, recruiterLeadName, dispatch]);

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (name: string, id: string) => {
    const updatedRecruiterLeads = recruiterLeadName?.includes(name)
      ? recruiterLeadName?.filter((lead) => lead !== name)
      : [...recruiterLeadName, name];
    setRecruiterLeadName(updatedRecruiterLeads);

    const updatedRecruiterIds = recruiterLeadId.includes(id)
      ? recruiterLeadId?.filter((lead) => lead !== id)
      : [...recruiterLeadId, id];
    setRecruiterLeadId(updatedRecruiterIds);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          textTransform: "none",
          display: "flex", // Ensure button content is spaced correctly
          alignItems: "center", // Align icon and text
          borderColor: "#B0B0B0", // Grey border color
          color: "#000", // Text color for the button
          "&:hover": {
            borderColor: "#A0A0A0", // Lighter grey on hover
          },
        }}
      >
        {recruiterLeadName?.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {recruiterLeadName?.slice(0, 1)?.map((name) => (
              <Chip
                key={name}
                label={name}
                onDelete={() =>
                  handleToggle(
                    name,
                    recruiterLead?.find((lead) => lead.label === name)?.value ||
                      ""
                  )
                }
                sx={{
                  fontSize: "10px",
                  height: "20px",
                }}
              />
            ))}
            {recruiterLeadName?.length > 1 && (
              <Chip
                label={`+${recruiterLeadName?.length - 1}`}
                sx={{
                  fontSize: "10px",
                  height: "20px",
                  backgroundColor: "#f0f0f0",
                  color: "#8C8C8C",
                }}
              />
            )}
          </Box>
        ) : (
          <Typography sx={{ color: "grey" }}>Choose</Typography>
        )}
        <ArrowDropDownIcon sx={{ fontSize: 18, marginLeft: 1 }} />
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: anchorEl ? anchorEl.offsetWidth : "auto", // Match the button's width
            maxHeight: "300px",
            marginTop: "2px",
            overflowY: "auto", // Allow scrolling if content overflows
          },
        }}
      >
        <Box sx={{ padding: 1 }}>
          <TextField
            size="small"
            placeholder="Search..."
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              marginBottom: 2,
              "& .MuiInputBase-input": { fontSize: "12px" },
            }}
          />
          {recruiterLead
            ?.filter((lead) =>
              lead.label?.toLowerCase()?.includes(searchQuery?.toLowerCase())
            )
            .map((lead) => (
              <MenuItem
                key={lead.value}
                onClick={() => handleToggle(lead.label, lead.value)}
                sx={{
                  fontSize: "10px",
                  padding: "4px 6px",
                  gap: "10px", // Gap between checkbox and label
                }}
              >
                <Checkbox
                  checked={recruiterLeadName?.includes(lead.label)}
                  size="small"
                  sx={{
                    padding: "2px",
                    "& .MuiSvgIcon-root": { fontSize: "14px" }, // Smaller checkbox icon
                  }}
                />
                <ListItemText
                  primary={lead.label}
                  sx={{
                    margin: 0,
                    fontSize: "10px", // Reduced text size
                  }}
                />
              </MenuItem>
            ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default RecruiterLeadDropDown;
