import React, { useState } from "react";
import { Box, Typography, Chip, IconButton } from "@mui/material";
import greenDot from "../../../assets/images/greenDot.svg";
import { Paper, Stepper, Step, StepLabel } from "@mui/material";

import goodTick from "../../../assets/images/goodTick.svg";
import orangeRoundClock from "../../../assets/images/orangeRoundClock.svg";
import AssignRecruiterLead from "./assignRecruiterLead";
import AssignRecruiterNew from "./assignRecruiterNew";
import { processMap } from "../../../constants/fieldConstants";
import commentIcon from "../../../assets/images/comment-icon.svg";
import { toggleCommentsDrawer } from "../../../redux/features/commentsSlice";
import { useDispatch } from "react-redux";
import CommentsDrawer from "../../comments/CommentsDrawer";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate } from "react-router-dom";
// import pdf from "../../../assets/images/pdf 1.svg"
// import EditOrShowAssignRecruiter from './EditOrShowAssignRecruiter';
// import AssignRecruiterView from './AssignRecruiterView';
// import { assignRecruiter } from '../../../redux/features/recruiterSlice';

export interface RequestToHireProps {
  positionTitle?: string;
  status?: string;
  Steps: string[];
  approvalStatus: { l1: string; l2: string };
  l1?: string;
  l2?: string;
  numOfPosition?: string;
  recruiterAssigned?: boolean;
  recruiterLeadAssigned?:boolean;
  numOfOpenPosition?: any;
  data?: any;
}


const Headergpt: React.FC<RequestToHireProps> = ({
  positionTitle,
  status,
  Steps,
  numOfPosition,
  l1,
  l2,
  recruiterAssigned,
  recruiterLeadAssigned,
  numOfOpenPosition,
  data,
}) => {
  // const  role = localStorage?.getItem("user")
  //   ? JSON.parse(localStorage.user)
  //   : null;
  const toggleComment = useSelector(
    (state: RootState) => state.comments.openDrawer
  );

  // const rolee = localStorage.getItem("user")?JSON.parse(localStorage.user)?.roleName:"";

  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "{}")
    : {
        permissionsList: {},
        roleName: "",
      };

  const permissionsList = userData.permissionsList || {};

  console.log("permissionsListt",permissionsList);
  // const fileUrl = data.jdAttachmentFileUrl
  const [view, setView] = useState<boolean>(false);
  // const [uploadedFile, setUploadedFile] = useState<string | null>(fileUrl || null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { queryParam } = location.state || {};

  const handleBackButtonClick = () => {
    const tabParam = queryParam || "dashboard";
    navigate(`/dashboard?tab=${tabParam}`);
  };

  const getKeyForProcessMap = (rthProcess: string, jobPosted: boolean | undefined) => {
    if (rthProcess === "JobPost") {
      return `JobPost&JobPosted:${jobPosted ? "True" : "False"}`;
    }
    return rthProcess;
  };

  const key = getKeyForProcessMap(data?.rthProcess, data?.jobPosted);

  // const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if (event.target.files && event.target.files[0]) {
  //     const file = event.target.files[0];
  //     setUploadedFile(URL.createObjectURL(file)); // Temporary URL for preview
  //     console.log("Uploaded file:", file);
  //   }
  // };

  // const handleRemove = () => {
  //   setUploadedFile(null);
  // };
  
  return (
    <>
      <Box sx={{ p: 2, backgroundColor: "#f0f4ff", borderRadius: 2 }}>
        {/* Top Section: Logo, Title, Status, Edit/Delete Icons */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={handleBackButtonClick}>
              <KeyboardBackspaceIcon />
            </IconButton>
            <Typography
              variant="h4"
              sx={{ flexGrow: 1, fontSize: "20px", fontWeight: "Bold" }}
            >
              {" "}
              {(positionTitle ?? "").length > 32
                ? `${positionTitle?.substring(0, 32)}...`
                : positionTitle}
            </Typography>
            <Chip
              label={status}
              // color={status === "Open" ? "success" : "default"}
              sx={{
                ml: 2,
                backgroundColor:
                  status === "Declined" ? "#F9D9CF99" : "#00AB550D",
                border:
                  status === "Declined"
                    ? "2px solid #F04E4E"
                    : "1px solid green",
                borderRadius: "5px",
                color: status === "Declined" ? "red" : "#00AB55",
              }}
              {...(status !== "Declined" && {
                icon: <img src={greenDot} alt="status indicator" />,
              })}
            />
            {/* <Chip label="Waiting for L1 & L2 Approval" sx={{ ml: 1, backgroundColor: '#f0f0f0', color: '#555',border:'1px solid #707b7c ',borderRadius:'5px' }} /> */}
          </Box>
          <Box
            sx={{
              width: "30px",
              height: "30px",
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => dispatch(toggleCommentsDrawer(true))}
          >
            <img src={commentIcon} alt="comment icon" />
          </Box>
        </Box>
        
        {((permissionsList.assignAndEditRecruiterLead) && (status !== "Declined" && status !== "Waiting for the L1&L2 Approvals") ) && (
          <Box
            sx={{
              p: 2,
              backgroundColor: "background.default",
              marginBottom: "1%",
              borderRadius: 2,
            }}
          >
            <AssignRecruiterLead
              numOfPosition={numOfPosition}
              numOfOpenPosition={numOfOpenPosition}
            />
          </Box>
        )}

        {((permissionsList.assignAndEditRecruiter) && (status !== "Declined" && status !== "Waiting for the L1&L2 Approvals") ) && (
          <Box>
            <Box
              sx={{
                p: 2,
                backgroundColor: "background.default",
                marginBottom: "1%",
                borderRadius: 2,
              }}
            >
              <AssignRecruiterNew
                numOfPosition={numOfPosition}
                numOfOpenPosition={numOfOpenPosition}
                view={view}
                setView={setView}
              />
            </Box>
          </Box>
        )}

        {/* {permissionsList.assignAndEditRecruiterLead && (
          <Box
          sx={{
            p: 2,
            backgroundColor: "background.default",
            marginBottom: "1%",
            borderRadius: 2,
          }}
        >
          {uploadedFile ? (
            <Chip
              icon={<img src={pdf} alt="PDF Icon" style={{ width: 20, height: 20 }} />}
              label="Job description.pdf"
              onDelete={handleRemove}
              onClick={() => window.open(uploadedFile, "_blank")}
              sx={{
                cursor: "pointer",
              }}
            />
          ) : (
            (
              <Button
                variant="outlined"
                component="label"
                sx={{
                  // border: "none",
                  textTransform: "none", 
                }}
                
              >
                Attach Job Description(JD)
                <input
                  type="file"
                  accept=".pdf"
                  hidden
                  onChange={handleUpload}
                />
              </Button>
            )
          )}
         
        </Box>
    
        )} */}

       
        {/* MRF Process Steps */}

        <Paper
          elevation={1}
          sx={{ p: 2, backgroundColor: "background.default" }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            <Box sx={{ width: "80%" }}>
              <Typography
                variant="subtitle2"
                sx={{ mb: 1, fontSize: "10px", color: "gray", ml: "10px" }}
              >
                MRF Process
              </Typography>
              <Stepper activeStep={processMap[key]} alternativeLabel>
                {Steps.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconProps={{
                        sx: { fontSize: "18px" },
                      }}
                      sx={{
                        "& .MuiStepLabel-label": {
                          fontSize: "9px",
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Chip
                label={"L1 " + l1}
                sx={
                  l1 === "Approved"
                    ? {
                        mr: 1,
                        backgroundColor: "#F6FFED",
                        borderRadius: "5px",
                        color: "#595959",
                        border: "1px solid #D9F7BE",
                      }
                    : {
                        mr: 1,
                        backgroundColor: "#FFF7E6",
                        border: "1px solid #FFE7BA",
                        borderRadius: "5px",
                        color: "#595959",
                      }
                }
                icon={
                  l1 === "Approved" ? (
                    <img src={goodTick} alt="status indicator" />
                  ) : (
                    <img src={orangeRoundClock} alt="status indicator" />
                  )
                }
              />
              {l1 !== "Declined" && (
                <Chip
                  label={"L2 " + (l2 ?? "Pending")}
                  sx={
                    l2 === "Approved"
                      ? {
                          mr: 1,
                          backgroundColor: "#F6FFED",
                          borderRadius: "5px",
                          color: "#595959",
                          border: "1px solid #D9F7BE",
                        }
                      : {
                          mr: 1,
                          backgroundColor: "#FFF7E6",
                          border: "1px solid #FFE7BA",
                          borderRadius: "5px",
                          color: "#595959",
                        }
                  }
                  icon={
                    l2 === "Approved" ? (
                      <img src={goodTick} alt="status indicator" />
                    ) : (
                      <img src={orangeRoundClock} alt="status indicator" />
                    )
                  }
                />
              )}
            </Box>
          </Box>
        </Paper>

        {/* Approval Status */}
      </Box>
      {toggleComment && <CommentsDrawer />}
    </>
  );
};
export default Headergpt;
