import { Box, Button, Paper, Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PersonalInformation } from './EmployeeInformationPages/PersonalInformation';
import AdditionalInformation from './AdditionalInformation';
import { Education } from './EmployeeInformationPages/Education';
import { Experience } from './EmployeeInformationPages/Experience';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { editCandidate, getCandidateByCandidateId } from '../../../../redux/features/resume';
import ResumeReUpload from './ResumeReUpload';
import Address from './AddressForm';
import { toast } from 'react-toastify';
import { clearSelectedCity, clearSelectedCountry, clearSelectedState } from '../../../../redux/features/resumeSlice';

export const EmployeInformation = () => {
  const [value, setValue] = React.useState(0);
  const [formValues,setFormValues]=useState<any>([]);
  const {candidateId}=useParams();
  const dispatch=useAppDispatch();
  const state=useAppSelector((state:any)=>state.resume.candidate);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const[selectedState,setSelectedState]=useState<any>([]);
    const[selectedCountry,setSelectedCountry]=useState<any>([]);
    const[selectedCity,setSelectedCity]=useState<any>([]);
    const[emailAddress,setEmailAddress]=useState('');

  
  const sectionRefs = useMemo(
    () => ({
      resume: React.createRef<HTMLDivElement>(),
      personalInfo: React.createRef<HTMLDivElement>(),
      additionalInfo: React.createRef<HTMLDivElement>(),
      address: React.createRef<HTMLDivElement>(),
      education: React.createRef<HTMLDivElement>(),
      experience: React.createRef<HTMLDivElement>(),
    }),
    []
  );

  // Handle tab change
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    scrollToSection(newValue);
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formValues.howDidYouHeardAbtUs) {
      newErrors.howDidYouHeardAbtUs = 'This is required';
    }
    if (!formValues.firstName) {
      newErrors.firstName = 'First Name is required';
    }
    if (!formValues.lastName) {
      newErrors.lastName = 'Last Name is required';
    }
    // if(!formValues.middleName){
    //   newErrors.middleName='Middle Name is required'
    // }
    if(!formValues.expectedCtc){
      newErrors.expectedCtc='Expected CTC is required'
    }else if (!/^\d{1,10}$/.test(formValues.expectedCtc)) {
      newErrors.expectedCtc = 'Expected CTC must be a numeric value up to 10 digits';
    }

    if(!formValues.noticePeriods){
      newErrors.noticePeriods='Required field'
    }else if (!/^\d{1,3}$/.test(formValues.noticePeriods)) {
    newErrors.noticePeriods = 'Notice period must be a numeric value up to 3 digits';
  }

    if(!formValues.currentCtc){
      newErrors.currentCtc='Current CTC is required'
    } else if (!/^\d{1,10}$/.test(formValues.currentCtc)) {
    newErrors.currentCtc = 'Current CTC must be a numeric value up to 10 digits';
  }

    if (!formValues.emailAddress) {
      newErrors.emailAddress = 'Email Address is required';
    } else if (!/\S+@\S+\.\S+/.test(formValues.emailAddress)) {
      newErrors.emailAddress = 'Email Address is invalid';
    }
    if (!formValues.phoneNumber) {
      newErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
      newErrors.phoneNumber = 'Phone Number must be 10 digits';
    }
    // if (!formValues.dateOfBirth) {
    //   newErrors.dateOfBirth = 'Date of Birth is required';
    // }
 
    setErrors(newErrors);
 
    return Object.keys(newErrors).length === 0;
  };
      
  const scrollToSection = (sectionIndex: number) => {
    const sectionKeys = Object.keys(sectionRefs) as (keyof typeof sectionRefs)[];
    const sectionKey = sectionKeys[sectionIndex];
    sectionRefs[sectionKey]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleSave=async ()=>{
    const isValid=validateForm();
    if(isValid){

      const updatedFormValues = { ...formValues };

      if (updatedFormValues.emailAddress === emailAddress) {
        delete updatedFormValues.emailAddress; // Remove emailAddress if it's the same
      }

    const payload={
      ...updatedFormValues,
      workExperience: 
      typeof updatedFormValues.workExperience === "string" 
        ? updatedFormValues.workExperience.toLowerCase() === "yes"
        : !!updatedFormValues.workExperience,
      status:"Completed",
    }
    try {
      const response = await dispatch(editCandidate({candidateId:candidateId,data:payload })); // Dispatch the thunk
      if(editCandidate.fulfilled.match(response)){
        const {status,message}=response.payload||{};
        if(status===true){
        dispatch(getCandidateByCandidateId(candidateId));
        dispatch(clearSelectedCity());
             dispatch(clearSelectedCountry());
             dispatch(clearSelectedState());
        toast.success("Candidate Updated Successfully!");
        }
        else if(status===400){
          toast.error(message||'Data is not Proper');
        }
      }
      else{
        toast.error('Failed to Submit the Details');
      }
      
  } catch (error) {
      console.error("Error updating candidate:", error); 
  }
    }
  }
  
  const handleScroll = useCallback(() => {
    const sectionKeys = Object.keys(sectionRefs) as (keyof typeof sectionRefs)[];
    const container = scrollContainerRef.current;

    if (container) {
      const containerTop = container.getBoundingClientRect().top;

      sectionKeys.forEach((key, index) => {
        const section = sectionRefs[key].current;
        if (section) {
          const rect = section.getBoundingClientRect();
          const sectionTop = rect.top - containerTop;

          if (sectionTop >= 0 && sectionTop < container.clientHeight / 2) {
            setValue(index);
          }
        }
      });
    }
  }, [sectionRefs,scrollContainerRef]);

  useEffect(() => {
    const container = scrollContainerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);


  useEffect(()=>{
    dispatch(getCandidateByCandidateId(candidateId));
  },[candidateId,dispatch])
  
  useEffect(() => {
    if (state) {
      setFormValues(state);
      const cityValue=Number(state?.address?.city);
      const countryValue=Number(state?.address?.country);
      const stateValue=Number(state?.address?.state);
      setSelectedCity(cityValue);
      setSelectedCountry(countryValue);
      setSelectedState(stateValue);
      setEmailAddress(state?.emailAddress);
    }
  }, [state]);  
    
  return (
    <Box sx={{ mt: '20px', ml: '180px', height:'70vh'}}>
      <Paper sx={{ height: '100%', width: '74vw', boxShadow: 'none', borderRadius:'10px' }}>
        <Box sx={{ position:'sticky', top:0, backgroundColor:'white', zIndex:1, borderRadius:'10px'}}>
        <Tabs value={value} onChange={handleTabChange}  TabIndicatorProps={{
          style: {        
            width: '20px',
            marginLeft: `${value === 0 ? '35px' : value ===1 ? '66px': value === 2 ? '66px': value === 3 ? '32px': value === 4 ?'34px':'36px'}`, // Adjusting indicator based on selected tab            
          },
        }}>
          <Tab label="Resume" sx={{ textTransform: 'none' }}/>
          <Tab label="Personal Information"sx={{ textTransform: 'none' }} />
          <Tab label="Additional Information"sx={{ textTransform: 'none' }} />
          <Tab label="Address"sx={{ textTransform: 'none' }} />
          <Tab label="Education"sx={{ textTransform: 'none' }} />
          <Tab label="Experience" sx={{ textTransform: 'none' }}/>
        </Tabs>
        </Box>   

        <Box sx={{ height: '58vh', overflowY: 'auto', marginTop: 5, m:3}} ref={scrollContainerRef}>
          <Box ref={sectionRefs.resume} sx={{ height: 'auto', padding: 2 }}>
            <ResumeReUpload formValues={formValues} setFormValues={setFormValues}/>
          </Box>

          <Box ref={sectionRefs.personalInfo} sx={{ height: 'auto', padding: 2 }}>
            <PersonalInformation formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors}/>
          </Box>

          <Box ref={sectionRefs.additionalInfo} sx={{ height: 'auto', padding: 2,mt:'10px' }}>
            <AdditionalInformation formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors}/>
          </Box>

          <Box ref={sectionRefs.address} sx={{ height: 'auto', padding: 2,mt:'10px' }}>
            <Address formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors} selectedState={selectedState} setSelectedState={setSelectedState}
        selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} selectedCity={selectedCity} setSelectedCity={setSelectedCity}/>
          </Box>

          <Box ref={sectionRefs.education} sx={{ height: 'auto', padding: 2,mt:'10px'}}>
           <Education formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors}/>
          </Box>

          <Box ref={sectionRefs.experience} sx={{ height: 'auto', padding: 2, overflowY:'auto' ,mb:4,mt:'10px'}}>
            <Experience formValues={formValues} setFormValues={setFormValues} errors={errors} setErrors={setErrors}/>
          </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', boxShadow: 'none', gap:2, pb:5, pr:3,mt:'10px'}}>
         <Button variant="outlined" color="primary" sx={{textTransform:'none',borderRadius:'6px' }}>
            Cancel
          </Button>
          <Button variant="contained" color="primary"sx={{textTransform:'none',borderRadius:'6px'}} onClick={handleSave} >
            Save Changes
          </Button>
        </Box>
        </Box>
      </Paper>
    </Box>
  );
};
