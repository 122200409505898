import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, Grid, Radio, RadioGroup, FormControlLabel, Typography, SelectChangeEvent } from '@mui/material';
import DeleteIcon from '../../../../../assets/images/del_Icon.svg';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { InterviewLinkUI, SearchableDropdown, SelectUI, TextFieldUI } from './InputsInterviewSchedule';
import { getCandidateByRthId } from '../../../../../redux/features/candidate';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { addInterview, editInterview, getCandidateInterviewDetails, getInterviewDetailsByRecruiterId, getL1Approvers, getMrfDetails, sentInterviewMail } from '../../../../../redux/features/interviewSchedule';
import { InterviewMode } from '../../../../../constants/dummyConst';
import { toast } from 'react-toastify';
import { getRthPositionReportsTo } from '../../../../../Crud/rth';
import { BASE_COPY_URL } from '../../../../../constants/Crudurl';
 
 
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
 
 
 
export default function ScheduleInterviewDialog ( {DialogOpen, close,interviewNextRound,interviewId,prevInterviewDate,prevInterviewFrom }:any ) {
 
  
  const [typeOfRTH, setTypeOfRTH] = React.useState('Internal');
  const hasInitialized = useRef(false);
  const [rounds, setRounds] = React.useState<string[]>([]);  
  const mrfDetailsData=useAppSelector((state:any)=>state.resume.mrfDetails);
  const [values, setValues]=useState<any>({
    candidateId:'',
    interviewPlatform:'External',
    interviewDet: [
      {
        interviewRound: '',
        interviewName: '',
        interviewMode: '',
        interviewLink: '',
        interviewDate: '',
        from: '',
        to: '',
        interviewerId:'',
        optionalAttendeesId:null,
      }
    ]  
  });
  const emptyState = {
    candidateId: '',
    interviewPlatform: '',
    interviewDet: [
      {
        interviewRound: '',
        interviewName: '',
        interviewMode: '',
        interviewLink: '',
        interviewDate: '',
        from: '',
        to: '',
        interviewerId: '',
        optionalAttendeesId: '',
      }
    ]  
  };
  
  
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const dispatch=useAppDispatch();
  // const state=useAppSelector((state)=>state.interviewSchedule);
  const recruiterId=localStorage.getItem('userId');
  const [interviewRound,setInterviewRound]=useState('interviewDetL1');
 
  const location = useLocation();
  const {candidateName,candidateId} = useParams();
  const path = location.pathname.includes('candidate');
  const {id,jobTitle} = useParams();
  // const[level,setLevel]=useState('');
  
 
  const handleInitialRound = () => {
    const newRound = `Interview Round - ${rounds.length + 1}`;
    setRounds((prevRounds) => [...prevRounds, newRound]); // Directly update rounds  
    setValues({
      ...values,
      interviewDet: [
        {
          interviewRound: '',
          interviewName: newRound,
          interviewMode: '',
          interviewLink: '',
          interviewDate: '',
          from: '',
          to: '',
          interviewerId:'',
          optionalAttendeesId:null,
        }
      ] 
    })
  };
 
  const handleclose = () => {
    hasInitialized.current = false;
    close();
    setRounds([]);
    setErrors({});
    setValues(emptyState);
  }
 
 
  // Add a new round and close the dialog
 
  const handleDeleteRound = (index: number) => {
    const updatedRounds = rounds.filter((_, i) => i !== index);
  
    const updatedInterviewDet = values.interviewDet.filter((_:any, i:any) => i !== index);

    setRounds(updatedRounds);
    setValues({
      ...values,
      interviewDet: updatedInterviewDet.map((det:any, i:any) => ({
        ...det,
        interviewName: `Interview Round - ${i + 1}`, // Reindex rounds if necessary
      })),
    });
  };
 
  
  
  // const handleInputChangeNormal = (event: React.ChangeEvent<HTMLInputElement>) => {   
  //   const { name, value } = event.target;    
  //   setValues({
  //     ...values,
  //     [name]: value,
  //   });

  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [name]: '', // Clear the error
  //   }));
  // };
 
  const handleSelectChangeNormal=(event:SelectChangeEvent<string>)=>{
    const {name,value}=event.target;
    
    setValues({
      ...values,
      [name]:value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));
  };

  const handleInputChangeTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target; // e.g., name = 'interviewDet[0].from', value = '10:00'
    const [prefix, index, field] = name.match(/([a-zA-Z]+)\[(\d+)\]\.([a-zA-Z]+)/)!.slice(1);
  
    setValues((prevValues:any) => {
      const updatedValues = [...prevValues[prefix]]; // Clone interviewDet array
      const currentItem = { ...updatedValues[+index] }; // Clone the object at the index
  
      if (field === 'from' || field === 'to') {
        // Get today's date in YYYY-MM-DD format
        const today = new Date().toISOString().split('T')[0];
        const date = currentItem?.interviewDate || today; // Use current date if interviewDate is not provided
        currentItem[field] = `${date}T${value}:00`; // Update the field with ISO format
      }
      updatedValues[+index] = currentItem; // Update the array at the index
  
      return { ...prevValues, [prefix]: updatedValues }; // Return the updated state
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    const nameParts = name.split(/[.[\]]+/).filter(Boolean);
    const index = parseInt(nameParts[1], 10); // Extract index (e.g., 0 for the first round)
    const field = nameParts[2]; // Extract field name (e.g., interviewDate)
  
    setValues((prevValues: any) => {
      const updatedInterviewDet = [...prevValues.interviewDet];
      updatedInterviewDet[index] = {
        ...updatedInterviewDet[index],
        [field]: value,
      };
  
      // Perform validation for duplicate dates
      if (field === 'interviewDate') {
        const otherDates = updatedInterviewDet
          .map((round, idx) => (idx !== index ? round.interviewDate : null))
          .filter(Boolean); // Remove null/undefined values
  
        if (otherDates.includes(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: 'This date is already selected in another round.',
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '', // Clear the error if no duplicate is found
          }));
        }
      }
  
      return { ...prevValues, interviewDet: updatedInterviewDet };
    });
  };
  
 
 
  const handleSelectChange = (e:any) => {
    const { name, value } = e.target;
    const nameParts = name.split(/[.[\]]+/).filter(Boolean);
    const index = nameParts[1];
    const field = nameParts[2];
 
    setValues((prevValues:any) => {
      const updatedInterviewDet = [...prevValues.interviewDet];
      updatedInterviewDet[index] = {
        ...updatedInterviewDet[index],
        [field]: value
      };
      return { ...prevValues, interviewDet: updatedInterviewDet };
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error
    }));
  };
  
 
  const handleAddRound=(index:any)=>{
    const newRound = `Interview Round - ${rounds.length + 1}`;
    setValues({
      ...values,
      interviewDet: [
        ...values.interviewDet, 
        {
          interviewRound: '',
          interviewName: newRound,
          interviewMode: '',
          interviewLink: '',
          interviewDate: '',
          from: '',
          to: '',
          interviewerId:'',
          optionalAttendeesId:null,
        }
      ] 
    })
    setRounds((prevRounds) => [...prevRounds, newRound]); // Directly update rounds
  };
 
  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const today = new Date();
    today.setHours(0, 0, 0, 0); 
       
  
    // Define the valid time range for 'from' (8:00 AM - 6:55 PM) and 'to' (8:05 AM - 7:00 PM)
    const fromStart = new Date(today.getTime());
    fromStart.setHours(8, 0, 0, 0); // 8:00 AM
  
    const fromEnd = new Date(today.getTime());
    fromEnd.setHours(18, 55, 0, 0); // 6:55 PM
  
    const toStart = new Date(today.getTime());
    toStart.setHours(8, 5, 0, 0); // 8:05 AM
  
    const toEnd = new Date(today.getTime());
    toEnd.setHours(19, 0, 0, 0); // 7:00 PM
  
  
    values.interviewDet.forEach((field: any, index: number) => {
      if (!field.interviewRound) {
        newErrors[`interviewDet[${index}].interviewRound`] = 'Interview round is required';
      }
      if (!field.interviewMode) {
        newErrors[`interviewDet[${index}].interviewMode`] = 'Interview mode is required';
      }
      if (!field.interviewDate) {
        newErrors[`interviewDet[${index}].interviewDate`] = 'Interview date is required';
      }else if(prevInterviewDate) {
        const interviewDate = new Date(field.interviewDate);
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const formattedPrevDate = prevInterviewDate.toLocaleDateString('en-US', options);
 

const optionsDate: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const formattedDate = prevInterviewFrom.toLocaleDateString('en-US', optionsDate);

const optionsTime: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
const formattedTime = prevInterviewFrom.toLocaleTimeString('en-US', optionsTime);

const formattedPrevInterviewFrom = `${formattedDate} (${formattedTime})`;
 
          if (interviewDate < prevInterviewDate) {
          newErrors[`interviewDet[${index}].interviewDate`] = `Interview date must be greater than the previous interview date (${formattedPrevDate})`;
        }
        else if (interviewDate.getTime()=== prevInterviewDate.getTime()) {  
           const fieldFrom=new Date(field?.from);
                    if (fieldFrom.getTime() <= prevInterviewFrom.getTime()) {           
            newErrors[`interviewDet[${index}].from`] = `From time must be greater than the previous interview time(${formattedPrevInterviewFrom})`;
          }
        }
      }
  
      if (!field.from) {
        newErrors[`interviewDet[${index}].from`] = 'Start time is required';
      } else {
        // Create a Date object for comparison
        const [fromHour, fromMinute] = field.from.split(':').map(Number);
        const fromTime = new Date(today.setHours(fromHour, fromMinute, 0, 0));
        if (fromTime < fromStart || fromTime > fromEnd) {
          newErrors[`interviewDet[${index}].from`] = 'Start time must be between 8:00 AM and 6:55 PM';
        }
        if (fromTime <= new Date()) {
          newErrors[`interviewDet[${index}].from`] = 'Start time must be greater than the current time';
        }
      }
  
      if (!field.to) {
        newErrors[`interviewDet[${index}].to`] = 'End time is required';
      } else {
        // Create a Date object for comparison
        const [toHour, toMinute] = field.to.split(':').map(Number);
        const toTime = new Date(today.setHours(toHour, toMinute, 0, 0));
        if (toTime < toStart || toTime > toEnd) {
          newErrors[`interviewDet[${index}].to`] = 'End time must be between 8:05 AM and 7:00 PM';
        }
        if (toTime <= new Date()) {
          newErrors[`interviewDet[${index}].to`] = 'End time must be greater than the current time';
        }
      }
  
    // Ensure 'from' time is earlier than 'to' time
    if (field.from && field.to) {
      const fromTime = new Date(field.from); // Parse the 'from' string as a Date object
      const toTime = new Date(field.to); // Parse the 'to' string as a Date object

      if (fromTime >= toTime) {
        newErrors[`interviewDet[${index}].from`] = 'Start time must be earlier than end time';
        newErrors[`interviewDet[${index}].to`] = 'End time must be later than start time';
      }
      if (fromTime.getTime() === toTime.getTime()) {
        newErrors[`interviewDet[${index}].from`] = 'Start time and end time must not be the same';
        newErrors[`interviewDet[${index}].to`] = 'Start time and end time must not be the same';
      }
    }
 
      if (!field.interviewerId) {
        newErrors[`interviewDet[${index}].interviewerId`] = 'Interviewer is required';
      }
    });
    const interviewDates = values.interviewDet.map((field: any) => field.interviewDate);
  const duplicateDate = interviewDates.find((date:any, index:any) => interviewDates.indexOf(date) !== index);
  
  if (duplicateDate) {
    const duplicateIndex = interviewDates.indexOf(duplicateDate);
    newErrors[`interviewDet[${duplicateIndex}].interviewDate`] = 'Interview dates must be unique';
  }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

   const handleSubmit=async()=>{

    if(validateFields()){
    let payload;
if(interviewNextRound && path){
    const commonFields={
    candidateId:candidateId,
    rthId:id,
    recruiterId:recruiterId,
  }
  const interviewFields:{[key:string]:any}={};
  if (values.interviewDet && values.interviewDet.length > 0) {
    values.interviewDet.forEach((interview: any, index: any) => {
      const fieldName = interviewNextRound;
      // setInterviewRound('interviewDetL1');
      // Extracting the id from the interviewerId object and assigning it to interviewFields
      interviewFields[fieldName] = {
        ...interview,
        interviewerId: interview.interviewerId ? interview.interviewerId.id : null,
        optionalAttendeesId:interview.optionalAttendeesId ? interview.optionalAttendeesId.id :null
      };
    });
  }  
  payload={...commonFields,...interviewFields};
}
  else if(path){
    const commonFields={
        rthId:id,
        recruiterId:recruiterId,
        candidateId:candidateId,
        interviewPlatform:values?.interviewPlatform
      }
      const interviewFields: { [key: string]: any } = {};

      if (values.interviewDet && values.interviewDet.length > 0) {
        values.interviewDet.forEach((interview: any, index: any) => {
          const fieldName = `interviewDetL${index + 1}`;
          // setInterviewRound('interviewDetL1');
          // Extracting the id from the interviewerId object and assigning it to interviewFields
          interviewFields[fieldName] = {
            ...interview,
            interviewerId: interview.interviewerId ? interview.interviewerId.id : null,
            optionalAttendeesId:interview.optionalAttendeesId ? interview.optionalAttendeesId.id :null
          };
        });
      }
     

  payload = { ...commonFields, ...interviewFields };
    }
    
    else{
      const commonFields = {
        rthId: id,
        recruiterId: recruiterId,
        candidateId:values?.candidateId,
        interviewPlatform:values?.interviewPlatform
      };    
      const interviewFields: { [key: string]: any } = {};

      if (values.interviewDet && values.interviewDet.length > 0) {
        values.interviewDet.forEach((interview: any, index: any) => {
          const fieldName = `interviewDetL${index + 1}`;
          // setInterviewRound('interviewDetL1');
          // Extracting the id from the interviewerId object and assigning it to interviewFields
          interviewFields[fieldName] = {
            ...interview,
            interviewerId: interview.interviewerId ? interview.interviewerId.id : null,
            optionalAttendeesId:interview.optionalAttendeesId ? interview.optionalAttendeesId.id :null
          };
        });
      }
      
  payload = { ...commonFields, ...interviewFields };

    }
     
    if(interviewNextRound&&path){
      console.log(interviewRound,'interviewRound');
      
      const resultAction=await dispatch(editInterview({interviewId:interviewId,data:payload}));
      if(editInterview.fulfilled.match(resultAction)){
        const{status,message,data}=resultAction.payload||{};
        const feedBackFormLink = `${BASE_COPY_URL}/newRequestToHire/${jobTitle}/${id}/${candidateName}/${data?.candidateId}/${interviewRound}/${data?.interviewId}/InterviewFeedBack`;
      const interviewLink = data?.[interviewRound]?.interviewLink;
        const mailPayload={
          interviewId:data?.interviewId,
          level:interviewRound==='interviewDetL1'?'L1Round':interviewRound==='interviewDetL2'?'L2Round':interviewRound==='interviewDetL3'?'L3Round':'L1Round',
          interviewLink,
          feedBackFormLink,
        }
        if(status===true){
          toast.success(message);
          await dispatch(sentInterviewMail(mailPayload));
          dispatch(getCandidateInterviewDetails(candidateId));
            }
        else{
          toast.error('Improper Data');
        }
      }
    }
    else {
    const resultAction=await dispatch(addInterview(payload));
    if(addInterview.fulfilled.match(resultAction)){
      const {status,message,data}=resultAction.payload||{};
      const feedBackFormLink = `${BASE_COPY_URL}/newRequestToHire/${jobTitle}/${id}/${candidateName}/${data?.candidateId}/${interviewRound}/${data?.interviewId}/InterviewFeedBack`;
      const interviewLink = data?.[interviewRound]?.interviewLink;

          const mailPayload={
            interviewId:data?.interviewId,
            level:interviewRound==='interviewDetL1'?'L1Round':interviewRound==='interviewDetL2'?'L2Round':interviewRound==='interviewDetL3'?'L3Round':'L1Round',
            interviewLink,
            feedBackFormLink,
          }

      if(status===true){
        toast.success(message);
        dispatch(getCandidateInterviewDetails(candidateId));
        if(path){
          setValues(emptyState);
          await dispatch(sentInterviewMail(mailPayload));
        }
        if(!path){
          await dispatch(getInterviewDetailsByRecruiterId(recruiterId));
          await dispatch(sentInterviewMail(mailPayload));
        }
        setValues(emptyState);
      }
      else{
        toast.error(message||'Improper Data');
      }
    }
       
  }
  handleclose();
  }
  }
 
 
  useEffect(()=>{ 
    getCandidateByRthId(id).then(response=>{
      const transformedOptions = response.data.map((candidate: any) => ({
        label: candidate.firstName, // Assuming `name` exists in the response data
        value: candidate.candidateId,   // Assuming `id` exists in the response data
      }));
      setOptions(transformedOptions);
    });
  },[id])
 
  useEffect(()=>{
    dispatch(getL1Approvers(''));
  },[rounds,dispatch])

  useEffect(()=>{
    if(interviewNextRound){    
      let newRound:any
      switch(interviewNextRound){
        case 'interviewDetL2':
           newRound='Interview Round-2'
          break;
        case 'interviewDetL3':
           newRound='Interview Round-3'
          break;
      }
      setRounds((prevRounds) => [...prevRounds, newRound]); // Directly update rounds  
      setValues((prevValues:any) => ({
        ...prevValues,
        interviewDet: [
          {
            interviewRound: '',
            interviewName: newRound,
            interviewMode: '',
            interviewLink: '',
            interviewDate: '',
            from: '',
            to: '',
            interviewerId: '',
            optionalAttendeesId: null,
          },
        ],
      }));
      setInterviewRound(interviewNextRound);
    }
  },[interviewNextRound])   
  
  useEffect(()=>{
  if(id){
    dispatch(getMrfDetails(id));
  }
  },[id,dispatch])

  useEffect(()=>{
    if(mrfDetailsData){
      setTypeOfRTH(mrfDetailsData?.TypeOfRth)
    }
  },[mrfDetailsData]);
        
  return (
    <React.Fragment>
      <Dialog
      open={DialogOpen}
      TransitionComponent={Transition}
      keepMounted
      aria-describedby="schedule-interview-dialog-description"
      maxWidth={false}
      fullWidth
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          handleclose();
        }
      }}
      PaperProps={{
        style: {
          width: '1400px',
          maxWidth: '1400px',  
          borderRadius:'8px'        
        },
      }}
    >
        <Box sx={{border:'1px solid #D9D9D9', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
        <DialogTitle sx={{ p:'20px', pl:'28px' }}>{path?`Schedule Interview For ${candidateName}`: "Schedule Interview" }</DialogTitle>
        <Box sx={{pr:'20px'}}>
        <Box sx={{width:'30px', height:'30px', bgcolor:'#ECECEC', borderRadius:'50%', display:'flex', justifyContent:'center', alignItems:'center' }}>
        <CloseRoundedIcon sx={{color:'#989696',  fontSize:'24px', cursor: 'pointer', '&:hover':{color:'#4a4a4a'} }}  onClick={handleclose} />
        </Box>
        </Box>
        </Box>
        <DialogContent>
          <Box component="form" sx={{ mt: 2}}>
            <Grid container spacing={3}>
             
              {/* Candidate */}
              {!path &&
              <Grid item xs={4}>
              <Typography sx={{mb:'10px'}}>Candidate</Typography>
              <SelectUI value={values.candidateId} handleInputChange={handleSelectChangeNormal} name={'candidateId'} option={options} label={'Choose Candidate'} width={"396px"}/>
              </Grid>}
             
              {/* Type of MRF */}
              <Grid item xs={12}>
              <Typography>Type of MRF</Typography>
              <RadioGroup row value={typeOfRTH} sx={{cursor:'not-allowed'}}>
                <Box
                  sx={{
                    bgcolor: typeOfRTH === "Internal" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: typeOfRTH === "Internal" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                    cursor:'not-allowed'
                  }}
                >
                  <FormControlLabel
                    value="Internal"
                    sx={{ color: typeOfRTH === "Internal" ? "#1677FF" : "#919EAB",cursor:'not-allowed' }}
                    control={<Radio sx={{ color: typeOfRTH === "Internal" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="Internal"
                  />
                </Box>
 
                <Box
                  sx={{
                    bgcolor: typeOfRTH === "External" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: typeOfRTH === "External" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                    cursor:'not-allowed'
                  }}
                >
                  <FormControlLabel
                    value="External"
                    sx={{ color: typeOfRTH === "External" ? "#1677FF" : "#919EAB",cursor:'not-allowed' }}
                    control={<Radio sx={{ color: typeOfRTH === "External" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="External"
                  />
                </Box>
              </RadioGroup>
            </Grid>
 
            {/* Interview Platform */}
            <Grid item xs={12}>
              <Typography>Interview Platform</Typography>
              <RadioGroup row value={values?.interviewPlatform} name='interviewPlatform'>
                <Box
                  sx={{
                    bgcolor: values?.interviewPlatform === "Theron Connect" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: values?.interviewPlatform === "Theron Connect" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                    cursor:'not-allowed'
                  }}
                >
                  <FormControlLabel
                    value="Theron Connect"
                    sx={{ color: values?.interviewPlatform === "Theron Connect" ? "#1677FF" : "#919EAB",cursor:'not-allowed' }}
                    control={<Radio sx={{ color: values?.interviewPlatform === "Theron Connect" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="Theron Connect"
                  />
                </Box>
 
                <Box
                  sx={{
                    bgcolor: values?.interviewPlatform === "External" ? "#F0F5FF" : "#00000008",
                    mr: "10px",
                    height: "50px",
                    borderRadius: "8px",
                    paddingLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    border: values?.interviewPlatform === "External" ? "1px solid #1677FF" : "none",
                    mt: "10px",
                    width: "182px",
                    cursor:'not-allowed'
                  }}
                >
                  <FormControlLabel
                    value="External"
                    sx={{ color: values?.interviewPlatform === "External" ? "#1677FF" : "#919EAB",cursor:'not-allowed' }}
                    control={<Radio sx={{ color: values?.interviewPlatform === "External" ? "#1677FF" : "#919EAB", '&.Mui-checked': { color: '#1677FF' } }} />}
                    label="External"
                  />
                </Box>
              </RadioGroup>
            </Grid>
             
              {/* Interview Round - 01 */}
              <Grid item xs={12} sx={{overflowY:'auto', height:'auto'}}>
              {rounds.map((round, index) => (
                <Box sx={{ bgcolor: '#f7f7f7', p: '20px', borderRadius: '8px', mb: '20px' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', pb: '16px' }}>
                    <Typography sx={{ fontSize: '16px', fontWeight: 600 }}>{round}</Typography>
                    {!interviewNextRound && index === rounds.length - 1 && (
                      <Typography sx={{ fontSize: '12px', color: '#1677FF', fontWeight: 600, cursor: 'pointer' }} onClick={() => handleAddRound(index)}>
                        +Add Round
                      </Typography>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 1, flexWrap: 'nowrap', height:"auto", overflowX:"scroll"}}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextFieldUI 
                      value={values?.interviewDet[index]?.interviewRound || ''} 
                      name={`interviewDet[${index}].interviewRound`}  
                      placeholder={"L1"} 
                      handleInputChange={handleInputChange}  
                      error={!!errors[`interviewDet[${index}].interviewRound`]} 
                      helperText={errors[`interviewDet[${index}].interviewRound`]} 
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <SelectUI 
                      value={values?.interviewDet[index]?.interviewMode || ''} 
                      handleInputChange={handleSelectChange} 
                      name={`interviewDet[${index}].interviewMode`} 
                      option={InterviewMode} 
                      label={'Interview mode'} 
                      error={!!errors[`interviewDet[${index}].interviewMode`]} 
                      helperText={errors[`interviewDet[${index}].interviewMode`]} 
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <InterviewLinkUI 
                      value={values?.interviewDet[index]?.interviewLink || ''} 
                      name={`interviewDet[${index}].interviewLink`} 
                      placeholder={"Interview Link"} 
                      handleInputChange={handleInputChange} 
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextFieldUI 
                      value={values?.interviewDet[index]?.interviewDate || ''} 
                      name={`interviewDet[${index}].interviewDate`} 
                      placeholder={"L1"} 
                      handleInputChange={handleInputChange}  
                      type={'date'} 
                      error={!!errors[`interviewDet[${index}].interviewDate`]} 
                      helperText={errors[`interviewDet[${index}].interviewDate`]}  
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextFieldUI 
                      value={values.interviewDet[index]?.from?.split('T')[1]?.slice(0, 5) || ''} 
                      name={`interviewDet[${index}].from`} 
                      placeholder={""} 
                      handleInputChange={handleInputChangeTime}  
                      type={'time'} 
                      error={!!errors[`interviewDet[${index}].from`]} 
                      helperText={errors[`interviewDet[${index}].from`]} 
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <TextFieldUI 
                      value={values.interviewDet[index]?.to?.split('T')[1]?.slice(0, 5) || ''} 
                      name={`interviewDet[${index}].to`} 
                      placeholder={""} 
                      handleInputChange={handleInputChangeTime}  
                      type={'time'}
                    error={!!errors[`interviewDet[${index}].to`]} 
                      helperText={errors[`interviewDet[${index}].to`]} 
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                  <SearchableDropdown
                    searchAPICall={getRthPositionReportsTo} // Same as the API call in CommonAutocomplete
                    label="Choose Interviewer" // Same label as CommonAutocomplete
                    value={
                      values?.interviewDet[index]?.interviewerId
                        ? { id: values?.interviewDet[index]?.interviewerId?.id, label: values?.interviewDet[index]?.interviewerId?.label }
                        : null
                    } 
                    onOptionSelect={(selectedOption:any) =>
                      handleSelectChange({
                        target: {
                          name: `interviewDet[${index}].interviewerId`,
                          value: selectedOption,
                        },
                      })
                    } 
                    error={!!errors[`interviewDet[${index}].interviewerId`]} 
                    helperText={errors[`interviewDet[${index}].interviewerId`]}
                  />

                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                       <SearchableDropdown
                    searchAPICall={getRthPositionReportsTo} // Same as the API call in CommonAutocomplete
                    label="Optional Attendees" // Same label as CommonAutocomplete
                    value={
                      values?.interviewDet[index]?.optionalAttendeesId
                        ? { id: values?.interviewDet[index]?.optionalAttendeesId?.id, label: values?.interviewDet[index]?.optionalAttendeesId?.label }
                        : null
                    } 
                    onOptionSelect={(selectedOption:any) =>
                      handleSelectChange({
                        target: {
                          name: `interviewDet[${index}].optionalAttendeesId`,
                          value: selectedOption,
                        },
                      })
                    } 
                    error={!!errors[`interviewDet[${index}].optionalAttendeesId`]} 
                    helperText={errors[`interviewDet[${index}].optionalAttendeesId`]}
                  />
                  </Grid>

                  
                    {/* Delete Icon */}
                    <Grid item sx={{p:0, m:0}}>
                    <img 
                      src={DeleteIcon} 
                      alt='Delete Icon' 
                      style={{ paddingLeft: '6px', cursor: 'pointer', width: '20px', marginTop:'14px'}} 
                      onClick={() => handleDeleteRound(index)} 
                    />
                    </Grid>
                </Box>
                </Box>

                ))}
                 
                 {rounds.length <= 0 && (
                <Typography sx={{ fontSize: '12px', color: '#1677FF', fontWeight: 600, cursor: 'pointer', mt: 2 }} onClick={handleInitialRound}>
                  + Create Interview Round
                </Typography>
                 )}
                             
              </Grid>
 
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{height:'50px'}}>
          <Box sx={{pb:'20px'}}>
          <Button variant="contained" sx={{ bgcolor:"#3185FC", borderRadius:'8px', mr: 2, textTransform:'none','&:hover':{bgcolor:'#3185FC'}}} disabled={rounds.length <= 0} onClick={handleSubmit}  >
            Schedule Interview
          </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </React.Fragment>    
  );
}

