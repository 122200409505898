import React, { useEffect, useState } from "react";
import { Box, Grid, InputAdornment, InputBase, CircularProgress } from "@mui/material";
import InProgressCard from "./InprogressCard";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
import { getInProgressAPIData } from "../../../../Crud/card";
import { useAppSelector } from "../../../../redux/hooks";
import SearchIcon from '@mui/icons-material/Search';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,        // Extra-small: <600px
      sm: 600,      // Small: 600px to 960px
      md: 960,      // Medium: 960px to 1280px
      lg: 1380,     // Large: 1280px to 1920px
      xl: 1920,     // Extra-large: >1920px
    },
  },
});

interface MyRequestsCardProps {
  drawerOpen: boolean;
  setLowCount: Function;
  setMediumCount: Function
  setHighCount: Function
}

const InProgress: React.FC<MyRequestsCardProps> = ({ drawerOpen, setLowCount, setHighCount, setMediumCount }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userId = localStorage.getItem("userId") ?? "";
  const [searchQuery, setSearchQuery] = useState<string>('');
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }

    const timeoutId = setTimeout(() => {
      setLoading(true);
      getInProgressAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          setLoading(false);
          setData(res?.data?.listOfRth);
          setHighCount(res?.data?.count?.InProgressPriorityCount?.HighCount);
          setLowCount(res?.data?.count?.InProgressPriorityCount?.LowCount);
          setMediumCount(res?.data?.count?.InProgressPriorityCount?.MediumCount);
        })
        .catch((error) => {
          setData([])
        })
        .finally(() => {
          setLoading(false);
          setSearchLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [userId, teamHeadFilter, searchQuery, setHighCount, setLowCount, setMediumCount, setData]);


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
    <ThemeProvider theme={customTheme}>
      <Grid item sx={{ marginTop: data.length > 0 ? "-54px" : "-58px", paddingLeft:"10px" }} position='absolute'>
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: '1px solid #ccc',
            borderRadius: 1,
            padding: 0,
            backgroundColor: 'white',
            minHeight: '3px',
            fontSize: 14,
            '.MuiInputBase-input': {
              padding: '5px 5px',
            }
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '25%',
              transform: 'translateY(-50%)',
              color: '#2F54EB',
            }}
          />
        )}
      </Grid>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
            }}
          >
            <CircularProgress size={100} sx={{ color: '#2F54EB' }} />
          </Box>
        ) : data?.length > 0 ? (
          <Grid container spacing={1} columns={12}>
            {data.map((job: any, index: number) => (
              <Grid 
              item 
              xs={12} 
              sm={6}  
              md={drawerOpen ? 4 : 3}  
              lg={drawerOpen ? 3 : 2.4}  
              xl={drawerOpen ? 3 : 2.4} 
              key={index}
            >
                <InProgressCard job={job} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "300px" }}>
            <img
              src={NoDataAvail}
              alt="No Data Available"
              style={{ maxWidth: "200px" }}
            />
          </Box>
        )}
      </Box>
      </ThemeProvider>
    </>
  );
};

export default InProgress;