import React from 'react';
import { Box, Typography } from '@mui/material';
import { Job } from './types';

// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface prop{
  role:string
  job?: Job;
}

const ToBeAssigned: React.FC<prop> = ({role,job}) => {
  console.log("ToBeAssigned job",job);
  return (
    <Box>
    
    <Box sx={{ display: 'flex', justifyContent: 'space-between', padding:"15px 10px"}} >
        <Box>
          {
            job?.recruiterLeadAssigned === false ? (
              <Typography color="textSecondary" sx={{ fontSize: '10px', color: "#8C8C8C", }}> Click to Assign Recruiter Lead</Typography>
            ) : (
              <Typography color="textSecondary" sx={{ fontSize: '10px', color: "#8C8C8C", }}> Click to Assign Recruiter</Typography>
            )
          }
    
    </Box>
    </Box>


    </Box>
  );
};

export default ToBeAssigned;

