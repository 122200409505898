import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  IconButton,
  Hidden,
  Box,
  Tooltip,
} from "@mui/material";
import { Menu } from "@mui/icons-material";
import theronlogo from "../../../assets/images/theronlogo.svg";
import dashboardIcon from "../../../assets/images/dashboardIcon.svg";
import rthIcon from "../../../assets/images/rthIcon.svg";
import reportsIcon from "../../../assets/images/reports.svg";
import resumeIcon from "../../../assets/images/resumeIcon.svg";
import interviewIcon from "../../../assets/images/usersIcon.svg";
import dash from "../../../assets/images/dashboard2.svg";
import trh2 from "../../../assets/images/rth2.svg";
import usericon from "../../../assets/images/usersicon2.svg";
import resumeicon2 from "../../../assets/images/resumeicon2.svg";
import reportsicon2 from "../../../assets/images/reportsicon2.svg";
// import settingsIcon from "../../../assets/images/settingsIcon.svg";
// import settingicon2 from "../../../assets/images/settingicon2.svg";
import mastericon from "../../../assets/images/mastericon.svg";
import mastericon2 from "../../../assets/images/mastericon2.svg";
import OfferApproval from "../../../assets/images/jobOffer.svg";
import OfferApproval2 from "../../../assets/images/jobOffer2.svg";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useLocation } from "react-router-dom";

const drawerWidth = 64;

const defaultPermissions = {
  assignRecruiterLead: false,
  assignRecruiter: false,
  jj: false,
};

// interface Permission {
//     [key: string]: boolean;
//   }

const Sidebar: React.FC = () => {
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [userRole, setUserRole] = useState<string>("");
  // const [permissions, setPermissions] = useState<Permission>({});
  const [activeIcon, setActiveIcon] = useState<string>("");
  const location = useLocation();
  // const active = location.pathname;
  // const { permissionsList, roleName } = localStorage.getItem("user")
  //   ? JSON.parse(localStorage.user)
  //   : null;
  console.log("userRole", userRole);
  // console.log("permissionsList", permissionsList);
  const userData = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "{}")
    : {
        permissionsList: defaultPermissions,
        roleName: "",
      };

  const permissionsList = userData.permissionsList || defaultPermissions;
  const roleName = userData.roleName || "";
  console.log("permissionnnnnn", permissionsList.addInterviewer);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded: any = jwtDecode(token);
        const role = decoded.role;
        const rrole = role?.replace(/\s+/g, "") || "";
        setUserRole(rrole);

        const currentPath = window.location.pathname;
        setInitialActiveIcon(currentPath);
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  const setInitialActiveIcon = (path: string) => {
    switch (path) {
      case "/DashBoardRTH":
      case "/DashBoardTAH":
      case "/DashBoardRL":
      case "/DashBoardR":
      case "/DashBoardAH":
        setActiveIcon("dashboard");
        break;
      case "/masters":
        setActiveIcon("master");
        break;
      case "/dashboard":
      case "/newrth":
        setActiveIcon("rth");
        break;
      case "/interview":
        setActiveIcon("interview");
        break;
      case "/resumeRepo":
        setActiveIcon("resume");
        break;
      case "/reports":
        setActiveIcon("reports");
        break;
      case "/offer-approval":
      case "/job-offer-tableview":
        setActiveIcon("OfferApproval");
        break;
      case "/settings":
        setActiveIcon("settings");
        break;
      default:
        setActiveIcon("");
    }
  };

  useEffect(() => {
    setInitialActiveIcon(location.pathname);
  }, [location.pathname]);

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setIsMobileOpen(!isMobileOpen);
  };

  const handleIconClick = (iconName: string, path: string) => {
    setActiveIcon(iconName);
    navigate(path);
  };

  const getButtonStyle = (iconName: string) => ({
    backgroundColor: activeIcon === iconName ? "#f0f0f0" : "transparent",
    borderRadius: "12px",
  });

  const drawerContent = (
    <List sx={{ padding: 0 }}>
      <Box display={"flex"} flexDirection={"column"}>
        <Box>
          <ListItem
            sx={{
              justifyContent: "center",
              padding: "10px 0",
              marginBottom: "1%",
            }}
          >
            <img
              src={theronlogo}
              alt="logo"
              style={{ maxWidth: "40px", maxHeight: "40px" }}
            />
          </ListItem>
        </Box>
        <Box>
        {roleName !== "ADMIN" && (
          <ListItem sx={{ justifyContent: "center", padding: "10px 0" }}>
            <Tooltip
              title="Dashboard"
              placement="right"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#000",
                    color: "#fff",
                    fontSize: "0.8rem",
                  },
                },
                arrow: { sx: { color: "#000" } },
              }}
            >
              <IconButton
                sx={getButtonStyle("dashboard")}
                onClick={() => handleIconClick("dashboard", "/DashBoardRTH")}
              >
                <img
                  src={activeIcon === "dashboard" ? dash : dashboardIcon}
                  alt="Dashboard Icon"
                  style={{ maxWidth: "40px", maxHeight: "40px" }}
                />
              </IconButton>
            </Tooltip>
          </ListItem>)}
          {roleName === "ADMIN" && (
            <ListItem sx={{ justifyContent: "center", padding: "10px 0" }}>
              <Tooltip
                title="Master"
                placement="right"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "0.8rem",
                    },
                  },
                  arrow: { sx: { color: "#000" } },
                }}
              >
                <IconButton
                  sx={getButtonStyle("master")}
                  onClick={() => handleIconClick("master", "/masters")}
                >
                  <img
                    src={activeIcon === "master" ? mastericon2 : mastericon}
                    alt="Master Icon"
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                  />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}
          {roleName !== "ADMIN" && (
            <ListItem sx={{ justifyContent: "center", padding: "10px 0" }}>
              <Tooltip
                title="MRF"
                placement="right"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "0.8rem",
                    },
                  },
                  arrow: { sx: { color: "#000" } },
                }}
              >
                <IconButton
                  sx={getButtonStyle("rth")}
                  onClick={() => handleIconClick("rth", "/dashboard")}
                >
                  <img
                    src={activeIcon === "rth" ? trh2 : rthIcon}
                    alt="logo"
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                  />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          {(permissionsList.assignAndEditRecruiterLead ||
            permissionsList.assignAndEditRecruiter ||
            permissionsList.recruiterAccess) && (
            <ListItem sx={{ justifyContent: "center", padding: "10px 0" }}>
              <Tooltip
                title="Interview"
                placement="right"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "0.8rem",
                    },
                  },
                  arrow: { sx: { color: "#000" } },
                }}
              >
                <IconButton
                  sx={getButtonStyle("interview")}
                  onClick={() => handleIconClick("interview", "/interview")}
                >
                  <img
                    src={activeIcon === "interview" ? usericon : interviewIcon}
                    alt="logo"
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                  />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          {(permissionsList.assignAndEditRecruiterLead ||
            permissionsList.assignAndEditRecruiter ||
            permissionsList.recruiterAccess) && (
            <ListItem sx={{ justifyContent: "center", padding: "10px 0" }}>
              <Tooltip
                title="Resume Repo"
                placement="right"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "0.8rem",
                    },
                  },
                  arrow: { sx: { color: "#000" } },
                }}
              >
                <IconButton
                  sx={getButtonStyle("resume")}
                  onClick={() => handleIconClick("resume", "/resumeRepo")}
                >
                  <img
                    src={activeIcon === "resume" ? resumeicon2 : resumeIcon}
                    alt="logo"
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                  />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          {roleName !== "ADMIN" && (
            <ListItem sx={{ justifyContent: "center", padding: "10px 0" }}>
              <Tooltip
                title="Reports"
                placement="right"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "0.8rem",
                    },
                  },
                  arrow: { sx: { color: "#000" } },
                }}
              >
                <IconButton
                  sx={getButtonStyle("reports")}
                  onClick={() => handleIconClick("reports", "/reports")}
                >
                  <img
                    src={activeIcon === "reports" ? reportsicon2 : reportsIcon}
                    alt="logo"
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                  />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          {(permissionsList.addInterviewer || permissionsList.recruiterAccess || permissionsList.assignAndEditRecruiter || permissionsList.assignAndEditRecruiterLead || permissionsList.offerApprover ) && (
            <ListItem sx={{ justifyContent: "center", padding: "10px 0" }}>
              <Tooltip
                title="Offer Approval"
                placement="right"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "0.8rem",
                    },
                  },
                  arrow: { sx: { color: "#000" } },
                }}
              >
                <IconButton
                  sx={getButtonStyle("OfferApproval")}
                  onClick={() => {
                    const path = permissionsList.offerApprover
                      ? "/job-offer-tableview"
                      : "/offer-approval";
                    handleIconClick("OfferApproval", path);
                  }}
                >
                  <img
                    src={
                      activeIcon === "OfferApproval"
                        ? OfferApproval2
                        : OfferApproval
                    }
                    alt="logo"
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                  />
                </IconButton>
              </Tooltip>
            </ListItem>
          )}

          {/* {roleName !== "ADMIN" && (
            <ListItem sx={{ justifyContent: "center", padding: "10px 0" }}>
              <Tooltip
                title="Settings"
                placement="right"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "0.8rem",
                    },
                  },
                  arrow: { sx: { color: "#000" } },
                }}
              >
                <IconButton
                  sx={getButtonStyle("settings")}
                  onClick={() => handleIconClick("settings", "/settings")}
                >
                  <img
                    src={
                      activeIcon === "settings" ? settingicon2 : settingsIcon
                    }
                    alt="logo"
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                  />
                </IconButton>
              </Tooltip>
            </ListItem>
          )} */}
        </Box>
      </Box>
    </List>
  );

  return (
    <Box sx={{ display: "flex", width: 64 }}>
      <Hidden smUp>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ marginLeft: 2, marginTop: 2 }}
        >
          <Menu />
        </IconButton>
        <Drawer
          variant="temporary"
          open={isMobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "linear-gradient(180deg, #36CFC9, #2F54EB)",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
              background: "linear-gradient(180deg, #36CFC9, #2F54EB)",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </Hidden>
    </Box>
  );
};

export default Sidebar;
