import { Box, Button, Card, Grid, InputAdornment, InputBase, Typography, CircularProgress } from "@mui/material";
import JobHeader from "../common/JobHeader";
import JobTime from "../common/JobTime";
import JobDetails from "../common/JobDetails";
import { useNavigate } from "react-router-dom";
import NoDataAvail from "../../assets/images/NoDataAvail.svg";
import { useEffect, useState } from "react";
import { getReadyForJdCreationAPIData } from "../../Crud/card";
import { useAppSelector } from "../../redux/hooks";
import SearchIcon from '@mui/icons-material/Search';

interface ReadyForJdCreationCardProps {
  drawerOpen: boolean;
  setLowCount: Function;
  setMediumCount: Function
  setHighCount: Function
}

const ReadyForJdCreation: React.FC<ReadyForJdCreationCardProps> = ({ drawerOpen,setLowCount, setHighCount, setMediumCount }) => {
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userId = localStorage.getItem("userId") ?? "";
  const [searchQuery, setSearchQuery] = useState<string>('');
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const navigate = useNavigate();
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }

    const timeoutId = setTimeout(() => {
      setLoading(true);
      getReadyForJdCreationAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          setData(res?.data?.listOfRth);          
          setLowCount(res.data.count.ReadyForJdCreationPriorityCount.LowCount);
          setHighCount(res.data.count.ReadyForJdCreationPriorityCount.HighCount);
          setMediumCount(res.data.count.ReadyForJdCreationPriorityCount.MediumCount);
        })
        .catch((error) => {
        })
        .finally(() => {
          setLoading(false);
          setSearchLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [userId, teamHeadFilter, searchQuery,setMediumCount,setLowCount,setHighCount]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };
  const handleCardClick = (rthId: string) => {
    navigate(`/request-hire`, { state: { id: rthId, queryParam: "ready-for-jd-creation", label: "ready-for-jd-creation" } });
  };
  return (
    <>
      <Grid item sx={{ marginTop: data.length > 0 ? "-60px" : "-58px" }} position='absolute'>
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: '1px solid #ccc',
            borderRadius: 1,
            padding: 0,
            backgroundColor: 'white',
            minHeight: '3px',
            fontSize: 14,
            '.MuiInputBase-input': {
              padding: '5px 5px',
            }
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '25%',
              transform: 'translateY(-50%)',
              color: '#2F54EB',
            }}
          />
        )}
      </Grid>
      <Box>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '300px',
            }}
          >
            <CircularProgress size={100} sx={{ color: '#2F54EB' }} />
          </Box>
        ) : data?.length > 0 ? (
          <Box sx={{ display: "grid", gridTemplateColumns: drawerOpen ? "repeat(4, 1fr)" : "repeat(5, 1fr)", gap: 2, alignItems: "flex-start" }}>
            {data?.map((item: any) => (
              <Card
                key={item?.seqId}
                sx={{
                  height: "auto",
                  minHeight: "120px",
                  minWidth: "fit-content",
                  boxShadow: "none",
                  border: "1px solid #D9D9D9",
                  borderRadius: "8px",
                  cursor:"pointer"
                }}
                onClick={()=>handleCardClick(item?.rthId)}
              >
                <Box sx={{ display: "flex", flexDirection: "column", margin: 1, position: "relative" }}>
                  <Box sx={{ paddingInline: "0.5rem", cursor: "pointer" }}>
                    <JobHeader department={item?.positionTitle} priority={item?.priority} business={item?.business} seqId={item?.seqId} />
                    <JobTime posted={item?.posted} priority={item?.priority} seqId={item?.seqId} createdAt={item?.createdAt} />
                    <JobDetails typeOfRth={item?.typeOfRth} modeOfRth={item?.modeOfRth} rthProcess={item?.rthProcess} status={item?.status} modesOfRth={item?.modesofRth} />
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", ml: 1, marginTop: "2px" }}>
                          <Typography variant="body2" sx={{ fontSize: "10px", lineHeight: 1.2 }}>
                            {item?.name}
                          </Typography>
                          <Typography variant="caption" sx={{ fontSize: "8px", lineHeight: 1.2, color: "gray" }}>
                            {item?.reqName}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 2 }}>
                    <Button
                      sx={{
                        textTransform: "none",
                        minWidth: "80px",
                        padding: "4px 8px",
                        fontSize: "10px",
                        background: "#1677FF",
                        color: "#FFFFFF",
                        "&:hover": {
                          background: "#1677FF",
                          color: "#FFFFFF",
                          boxShadow: "none",
                        },
                      }}
                      onClick={(e) => {
                        navigate(`/JDCreation/${item?.rthId}`);
                        e.stopPropagation();
                      }}
                    >
                      Create JD
                    </Button>
                  </Box>
                </Box>
              </Card>
            ))}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <img src={NoDataAvail} alt="No Data Available" style={{ maxWidth: "200px" }} />
          </Box>
        )}
      </Box>
    </>
  );
}

export default ReadyForJdCreation;