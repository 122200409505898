import { Box, Paper, Typography, useMediaQuery } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getRthDetailsByRthId } from "../../../redux/features/resume";
 
export default function JobDetails( {drawerOpen}:any ) {
  const{id}=useParams();
  const dispatch=useAppDispatch();
  const details=useAppSelector((state:any)=>state.resume.rthDetails);
 
  const Md = useMediaQuery("(max-width: 1504px)");
 
  useEffect(()=>{
    dispatch(getRthDetailsByRthId(id));
  },[id,dispatch]);
 
  return (
    <Box sx={{ mt: '10px' }} ml={drawerOpen ? "20px": "115px" }>
      <Paper sx={{ height: Md?'65vh':'72vh', width: drawerOpen ? (Md ? '72vw' : '78vw') : (Md ? '84vw' : '87vw'),  boxShadow: 'none', mr:(drawerOpen && Md)?'22.5px':'0', borderRadius:'8px', overflowY:'auto','&::-webkit-scrollbar': {
      width: '0px', // Set the scrollbar width
      height: '0px', // Set the scrollbar height (if horizontal)
    }}}>
        <Box>
      {details?.jdTemplateText ? (
          <Typography sx={{pt:2,ml:2}}
            dangerouslySetInnerHTML={{ __html: details?.jdTemplateText }}
          />
        ) : (
          <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', height:'70vh', width: '85vw'}}>
          <Typography sx={{ mt: 2 }}>No description available.</Typography>
          </Box>
        )}
</Box>
    </Paper>
    </Box>
  )
}