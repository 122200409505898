import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openDrawer: false,
  commentGetDataRefresh: false,
  mainReplyComment: {},
  child1ReplyComment: {},
};
export const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    toggleCommentsDrawer: (state, action) => {
      state.openDrawer = action.payload;
    },
    toggleCommentGetDataRefresh: (state, action) => {
      state.commentGetDataRefresh = action.payload;
    },
    getMainReplyComment: (state, action) => {
      state.mainReplyComment = action.payload;
    },
    getChild1ReplyComment: (state, action) => {
      state.child1ReplyComment = action.payload;
    },
  },
});

export const {
  toggleCommentsDrawer,
  toggleCommentGetDataRefresh,
  getMainReplyComment,
  getChild1ReplyComment
} = commentsSlice.actions;
export default commentsSlice.reducer;
