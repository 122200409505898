import React from "react";
import { Box, Typography } from "@mui/material";
import RecruiterLeadDropDown from "./RecruiterLeadDropDown";
import RecruiterDropDown from "./RecruiterDropDown";

interface Prop {
  tabName: any;
}

const DropDownsOfRandRl: React.FC<Prop> = ({ tabName }) => {  

  const userData = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user") || "{}")
  : {
      permissionsList: {},
      roleName: "",
    };

const permissionsList = userData.permissionsList || {};

  return (
    <Box>
      {tabName === "Assigned MRF" && (permissionsList.assignAndEditRecruiter) && (
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              paddingLeft: "10px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            Recruiter
          </Typography>
          <RecruiterDropDown />
        </Box>
      )}

      {tabName === "Assigned MRF" && (permissionsList.assignAndEditRecruiterLead) && (
        <Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              paddingLeft: "10px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            Recruiter Lead
          </Typography>
          <RecruiterLeadDropDown />
        </Box>
      )}
    </Box>
  );
};

export default DropDownsOfRandRl;
