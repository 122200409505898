import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';
import JobDetails from './JobDetails';
import JobFooter from './JobFooter';
import JobProgress from './JobProgress';
import JobTime from './JobTime';
import JobHeader from './JobHeader';
import { fetchJobs, Job, movetojob, jobpost } from '../../redux/features/jobcardSlice';
import closedImg from '../../assets/images/closedtext.svg'
import ApprovalActions from './jobApprovel';
import ToBeAssigned from './tobeAssigned';
import { useNavigate, useLocation } from 'react-router-dom';
import { setL1Status, setL2Status } from '../../redux/features/rthSlice';
import { useDispatch } from 'react-redux';
import Postin from './Postin';

import { AppDispatch } from '../../redux/store';
// import { log } from 'console';

interface JobCardProps {
  job?: Job;
  label?: string;
  userId?: string
  teamHeadFilterDto?: any
}


const JobCard: React.FC<JobCardProps> = ({ job,label, userId, teamHeadFilterDto }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();  
  const location = useLocation();

  const handleRctrButton=async()=>{
    
    if (job?.rthId && label === "Waiting to Move to Job") 
    { 
      await dispatch(movetojob({ rthId: job.rthId, status: "MoveToJob" })); 
      dispatch(fetchJobs({ userId, teamHeadFilterDto }));  
      
    }
    if (job?.rthId && label === "Ready for JD Creation") {
      navigate(`/JDCreation/${job?.rthId}`)
    }
    if (job?.rthId && label === "Job Post") 
      { 
        await dispatch(jobpost({ rthId: job.rthId })); 
        dispatch(fetchJobs({ userId, teamHeadFilterDto }));         
      }
  }

  const handleApproveL1 = async (jobId?: string | undefined) => {

    await setL1Status({ rthId: jobId, status: "Approved" })
    dispatch(fetchJobs({ userId, teamHeadFilterDto }));    
  };

  const handleApproveL2 =async (jobId?: string | undefined) => {
    await setL2Status({ rthId: jobId, status: "Approved" })
    dispatch(fetchJobs({ userId, teamHeadFilterDto }));    
  };

  const handleDeclineL1 =async (jobId: string | undefined) => {
    // Handle decline action
    await setL1Status({ rthId: jobId, status: "Declined" })
    dispatch(fetchJobs({ userId, teamHeadFilterDto }));    
  };
  const handleDeclineL2 =async (jobId: string | undefined) => {
    // Handle decline action
    await setL2Status({ rthId: jobId, status: "Declined" })
    dispatch(fetchJobs({ userId, teamHeadFilterDto }));    
  };

  const handleBoxClick = () => {
    if (location.pathname.includes('newRequestToHire')) {
      navigate(`/newRequestToHire/${job?.positionTitle}/${job?.rthId}`);
    } else {
      navigate(`/request-hire`, { state: { id: job?.rthId } });
    }
  }
  const checkRth = label === "overall MRF";
  const checkRequests = label === "My Requests";
  const checkRthL1Status = job?.l1Status === "Pending";
  const checkRthL2Status = job?.l1Status === "Approved" && job?.l2Status === "Pending";
  const checkReruestsStatus = job?.l1Status === "Approved" && job?.l2Status === "Approved";


  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: 1, position: 'relative' }} onClick={handleBoxClick}>
      <Box sx={{
        backgroundColor: label === "Closed"
          ? "#F6F6F6"
          : (checkRth && checkRthL1Status) || (checkRequests && checkRthL2Status)
            ? "#F2ECFA"
            : (checkRth && checkRthL2Status) || (checkRequests && checkReruestsStatus)
              ? "#EAF5FE" : checkRequests || checkRthL1Status ? "#FAF8EC"
                : "white",
        borderRadius: "10px",
        border: (checkRth && checkRthL1Status) || (checkRequests && checkRthL2Status)
          ? "2px solid #5708B8"
          : (checkRth && checkRthL2Status) || (checkRequests && checkReruestsStatus)
            ? "2px solid #36CFC9" : checkRequests || checkRthL1Status ? "2px solid #FFE546"
              : "1px solid lightgray",
        boxShadow: ((checkRth && checkRthL1Status) || (checkRequests && checkRthL2Status)) || ((checkRth && checkRthL2Status) || (checkRequests && checkReruestsStatus))
          ? "0px 4px 10px rgba(0, 0, 0, 0.1)"
          : "none",
        width: "100%",
        padding: "7px",
        paddingInline:"0.5rem",
        cursor: 'pointer'
      }}>

        <JobHeader department={job?.positionTitle} priority={job?.priority} business={job?.business} seqId={job?.seqId} />

        <JobTime posted={job?.posted} priority={job?.priority} seqId={job?.seqId} />

        <JobDetails typeOfRth={job?.typeOfRth} modeOfRth={job?.modeOfRth} rthProcess={job?.rthProcess} status={job?.status} modesOfRth={job?.modesofRth} />

        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ width: "100%" }}>
            


            {checkRth && job?.l1Status === "Approved" && job?.l2Status === "Approved" ? (
              <Box p={1}>
                <Typography variant="h6" fontSize={"100%"} sx={{ color: 'green' }}>Accepted</Typography>
              </Box>

            ) : (
              checkRth && (
                <ApprovalActions
               
                  onApprove={(event) => {
                    event.stopPropagation();
                    if (job?.l1Status === "Pending") {
                      handleApproveL1(job?.rthId);
                    }
                    if (job?.l1Status === "Approved") {

                      handleApproveL2(job.rthId);
                    }
                  }}
                  onDecline={(event) => {
                    event.stopPropagation();
                    if (job?.l1Status === "Pending") {
                      handleDeclineL1(job?.rthId)
                    }
                    if (job?.l1Status === "Approved") {
                      handleDeclineL2(job?.rthId)
                    }
                  }}
                />
              )
            )}


            {label === "To Be Assigned" && (
              <ToBeAssigned
              />
            )}
            {(job?.status === "Closed" || job?.status === "In Progress") ?
              <Box>
                <Box sx={{ display: "flex", marginTop: "20px", paddingLeft: "2px" }}>
                  {
                    (label !== "Job Post" && label !== "Waiting to Move to Job" && label !== "Ready for JD Creation") && (
                      <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                        <Box>
                          <Avatar src={job.posReportsTo.profilePicture} alt="jobcardimagea" sx={{ width: 24, height: 24 }}></Avatar>
                        </Box>

                        <Box display={'flex'} flexDirection={'column'}>
                          <Typography sx={{ fontSize: '12px' }}>{job.posReportsTo.firstName}</Typography>
                          <Typography sx={{ fontSize: '10px' }}>{job.posReportsTo.role}</Typography>
                        </Box>

                      </Box>
                    )
                  }
                  {
                    (label === "Job Post" || label === "Waiting to Move to Job" || label === "Ready for JD Creation") && (
                      <Postin 
                      label={label}
                      jobPosted={job?.jobPosted}
                      handleRecruiterButton={handleRctrButton}
                      />
                    )
                  }
                  <Box sx={{ display: 'flex', flexDirection: 'column', ml: 1, marginTop: "2px" }}>
                    <Typography variant='body2' sx={{ fontSize: '10px', lineHeight: 1.2 }}>{job?.name}</Typography>
                    <Typography variant='caption' sx={{ fontSize: '8px', lineHeight: 1.2, color: 'gray' }}>{job?.reqName}</Typography>
                  </Box>
                </Box>
                {label === "Closed" && (
                  <Box sx={{ position: "absolute", top: 35, right: 15 }}>
                    <img src={closedImg} style={{ height: "90px" }} alt="close" />
                  </Box>
                )}
              </Box>
              :
              <>
                {job?.tabStatus === "open" ?
                  <JobFooter status={job?.status} />
                  :
                  <>
                    {(!checkRth && label === "My Requests") && (
                      <JobProgress L1Status={job?.l1Status} L2Status={job?.l2Status} />)}
                    <JobFooter status={job?.status} />
                  </>
                }
              </>
            }
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default JobCard;