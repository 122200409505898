export const navigateBasedOnPriority = (permission:any, navigate:any) => {
  
    if (permission.mrfUsers) {
      navigate("/DashBoardRTH");
    } else if (permission.assignAndEditRecruiterLead) {
      navigate("/DashBoardTAH");
    } else if (permission.assignAndEditRecruiter) {
      navigate("/DashBoardRL");
    } else if (permission.recruiterAccess) {
      navigate("/DashBoardR");
    } else if (permission.approveDeclineMrf) {
      navigate("/DashBoardAH");
    } else if (permission.offerApprover) {
      navigate("/job-offer-tableview");
    } else {
    //   navigate("/no-page");
      console.error("No matching module permission found.");
    }
  };
  