import React from 'react';
import { Box, Chip } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import pendingicon from '../../../assets/images/pendingicon.svg';
import Declineicon from '../../../assets/images/Declineicon.svg'

interface JobStatusProps {
  L1Status?: string;
  L2Status?: string;
  
}

const JobProgress: React.FC<JobStatusProps> = ({L1Status, L2Status}) => {
  return (
    <Box display="flex" alignItems="center" mt={1} p={1} sx={{ paddingLeft: "2px" }}>
      { L1Status === "Approved" ? 
      <Chip
      label={`L1 ${L1Status}`}
      icon={<CheckCircleOutlineIcon style={{ color: '#4caf50', fontSize: '12px' }} />}
      sx={{ backgroundColor: '#F6FFED', color: '#595959', fontSize: '10px', border: "1px solid #D9F7BE", height: '24px' }}
    /> : L1Status === "Declined" ?
    <Chip
      label={`L1 ${L1Status}`} 
      icon={<img src={Declineicon} alt='Declined' style={{ width: '10px', height: '10px' }} />}
      sx={{ backgroundColor: '#FFE7E8', color: '#595959', fontSize: '10px', padding: '1px', margin: '1px', border: "1px solid rgba(232, 80, 91, 0.6)", height: '24px' }}
    /> :
    <Chip
        label={`L1 ${L1Status}`}
        icon={<img src={pendingicon} alt='pending' style={{ width: '10px', height: '10px' }} />}
        sx={{ backgroundColor: '#FFF7E6', color: '#595959', fontSize: '10px', padding: '1px', margin: '1px', border: "1px solid #FFE7BA", height: '24px' }}
      />
    }
      {L1Status !== "Declined" && (
        <>
      <Box sx={{ width: "40px", backgroundColor: "#fff", height: "0px", border: "1px dashed #BFBFBF" }}></Box>
      { L2Status === "Approved" ? 
      <Chip
      label={`L2 ${L2Status}`}
      icon={<CheckCircleOutlineIcon style={{ color: '#4caf50', fontSize: '12px' }} />}
      sx={{ backgroundColor: '#F6FFED', color: '#595959', fontSize: '10px', border: "1px solid #D9F7BE", height: '24px' }}
    /> : L2Status === "Declined" ?
    <Chip
      label={`L2 ${L2Status}`} 
      icon={<img src={Declineicon} alt='Declined' style={{ width: '10px', height: '10px' }} />}
      sx={{ backgroundColor: '#FFE7E8', color: '#595959', fontSize: '10px', padding: '1px', margin: '1px', border: "1px solid rgba(232, 80, 91, 0.6)", height: '24px' }}
    />:
    <Chip
        label={`L2 ${L2Status}`} 
        icon={<img src={pendingicon} alt='pending' style={{ width: '10px', height: '10px' }} />}
        sx={{ backgroundColor: '#FFF7E6', color: '#595959', fontSize: '10px', padding: '1px', margin: '1px', border: "1px solid #FFE7BA", height: '24px' }}
      />    
    }
    </>
  )}
    </Box>
  );
}

export default JobProgress;
