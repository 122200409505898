import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Grid, TextField, Divider, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { getApprovers } from "../../../../Crud/rth";
import { editDepartment, fetchDepartment } from "../../../../redux/features/departmentSlice";
import { toast } from "react-toastify";
import Avatar from "@mui/material/Avatar";
import Select from "react-select";

interface EditUserFormProps {
    open: boolean;
    onClose: () => void;
    user: DepartmentData;
}

export interface Approver1 {
    userId: string;
    userName: string;
    designation: string;
    imageUrl: string | null;
}

export interface Approver2 {
    userId: string;
    userName: string;
    designation: string;
    imageUrl: string | null;
}

export interface DepartmentData {
    id?: string;
    departmentId: string;
    departmentName: string;
    l1Approver: Approver1;
    l2Approver: Approver2;
    l3Approver: null;
    status: string;
    createdAt: string;
}

const EditDepartment: React.FC<EditUserFormProps> = ({ open, onClose, user }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [formData, setFormData] = useState<DepartmentData>(user);
    console.log(formData);
    const [l1Approvers, setL1Approvers] = useState<{ value: string; label: string; imageUrl: string | null; designation: string }[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    useEffect(() => {
        getApprovers(searchTerm)
            .then((res) => {
                const data: { value: string; label: string; imageUrl: string | null; designation: string }[] = res.data?.map((i: any) => ({
                    value: i.userId,
                    label: i.firstName,
                    imageUrl: i.imageUrl,
                    designation: i.designation,
                })) || [];

                const currentL1 = user.l1Approver
                    ? { value: user.l1Approver.userId, label: user.l1Approver.userName, imageUrl: user.l1Approver.imageUrl, designation: user.l1Approver.designation }
                    : { value: "", label: "Select L1 Approver", imageUrl: null, designation: "" };

                const currentL2 = user.l2Approver
                    ? { value: user.l2Approver.userId, label: user.l2Approver.userName, imageUrl: user.l2Approver.imageUrl, designation: user.l2Approver.designation }
                    : { value: "", label: "Select L2 Approver", imageUrl: null, designation: "" };

                const updatedApprovers: { value: string; label: string; imageUrl: string | null; designation: string }[] = [...data];

                if (currentL1.value && !updatedApprovers.some((a) => a.value === currentL1.value)) {
                    updatedApprovers.push(currentL1);
                }
                if (currentL2.value && !updatedApprovers.some((a) => a.value === currentL2.value)) {
                    updatedApprovers.push(currentL2);
                }
                setL1Approvers(updatedApprovers);
            })
            .catch((err) => console.error(err));
    }, [user, searchTerm]);

    const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleL1ApproverChange = (selectedOption: any) => {
        setFormData((prev) => ({
            ...prev,
            l1Approver: selectedOption
                ? {
                    ...prev.l1Approver,
                    userId: selectedOption.value,
                }
                : { ...prev.l1Approver, userId: "" },
        }));
    };

    const handleL2ApproverChange = (selectedOption: any) => {
        setFormData((prev) => ({
            ...prev,
            l2Approver: selectedOption
                ? {
                    ...prev.l2Approver,
                    userId: selectedOption.value,
                }
                : { ...prev.l2Approver, userId: "" },
        }));
    };

    const handleSearchChange = (inputValue: string) => {
        setSearchTerm(inputValue);
    };

    const handleSubmit = async () => {
        try {
            const { departmentId, l1Approver, l2Approver } = formData;
            if (!departmentId) {
                console.error("Department ID is missing.");
                return;
            }
            const updatedDepartmentData = {
                ...formData,
                l1Approver: {
                    userId: l1Approver.userId,
                    userName: l1Approvers.find((approver) => approver.value === l1Approver.userId)?.label || "",
                    designation: "",
                    imageUrl: null,
                },
                l2Approver: {
                    userId: l2Approver.userId,
                    userName: l1Approvers.find((approver) => approver.value === l2Approver.userId)?.label || "",
                    designation: "",
                    imageUrl: null,
                },
            };
            toast.success("Approvers updated successfully");
            const updatedDepartment = await dispatch(editDepartment(updatedDepartmentData)).unwrap();
            setFormData(updatedDepartment);
            await dispatch(fetchDepartment());
            onClose();
        } catch (error) {
            toast.error("Please select L1 Approver and L2 Approver");
            console.error("Failed to update the department: ", error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                Edit Department
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: "absolute", right: 8, top: 8, backgroundColor: "#ECECEC" }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box component="form" noValidate autoComplete="off" sx={{ mt: 1 }}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Department Name"
                                name="departmentName"
                                value={formData.departmentName}
                                onChange={handleTextFieldChange}
                                variant="outlined"
                                size="small"
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body1" sx={{ fontWeight: '500', marginBottom: '8px', fontSize: '0.85rem' }}> L1 Approver </Typography>
                            <Select
                                options={l1Approvers
                                    .filter((approver) => approver.value !== formData.l2Approver?.userId)
                                    .map((approver) => ({
                                        value: approver.value,
                                        label: approver.label,
                                        imageUrl: approver.imageUrl,
                                        designation: approver.designation,
                                    }))}
                                value={
                                    formData.l1Approver && formData.l1Approver.userId
                                        ? l1Approvers.find((approver) => approver.value === formData.l1Approver.userId)
                                        : null
                                }
                                onChange={handleL1ApproverChange}
                                isClearable
                                placeholder="Select L1 Approver"
                                onInputChange={handleSearchChange}
                                filterOption={(option, inputValue) => {
                                    return option.label.toLowerCase().includes(inputValue.toLowerCase());
                                }}
                                formatOptionLabel={(data) => (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {data.imageUrl ? (
                                            <img
                                                src={data.imageUrl}
                                                alt={data.label}
                                                style={{ width: 40, height: 40, borderRadius: "50%" }}
                                            />
                                        ) : (
                                            <Avatar alt={data.label} src={data.imageUrl || undefined} />
                                        )}
                                        <div style={{ marginLeft: 8 }}>
                                            <div style={{ fontWeight: "bold" }}>{data.label}</div>
                                            <div style={{ fontSize: "0.75rem", color: "#555" }}>
                                                {data.designation}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    control: (base) => ({ ...base, minHeight: 50, padding: "5px" }),
                                    valueContainer: (base) => ({ ...base, padding: "0 8px" }),
                                }}
                                menuPosition="absolute"
                            />
                        </Grid>
                        <Grid item xs={6}>
                        <Typography variant="body1" sx={{ fontWeight: '500', marginBottom: '8px', fontSize: '0.85rem' }}> L2 Approver </Typography>
                            <Select
                                options={l1Approvers
                                    .filter((approver) => approver.value !== formData.l1Approver?.userId)
                                    .map((approver) => ({
                                        value: approver.value,
                                        label: approver.label,
                                        imageUrl: approver.imageUrl,
                                        designation: approver.designation,
                                    }))}
                                value={
                                    formData.l2Approver && formData.l2Approver.userId
                                        ? l1Approvers.find((approver) => approver.value === formData.l2Approver.userId)
                                        : null
                                }
                                onChange={handleL2ApproverChange}
                                isClearable
                                placeholder="Select L2 Approver"
                                onInputChange={handleSearchChange}
                                filterOption={(option, inputValue) => {
                                    return option.label.toLowerCase().includes(inputValue.toLowerCase());
                                }}
                                formatOptionLabel={(data) => (
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        {data.imageUrl ? (
                                            <img
                                                src={data.imageUrl}
                                                alt={data.label}
                                                style={{ width: 40, height: 40, borderRadius: "50%" }}
                                            />
                                        ) : (
                                            <Avatar alt={data.label} src={data.imageUrl || undefined} />
                                        )}
                                        <div style={{ marginLeft: 8 }}>
                                            <div style={{ fontWeight: "bold" }}>{data.label}</div>
                                            <div style={{ fontSize: "0.75rem", color: "#555" }}>
                                                {data.designation}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    control: (base) => ({ ...base, minHeight: 50, padding: "5px" }),
                                    valueContainer: (base) => ({ ...base, padding: "0 8px" }),
                                }}
                                menuPosition="absolute"
                            />
                        </Grid> 
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={!formData.l1Approver?.userId || !formData.l2Approver?.userId}
                    sx={{
                        backgroundColor: "#1677FF",
                        borderRadius: "8px",
                        textTransform: "none",
                    }}
                >
                    Update
                </Button>
            </DialogActions>
            <Box></Box>
        </Dialog>
    );
};


export default EditDepartment;
