import { createSlice, PayloadAction } from "@reduxjs/toolkit"

interface Status {
    label: string;
    value: string;
  }
interface filterState{
    interviewDetNoticePeriod:string[],
    interviewDetExpectedCtc:string,
    interviewDetSource:string[],
    interviewDetInterviewStage:string[],
    selectedStatuses: Status[],
    candidateInterviewStage:string[],
    candidateSource:string[],
    candidateExpectedCtc:string,
    candidateNoticePeriod:string,

}

const initialState:filterState={
    interviewDetNoticePeriod:[],
    interviewDetExpectedCtc:'',
    interviewDetInterviewStage:[],
    interviewDetSource:[],
    selectedStatuses: [], 
    candidateInterviewStage:[],
    candidateSource:[],
    candidateExpectedCtc:'',
    candidateNoticePeriod:''
}

const resumeInfoFilterSlice=createSlice({
    name:'resumeInfoFilter',
    initialState,
    reducers:{
        setInterviewDetNoticePeriod:(state,action:PayloadAction<string[]>)=>{
            state.interviewDetNoticePeriod=action.payload;
        },
        setInterviewDetExpectedCtc:(state,action:PayloadAction<string>)=>{
            state.interviewDetExpectedCtc=action.payload;
        },
        setInterviewSource:(state,action:PayloadAction<string[]>)=>{
            state.interviewDetSource=action.payload;
        },
        setInterviewStage:(state,action:PayloadAction<string[]>)=>{
            state.interviewDetInterviewStage=action.payload;
        },
        toggleStatus: (state, action: PayloadAction<Status>) => {
            const exists = state.selectedStatuses.find(status => status.value === action.payload.value);
            if (exists) {
              state.selectedStatuses = state.selectedStatuses.filter(status => status.value !== action.payload.value);
            } else {
              state.selectedStatuses.push(action.payload);
            }
          },
          setSelectedStatuses: (state, action: PayloadAction<Status[]>) => {
            state.selectedStatuses = action.payload;
          },
          clearSelectedStatuses: (state) => {
            state.selectedStatuses = [];
          },
          setCandidateInterviewStage:(state,action:PayloadAction<string[]>)=>{
            state.candidateInterviewStage=action.payload;
        },
        setCandidateSource:(state,action:PayloadAction<string[]>)=>{
            state.candidateSource=action.payload;
        },
        setCandidateExpectedCtc:(state,action:PayloadAction<string>)=>{
            state.candidateExpectedCtc=action.payload;
        },
        setCandidateNoticePeriod:(state,action:PayloadAction<string>)=>{
            state.candidateNoticePeriod=action.payload;
        },
    }
});

export const {setInterviewDetExpectedCtc,setInterviewDetNoticePeriod,setInterviewStage,setInterviewSource,
    toggleStatus, // Exported for use in components
    setSelectedStatuses,
    clearSelectedStatuses,setCandidateInterviewStage,setCandidateSource,setCandidateExpectedCtc,setCandidateNoticePeriod
}=resumeInfoFilterSlice.actions;

export default resumeInfoFilterSlice.reducer;