import React from 'react';
import { Box } from '@mui/material';
import BaseJobCard from '../BaseJobCard';
import JobProgress from '../../commoncard/jobProgress';
import JobFooter from '../../commoncard/JobFooter';
import { Job } from '../../commoncard/types';

interface MyRequestsCardProps {
  job?: Job;
}

const MyRequestsCard: React.FC<MyRequestsCardProps> = ({ job }) => {
  
  return (
    <BaseJobCard job={job} label="My Requests" queryParam= "my-requests">
      <Box sx={{ width: "100%" }}>
        {job?.tabStatus === "open" ? (
          <JobFooter status={job?.status} />
        ) : (
          <>
            <JobProgress L1Status={job?.l1Status} L2Status={job?.l2Status ===null?"Pending":job?.l2Status} />
            <JobFooter status={job?.status} />
          </>
        )}
      </Box>
    </BaseJobCard>
  );
};

export default MyRequestsCard;