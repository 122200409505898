import React from 'react';
import { Box } from '@mui/material';
import JobCardHeader from '../commoncard/JobCardHeader';
import JobMode from '../commoncard/JobMode';
import JobCardTime from '../commoncard/jobCardTime';
import { useNavigate, useLocation } from 'react-router-dom';
import { BaseJobCardProps } from '../commoncard/types';

const BaseJobCard: React.FC<BaseJobCardProps> = ({ job, label, queryParam, children  }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleBoxClick = () => {
    if (location.pathname.includes('newRequestToHire')) {
      navigate(`/newRequestToHire/${job?.positionTitle}/${job?.rthId}`);
    } else {
      console.log("Navigating with label:", label); 
      navigate(`/request-hire`, { state: { id: job?.rthId, label, queryParam} });
    }
  };

  const getCardStyle = () => {
    const checkRth = label === "overall MRF";
    const checkRequests = label === "My Requests";
    const checkRthL1Status = job?.l1Status === "Pending";
    const checkRthL2Status = job?.l1Status === "Approved" && job?.l2Status === "Pending";
    const checkReruestsStatus = job?.l1Status === "Approved" && job?.l2Status === "Approved";
    const checkDecline = job?.l1Status === "Declined" || job?.l2Status === "Declined";

    return {
      backgroundColor: label === "Closed"
        ? "#F6F6F6"
        : (checkRth && checkRthL2Status) || (checkRequests && checkRthL2Status)
          ? "#F2ECFA" : (checkRequests && checkDecline) || (checkRth && checkDecline)? "#F9D9CF99"
          : (checkRth && checkRthL2Status) || (checkRequests && checkReruestsStatus) || (checkRth && checkReruestsStatus) 
            ? "#EAF5FE" : (checkRequests && checkRth) || checkRthL1Status ? "#FAF8EC"
              : "white",
      borderRadius: "10px",
      border: (checkRth && checkRthL2Status) ||(checkRequests && checkRthL2Status)
        ? "2px solid #5708B8" : (checkRequests && checkDecline) || (checkRth && checkDecline) ? "2px solid #F04E4E"
        : (checkRth && checkRthL2Status) || (checkRequests && checkReruestsStatus) || (checkRth && checkReruestsStatus)
          ? "2px solid #36CFC9" : (checkRequests && checkRth) || checkRthL1Status ? "2px solid #FFE546"
            : "1px solid lightgray",
      boxShadow: ((checkRth && checkRthL1Status) || (checkRequests && checkRthL2Status)) || ((checkRth && checkRthL2Status) || (checkRequests && checkReruestsStatus) || (checkRth && checkReruestsStatus)) || 
      ((checkRequests && checkDecline) || (checkRth && checkDecline))
        ? "0px 4px 10px rgba(0, 0, 0, 0.1)"
        : "none",
      width: "100%",
      padding: "7px",
      paddingInline: "0.5rem",
      cursor: 'pointer'
    };
  };

  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: 1, position: 'relative' }} onClick={handleBoxClick}>
      <Box sx={getCardStyle()}>
        <JobCardHeader department={job?.positionTitle}  business={job?.business} />
        <JobCardTime posted={job?.posted} priority={job?.priority} seqId={job?.seqId}  createdAt={job?.createdAt}/>
        <JobMode typeOfRth={job?.typeOfRth} modeOfRth={job?.modeOfRth} rthProcess={job?.rthProcess} status={job?.status} modesOfRth={job?.modesofRth} />
        {children}
      </Box>
    </Box>
  );
};

export default BaseJobCard;