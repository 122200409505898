import { useState } from "react";
import { Box, Typography, Divider, Collapse, FormControl, Checkbox } from "@mui/material";
import TuneIcon from "@mui/icons-material/Tune";
import NavigateNext from "@mui/icons-material/NavigateNext";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import { styled } from "@mui/system";

const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fontSize: 12,
  },
}));

const CustomSmallFormControlLabel = (props: {
  label: string;
  number?: string;
  isChecked?: boolean;
  onChange?: (e: any) => void;
  color?: string;
}) => (
  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", fontWeight: "400" }}>
    <SmallCheckbox checked={props.isChecked} sx={{ borderColor: "#D9D9D9" }} onChange={props.onChange} />
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
      <Box sx={{ flexBasis: "100%" }}>
        <Typography sx={{ fontSize: "0.75rem", color: props.color, whiteSpace: 'nowrap'}}> {props.label}</Typography>
      </Box>
      <Box sx={{ flexBasis: "30%" }}>
        <Typography sx={{ fontSize: "12px", textAlign: "right", color: "#8C8C8C" }}> {props.number}</Typography>
      </Box>
    </Box>
  </Box>
);

const Filter = ({ onFilterChange }: { onFilterChange: (filters: string[]) => void }) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const toggleDrawer = () => { setDrawerOpen((prev) => !prev) };

  const handleCheckboxChange = (filter: string) => {
    setSelectedFilters((prev) => {
      const newFilters = prev.includes(filter)
        ? prev.filter((item) => item !== filter)
        : [...prev, filter];
      onFilterChange(newFilters);
      return newFilters;
    });
  };

  const filters = [
    { label: "L1 Pending", value: "L1 Pending", color: "#FFA940" },
    { label: "L1 Rejected", value: "L1 Rejected", color: "#F04E4E" },
    { label: "L2 Pending", value: "L2 Pending", color: "#FFA940" },
    { label: "L2 Rejected", value: "L2 Rejected", color: "#F04E4E" },
    { label: "Both Levels Approved", value: "Approved", color: "#389E0D" },
  ];

  return (
    <Box
      bgcolor={"white"}
      sx={{
        margin: "20px",
        marginTop: "0px",
        borderRadius: "10px",
        marginRight: "0px",
        zIndex: 1000,
        top: "-10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          bgcolor: "white",
          padding: "10px",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "2px",
          borderRadius: "10px",
        }}
      >
        <TuneIcon sx={{ fontSize: 20 }} />
        {drawerOpen && <Typography variant="h6" sx={{ fontSize: 14 }}>Filter</Typography>}
        <Box onClick={toggleDrawer}>
          {drawerOpen ? <NavigateBefore sx={{ fontSize: 14, cursor: 'pointer' }} /> : <NavigateNext sx={{ fontSize: 14, cursor: 'pointer' }} />}
        </Box>
      </Box>
      {drawerOpen && <Divider />}
      <Collapse in={drawerOpen} timeout="auto" unmountOnExit>
        <Box
          padding={2}
          bgcolor={"white"}
          sx={{
            borderBottomRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            overflowX: "auto",
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
              }}
            >
              Approval Level
            </Typography>
              <FormControl component="fieldset">
                {filters.map((filter) => (
                  <CustomSmallFormControlLabel
                    key={filter.value}
                    label={filter.label}
                    isChecked={selectedFilters.includes(filter.value)}
                    onChange={() => handleCheckboxChange(filter.value)}
                    color={filter.color}
                  />
                ))}
              </FormControl>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Filter;
