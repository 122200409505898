import {
  Box,
  Button,
  Checkbox,
  Chip,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { getRthLocationData } from "../../../Crud/rth";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import {
  setLocationId,
  setLocationName,
} from "../../../redux/features/locationSlice";
import { setFilterState } from "../../../redux/features/teamHeadFilterSlice";

const LocationDropDown: React.FC = () => {
  const dispatch = useDispatch();

  const [location, setLocation] = useState<any[]>([]);

  console.log("location loc",location);
  const locationName = useSelector(
    (state: RootState) => state.location.locationName
  );
  const locationId = useSelector(
    (state: RootState) => state.location.locationId
  );

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  console.log(searchLoading);
  
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (name: string, id: string) => {
    const updatedLocationNames = locationName.includes(name)
      ? locationName.filter((title) => title !== name)
      : [...locationName, name];
    dispatch(setLocationName(updatedLocationNames));

    const updatedLocationIds = locationId.includes(id)
      ? locationId.filter((title) => title !== id)
      : [...locationId, id];
    dispatch(setLocationId(updatedLocationIds));
  };

  useEffect(() => {
    dispatch(
      setFilterState({
        location: locationName.length ? locationName : [],
      })
    );
  }, [locationName,dispatch]);

  const isOpen = Boolean(anchorEl);

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }
    const timeoutId = setTimeout(() => {
      getRthLocationData()
        .then((res) => {
          console.log(res.data)
          const dataWithIndex = res.data.map((item: string,index:any) => ({
            value: index,
            label: item,
          }));
          setLocation(dataWithIndex);
        })
        .catch((err) => console.error(err));
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchQuery]);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          borderColor: "#B0B0B0",
          color: "#000",
          "&:hover": {
            borderColor: "#A0A0A0",
          },
        }}
      >
        {locationName.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {locationName.slice(0, 1).map((name) => (
              <Chip
                key={name}
                label={name}
                onDelete={() =>
                  handleToggle(
                    name,
                    location.find((lead) => lead.label === name)?.value || ""
                  )
                }
                sx={{
                  fontSize: "10px",
                  height: "20px",
                }}
              />
            ))}
            {locationName.length > 1 && (
              <Chip
                label={`+${locationName.length - 1}`}
                sx={{
                  fontSize: "10px",
                  height: "20px",
                  backgroundColor: "#f0f0f0",
                  color: "#8C8C8C",
                }}
              />
            )}
          </Box>
        ) : (
          <Typography sx={{ color: "grey" }}>Choose</Typography>
        )}
        <ArrowDropDownIcon sx={{ fontSize: 18, marginLeft: 1 }} />
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: anchorEl ? anchorEl.offsetWidth : "auto",
            maxHeight: "300px",
            marginTop: "2px",
            overflowY: "auto",
          },
        }}
      >
        <Box sx={{ padding: 1 }}>
          <TextField
            size="small"
            placeholder="Search..."
            fullWidth
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              marginBottom: 2,
              "& .MuiInputBase-input": { fontSize: "12px" },
            }}
          />
          {location
            ?.filter((lead) =>
              lead?.label?.toLowerCase().includes(searchQuery?.toLowerCase())
            )
            .map((lead) => (
              <MenuItem
                key={lead.value}
                onClick={() => handleToggle(lead.label, lead.value)}
                sx={{
                  fontSize: "10px",
                  padding: "4px 6px",
                  gap: "10px",
                }}
              >
                <Checkbox
                  checked={locationName.includes(lead.label)}
                  size="small"
                  sx={{
                    padding: "2px",
                    "& .MuiSvgIcon-root": { fontSize: "14px" },
                  }}
                />
                <ListItemText
                  primary={lead.label}
                  sx={{
                    margin: 0,
                    fontSize: "10px",
                  }}
                />
              </MenuItem>
            ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default LocationDropDown;
