import React, { useEffect, useState } from 'react';
import {
  Typography,
  Grid,
  Box,
  SelectChangeEvent,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
} from '@mui/material';
import CommonTextField from '../../../common/CommonTextField';
import CommonSelect from '../../../common/CommomSelect';
import {  YearsInExp, YesNo } from '../../../../constants/dummyConst';
import CommonMultiSelect from '../../../common/CommonMultiSelect';
import { useLocation } from 'react-router-dom';
import DeleteIcon from '../../../../assets/images/del_Icon.svg';
import {useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { getSkill } from '../../../../redux/features/resume';
 
 
const Experience = ({ formValues, setFormValues,errors,setErrors }: any) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [newExperienceLabel, setNewExperienceLabel] = useState('');
  const location=useLocation();
  const dispatch=useAppDispatch();
  const path=location.pathname.includes("candidateForm");
  const State=useAppSelector((state)=>state.resume);
  const {skills}=State;
  const[skillOptions,setSkillOptions]=useState<any[]>([]);
  const[searchState,setSearchState]=useState(false);
 
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    if (name === 'totYearsOfExp') {
      const regex = /^[a-zA-Z0-9.-]*$/; // Allows alphabets, digits, dots, and hyphens
      if (!regex.test(value)) {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          totYearsOfExp: "Special characters are not allowed (except '.' and '-')",
        }));
        return; // Prevent updating the state with invalid characters
      } else {
        setErrors((prevErrors: any) => ({
          ...prevErrors,
          totYearsOfExp: "",
        }));
      }
    }
  
    if (name === "skills") {
      const selectedSkills = Array.isArray(value) ? value : [value]; // Fallback to array if value is a single string
      if (selectedSkills.length > 5) {
        setErrors((prevErrors:any) => ({
          ...prevErrors,
          skills: "You can select up to 5 skills only",
        }));
        return; // Prevent updating the state if the limit is exceeded
      } else {
        setErrors((prevErrors:any) => ({
          ...prevErrors,
          skills: "",
        }));
      }
    }

    if(name==='workExperience'){
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        workExperience:''
      }));
    }

    if(name==='totYearsOfExp'){
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        totYearsOfExp:''
      }));
    }

   setFormValues({
    ...formValues,
    [name]: value,
  });
  };

  const handleSkillChange=(event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<string>) => {
 
     const {name,value}=event.target;
     if(name==='skills'){
      const selectedSkills = [`${value}`]; 
        setFormValues({
          ...formValues,
          [name]: selectedSkills,
        }); 
     }
  }
 
 
  const handleDeleteExperience = (index: number) => {
    const updatedExperience = formValues.experience.filter((_: any, i: number) => i !== index);
    setFormValues({
      ...formValues,
      experience: updatedExperience,
    });
  };
 
  const handleInputChangeNew = (event: React.ChangeEvent<HTMLInputElement>, index?: number) => {
    const { name, value } = event.target;
   
    if(name==='from'){
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        from:'',
      }))
    }

    if(name==='to'){
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        to:'',
      }))
    }

    if(name==='jobTitle'){
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        jobTitle:'',
      }))
    }

    if(name==='company'){
      setErrors((prevErrors:any)=>({
        ...prevErrors,
        company:'',
      }))
    }

    if (typeof index === 'number') {
      setErrors((prevErrors: any) => {
        const updatedErrors = [...(prevErrors.experience || [])];
        updatedErrors[index] = updatedErrors[index] || {};
  
        // Validation: Check for max length (64 characters)
        if (value.length > 64) {
          updatedErrors[index][name] = `Maximum of 64 characters allowed for ${name}`;
        } else {
          updatedErrors[index][name] = ''; // Clear the error if input is valid           
        }
       
      // Additional validation for 'from' and 'to' fields
      const experience = formValues.experience[index];
      const currentDate = new Date();
      const startOfDay = new Date(currentDate.setHours(0, 0, 0, 0)); // Create a new date object for the start of the day

      // Check if 'from' or 'to' is in the future
      if (name === 'from') {
        if (new Date(value) > currentDate) {
          updatedErrors[index]['from'] = `'From' date cannot be  future or today date`;
        } else if (new Date(value) >= startOfDay) { // Compare with the start of the day
          updatedErrors[index]['from'] = `'From' date cannot be today or a future date`;
        } else if (experience.to && new Date(value) > new Date(experience.to)) {
          updatedErrors[index]['from'] = `'From' date cannot be later than 'To' date`;
        } else if (experience.to && new Date(value).toDateString() === new Date(experience.to).toDateString()) {
          updatedErrors[index]['from'] = `'From' date cannot be the same as 'To' date`;
        } else {
          updatedErrors[index]['from'] = '';
          setErrors((prevErrors:any)=>({
            ...prevErrors,
            from:'',
          })) 
        }
      }

      if (name === 'to') {
        if (new Date(value) > currentDate) {
          updatedErrors[index]['to'] = `'To' date cannot be in the future`;
        } else if (experience.from && new Date(value) < new Date(experience.from)) {
          updatedErrors[index]['to'] = `'To' date cannot be earlier than 'From' date`;
        } else if (experience.from && new Date(value).toDateString() === new Date(experience.from).toDateString()) {
          updatedErrors[index]['to'] = `'To' date cannot be the same as 'From' date`;
        } else {
          updatedErrors[index]['to'] = ''; // Clear the error if valid
          setErrors((prevErrors:any)=>({
            ...prevErrors,
            to:'',
          }))
        }
      }
      return { ...prevErrors, experience: updatedErrors };
    });
  
      const updatedEducation = [...formValues.experience];
      updatedEducation[index][name] = value;
      setFormValues((prevFormData:any) => ({
        ...prevFormData,
        experience: updatedEducation,
      }));

    } else {

      setErrors((prevErrors: any) => ({
        ...prevErrors,
        [name]: value.length > 64 ? `Maximum of 64 characters allowed for ${name}` : '',
      }));

      setFormValues((prevFormData:any) => ({
        ...prevFormData,
        [name]: value,
      }));

    }
  };
 
  const handleAddExperience = () => {
    const currentCount = formValues.experience.length;
    if (currentCount < 20) {
      setFormValues((prevValues: any) => ({
        ...prevValues,
        experience: [
          ...prevValues.experience,
          {
            expLabel: `Experience ${currentCount + 1}`,
            jobTitle: '',
            company: '',
            from: '',
            to: '',
          },
        ],
      }));
    }
    setErrors((prevErrors: any) => {
      const updatedErrors = [...(prevErrors.experience || []), {}];
      return { ...prevErrors, experience: updatedErrors };
    });
  
  };

 
  const handleDialogClose = () => {
    // Close the dialog
    setOpenDialog(false);
  };
 
  const handleDialogSubmit = () => {
    // Append the new experience to the formValues
    setFormValues((prevValues: any) => ({
      ...prevValues,
      experience: [
        ...(prevValues.experience|| []),
        {
          expLabel: newExperienceLabel,
          jobTitle: '',
          company: '',
          from: '',
          to: '',
        },
      ],
    }));
    // Reset the label and close the dialog
    setNewExperienceLabel('');
    setOpenDialog(false);
  };
 
  const toRoman = (num: number) => {
    const romanNumerals: [number, string][] = [
      [1000, 'M'], [900, 'CM'], [500, 'D'], [400, 'CD'],
      [100, 'C'], [90, 'XC'], [50, 'L'], [40, 'XL'],
      [10, 'X'], [9, 'IX'], [5, 'V'], [4, 'IV'],
      [1, 'I'],
    ];
 
    let result = '';
    for (let [value, symbol] of romanNumerals) {
      while (num >= value) {
        result += symbol;
        num -= value;
      }
    }
    return result;
  };

  const transformCityData = (data: any[]) => {
    return data?.map(data => ({
      label: data?.skillName,      // Use cityName as the label
      value: data?.skillName, // Use cityId as value, fallback to cityName if cityId is null
    }));
  };
  
  const fetchMoreSkillOptions = async (searchTerm: string, page: number) => {
    if(searchTerm.length>0){
      setSearchState(true);
    }
    try {
      const result = await dispatch(getSkill({ search: searchTerm, page, size: 10 })).unwrap();
      const newOptions = transformCityData(result?.skills || []);
  
      // Append new options to existing skillOptions
      setSkillOptions((prevOptions) => {
        console.log(prevOptions,'ibghjgh');
        
        const mergedOptions = [...prevOptions, ...newOptions];
        
        // Remove duplicates by value (optional, if skill names might repeat)
        const uniqueOptions = Array.from(
          new Map(mergedOptions.map((item) => [item.value, item])).values()
        );
  
        return uniqueOptions;
      });
    } catch (error) {
      console.error("Error fetching more skill options:", error);
    }
  };
  
   
  useEffect(()=>{
    if (!formValues.experience || formValues?.experience?.length === 0) {
      setFormValues((prevFormData:any) => ({
        ...prevFormData,
        experience: [{
          jobTitle: '',
          company: '',
          from: '',
          to: '',
          expLabel: 'Latest Experience', // Label for the default entry
        }]
      }));

    }
  },[formValues,setFormValues])

  useEffect(()=>{
   dispatch(getSkill({search :'', page :0, size :10}));
   
  },[dispatch])

  useEffect(()=>{
    const skillData=transformCityData(skills); 
    if(!searchState)
    setSkillOptions((prevOptions) => [...prevOptions, ...skillData]);
    else if(searchState){
      setSkillOptions(skillData);
    }
  },[skills,searchState])

  
  return (
    <Box sx={{ pt: '4px', ml:path?'30px':'' }}>
      {/* <Paper elevation={0} sx={{ overflowY: 'auto', pt: '10px', position: 'relative', height: '62vh' }}> */}
        <Typography sx={{ fontSize: '20px', fontWeight: '500', mb: '27px' }}>
        {path ? '5.Experience' : 'Experience'}
          </Typography>
        <Box sx={{ width: '50vw' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px' }}>Skills(You can choose upto 5 skills)</Typography>
              {!path &&
              <CommonMultiSelect
                labelId=""
                label=""
                name="skills"
                value={formValues.skills || []}
                onChange={handleInputChange}
                options={skillOptions}
                error={!!errors.skills} 
                pageType='skillsSearch'
                helperText={errors.skills || ""}    
                fetchMoreOptions={(searchTerm:any, page:any) => fetchMoreSkillOptions(searchTerm, page)}      
              />
              }
              {path &&
                  <CommonTextField
                  label=''
                  name='skills'
                  value={formValues.skills || []}
                  onChange={handleSkillChange}
                  error={!!errors.skills} 
                  helperText={errors.skills || ""}  
                  />                  
              }
            </Grid>
          </Grid>
          <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{mt:'10px'}}>
             <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'10px', textWrap:'nowrap'}}>Do you have work Experience?
             {path && <span style={{ color: 'red' }}> *</span>}
             </Typography>
                 <CommonSelect
                  labelId="workExperience"
                  label=""
                  name="workExperience"
                  value={formValues.workExperience||''}
                  onChange={handleInputChange}
                  error={!!errors?.workExperience}
                  helperText={errors?.workExperience}
                  options={YesNo}
                />
             
             {/* {path&&
             <CommonTextField
             label=""
             name='workExperience'
             value={formValues.workExperience || ''}
             onChange={handleInputChange}
             error={!!errors?.workExperience} // Check error for specific index
             helperText={errors?.workExperience} // Display error for specific index         
           />
             } */}

             </Grid>
             <Grid item xs={12} md={4} sx={{mt:'10px'}}>
             <Typography sx={{fontSize:'14px',color:'#bababa' , mb:'10px'}}>Total Years in Experience
             {path && <span style={{ color: 'red' }}> *</span>}
             </Typography>
             {!path &&
             <CommonSelect
                  labelId="totYearsOfExp"
                  label=""
                  name="totYearsOfExp"
                  value={formValues.totYearsOfExp||''}
                  onChange={handleInputChange}
                  error={!!errors?.totYearsOfExp}
                  helperText={errors?.totYearsOfExp}
                  options={YearsInExp}
                />
             }
             {path &&
                
                <CommonTextField
                label=''
                name='totYearsOfExp'
                value={formValues.totYearsOfExp||''}
                onChange={handleInputChange}
                error={!!errors?.totYearsOfExp}
                helperText={errors.totYearsOfExp}
                />
             }
             </Grid>
             </Grid>
          {formValues?.workExperience==='Yes'&&formValues?.experience && formValues?.experience?.map((experience: any, index: number) => (
            <div key={index}>
                          <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography sx={{ mt: '16px', fontSize: '16px', fontWeight: 'bold' }}>
                 {index === 0
            ? `${toRoman(index + 1)}) Latest Experience`
        : `${toRoman(index + 1)}) ${experience.expLabel || `Experience ${index + 1}`}`}
 
              </Typography>
              {index > 0 && ( // Render the delete icon only for indexes greater than 0
              <IconButton
                aria-label="delete"
                onClick={() => handleDeleteExperience(index)}
                size="small"
                sx={{
                  ml: 1, // Add some margin for spacing
                  color: 'inherit', // Default color
                  '&:hover': {
                    color: 'red', // Change color to red on hover
                  },
                }}            
              >
                <img src={DeleteIcon} alt='Delete Icon' />
              </IconButton>
              )}
            </Box>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px', mt: '22px' }}>Job Title
                {path && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                  <CommonTextField
                    label=""
                    name={`jobTitle`}
                    value={experience.jobTitle || ''}
                    onChange={(event) => handleInputChangeNew(event, index)}
                    error={!!errors?.jobTitle||!!errors?.experience?.[index]?.jobTitle} // Check error for specific index
                    helperText={errors?.jobTitle||errors?.experience?.[index]?.jobTitle} // Display error for specific index
                  
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px', mt: '22px' }}>Company
                {path && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                  <CommonTextField
                    label=""
                    name={`company`}
                    value={experience.company || ''}
                    onChange={(event) => handleInputChangeNew(event, index)}
                    error={!!errors?.company||!!errors?.experience?.[index]?.company} // Check error for specific index
                    helperText={errors?.company||errors?.experience?.[index]?.company} // Display error for specific index
                  
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px', mt: '22px' }}>From
                {path && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                  <CommonTextField
                    label=""
                    type={'date'}
                    name={`from`}
                    value={experience.from || ''}
                    onChange={(event) => handleInputChangeNew(event, index)}
                    error={!!errors?.from||!!errors?.experience?.[index]?.from} // Check error for 'from' field
                    helperText={errors?.from||errors?.experience?.[index]?.from} // Display error for 'from' field
        
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                <Typography sx={{ fontSize: '14px', color: '#bababa', mb: '10px', mt: '22px' }}>To
                {path && <span style={{ color: 'red' }}> *</span>}
                </Typography>
                  <CommonTextField
                    label=""
                    name={`to`}
                    type={'date'}
                    value={experience.to || ''}
                    onChange={(event) => handleInputChangeNew(event, index)}
                    error={!!errors?.to||!!errors?.experience?.[index]?.to} // Check error for 'from' field
                    helperText={errors?.to||errors?.experience?.[index]?.to} // Display error for 'from' field
        
                  />
                </Grid>
              </Grid>
            </div>
          ))}
          {formValues.workExperience?.toLowerCase()==='yes'&&
          <Box sx={{ mt: '8px' }}>
            <Button onClick={handleAddExperience}>+ Add Experience</Button>
          </Box>
           }
        </Box>
 
        {/* Dialog for Adding Experience */}
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Add Experience</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter the label for the new experience.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Experience Label"
              type="text"
              fullWidth
              variant="outlined"
              value={newExperienceLabel}
              onChange={(e) => setNewExperienceLabel(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Cancel</Button>
            <Button onClick={handleDialogSubmit}>OK</Button>
          </DialogActions>
        </Dialog>
      {/* </Paper> */}
    </Box>
  );
};
 
export default Experience;
 
 
