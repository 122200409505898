// src/redux/slices/locationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LocationState {
  locationName: string[];
  locationId: string[];
}

const initialState: LocationState = {
  locationName: [],
  locationId: [],
};

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocationName(state, action: PayloadAction<string[]>) {
      state.locationName = action.payload;
    },
    setLocationId(state, action: PayloadAction<string[]>) {
      state.locationId = action.payload;
    },
    resetLocationState(state) {
      state.locationName = [];
      state.locationId = [];
    },
  },
});

export const { setLocationName, setLocationId, resetLocationState } =
  locationSlice.actions;

export default locationSlice.reducer;
