
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "../../constants/Crudurl";

export const getAllInterviewsModuleData=createAsyncThunk('interview/Calander',
    async(data:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.post(`${BaseUrl}/interviewRepo/getAllInterviews`,data,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        } catch(error:any){
            return error.response.data;
        }
    }
)

export const getAllInterviewsStatusData=createAsyncThunk('interview/Status',
    async({data, searchQuery,page}:any)=>{        
        try{ 
            const token=localStorage.getItem('token');
            const response=await axios.post(`${BaseUrl}/interviewRepo/getInterviewStatus?page=${page}&size=${20}&search=${searchQuery} `,data,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        } catch(error:any){
            return error.response.data;
        }
    }
)

export const getInterviewMrfDetails=createAsyncThunk('interview/getInterviewMrfDetails',
    async(mrfId:any)=>{
        const response=await axios.get(`${BaseUrl}/interviewRepo/getInterviewMrfDetails/${mrfId}`);
        return response.data;
    }
)