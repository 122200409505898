import { Autocomplete, CircularProgress, FormControl, FormHelperText, MenuItem, Select, TextField } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCallback, useEffect, useState } from 'react';
interface Option {
  label: string;
  value: string;
}

interface OptionSearch {
  id: string | number;
  label: string;
}

export const TextFieldUI = ({value, handleInputChange, placeholder, name, type, error, helperText }:any) => {
  const isTimeField = type === "time"; // Check if the type is time
  const timeInputProps = isTimeField
    ? {
        inputProps: {
          min: "08:00", // Minimum time
          max: "19:00", // Maximum time
          step: 300, // Optional: Step interval in seconds (e.g., 300 = 5 minutes)
        },
      }
    : {};
    const today = new Date().toISOString().split('T')[0]; // Get today's date in yyyy-MM-dd format
  const isInterviewDate = /interviewDet\[\d+\]\.interviewDate/.test(name);

  return (
    <TextField
    id="outlined-basic"
    placeholder={placeholder}
    variant="outlined"
    name={name}
    value={value}
    onChange={handleInputChange}
    type = {type}
    error={error}
    helperText={helperText}
    {...timeInputProps}
        sx={{        
      '& .MuiOutlinedInput-root': {
        height: '55px',
        backgroundColor: 'white',
        color: '#9A9A9A',
        width: "150px",
        borderRadius:'8px',
        '& fieldset': {
          borderColor: '#D9D9D9',
        },
        '&:hover fieldset': {
          borderColor: '#D9D9D9',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#D9D9D9', // Ensure consistent border color on focus
        },
      },
      '& .MuiInputLabel-root': {
        color: '#9A9A9A',
      },
      '& .MuiInputLabel-root.Mui-focused': {
        color: '#9A9A9A',
      },
    }}
    InputProps={{
      inputProps: {
        min: name === 'prefStartDate'||isInterviewDate||'interviewDate' ? today : undefined,
        max: name === 'dateOfBirth' ? today : undefined,
      },
      sx: {
        '& input': {
          color: value ? 'inherit' : 'gray', 
          fontWeight: 'normal', 
        },
      },
    }}
    />  
  )
}
 
export const SelectUI = ({value, handleInputChange, name, option, label, error=false,helperText='',width}:any) => {
  return (
    <FormControl  error={error}>
      <Select                  
      defaultValue=""
      IconComponent={KeyboardArrowDownIcon} // Use the custom icon for dropdown
      value={value}
      onChange={handleInputChange}
      name={name}
      sx={{
        width: width? width : "150px",
        bgcolor:'white',
        borderRadius:'8px',
        height: '55px',
        color: '#000', // Black color for the selected text
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #9A9A9A', // Custom border for normal state
          borderRadius: '8px', // Rounded corners if needed
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #9A9A9A', // Keep border color consistent on hover
        },
        '& .MuiSelect-select': {
          padding: '10px', // Adjust padding inside the select box
          color: '#000', // Black color for the selected text
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #9A9A9A', // Keep border color consistent when focused
        },
      }}
      displayEmpty
      renderValue={(selected) => {
        if (!selected) {
          return <span style={{ color: '#9A9A9A' }}>{label || 'Select an option'}</span>; // Placeholder text styling
        }
        const selectedOption = option.find((opt: any) => opt.value === selected);
        return selectedOption ? selectedOption.label : selected;            
      }}
    >
        {option.map((option:any)=> (
    <MenuItem key={option.value} value={option.value}>
      {option.label}
    </MenuItem>            
    ))}
    
      </Select>
    {helperText && (
    <FormHelperText>{helperText}</FormHelperText>
    )}
    </FormControl>
  )
}
 
export const InterviewLinkUI = ({ value, handleInputChange, name }:any) => {
    return(
        <TextField
        value={value}
        onChange={handleInputChange}
        name={name}
        id="outlined-disabled"
        placeholder="Interview Link"
        sx={{
            '& .MuiOutlinedInput-root': {
            height: '55px',
            backgroundColor: 'white',
            color: '#9A9A9A',
            width: "150px", 
            borderRadius: '8px',
            '& fieldset': {
                borderColor: '#D9D9D9',
            },
            '&:hover fieldset': {
                borderColor: '#D9D9D9',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#D9D9D9',
            },
            },
            '& .MuiInputLabel-root': {
            color: '#9A9A9A',
            },
            '& .MuiInputLabel-root.Mui-focused': {
            color: '#9A9A9A',
            },
        }}
        // InputProps={{
        //     endAdornment: (
        //     <InputAdornment position="end">
        //         <img src={interviewLinkCopyIcon} alt="interviewLinkCopyIcon" />
        //     </InputAdornment>
        //     ),
        // }}
        />
    )
}
 
export const CommonAutocomplete= ({
  value,
  handleChange,
  name,
  fetchOptions,
  label,
  initialOptions = [],
  error=false,
  helperText=''
}:any) => {

  const formattedInitialOptions = initialOptions.map((option: any) => ({
    label: `${option.firstName} ${option.lastName}`,
    value: option.userId,
  }));

  // const[inputValue,setInputValue]=useState<any[]>([]);
  const [options, setOptions] = useState(formattedInitialOptions);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);


  const handleInputChange = async (query: string) => {
    if (!query) return;
    try {
      const fetchedOptions = await fetchOptions(query);
      setOptions(fetchedOptions);
    } catch (error) {
      console.error('Error fetching options:', error);
    }
  };

  
  useEffect(() => {
    const matchedOption = options.find((opt:any) => opt.value === value);
    if (matchedOption) {
      setSelectedOption(matchedOption);
      // setInputValue(matchedOption.label); 
    } else {
      // setInputValue(value); 
    }
  }, [value, options]); // This hook runs when either 'value' or 'options' changes

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => option.label || ''}
      value={selectedOption || null} // Set value to selectedOption
      onInputChange={(_event, newInputValue, reason) => {
        if (reason === 'input') {
          handleInputChange(newInputValue); // Trigger API only when typing
        }
      }}
      onChange={(_event, newValue) => {
        handleChange({
          target: {
            name,
            value: newValue?.value || '',
          },
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={label}
          variant="outlined"
          error={error}
          helperText={helperText}
          sx={{width: "150px"}}
          InputProps={{
            ...params.InputProps,
             }}
        />
      )}
      sx={{        
        '& .MuiOutlinedInput-root': {
          height: '55px',
          backgroundColor: 'white',
          color: '#9A9A9A',
          width: "150px",
          borderRadius:'8px',
          '& fieldset': {
            borderColor: '#D9D9D9',
          },
          '&:hover fieldset': {
            borderColor: '#D9D9D9',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#D9D9D9', // Ensure consistent border color on focus
          },
        },
        '& .MuiInputLabel-root': {
          color: '#9A9A9A',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: '#9A9A9A',
        },
      }}
      isOptionEqualToValue={(option, value) => option.value === value.value}
    />
  );
};

export const SearchableDropdown = ({
  searchAPICall,
  label,
  onOptionSelect,
  value,
  helperText,
  error,
  name,
  l2IdApproval,
}: any) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [options, setOptions] = useState<OptionSearch[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const fetchOptions = useCallback(
    async (query = "", currentPage = 0) => {
      if (!hasMore && currentPage > 0) return;

      setIsFetching(true);
      try {
        const response = await searchAPICall(query, currentPage, 10);
        const fetchedOptions = response.data
          ?.map((item: any) => ({
            id: item.userId,
            label: `${item.firstName} ${item.lastName} | ${item.designation}`,
            firstName: item.firstName,
            designation: item.designation,
          }))
          ?.filter((i: any) => i.id !== l2IdApproval) || [];

        setOptions((prev) => {
          const uniqueOptions = [
            ...(prev || []),
            ...fetchedOptions.filter(
              (newOption: OptionSearch) =>
                !prev.some((existingOption) => existingOption.id === newOption.id)
            ),
          ];
          return currentPage === 0 ? fetchedOptions : uniqueOptions;
        });

        setHasMore(fetchedOptions.length === 10);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    },
    [hasMore, searchAPICall, l2IdApproval]
  );

  const handleScroll = useCallback(
    (event: React.SyntheticEvent) => {
      const listboxNode = event.currentTarget;
      if (
        listboxNode &&
        listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight &&
        hasMore &&
        !loading &&
        !isFetching
      ) {
        setPage((prevPage) => {
          const nextPage = prevPage + 1;
          fetchOptions(searchTerm, nextPage);
          return nextPage;
        });
      }
    },
    [hasMore, loading, isFetching, searchTerm, fetchOptions]
  );

  useEffect(() => {
    if (!value && searchTerm.length>0){
      const delayDebounce = setTimeout(() => {
        setPage(0); // Reset page when search term changes
        fetchOptions(searchTerm, 0);
      }, 300);
      return () => clearTimeout(delayDebounce);
    } else if(value) {
      fetchOptions("", page);
    }
  }, [searchTerm, fetchOptions, value,page]);

    return (
    <Autocomplete
      fullWidth
      size="small"
      options={options}
      getOptionLabel={(option) => option.label}
      loading={loading}
      onInputChange={(event, value) => setSearchTerm(value)}
      onChange={(event, newValue) => onOptionSelect(newValue)}
      onOpen={() => {
        setPage(0); // Reset page to 0 on dropdown open
        fetchOptions("", 0); // Fetch first page of options on open
      }}
      ListboxProps={{
        onScroll: handleScroll,
        sx: {
          maxWidth: 500,
          "& .MuiAutocomplete-option": {
            fontSize: "0.8rem",
          },
        '&::-webkit-scrollbar': { width: '0px', height: '0px' }
        },
      }}
      sx={{
        "& .MuiAutocomplete-root": {
          width: "100%",
        },
      }}
      value={
        typeof value === "string"
          ? options.find((option) => option.id === value) || null
          : options.find((option) => option.id === value?.id) || value || null
      }
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          name={name}
          placeholder={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "55px",
              backgroundColor: "white",
              color: "#9A9A9A",
              width: "150px",
              borderRadius: "8px",
              "& fieldset": {
                borderColor: "#D9D9D9",
              },
              "&:hover fieldset": {
                borderColor: "#D9D9D9",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#D9D9D9", // Ensure consistent border color on focus
              },
            },
            "& .MuiInputLabel-root": {
              color: "#9A9A9A",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#9A9A9A",
            },
          }}
        />
      )}
      renderOption={(props, option) => (
        <MenuItem sx={{ minWidth: "350px", fontSize: "0.7rem", overflow: "auto" }} {...props}>
          {option.label}
        </MenuItem>
      )}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
    />
  );
};