import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { useDispatch } from "react-redux";
import {
  getChild1ReplyComment,
  getMainReplyComment,
  toggleCommentGetDataRefresh,
  toggleCommentsDrawer,
} from "../../redux/features/commentsSlice";
import { IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TelegramIcon from "@mui/icons-material/Telegram";
import { addComment, mainReplyComment, replyChild1Comment } from "../../Crud/comments";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CommentsView from "./CommentsView";
import { useUserId } from "../../hooks/useUserId";
import { useCommentsState } from "../../hooks/useCommentsState";
export default function CommentsDrawer() {
  const { getDataRefresh, toggleComment, mainReply, child1Reply }:any = useCommentsState();
  const userId = useUserId();
  const [comments, setComments] = useState("");
  const [errorComments, setErrorComments] = useState("");
  const getId = useParams();
  // console.log('getId',getId.id);
// console.log("child1Reply",child1Reply)
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = location.state || {};
  console.log('id',id);
  const handleCommentsCreate = () => {
    if (!comments) {
      setErrorComments("Comments required field");
      return;
    }
  
    setComments("");
    setErrorComments("");
  
    const onSuccess = (message:string) => {
      toast.success(message);
      dispatch(toggleCommentGetDataRefresh(!getDataRefresh));
      dispatch(getMainReplyComment({}));
      dispatch(getChild1ReplyComment({}));
    };
  
    const onError = (message:string) => toast.error(message);
  
    if (mainReply?.commentedBy?.userName) {
      mainReplyComment(mainReply?.commentId, comments, userId)
        .then(() => onSuccess("Reply successfully added."))
        .catch(() => onError("Reply add failed."));
    } else if (child1Reply?.repliedBy?.userName) {
      replyChild1Comment(comments, child1Reply?.parentId, child1Reply?.replyId, userId)
        .then(() => onSuccess("Reply successfully added."))
        .catch(() => onError("Reply add failed."));
    } else {
      const data = {
        rthId:getId.id?? id,
        commentedBy: userId,
        commentedText: comments,
      };
      addComment(data)
        .then(() => onSuccess("Comment successfully posted."))
        .catch(() => onError("Comment post failed."));
    }
  };
  
  const handleCommentChange = (e: any) => {
    const value = e.target.value;
    setComments(value);
    setErrorComments(value ? "" : "Comments required field");
  };
  const handleCloseDrawer = () => {
    dispatch(toggleCommentsDrawer(false));
    dispatch(getMainReplyComment({}));
    dispatch(getChild1ReplyComment({}));
  };

  const DrawerList = (
    <Box sx={{ width: 350, position: "relative" }} role="presentation">
      <Box mx={2} my={4}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            my: 2,
            mx: 4,
          }}
        >
          <Typography sx={{ fontWeight: "bold" }}>Comments</Typography>
          <CloseIcon
            fontSize="small"
            sx={{ color: "#989696", cursor: "pointer" }}
            onClick={handleCloseDrawer}
          />
        </Box>
        <Divider />
      </Box>
      <CommentsView />
      <Box sx={{ position: "fixed", bottom: 30, right: 5, width: "310px" }}>
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <TextField
            placeholder="Put your thoughts"
            variant="standard"
            fullWidth
            value={comments}
            onChange={handleCommentChange}
            inputProps={{ maxLength: 5000 }}
          />
          <IconButton
            sx={{
              backgroundColor: "#1976d2",
              color: "#fff",
              borderRadius: "50%",
              "&:hover": { backgroundColor: "#155a9e" },
              height: "25px",
            }}
            onClick={handleCommentsCreate}
          >
            <TelegramIcon sx={{ fontSize: "16px" }} />
          </IconButton>
        </Box>
        {(mainReply?.commentedBy?.userName || child1Reply?.repliedBy?.userName) && (
          <Box
            sx={{
              position: "absolute",
              top: -10,
              fontSize: 12,
              color: "#1976d2",
              backgroundColor: "white",
              padding: "0 5px",
            }}
          >
            {`@${
              child1Reply?.repliedBy?.userName ??
              mainReply?.commentedBy?.userName
            }`}
          </Box>
        )}

        {errorComments && (
          <Typography sx={{ fontSize: 12, color: "red", mt: 1 }}>
            {errorComments}
          </Typography>
        )}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        open={toggleComment}
        onClose={() => dispatch(toggleCommentsDrawer(false))}
        anchor="right"
      >
        {DrawerList}
      </Drawer>
    </div>
  );
}
