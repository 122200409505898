import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import CommonTextField from '../../../../common/CommonTextField'
 
export const Education = ({formValues,setFormValues,errors,setErrors}:any) => {

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
  
    // Create a copy of the educations array
    const updatedEducations = [...formValues.educations];
    const updatedErrors = errors.educations || [];
    const currentDate = new Date().toISOString().split('T')[0]; // Current date in YYYY-MM-DD format
  
    // Validation for 'Degree' field: only letters allowed
    if (name === 'degree') {
      const onlyLettersRegex = /^[a-zA-Z.\s]*$/; // Regex to allow only letters and spaces
      if (!onlyLettersRegex.test(value)) {
        updatedErrors[index] = {
          ...updatedErrors[index],
          degree: 'Only letters and spaces are allowed in Degree',
        };
      } else {
        updatedErrors[index] = {
          ...updatedErrors[index],
          degree: '', // Clear error if input is valid
        };
      }
    }
  
    // Update the specific education entry at the given index
    const updatedEntry = {
      ...updatedEducations[index],
      [name]: value,
    };
  
    // Validation for 'from' date: cannot be in the future or today
    if (name === 'from') {
      if (updatedEntry.from && updatedEntry.from > currentDate) {
        updatedErrors[index] = {
          ...updatedErrors[index],
          from: 'From date cannot be in the future or today',
        };
      } else {
        updatedErrors[index] = {
          ...updatedErrors[index],
          from: '',
        };
      }
    }
  
    // Validation for 'to' date: cannot be in the future
    if (name === 'to') {
      if (updatedEntry.to && updatedEntry.to > currentDate) {
        updatedErrors[index] = {
          ...updatedErrors[index],
          to: 'To date cannot be in the future',
        };
      } else if (updatedEntry.from && updatedEntry.to && updatedEntry.to <= updatedEntry.from) {
        updatedErrors[index] = {
          ...updatedErrors[index],
          to: 'To date must be greater than From date',
        };
      } else {
        updatedErrors[index] = {
          ...updatedErrors[index],
          to: '',
        };
      }
    }
  
    // Update the educations array and errors state
    updatedEducations[index] = updatedEntry;
    setFormValues((prevValues: any) => ({
      ...prevValues,
      educations: updatedEducations,
    }));
  
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      educations: updatedErrors,
    }));
  };
  
  
    useEffect(()=>{
      if (!Array.isArray(formValues.educations) || formValues.educations.length === 0) {
        setFormValues((prevFormData:any) => ({
          ...prevFormData,
            educations: [{
            university: '',
            degree: '',
            fieldOfStudy: '',
            from: '',
            to: '',
            eduLabel: 'Highest Education',
          }]
        }));
        }
      
    },[formValues,setFormValues])
  
  return (
    <>
    <Typography sx={{ fontSize: '14px', mb: '17px', m: '14px', backgroundColor: '#1677FF1A', color: '#1677FF', width: '70px', p: '3px', borderRadius: '3px', textAlign: 'center', fontWeight: '500' }}>Education</Typography>
    <Grid container spacing={2}>
    {formValues?.educations && formValues?.educations?.length > 0 ? (
    formValues?.educations?.map((entry: any, index: number) => (
    <>
    <Box sx={{ml:'20px',mt:'20px',fontWeight:'bold'}}>
    <Typography>{entry?.eduLabel}</Typography>
    </Box>
    <Box sx={{ mt: '20px', ml: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '60px' }}>
    <Grid item xs={12} md={3}>
      <Typography sx={{ mb: '16px', color: '#E3E3E3', fontSize: '14px' }}>University</Typography>
      <CommonTextField
        label=""
        name="university"
        value={entry.university}
        onChange={(event) => handleInputChange(event, index)} />
    </Grid>
    <Grid item xs={12} md={3}>
      <Typography sx={{ mb: '16px', color: '#E3E3E3', fontSize: '14px' }}>Degree</Typography>
      <CommonTextField
        label=""
        name="degree"
        value={entry.degree}
        onChange={(event) => handleInputChange(event, index)}
        error={Boolean(errors?.educations?.[index]?.degree)}
        helperText={errors?.educations?.[index]?.degree}
      />

    </Grid>
    <Grid item xs={12} md={4}>
      <Typography sx={{ mb: '16px', color: '#E3E3E3', fontSize: '14px' }}>Field of Study</Typography>
      <CommonTextField
        label=""
        name="fieldOfStudy"
        value={entry.fieldOfStudy}
        onChange={(event) => handleInputChange(event, index)} />
    </Grid>
    <Grid item xs={12} md={2}>
      <Typography sx={{ mb: '16px', color: '#E3E3E3', fontSize: '14px' }}>From</Typography>
      <CommonTextField
        label=""
        name="from"
        type='date'
        value={entry.from}
        onChange={(event) => handleInputChange(event, index)}
        error={Boolean(errors?.educations?.[index]?.from)}
        helperText={errors?.educations?.[index]?.from}
/>
    </Grid>
      </Box>
      <Box sx={{ mt: '20px', ml: '14px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '60px' }}>
      <Grid item xs={12}>
      <Typography sx={{mb:'16px',color:'#E3E3E3',fontSize:'14px'}}>To</Typography>
      <CommonTextField
      label=""
      name="to"
      type='date'
      value={entry.to}
      onChange={(event) => handleInputChange(event, index)}
      error={Boolean(errors?.educations?.[index]?.to)}
      helperText={errors?.educations?.[index]?.to}

      />
    </Grid>
    </Box>
    </>
    ))
    ):(
    <Typography sx={{ color: '#E5E3E3', pl:"30px", pt:'20px' }}>No education data available.</Typography>
    )}
    </Grid></>
    )
    }
 
 