import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TeamHeadFilterState {
  l1StatusPending: boolean;
  l1StatusDeclined: boolean;
  l2StatusApproved: boolean;
  l2StatusPending:boolean;
  l2StatusDeclined:boolean;
  internalOnRole: boolean;
  internalContract: boolean;
  externalOneTime: boolean;
  externalContract: boolean;
  priorityLow: boolean;
  priorityMedium: boolean;
  priorityHigh: boolean;
  rthStages: string[] ;
  createdBy: string[];
  recruiter: string[];
  recruiterLead:string[];
  positionTitle: string[];
  fromDate:string | null;
  toDate:string | null;
  page: number;
  size: number;
  location:string[];
  business:string[];  
  postedIn:string[];
}

const initialState: TeamHeadFilterState = {
  l1StatusPending: false,
  l1StatusDeclined: false,
  l2StatusApproved: false,
  l2StatusPending:false,
  l2StatusDeclined:false,
  internalOnRole: false,
  internalContract: false,
  externalOneTime: false,
  externalContract: false,
  priorityLow: false,
  priorityMedium: false,
  priorityHigh: false,
  recruiterLead:[],
  postedIn:[],
  rthStages: [],
  createdBy: [],
  recruiter: [],
  positionTitle: [],
  location:[],
  business:[],  
  fromDate:null,
  toDate:null,
  page: 0,
  size: 200,
};

const teamHeadFilterSlice = createSlice({
  name: 'teamHeadFilter',
  initialState,
  reducers: {
    setFilterState: (state, action: PayloadAction<Partial<TeamHeadFilterState>>) => {
      return { ...state, ...action.payload };
    },
    resetFilterState: () => initialState,
  },
});

export const { setFilterState, resetFilterState } = teamHeadFilterSlice.actions;
export default teamHeadFilterSlice.reducer;
