// profileSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BaseUrl } from '../../constants/Crudurl';
import { profileUpdateGetData } from './commonSlice';
// import { useDispatch } from 'react-redux';
// import { jwtDecode } from "jwt-decode";

interface ProfileState {
  data: any;
  userData:any,
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ProfileState = {
  data: null,
  userData:null,
  status: 'idle',
  error: null,
};
// export const getProfileAPI = async(userId:string) => {
//     const token = localStorage.getItem("token");
//     return  await axios.get(
//       `${BaseUrl}/profile/getProfile?id=${userId}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//   };

export const updateProfilePicture=createAsyncThunk('profile/fetchProfile',async(userId:string,{getState})=>{
  const token = localStorage.getItem('token'); 
  const response=await axios.delete(`${BaseUrl}/profile/deleteProfileById?id=${userId}`,{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  return response.data;
})

export const fetchProfile = createAsyncThunk('profile/fetchProfile', async (userId:string, { getState,dispatch }) => {
    const token = localStorage.getItem('token'); 
    if (!token) throw new Error('No token found');
    const response = await axios.get(`${BaseUrl}/theronAdmin/getUserById/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.warn("response.data",response.data.data?.imageUrl)
          dispatch(profileUpdateGetData(response.data.data?.imageUrl));
    return response.data;
  });

  export const updateProfile = createAsyncThunk('profile/updateProfile',async ({ userId, profileData }: { userId: string, profileData: any }, { getState }) => {
      const token = localStorage.getItem('token');
    //   const token = "eyJhbGciOiJIUzI1NiJ9.eyJyb2xlIjoiVVNFUiIsIm5hbWUiOiJwcmF2ZWVuQGdtYWlsLmNvbSIsInVzZXJJZCI6IjY2YzU3NDQ1MjEzYzM5MzllMzQ2MjgyNiIsImVtYWlsIjoicHJhdmVlbkBnbWFpbC5jb20iLCJzdWIiOiJwcmF2ZWVuQGdtYWlsLmNvbSIsImlhdCI6MTcyNDIxNjM4OSwiZXhwIjoxNzI0MzAyNzg5fQ.HlFrVYtIUG-MGA4AQDvbum1z-2KTHgsyPxMCVIE7x1Y"
      if (!token) throw new Error('No token found');
      const response = await axios.put(`${BaseUrl}/profile/updateProfile?id=${userId}`,
        profileData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      return response.data;
    }
  );
  


const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
        state.userData = action.payload
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(updateProfile.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      });
  },
});

export default profileSlice.reducer;