import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { BaseUrl } from "../../constants/Crudurl";

export const getL1Approvers=createAsyncThunk('resume/getL1Approvers',
    async({search,page,size}:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/master/getL1Approvers?search=${search}&page=${page}&size=${size}`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)
export const getCandidateInterviewDetails=createAsyncThunk('resume/getCandidateInterviewDetails',
    async(candidateId:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.get(`${BaseUrl}/interview/getInterviewByCandidateId/${candidateId}`,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        }catch(error:any){
            return error.response.data;
        }
    }
)

export const addInterview=createAsyncThunk('resume/addInterview',
    async(data:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.post(`${BaseUrl}/interview/addInterview`,data,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response.data;
        } catch(error:any){
            return error.response.data;
        }
    }
)

export const getInterviewDetailsByRecruiterId=createAsyncThunk('resume/getCandidateByCandidateId',
    async({ RecruiterId, rthId, data}:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.post(`${BaseUrl}/interview/getInterviewByRecAndRthId?recruiterId=${RecruiterId}&rthId=${rthId}`,data,
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            return response?.data;
        }catch(error:any){
          return error.response.data;
        }
    }
)

export const getInterviewDetailsByInterviewId=createAsyncThunk('resume/getInterviewDetailsByInterviewId',
    async(interviewId:any)=>{
        try{
            const response=await axios.get(`${BaseUrl}/interview/getInterviewById/${interviewId}`,              
            );
            return response?.data;
        }catch(error:any){
          return error.response.data;
        }
    }
)

export const interviewFeedBackL1=createAsyncThunk('resume/interviewFeedBackL1',
    async({interviewId,data}:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.put(`${BaseUrl}/interview/setL1FeedBack?interviewId=${interviewId}`,data,
                
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
                
            );
            if (response.status === 200) {
                toast.success('FeedBack Submitted Successfully'); // Show success toast
              }
        
            return response?.data;
        }catch(error:any){
            toast.error('Failed to submit feedback');
            return error.response.data;
        }
    }
)

export const interviewFeedBackL2=createAsyncThunk('resume/interviewFeedBackL2',
    async({interviewId,data}:any)=>{
        try{
            const token=localStorage.getItem('token');
            const response=await axios.put(`${BaseUrl}/interview/setL2FeedBack?interviewId=${interviewId}`,data,
                
                {
                    headers:{
                        Authorization:`Bearer${token}`,
                    },
                }
            );
            if (response.status === 200) {
                toast.success('FeedBack Submitted Successfully'); // Show success toast
              }
        
            return response?.data;
        }catch(error:any){
            toast.error('Failed to submit feedback');
            return error.response.data;
        }
    }
)

export const getPosition=createAsyncThunk('resume/getPosition',
    async()=>{
        const response=await axios.get(`${BaseUrl}/master/getPositionTitle`);
        return response.data;
    }
)

export const getCandidateDetails=createAsyncThunk('resume/getCandidateDetails',
    async(candidateId:any)=>{
        const response=await axios.get(`${BaseUrl}/offerApproval/candidateDetailPage/${candidateId}`);
        return response.data;
    }
)

export const getMrfDetails=createAsyncThunk('resume/getMrfDetails',
    async(mrfId:any)=>{
        const response=await axios.get(`${BaseUrl}/interviewRepo/getInterviewMrfDetails/${mrfId}`);
        return response.data;
    }
)

export const editInterview=createAsyncThunk('resume/editInterview',
    async({interviewId,data}:any)=>{
        const response=await axios.put(`${BaseUrl}/interview/editInterview/${interviewId}`,data);
        return response.data;
    }
)

export const setFeedBackStatus=createAsyncThunk('resume/setFeedBackStatus',
    async({interviewId,level,status}:any)=>{
        const response=await axios.put(`${BaseUrl}/interview/setFeedbackStatus?interviewId=${interviewId}&level=${level}&status=${status}`);
        return response.data;
    }
)

export const sentInterviewMail=createAsyncThunk('resume/sentInterviewMail',
    async(data:any)=>{
        const response=await axios.post(`${BaseUrl}/interview/sentInterviewMail`,data);
        return response.data;
    }
)

export const interviewFeedbackL3=createAsyncThunk('resume/interviewFeedbackL3',
    async({interviewId,data}:any)=>{
        const response=await axios.put(`${BaseUrl}/interview/setL3FeedBack?interviewId=${interviewId}`,data);
            return response.data;
    }
)