import axios from "axios";
import { BaseUrl } from "../constants/Crudurl";
import { ExportData } from "../components/reports/SearchAndExport";

export const getMrfApprovalStatusTh = async (id: string,filterData:any) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/mrfApprovalStatusTh/${id}`,filterData
    );
    console.log("getMrfApprovalStatusTh", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getMrfStageReportTh = async (id: string,filterData?:any) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/mrfStageReportTh/${id}`,filterData
    );
    console.log("getMrfStageReportTh", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getMrfApprovedReportTh = async (id: string,filterData:any) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/mrfApprovedReportTh/${id}`,filterData
    );
    console.log("getMrfApprovedReportTh", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getOverallMrfReportTh = async (id: string,filterData:any) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/overAllMrfReportTh/${id}`,filterData
    );
    console.log("getOverallMrfReportTh", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getMrfApprovalStatusAh = async (id: string,filterData:any) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/mrfApprovalStatusAh/${id}`,filterData
    );
    console.log("getMrfApprovalStatusAh", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getMrfStageReportAh = async (id: string,filterData:any) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/mrfStageReportAh/${id}`,filterData
    );
    console.log("getMrfStageReportAh", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getMrfApprovedReportAh = async (id: string,filterData:any) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/mrfApprovedReportAh/${id}`,filterData
    );
    console.log("getMrfApprovedReportAh", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getOverallMrfReportAh = async (id: string,filterData:any) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/overAllMrfReportAh/${id}`,filterData
    );
    console.log("getOverallMrfReportAh", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getSourceReportTa = async (id: string,filterData:any,search:string) => {
  try {    
    const response = await axios.post(`${BaseUrl}/report/sourceReportTa/${id}`,filterData,{
      params:{
        search:search
      }
    });        
    return response.data.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getSourceReportRl = async (id: string,filterData:any,search:string) => {
  try {
    const response = await axios.post(`${BaseUrl}/report/sourceReportRl/${id}`,filterData,{
      params:{
        search:search
      }
    });
    return response.data.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};
export const getSourceReportR = async (id: string,filterData:any,search:string) => {
  try {
    const response = await axios.post(`${BaseUrl}/report/sourceReportR/${id}`,filterData,{
      params:{
        search:search
      }
    });
    return response.data.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getMrfStageReportR = async (id: string,filterData:any,search?:string) => {
  try {
    const response = await axios.post(`${BaseUrl}/report/mrfStageReportR/${id}`,filterData);
    console.log("getMrfStageReportR", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getMrfStageReportRl = async (id: string,filterData:any,search?:string) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/mrfStageReportRl/${id}`,filterData
    );
    console.log("getMrfStageReportRl", response.data);
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getMrfReportTa = async (id: string,filterData:any,search?:string) => {
  try {    
    const response = await axios.post(`${BaseUrl}/report/mrfReportTa/${id}`,filterData);        
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getOverallMrfReportTa = async (id: string,filterData:any,search:string) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/OverAllMrfReportTa/${id}`,filterData,{
        params:{
          search:search
        }
      }
    );
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};
export const getOverAllReportRl = async (id: string,filterData:any,search:string) => {
  try {
    const response = await axios.post(`${BaseUrl}/report/overAllReportRl/${id}`,filterData,{
      params:{
        search:search
      }
    });
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};
export const getOverAllReportR = async (id: string,filterData:any,search:string) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/overAllReportR/${id}`,filterData,{
        params:{
          search:search
        }
      }
    );
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getCandidateReportTa = async (id: string,filterData:any,search:string) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/CandidateReportTa/${id}`,filterData,{
        params:{
          search:search
        }
      }
    );
    console.log("point",response.data.data)
    return response.data.data.Candidate_Interview_Status;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getCandidateReportRl = async (id: string,filterData:any,search:string) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/candidateReportRl/${id}`,filterData,{
        params:{
          search:search
        }
      }
    );
    return response.data.data.Candidate_Interview_Status;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getCandidateReportR = async (id: string,filterData:any,search:string) => {
  try {
    const response = await axios.post(
      `${BaseUrl}/report/candidateReportR/${id}`,filterData,{
        params:{
          search:search
        }
      }
    );
    
    return response.data.data.Candidate_Interview_Status;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
};

export const getCandStatusByRth=async(id:string,status:string)=>{
  try {
    const response = await axios.get(
      `${BaseUrl}/report/candStatusByRth`,{
        params:{
          rthId:id,
          status:status
        }
      }
    );
    return response.data;
  } catch (error: any) {
    return error.response?.data || { message: "An unknown error occurred" };
  }
}

export const exportReportApiFunction = async (reportData: ExportData,selectedTab:string) => {
  try {
    // Make the POST request to the server with the provided report data
    const response = await axios.post(
      'https://mytheron-dev-backend.citpl.info/report/reportExport',
      reportData,
      {
        headers: {
          'accept': '*/*',          
          'Content-Type': 'application/json',
        },
        responseType: 'blob', // This ensures the response is handled as binary data
      }
    );
 
    // Check if the response is successful
    if (response.status === 200) {
      // Create a URL for the blob
      const downloadUrl = URL.createObjectURL(response.data);
 
      // Create an anchor element to trigger the download
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = `${selectedTab}.xlsx`; // You can customize the file name
      document.body.appendChild(link);
     
      // Trigger a click event to download the file
      link.click();
     
      // Clean up by removing the link and revoking the object URL
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } else {
      console.error('Failed to export report');
    }
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};