import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  InputBase,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTableCell } from "./RthClosedTable";
import {
  getJobPostAPIData,
  getMoveToJobAPIData,
  getReadyForJdCreationAPIData,
  getResumeCardAPIData,
  getWaitingToMoveToJobAPIData,
} from "../../../../Crud/card";
import { useAppSelector } from "../../../../redux/hooks";
import priorityHighSymbol from "../../../../assets/images/priorityHighSymbol.svg";
import priorityMediumSymbol from "../../../../assets/images/priorityMediumSymbol.svg";
import priorityLowSymbol from "../../../../assets/images/priorityLowSymbol.svg";
import { useNavigate } from "react-router-dom";
import resumeIcon from "../../../../assets/images/resume.svg";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
import { GridCloseIcon } from "@mui/x-data-grid";
import CopyUrlBox from "../../../common/CopyUrlBox";

import naukriImage from "../../../../assets/images/naukri-round.svg";
import indeedImage from "../../../../assets/images/indeed-round.svg";
import linkedInImage from "../../../../assets/images/linkedIn-round.svg";

import SearchIcon from "@mui/icons-material/Search";

interface prop {
  tabName: string;
}

const RecruiterTable: React.FC<prop> = ({ tabName }) => {
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const [loading, setLoading] = useState<boolean>(false);
  console.log(loading);
  const userId = localStorage.getItem("userId") ?? "";
  const [tableData, setTableData] = useState<any[]>([]);
  const [buttonName, setButtonData] = useState<string>();
  const [lastColumn, setLastColumn] = useState<string>("Action");
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  console.log("setSearchLoading",setSearchLoading)
  const tableHead = [
    "SI.No.",
    "MRF Name",
    "Type of MRF",
    "Posted Time",
    "Business",
    "Priority",
    "Status",
    "Stage",
    lastColumn,
  ];
  
  const handleRowClick=(rthId:string,positionTitle:string)=>{
    if(tabName === "Resume")
    {
      navigate(`/dashboard/MRH/${positionTitle}/${rthId}`)
    }
    else{
      navigate(`/request-hire`, { state: { id: rthId } });
    }
  }

  useEffect(() => {
    if (tabName === "Waiting to Move to Job") {
      setLoading(true);
      getWaitingToMoveToJobAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
        })
        .catch((error) => {
          // setLoading(false);
          setTableData([]);
        })
        .finally(() => {
          setLoading(false);
        });
      setButtonData("Move to Job");
    } else if (tabName === "Ready for JD Creation") {
      setLoading(true);
      getReadyForJdCreationAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
        })
        .catch((error) => {
          // setLoading(false);
          setTableData([]);
        })
        .finally(() => {
          setLoading(false);
        });
      setButtonData("Create JD");
    } else if (tabName === "Job Post") {
      setLoading(true);
      getJobPostAPIData(userId, searchQuery, teamHeadFilter)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
          console.log("job post table api", res);
        })
        .catch((error) => {
          // setLoading(false);
          setTableData([]);
        })
        .finally(() => {
          setLoading(false);
        });
      setButtonData("Post a Job");
    } else if (tabName === "Resume") {
      setLoading(true);
      getResumeCardAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          // setLoading(false);
          setTableData(res?.data?.listOfRth);
        })
        .catch((error) => {
          // setLoading(false);
          setTableData([]);
        })
        .finally(() => {
          setLoading(false);
        });
      setLastColumn("Resume Status");
    }
  }, [tabName, teamHeadFilter, userId, searchQuery]);

  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<any>("");

  const handleButtonClick = async (rthId: string, buttonValue: string) => {
    console.log(rthId, buttonValue);
    if (buttonValue === "Move to Job") {
      await getMoveToJobAPIData(rthId, teamHeadFilter);
      await getWaitingToMoveToJobAPIData(userId, teamHeadFilter)
        .then((res) => {
          setLoading(false);
          setTableData(res?.data?.listOfRth);
        })
        .catch((error) => {
          setLoading(false);
          setTableData([]);
        });
    } else if (buttonValue === "Create JD") {
      navigate(`/JDCreation/${rthId}`);
    } else if (buttonValue === "Post a Job") {
    }
  };

  const handleSetItems=(data:any)=>{
                                    setOpen(true);
                                setItems(data);
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    console.log("search qqq", event.target.value);
  };

  console.log("tb data", tableData);

  return (
    <Box sx={{ width: "auto" }}>
      <Grid
        item
        sx={{ marginTop: tableData.length > 0 ? "-60px" : "-58px" }}
        position="absolute"
      >
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 0,
            backgroundColor: "white",
            minHeight: "3px",
            fontSize: 14,
            ".MuiInputBase-input": {
              padding: "5px 5px",
            },
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: "absolute",
              right: "10px",
              top: "25%",
              transform: "translateY(-50%)",
              color: "#2F54EB",
            }}
          />
        )}
      </Grid>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "300px",
          }}
        >
          <CircularProgress size={100} sx={{ color: "#2F54EB" }} />
        </Box>
      ) : tableData.length > 0 ? (
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="MRF table">
              <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                <TableRow
                  sx={{
                    "& th": {
                      padding: "4px 8px", // Adjust padding for header cells here
                      fontSize: "14px", // Optional: Adjust font size
                    },
                  }}
                >
                  {tableHead.map((data, index) => (
                    <StyledTableCell>{data}</StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td, & th": {
                    padding: "4px 8px", // Adjust padding here
                  },
                }}
              >
                {tableData?.map((data, index) => (
                  <TableRow key={data?.rthId} 
                  onClick={()=>handleRowClick(data.rthId,data.positionTitle)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.1)", // Example color
                    },
                  }}>
                    <StyledTableCell>{index + 1}</StyledTableCell>
                    <StyledTableCell>{data?.positionTitle}</StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "4px",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "8px",                            
                            color: "#8C8C8C",
                            height: "20px",
                            minWidth: "50px",
                          }}
                        >
                          {data.typeOfRth}
                        </Button>
                        <Button
                          size="small"
                          sx={{
                            backgroundColor: "#F0F0F0",
                            border: "1px solid lightgray",
                            fontSize: "8px",                            
                            color: "#8C8C8C",
                            height: "20px",
                            minWidth: "50px",
                          }}
                        >
                          {data.modeOfRth}
                        </Button>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>{new Date(data?.createdAt).toLocaleDateString('en-GB')}</StyledTableCell>
                    <StyledTableCell>
                      <Typography
                        sx={{
                          width: "fit-content",
                          fontSize: "12px",
                          padding: "3%",
                          height: "px",
                          border:
                            "1px solid " +
                            (data.business.businessName === "HEPL"
                              ? "#D3ADF7"
                              : "#87E8DE"),
                          backgroundColor:
                            data.business.businessName === "HEPL"
                              ? "#EFDBFF"
                              : "#B5F5EC",
                          color:
                            data.business.businessName === "HEPL"
                              ? "#722ED1"
                              : "#08979C",
                        }}
                      >
                        {data.business.businessName}
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                        gap={1}
                      >
                        {data.priority === "High" ? (
                          <Box>
                            <img src={priorityHighSymbol} alt="" />
                          </Box>
                        ) : data.priority === "Medium" ? (
                          <Box>
                            <img src={priorityMediumSymbol} alt="" />
                          </Box>
                        ) : (
                          <Box>
                            <img src={priorityLowSymbol} alt="" />
                          </Box>
                        )}
                        <Typography
                          sx={{
                            color:
                              data.priority === "High"
                                ? "#F04E4E"
                                : data.priority === "Medium"
                                ? "#FFA940"
                                : "#73D13D",
                            fontSize: "100%",
                          }}
                        >
                          {data.priority}
                        </Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Chip
                        label={data.status}
                        size="small"
                        sx={{
                          fontSize: "12px",
                          height: "px",
                          borderRadius: "1px",
                          backgroundColor: "#389E0D1A",
                          color: "#389E0D",
                        }}
                      />
                    </StyledTableCell>

                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Chip
                            label={data.rthProcess}
                            size="small"
                            sx={{
                              fontSize: "12px",
                              
                              height: "20px",
                              borderRadius: "1px",
                              backgroundColor: "transparent",
                              color: "#8C8A8A",
                            }}
                          />
                        </Box>
                      </Box>
                    </StyledTableCell>

                    {tabName === "Resume" && (
                      <StyledTableCell>
                        <Box display={"flex"} alignItems={"center"} gap={0.5}>
                          <img src={resumeIcon} alt="-" />
                          <Typography sx={{ fontSize: "12px" }}>
                            Resume Uploaded
                          </Typography>
                          <Chip
                            label={data.resumesCount}
                            size="small"
                            sx={{
                              fontSize: "12px",
                              borderRadius: "3px",
                              backgroundColor: "#1677FF1A",
                              color: "#1677FF",
                              padding: "0px",
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                    )}
                    {tabName === "Job Post" &&
                      (data.jobPosted === true ? (
                        <StyledTableCell>
                          <Box>
                            <Button
                            size="small"
                              sx={{
                                textTransform: "none",
                                minWidth: "80px",
                                padding: "4px 8px",
                                fontSize: "10px",
                                background: "#1677ff29",
                                border: "1px solid #1677FF",
                                color: "#1677FF",
                                "&:hover": {
                                  background: "#1677ff29",
                                  color: "#1677FF",
                                  boxShadow: "none",
                                },
                              }}
                              variant="outlined"
                              onClick={(e) => {
                                e.stopPropagation();
                                // setOpen(true);
                                // setItems(data);
                                handleSetItems(data);
                              }}
                            >
                              Posted in
                            </Button>
                          </Box>
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell>
                          <Box>
                            <Button
                            size="small"
                              sx={{
                                textTransform: "none",
                                minWidth: "80px",
                                padding: "4px 8px",
                                fontSize: "10px",
                                background: "#1677FF",
                                color: "#FFFFFF",
                                "&:hover": {
                                  background: "#1677FF",
                                  color: "#FFFFFF",
                                  boxShadow: "none",
                                },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                // setOpen(true);
                                // setItems(data);
                                handleSetItems(data);
                              }}
                            >
                              Post Job
                            </Button>
                          </Box>

                          <Dialog
                            open={open}
                            onClick={(e) => {
                              e.stopPropagation()
                              setOpen(false);
                            }}
                          >
                            <DialogTitle>
                              <Typography
                                sx={{ color: "#8C8C8C", fontSize: "14px" }}
                              >
                                {" "}
                                Share{" "}
                              </Typography>
                              <IconButton
                                edge="end"
                                onClick={(e) => {
                                  e.stopPropagation()
                                  setOpen(false)}}
                                aria-label="close"
                                size="small"
                                sx={{
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                  color: "#8C8C8C",
                                }}
                              >
                                <GridCloseIcon />
                              </IconButton>
                            </DialogTitle>
                            <Box mx={2}>
                              {" "}
                              <CopyUrlBox id={data?.rthId} />{" "}
                            </Box>
                            <Box mx={2} my={2}>
                              <Typography
                                sx={{ fontSize: "10px", color: "#8C8C8C" }}
                              >
                                Post here
                              </Typography>
                              {!items?.postedIn?.includes("naukri") && (
                                <Box
                                  component="img"
                                  src={naukriImage}
                                  alt="Naukri"
                                  sx={{ width: "20px", height: "20px" }}
                                />
                              )}
                              {!items?.postedIn?.includes("indeed") && (
                                <Box
                                  component="img"
                                  src={indeedImage}
                                  alt="Indeed"
                                  sx={{ width: "20px", height: "20px" }}
                                />
                              )}
                              {!items?.postedIn?.includes("linkedIn") && (
                                <Box
                                  component="img"
                                  src={linkedInImage}
                                  alt="linkedInImages"
                                  sx={{ width: "20px", height: "20px" }}
                                />
                              )}
                            </Box>
                            <>
                              {items?.jobPosted && (
                                <Box mx={2} my={2}>
                                  <Typography
                                    sx={{ fontSize: "10px", color: "#8C8C8C" }}
                                  >
                                    {" "}
                                    Posted here{" "}
                                  </Typography>
                                  {items?.postedIn?.includes("naukri") && (
                                    <Box
                                      component="img"
                                      src={naukriImage}
                                      alt="Naukri"
                                      sx={{ width: "20px", height: "20px" }}
                                    />
                                  )}
                                  {items?.postedIn?.includes("indeed") && (
                                    <Box
                                      component="img"
                                      src={indeedImage}
                                      alt="Indeed"
                                      sx={{ width: "20px", height: "20px" }}
                                    />
                                  )}
                                  {items?.postedIn?.includes("linkedIn") && (
                                    <Box
                                      component="img"
                                      src={linkedInImage}
                                      alt="linkedInImages"
                                      sx={{ width: "20px", height: "20px" }}
                                    />
                                  )}
                                </Box>
                              )}
                            </>
                          </Dialog>
                        </StyledTableCell>
                      ))}
                    {tabName !== "Resume" && tabName !== "Job Post" && (
                      <StyledTableCell>
                        <Button                        
                          size="small"
                          sx={{ textTransform: "none" }}
                          onClick={(e) =>{
                            e.stopPropagation();   
                            handleButtonClick(
                              data.rthId,
                              e.currentTarget.innerText
                            )
                          }
                        }
                        >
                          {buttonName}
                        </Button>
                      </StyledTableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "300px",
          }}
        >
          <img
            src={NoDataAvail}
            alt="No Data Available"
            style={{ maxWidth: "200px" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default RecruiterTable;
