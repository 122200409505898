import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../../redux/features/profileSlice";
import { RootState, AppDispatch } from "../../redux/store";
import {
  Box,
  Avatar,
  Typography,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import LogoutIcon from "@mui/icons-material/Logout";
import deleteicon from "../../assets/images/deleteicon.svg";
import camera from "../../assets/images/camera.svg";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  deleteProfilePictureAPI,
  getProfilePictureURL,
  updateProfilePictureAPI,
} from "../../Crud/profile";
import { toast } from "react-toastify";
import { stringAvatar } from "../../helpers/stringAvatar";
import { profileUpdateGetData } from "../../redux/features/commonSlice";

interface prop {
  setProfileView?: Function | undefined;
}

export const ProfilePage: React.FC<prop> = ({ setProfileView }) => {  
  const dispatch = useDispatch<AppDispatch>();
  const profile = useSelector((state: RootState) => state.profile.data?.data);
  const { roleName } = localStorage.getItem("user")
    ? JSON.parse(localStorage.user)
    : null;
  const status = useSelector((state: RootState) => state.profile.status);
  // console.warn('profile', profile?.imageUrl)
  const token: any = localStorage.getItem("token");
  const [edit, setEdit] = useState(false);
  const [imageUrl, setImageUrl] = useState(profile?.profilePicture);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [addProfile, setAddProfile] = useState("");

  console.log("imageUrl", imageUrl);
  console.log("addProfile", addProfile);
  // const [profilePic, setProfilePic] = useState(profile?.profilePicture);
  // const [formData, setFormData] = useState({
  //   firstName: "",
  //   lastName: "",
  //   email: "",
  //   designation: "",
  //   location: "",
  //   profilePicture: "",
  // });
  const decoded: any = jwtDecode(token);
  const userId = decoded.userId;

  useEffect(() => {
    dispatch(fetchProfile(userId));
  }, [dispatch, userId]);

  useEffect(() => {
    if (profile?.imageUrl) {
      setAddProfile(profile?.imageUrl);
      console.log("profile?.imageUrl", profile?.imageUrl);
      dispatch(profileUpdateGetData(profile?.imageUrl));
    } else {
      // profileUpdateStatusGetData("")
      setAddProfile("");
    }
  }, [profile?.imageUrl, dispatch]);
  // useEffect(() => {
  //   if (imageUrl) {
  //     setFormData({
  //       ...formData,
  //       profilePicture: imageUrl,
  //     });
  //   }
  // }, [imageUrl,formData]);

  const navigate = useNavigate();

  const handleEdit = () => {
    setEdit(!edit);
    setImageUrl("");
  };

  const handleProfilePicDelete = () => {
    if (imageUrl || addProfile) {
      deleteProfilePictureAPI(userId)
        .then(() => {
          toast.success("Profile deleted successfully.");
          setAddProfile("");
          dispatch(profileUpdateGetData(""));
          setEdit(false);
          setImageUrl("");
        })
        .catch(() => toast.error("Profile could not deleted."));
    }
  };

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleUpdate = () => {
  //   if (imageUrl) {
  //     let imageData = {
  //       imageUrl: imageUrl,
  //     };
  //     dispatch(profileUpdateGetData(imageUrl));
  //     updateProfilePictureAPI(userId, imageData)
  //       .then(() => toast.success("Updated Successfully."))
  //       .catch(() => toast.error("Update Failed"));
  //   }
  //   // dispatch(updateProfile({ userId, profileData: imageData }));
  //   setEdit(false);
  // };
  const handleProfilePicChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const validImageTypes = ["image/jpeg", "image/png"];
      if (!validImageTypes.includes(file.type)) {
        toast.error("Only image files (JPEG, PNG) are allowed.");
        return;
      }
      const maxSizeInMB = 5;
      const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        toast.error("File size exceeds 5MB. Please upload a smaller image.");
        return;
      }

      const reader = new FileReader();
      const formData = new FormData();
      formData.append("image", file);

      reader.onload = (e) => {
        setImageUrl(e.target?.result as string);

        getProfilePictureURL(formData)
          .then(async (res) => {
            setImageUrl(res.data.data);
            await setAddProfile(res.data.data);

            const imageUrl = {
              imageUrl: res.data.data,
            };

            await dispatch(profileUpdateGetData(res.data.data));

            await updateProfilePictureAPI(userId, imageUrl)
              .then(() => {
                toast.success("Profile image uploaded successfully.");
                setEdit(false);
              })
              .catch(() => toast.error("Profile image uploaded failed."));
          })
          .catch((err) => {
            console.error(err);
            toast.error("Failed to upload image.");
          });

        dispatch(profileUpdateGetData(imageUrl));
      };

      reader.readAsDataURL(file);
    }
  };

  // const handleProfilePicDelete = () => {
  //   setProfilePic("");
  // };
  // console.log("imageUrl",imageUrl);
  const handleLogout = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = (confirm: boolean) => {
    setOpenLogoutDialog(false);
    if (confirm) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("userId");
      navigate("/", { replace: true });
      console.log("User logged out");
    }
  };

  const handleBackIconButton=()=>{
    if(setProfileView)
    {
      setProfileView(false);
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          backgroundColor: "#f0f4ff",
          //   height: "3px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: 2,
            p: 2,
            mr: 2,
            ml: 2,
            mt: 2,
            height: "450px",
          }}
        >
          <Box
            sx={{
              padding: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box display={"flex"} gap={1}>
              {
                roleName === "EMPLOYEE" && (
                  <Box>
                  <IconButton onClick={handleBackIconButton}>
                    <ArrowBackIcon />
                  </IconButton>
                </Box>              
                )
              }              
              <Box sx={{ flexDirection: "column" }}>
                <Typography variant="h5" sx={{ fontSize: "20px" }}>
                  Profile
                </Typography>
                <Typography
                  variant="subtitle2"
                  sx={{ display: "flex", flexDirection: "row", color: "gray" }}
                >
                  Dashboard | Profile
                </Typography>
              </Box>
            </Box>
            <Box>
              {!edit && (
                <IconButton
                  sx={{ color: "gray" }}
                  aria-label="edit"
                  onClick={handleEdit}
                >
                  <EditIcon sx={{ fontSize: "20px" }} />{" "}
                  <Typography p={1}>Edit</Typography>
                </IconButton>
              )}
              <IconButton
                sx={{ color: "gray" }}
                aria-label="logout"
                onClick={handleLogout}
              >
                <LogoutIcon sx={{ fontSize: "20px" }} />{" "}
                <Typography p={1}>Logout</Typography>
              </IconButton>
            </Box>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Avatar
              {...stringAvatar(`${profile?.firstName} ${profile?.lastName}`,"headerProfile")}
              alt={profile?.firstName}
              src={addProfile || imageUrl}
              sx={{ width: 80, height: 80, mb: 0, mt: 3 }}
            />
            {edit && (
              <>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="row"
                  sx={{ paddingLeft: "10px" }}
                >
                  <label htmlFor="upload-photo">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      accept="image/*"
                      onChange={handleProfilePicChange}
                    />

                    <img
                      src={camera}
                      style={{ cursor: "pointer", marginBottom: "-3px" }}
                      alt="Camera Icon"
                    />
                  </label>
                  <img
                    src={deleteicon}
                    style={{ cursor: "pointer", padding: "10px" }}
                    alt="Delete Icon"
                    onClick={handleProfilePicDelete}
                  />
                </Box>
              </>
            )}
          </Box>
          {status === "loading" && <p>Loading...</p>}
          {status === "failed" && <p>Error loading profile</p>}
          {status === "succeeded" && (
            <Box mt={13}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={3}>
                  <Box>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      Employee name
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      {`${profile.firstName} ${profile.lastName}`}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} md={2.4}>
                {!edit ? (
                  <Box>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      Last name
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      {profile.lastName}
                    </Typography>
                  </Box>
                ) : (
                  <TextField
                    label="Last name"
                    name="lastName"
                    defaultValue={formData.lastName}
                    fullWidth
                    onChange={handleInputChange}
                    variant="outlined"
                    size="small"
                  />
                )}
              </Grid> */}
                <Grid item xs={12} md={3}>
                  <Box>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      Email
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      {profile.email}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box>
                    <Typography
                      variant="body1"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      Designation
                    </Typography>
                    <Typography
                      variant="h6"
                      align="center"
                      sx={{ fontSize: "15px" }}
                    >
                      {profile.designation}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={3}></Grid>
              </Grid>
            </Box>
          )}
          {/* {edit ? (
            <Box
              sx={{
                mt: 7,
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Box p={1}>
                <Button
                  variant="contained"
                  sx={{ color: "gray", backgroundColor: "#f0f4ff" }}
                  onClick={handleEdit}
                >
                  Cancel
                </Button>
              </Box>
              <Box p={1}>
                <Button
                  variant="contained"
                  sx={{ color: "#F0F5FF" }}
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </Box>
            </Box>
          ) : (
            ""
          )} */}
        </Box>
      </Box>

      {/* Logout Confirmation Dialog */}
      <Dialog
        open={openLogoutDialog}
        onClose={() => handleCloseLogoutDialog(false)}
        aria-labelledby="logout-dialog-title"
        aria-describedby="logout-dialog-description"
      >
        <DialogTitle id="logout-dialog-title">
          {"Are you sure you want to logout?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-dialog-description">
            {}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => handleCloseLogoutDialog(false)}
            color="primary"
            sx={{ mr: "45px" }}
          >
            No, Cancel
          </Button>
          <Button
            onClick={() => handleCloseLogoutDialog(true)}
            color="primary"
            sx={{ mr: "35px" }}
            autoFocus
          >
            Yes, Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProfilePage;
