import { Box, Checkbox, styled, Typography } from "@mui/material";
import React from "react";

interface props {
  label: string;
  count: number;
  data: any;
  setSelectedJobLabel:Function;
  selectedJobLabel:string[];
}

const SmallCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fontSize: 12,
  },
}));

const CustomSmallFormControlLabel2: React.FC<props> = ({
  label,
  count,
  data,
  setSelectedJobLabel,
  selectedJobLabel
}) => {
    
    const handleCheckboxChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        label: string
      ) => {
        setSelectedJobLabel((prev: string[]) => {
          if (event.target.checked) {
            // Add the label if it's checked
            return [...prev, label];
          } else {
            // Remove the label if it's unchecked
            return prev.filter((item) => item !== label);
          }
        });
      };
      
  return (
    <Box
      display={"flex"}
      flexDirection={"row"}
      alignItems={"center"}
      borderBottom={"0.2px solid #0000001A"}
    >
      <Box flexBasis={"10%"}>
        <SmallCheckbox
          onChange={(event) => handleCheckboxChange(event, data)}
          checked={selectedJobLabel.includes(label)}
        />
      </Box>
      <Box flexBasis={"80%"} display={"flex"} alignItems={"center"} gap={1}>
        <Typography fontSize={12} sx={{ color: "#434343", fontWeight: 600 }}>
          {label}
        </Typography>
      </Box>
      <Box flexBasis={"10%"}>
        <Typography fontSize={12} sx={{ color: "#8C8C8C", fontWeight: 400 }}>
          {count}
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomSmallFormControlLabel2;
