import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Signup from "../pages/signup";
import ForgotPassword from "../pages/forgotpassword";
import Login from "../pages/login";
import routesData from './routes.json';
import CheckEmail from "../pages/checkmail";
import ForgotPasswordEmail from "../pages/forgotpasswordemail";
import CreatePasswordEmail from "../pages/createpasswordemail";
import ResetEmail from "../pages/resetmail";
import ResetPasswordEmail from "../pages/resetpasswordmail";
import ResetPassword from "../pages/resetpassword";
import Dashboard from "../components/dashboard/Dashboard";
import NewRequestToHire from "../components/dashboard/Rth/newrth";
import ProfilePage from "../pages/profile/profilepage";
import HeaderBar from "../components/dashboard/commoncomponent/HeaderBar";
import { Box } from "@mui/material";
import Sidebar from "../components/dashboard/commoncomponent/SideBar";
import UxDesign from "../components/dashboard/UxArchitecture/uxdesignarchitect";
import UserMasterTable from "../components/admin/adminTabs/AdminTableViewNew";
import SetPassword from "../pages/setpassword";
import JDcreation from "../components/dashboard/Rth/recruiter/jdCreation/JdTabComponent";
import { RTHComponent } from "../components/dashboard/Rth/resume/RTHComponent";
import ResumeCardTab from "../components/dashboard/Rth/resume/ResumeCardTab";
import BulkUploadForm from "../components/dashboard/Rth/resume/forms";
import Forms from "../components/dashboard/Rth/resume/SideBar";
import { CandidateInfoTab } from "../components/dashboard/Rth/resume/candidateInfoTab";
// import FbMain from "../components/feedback/FbMain";
import AjMain from "../components/allJobs/AjMain"
import { InterviewEvaluationForm } from "../components/dashboard/Rth/resume/resumeCardTabFiles/InterviewEvaluationForm";
import BoardDash from "../components/dashboard/commoncomponent/BoardDash";
import NewRequestToHired from "../components/dashboard/Rth/newrthd";
import DashBoardTAH from "../components/dashboard/commoncomponent/DashBoard/TalentAcquisitionHead/DashBoardTA";
import DashBoardRL from "../components/dashboard/commoncomponent/DashBoard/Recruiter Lead/DashboardRL";
import DashBoardR from "../components/dashboard/commoncomponent/DashBoard/Recruiter/DashboardR";
import DashBoardAH from "../components/dashboard/commoncomponent/DashBoard/ApproverHead/DashBoardAH";
import DashBoardRTH from "../components/dashboard/commoncomponent/DashBoard/RTH Users/DashBoardRTH";
import { CandidateFormsStepper } from "../components/dashboard/Rth/resume/CandidateFormsStepper";
import SSOLogin from "../pages/ssologin";
import ReportMain from "../components/reports/ReportMain";
import { ResumeRepo } from "../components/ResumeRepository/resumeRepo";
import { InterviewModuleTab } from "../components/interviewModule/interviewModuleTab";
import { InterviewReviewForm } from "../components/dashboard/Rth/resume/resumeCardTabFiles/interviewFeedbackForm";
import { CandidateJobDetails } from "../components/dashboard/Rth/resume/resumeCardTabFiles/candidateJobDetails";
import JobOfferApproval from "../components/OfferApproval/view";
import editView  from "../components/OfferApproval/tableView/editView";
import TableView from "../components/OfferApproval/tableView/TableView";
import JobOfferApproval1 from "../components/OfferApproval/tableView/ViewApprover"
import OfferApproval from "../components/OfferApproval/intex";
import { SubmissionSuccess } from "../components/dashboard/Rth/resume/resumeCardTabFiles/submittedApplicationPage";
import InterviewFeedBack from "../components/dashboard/Rth/resume/resumeCardTabFiles/InterviewFeedBack";
 
interface RouteConfig {
  path: string;
  component: string;
}

const componentMap: { [key: string]: React.FC } = {
  Login,
  Signup,
  ForgotPassword,
  CheckEmail,
  ForgotPasswordEmail,
  CreatePasswordEmail,
  ResetEmail,
  ResetPasswordEmail,
  ResetPassword,
  SetPassword,
  Dashboard,
  NewRequestToHire,
  ProfilePage,
  UxDesign,
  JDcreation,
  RTHComponent,
  ResumeCardTab,
  BulkUploadForm,
  Forms,
  CandidateInfoTab,
  AjMain,
  InterviewEvaluationForm,
  BoardDash,
  NewRequestToHired,
  CandidateFormsStepper,
  SSOLogin,
  DashBoardRTH,
  DashBoardTAH,
  DashBoardRL,
  DashBoardR,
  DashBoardAH,
  ReportMain,
  ResumeRepo,
  OfferApproval,
  JobOfferApproval,
  TableView,
  JobOfferApproval1,
  InterviewModuleTab,
  InterviewReviewForm,
  CandidateJobDetails,
  SubmissionSuccess,
  InterviewFeedBack,
  editView
};

const AppRoute: React.FC = () => {
  const location = useLocation();
  const[title,setTitle]=useState('');
  const [subTitle, setSubTitle] = useState('');
  const hideSidebarAndHeaderPaths = [
    "/", "/login", "/signup", "/forgotpassword", "/checkemail",
    "/forgotpasswordemail", "/createpasswordemail", "/resetemail",
    "/resetpasswordemail", "/resetpassword", "/setpassword", "/feedback","/candidateForm","/viewPostedJobs","/SuccessfullySubmitted","/SubmittedFeedback", 
  ]; 

  const hideSidebarAndHeader = hideSidebarAndHeaderPaths.includes(location.pathname) ||
  /^\/candidateForm\/[^/]+$/.test(location.pathname) ||
  /^\/newRequestToHire\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/InterviewEvaluationForm$/.test(location.pathname) ||
  /^\/newRequestToHire\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/InterviewFeedBack$/.test(location.pathname) ||
  /^\/interviewReviewForm\/[^/]+\/[^/]+\/[^/]+$/.test(location.pathname)||
  /^\/interviewFeedBackForm\/[^/]+$/.test(location.pathname)||
  /^\/interviewReviewForm\/[^/]+\/[^/]+\/[^/]+\/ViewFeedback$/.test(location.pathname) || 
  /^\/candidateForm\/candidate\/[^/]+$/.test(location.pathname); 
    

  useEffect(() => {
    let matchedTitle = "Default Title";
    let matchedSubTitle = "Default Subtitle";
    const titleMap: { [key: string]: string } = {
      "/dashboard": "Manpower Requisition Form",
      "/masters": "Master",
      "/reports": "Reports",
      "/settings": "Settings",
      "/resumeRepo": "Resume Repo",
      "/offer-approval": "Offer Approval",
      "/job-offer-tableview": "Offer Approval",
      "/interview": "Interview",
      "/profile": "Profile",
      "/newRequestToHire":"Manpower Requisition Form",
      "/JDCreation":"Manpower Requisition Form",
      "/newrth": "Manpower Requisition Form",
      "/request-hire": "Manpower Requisition Form",
    };

    const subTitleMap: { [key: string]: string } = {
      "/dashboard": "Manpower Requisition Form",
      "/masters": "Master",
      "/reports": "Reports",
      "/settings": "Settings",
      "/resumeRepo": "Resume Repo",
      "/offer-approval": "Offer Approval",
      "/job-offer-tableview": "Offer Approval",
      "/interview": "Interview",
      "/profile": "Profile",
    "/newRequestToHire":"Manpower Requisition Form",
    "/JDCreation":"Manpower Requisition Form",
      "/newrth": "Manpower Requisition Form",
      "/request-hire": "Manpower Requisition Form",
    };

    for (const [path, title] of Object.entries(titleMap)) {
      if (location.pathname.includes(path)) {
        matchedTitle = title;
        break;
      }
    }

    for (const [path, subTitle] of Object.entries(subTitleMap)) {
      if (location.pathname.includes(path)) {
        matchedSubTitle = subTitle;
        break;
      }
    }
    if (
      location.pathname.includes("DashBoardRTH") ||
      location.pathname.includes("DashBoardAH") ||
      location.pathname.includes("DashBoardR") ||
      location.pathname.includes("DashBoardTAH") ||
      location.pathname.includes("DashBoardRL")
    ) {
      matchedTitle = "Dashboard";
      matchedSubTitle = "Dashboard";
    }
    setTitle(matchedTitle);
    setSubTitle(matchedSubTitle);
  }, [location.pathname]);


  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: '#f0f4ff', height: '100vh'}}>
      {!hideSidebarAndHeader && (
        <Box sx={{ width: 'auto', flexShrink: 0 }}>
          <Sidebar />
        </Box>
      )}
      <Box sx={{ flexDirection: 'column', flexGrow: 1, overflowY: "hidden", height: '100vh'}}>
        {!hideSidebarAndHeader && <HeaderBar title={title} subTitle={subTitle}/>}
        <Box sx={{ overflow: "auto", height: hideSidebarAndHeader ? "100%" : "calc(100vh - 64px)"}}>
          <Routes>
            {routesData.map((route: RouteConfig, index: number) => {
              const Component = componentMap[route.component];
              if (route.component === "UserMasterTable") {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={<UserMasterTable setSubTitle={setSubTitle} />}
                  />
                );
              }
              return <Route key={index} path={route.path} element={<Component />} />;
            })}
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default AppRoute;
