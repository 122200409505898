import React, { useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../../redux/store";
import { DataGrid, GridColDef, GridActionsCellItem, GridPaginationModel } from '@mui/x-data-grid';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, InputBase, } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit'
import { toast } from "react-toastify";
import deleteIcon from '../../../../assets/images/deleteicon.svg'
import AddBusinessForm, { BusinessData } from "./AddBusiness";
import EditBusinessForm from "./EditBusiness";
import { deleteBusiness, fetchBusiness } from "../../../../redux/features/businessSlice";

const BusinessTable: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<BusinessData | null>(null);
    const [selectedUserId, setSelectedUserId] = React.useState<string | null>(null);
    const [editOpen, setEditOpen] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({ page: 0, pageSize: 25 });
    const { users } = useSelector((state: RootState) => state.business);
    const [searchQuery, setSearchQuery] = useState("");

    const handleButtonClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEditClick = (user: BusinessData) => {
        setSelectedUser(user);
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
        setSelectedUser(null);
    };

    const handleDeleteClick = (businessId?: string) => {
        setSelectedUserId(businessId || '');
        setOpenDialog(true);
    };

    const handleConfirmDelete = async () => {
        if (selectedUserId) {
            try {
                await dispatch(deleteBusiness(selectedUserId));
                await dispatch(fetchBusiness());
                toast.success("Business Deleted Successfully");
            } catch (error) {
                toast.error("Failed to delete the Business. Please try again.");
            }
        } else {
            toast.error("Business ID not found!");
        } setOpenDialog(false);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedUserId(null);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
        setPaginationModel({ ...paginationModel, page: 0 });
    };

    const filteredRows = useMemo(() => {
        if (!users?.data) return [];
        const regex = new RegExp(`^${searchQuery}`, 'i'); 
        return users.data.filter((user) => regex.test(user.businessName));
    }, [users?.data, searchQuery]);

    const processedRows = useMemo(() => {
        // if (!users?.data) return [];
        const startIndex = paginationModel.page * paginationModel.pageSize;
        return filteredRows.slice(startIndex, startIndex + paginationModel.pageSize).map((row, index) => ({
            ...row,
            businessId: row.businessId || `temp-id-${index}`,
            serialNumber: startIndex + index + 1,
        }));
    }, [paginationModel.page, paginationModel.pageSize, filteredRows]);

    const columns: GridColDef[] = [
        {
            field: 'serialNumber',
            headerName: 'SI.No',
            flex: 0.01,
            sortable: false,
        },
        {
            field: 'businessName',
            headerName: 'Business Name',
            flex: 2.5,
        },
        {
            field: 'actions',
            headerName: 'Action',
            type: 'actions',
            flex: 0.2,
            getActions: (params) => [
                <GridActionsCellItem icon={<EditIcon sx={{ fontSize: "12px" }} onClick={() => handleEditClick(params.row)} />} label="Edit" />,
                <GridActionsCellItem icon={<img src={deleteIcon} alt="Delete" style={{ width: '10px', height: '10px' }} />} label="Delete" onClick={() => handleDeleteClick(params.row.businessId ?? '')} />,
            ],
        },
    ];

    return (
        <>
            <Box display="flex" alignItems="center" mt={2} justifyContent="space-between">
                <InputBase
                    placeholder="Search Business..."
                    value={searchQuery}
                    onChange={handleSearchChange}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                        </InputAdornment>
                    }
                    sx={{
                        border: '1px solid #ccc',
                        borderRadius: 2,
                        padding: 0,
                        backgroundColor: 'white',
                        width: '180px',
                        height: '36px',
                        fontSize: 12,
                    }}
                />
                <Box display="flex" alignItems="center" gap={2}>
                    <Button variant="contained" onClick={handleButtonClick} sx={{ textTransform: "none", backgroundColor: '#1677FF', fontSize: '12px', borderRadius: '8px' }}>
                        + Add Business
                    </Button>
                </Box>

                {/* Add Business */}
                <AddBusinessForm open={open} onClose={handleClose} />
            </Box>
            <Box sx={{ height: '80vh', marginTop: 2, marginBottom: 2, overflow: 'auto' }}>
                <DataGrid
                    rows={processedRows}
                    columns={columns}
                    getRowId={(row) => row.businessId || row.id}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(model) => setPaginationModel(model)}
                    pageSizeOptions={[25, 50, 100]}
                    rowCount={users?.data?.length || 0}
                    paginationMode="server"
                    disableColumnMenu
                    disableColumnSorting
                    rowHeight={40}
                    sx={{
                        minWidth: '600px',
                        '& .MuiDataGrid-cell': {
                            fontSize: '10px',
                            fontWeight: '500',
                            backgroundColor: '#FFFFFF',
                            padding: '0 8px',
                            borderRight: '0.5px solid #B5B5B5'
                        },
                        '& .MuiDataGrid-columnHeader': {
                            fontSize: '10px',
                            fontWeight: '500',
                            backgroundColor: '#F9F9F9',
                            padding: '0 8px',
                            borderRight: '0.5px solid #B5B5B5',
                            height: '40px !important',
                            lineHeight: '40px !important',
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            minHeight: '40px !important',
                            maxHeight: '40px !important',
                        },
                        '& .MuiDataGrid-columnHeader:last-child, & .MuiDataGrid-cell:last-child': {
                            borderRight: 'none',
                        },
                        '& .MuiDataGrid-columnHeader:nth-last-child(3)': {
                            borderRight: 'none',
                        },
                        '& .MuiDataGrid-columnSeparator': { display: 'none' },
                        '& .MuiDataGrid-iconButtonContainer': { visibility: 'visible' },
                        '& .MuiDataGrid-sortIcon': { opacity: 'inherit !important', color: '#CDCDCD', fontSize: '12px' },
                        '& .MuiDataGrid-selectedRowCount': { display: 'none' },
                        '& .MuiDataGrid-row:hover': { cursor: 'pointer' },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus': { outline: 'none' },
                    }}
                />
            </Box>
            <Dialog
                open={openDialog}
                onClose={handleCloseDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete this business?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary" sx={{ textTransform: "none", fontSize: '12px' }} variant="outlined">Cancel</Button>
                    <Button onClick={handleConfirmDelete} sx={{ textTransform: "none", fontSize: '12px' }} variant="contained" >Yes</Button>
                </DialogActions>
            </Dialog>

            {/* Edit Business */}
            {selectedUser && (
                <EditBusinessForm
                    open={editOpen}
                    onClose={handleEditClose}
                    user={selectedUser}
                />
            )}
        </>
    );
};

export default BusinessTable;
