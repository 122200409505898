import React from 'react';
import { Paper, Typography, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Person from '../../../../../assets/images/Person.svg';
import ArrowDrop from '../../../../../assets/images/arrowIconDashBoard.svg';
import { RootState } from '../../../../../redux/store';
import { useSelector } from 'react-redux';

const WelcomeSection: React.FC = () => {
  const navigate = useNavigate();
  const data = useSelector((state: RootState) => state.profile.data);
  const firstName = data?.data?.firstName || 'User';
  const lastName = data?.data?.lastName || '';

  const { permissionsList, roleName } = (() => {
    try {
      const userItem = localStorage.getItem("user");
      return userItem
        ? JSON.parse(userItem)
        : { permissionsList: {}, roleName: '' };
    } catch (error) {
      console.error("Error parsing user data:", error);
      return { permissionsList: {}, roleName: '' };
    }
  })();
console.log('roleName', roleName)
  const handleButtonClick = () => {
    navigate('/newrth');
  };

  return (
    <Paper
      sx={{
        background: '#36BDC9',
        borderRadius: '6px',
        color: 'white',
        position: 'relative',
        height: '200px',
        backgroundImage: `url(${ArrowDrop})`,
        backgroundSize: 'contain',
        backgroundPosition: 'right bottom',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
      }}
    >
      <Grid item p={2}>
        <Typography sx={{ fontWeight: '600', fontSize: '20px', marginBottom: '2px' }}>
          Welcome, {firstName} {lastName}!
        </Typography>
        <Typography sx={{ fontWeight: '100', fontSize: '10px', marginTop: '2px' }}>
          You can request new hires for your team.
        </Typography>
      </Grid>
      {permissionsList.mrfUsers &&
        <Grid sx={{ display: 'flex', alignItems: 'center', paddingLeft: '16px' }}>
          <Button
            variant="contained"
            onClick={handleButtonClick}
            sx={{
              backgroundColor: '#fff',
              color: '#36CFC9',
              textTransform: 'none',
              borderRadius: '6px',
              '&:hover': { backgroundColor: '#fff', color: '#36CFC9' },
              padding: '4px 8px',
              fontSize: '10px',
            }}
          >
            New Manpower Requisition Form (MRF)
          </Button>
        </Grid>
      }
      <Grid component="img" src={Person} alt="Person Icon" sx={{ position: 'absolute', bottom: 0, right: 0, maxWidth: '160px', }} />
    </Paper>
  );
};

export default WelcomeSection;
