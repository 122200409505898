import React from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { AppDispatch, RootState } from '../../../../redux/store';
import { setL1Status, setL2Status } from '../../../../redux/features/rthSlice';
// import { fetchJobs } from '../../../../redux/features/jobcardSlice';
import ApprovalActions from '../jobApprovel';
import { Job } from '../../commoncard/types';
import { toast } from "react-toastify";
import { getOverAllRthAPIData } from '../../../../Crud/card';
import { overallRthUpdate } from '../../../../redux/features/overallRthSlice';
import { useSelector } from 'react-redux';

interface RTHJobCardProps {
  job?: Job | null | undefined;
  teamHeadFilter?:any;
  onDataUpdate?: () => void;
}




const OverallDetail: React.FC<RTHJobCardProps> = ({ job,teamHeadFilter, onDataUpdate }) => {

    
      
  const dispatch = useDispatch<AppDispatch>();
  const approvedStatus = useSelector((state:RootState)=>state.overallRth.approved)
const userIds = localStorage?.getItem("userId")??"";

const handleApproveL1 = async (jobId?: string) => {
  try {
    await setL1Status({ rthId: jobId, status: "Approved" });
    toast.success("Successfully Approved");
    await getOverAllRthAPIData(userIds, teamHeadFilter);
    await dispatch(overallRthUpdate(!approvedStatus));
    onDataUpdate?.(); // Trigger parent component refresh
  } catch (error) {
    toast.error("Failed Approved");
  }
};

const handleApproveL2 = async (jobId?: string) => {
  try {
    await setL2Status({ rthId: jobId, status: "Approved" });
    toast.success("Successfully Approved");
    await getOverAllRthAPIData(userIds, teamHeadFilter);
    await dispatch(overallRthUpdate(true));
    await dispatch(overallRthUpdate(!approvedStatus));
    onDataUpdate?.(); // Trigger parent component refresh
  } catch (error) {
    toast.error("Failed Approved");
  }
};

const handleDeclineL1 = async (jobId?: string) => {
  try {
    await setL1Status({ rthId: jobId, status: "Declined" });
    await getOverAllRthAPIData(userIds, teamHeadFilter);
    onDataUpdate?.(); // Trigger parent component refresh
  } catch (error) {
    toast.error("Failed to decline");
  }
};

const handleDeclineL2 = async (jobId?: string) => {
  try {
    await setL2Status({ rthId: jobId, status: "Declined" });
    await getOverAllRthAPIData(userIds, teamHeadFilter);
    onDataUpdate?.(); // Trigger parent component refresh
  } catch (error) {
    toast.error("Failed to decline");
  }
};
// console.warn("jobId=======?",job?.status ==="Declined")
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 1 }}>
      <Box sx={{ width: "100%" }}>
        {job?.l1Status === "Approved" && job?.l2Status === "Approved" ? (
          <Box p={1}>
            {/* <Typography variant="h6" fontSize={"100%"} sx={{ color: 'green' }}>Accepted</Typography> */}
          </Box>
        ) :job?.l1Approval?.userId === userIds && job?.l1Status === "Approved" && job?.l2Status === "Pending" ? (
          <Box p={1}>
            
          </Box>) 
          :  job?.status !=="Declined" &&(            
          <ApprovalActions
          data={job}
          onDataUpdate={onDataUpdate} 
            onApprove={(event: any) => {
              event.stopPropagation();
              if (job?.l1Status === "Pending") {
                handleApproveL1(job?.rthId);
              }
              if (job?.l1Status === "Approved") {
                handleApproveL2(job?.rthId);
              }
            }}
            onDecline={(event: any) => {
              event.stopPropagation();
              if (job?.l1Status === "Pending") {
                handleDeclineL1(job?.rthId);
              }
              if (job?.l1Status === "Approved") {
                handleDeclineL2(job?.rthId);
              }
            }}
          />
        )}
        {
          job?.status ==="Declined" &&
         <Box p={1}>
            {/* <Typography variant="h6" fontSize={"100%"} sx={{ color: 'red' }}>Declined</Typography> */}
          </Box>
        }

      </Box>
      </Box>
    
  );
};

export default OverallDetail;