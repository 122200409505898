import React from "react";
import Switch from "@mui/material/Switch";

interface SwitchWithLabelProps {
  label: string;
  imageSrc: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  date: string;
}

const SwitchWithLabel = ({
  label,
  imageSrc,
  checked,
  onChange,
  date,
}: SwitchWithLabelProps) => {
let istDate;
  try {
    if (!date || isNaN(Date.parse(date))) {
      throw new Error("Invalid date format");
    }
    const utcDate = new Date(date.includes("Z") || date.includes("+") ? date : date + "Z");
     istDate = new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "Asia/Kolkata", 
    }).format(utcDate);
  } catch (error) {
    console.error("Error:", error);
  }
  
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Switch
          checked={checked}
          onChange={onChange}
          inputProps={{ "aria-label": label }}
          size="small"
        />
        <img
          src={imageSrc}
          alt={label}
          style={{ width: "20px", height: "20px" }}
        />
        <span style={{ fontWeight: "bold" }}>{label}</span>
        {checked && istDate !== "Invalid Date" && <span>{istDate}</span>}
      </div>
    </div>
  );
};

export default SwitchWithLabel;
