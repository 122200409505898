import { useEffect, useState } from 'react';
import { Box, Paper, Typography, Button, Rating, Tabs, Tab, Tooltip, TextField } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import InterviewEvaluationFormLogo from '../../../../../assets/images/InterviewEvaluationFormLogo.svg';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import {  useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getCandidateByCandidateId } from '../../../../../redux/features/resume';
import { getCandidateDetails, getInterviewDetailsByInterviewId } from '../../../../../redux/features/interviewSchedule';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { BASE_COPY_URL } from '../../../../../constants/Crudurl';
 
  export const InterviewReviewForm = () => {
  const [javaApiEvaluation, setJavaApiEvaluation] = useState('');
  const [interviewScore, setInterviewScore] = useState<number | undefined>(undefined);
  const [recommendation, setRecommendation] = useState<string | undefined>(undefined);
  const [tabIndex, setTabIndex] = useState(0);
  const[interviewData,setInterviewData]=useState<any>([]);
  const Navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState(null);
  const state=useAppSelector((state:any)=>state.resume.candidate);
  const interview=useAppSelector((state:any)=>state.interviewSchedule.interviewDetailsByInterviewId);
  const{candidateId, interviewId, positionName} = useParams();
  const { interviewRound } = useParams<{ interviewRound ?: string }>();
  const dispatch=useAppDispatch();
  const [feedbackData,setFeedbackData]=useState<any>([]);
  const location=useLocation();
  const path=location.pathname.includes('interviewFeedBackForm');
  const candidateDetails=useAppSelector((state:any)=>state.interviewSchedule.candidateDetails);
  const [tooltipText, setTooltipText] = useState('Share');
  const viewPath=location.pathname.includes('ViewFeedback');
 
  const handleClick = () => {
    // Construct the URL
    const copyUrl = `${BASE_COPY_URL}/interviewReviewForm/${candidateId}/${interviewId}/${positionName}/ViewFeedback`;
   
    // Copy the URL to the clipboard
    navigator.clipboard.writeText(copyUrl)
      .then(() => {
        // Update tooltip to "Link Copied"
        setTooltipText('Link Copied');
        setTimeout(() => {
          setTooltipText('Share'); // Revert tooltip text after 2 seconds
        }, 2000);
      })
      .catch(() => {
        // Handle error (optional)
        setTooltipText('Failed to copy');
        setTimeout(() => {
          setTooltipText('Share');
        }, 2000);
      });
  };
 
 
  const tabsData = [
    interviewData?.interviewDetL1?.feedbackForm && { label: interviewData?.interviewDetL1?.interviewRound, dataKey: 'interviewDetL1' },
    interviewData?.interviewDetL2?.feedbackForm && { label: interviewData?.interviewDetL2?.interviewRound, dataKey: 'interviewDetL2' },
    interviewData?.interviewDetL3?.feedbackForm && { label: interviewData?.interviewDetL3?.interviewRound, dataKey: 'interviewDetL3' },
  ].filter(Boolean);
     
  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number) => {
    setTabIndex(newIndex);
   
    switch (newIndex) {
      case 0:
        console.log('Setting feedbackData for interviewDetL1');
        setFeedbackData(interviewData?.interviewDetL1);
        break;
      case 1:
        console.log('Setting feedbackData for interviewDetL2');
        setFeedbackData(interviewData?.interviewDetL2);
        break;
      case 2:
        console.log('Setting feedbackData for interviewDetL3');
        setFeedbackData(interviewData?.interviewDetL3);
        break;
      default:
        console.log('Invalid tab selected');
        setFeedbackData(null);
        break;
    }
  };
 
  // const getEmoji = (label: string) => {
  //   switch (label) {
  //     case 'Low':
  //       return '🙁'; // Sad face
  //     case 'Fair':
  //       return '☹️'; // Neutral face
  //     case 'Average':
  //       return '🙂'; // Relaxed face
  //     case 'Good':
  //       return '😀'; // Smiley face
  //     case 'Excellent':
  //       return '🤩'; // Heart eyes
  //     default:
  //       return '';
  //   }
  // };
 
  const handleNavigate = () =>{
    Navigate(-1)
  }
 
  const formatTimeRange = (from: string, to: string) => {
    const formatTime = (time: string) => {
      const date = new Date(time);
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format
      return `${formattedHours}:${minutes}${period}`;
    };
 
    return `${formatTime(from)} To ${formatTime(to)}`;
  };
 
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
   
    // Get the day, month, and year
    const day = date.getDate().toString().padStart(2, '0'); // Ensure two digits for day
    const month = date.toLocaleString('default', { month: 'short' }); // Get abbreviated month
    const year = date.getFullYear();
 
    return `${day} ${month} ${year}`;
  };
 
 
  useEffect(()=>{
    dispatch(getCandidateByCandidateId(candidateId));
    dispatch(getInterviewDetailsByInterviewId(interviewId));
  },[candidateId, interviewId, dispatch])
 
  useEffect(()=>{
   if(interviewData){
    let roundData=null;
 
    if(interviewRound==='interviewDetL1'){
      roundData=interviewData?.interviewDetL1?.feedbackForm;
    } else if(interviewRound==='interviewDetL2'){
      roundData=interviewData?.interviewDetL2?.feedbackForm;
    } else if(interviewRound==='interviewDetL3'){
      roundData=interviewData?.interviewDetL3?.feedbackForm;
    }
    if (roundData) {
      // If roundData is found, log it to the console
      setFeedbackData(roundData);
    } else {
      console.log('No matching interview round found');
    }
   }
 
  },[interviewData,interviewRound])
 
 
  useEffect(()=>{
    if(feedbackData){
      setSelectedButton(feedbackData?.feedbackForm?.windowViolation);
      setJavaApiEvaluation(feedbackData?.feedbackForm?.interviewerComments||'');
      setInterviewScore(feedbackData?.feedbackForm?.interviewScore);
      setRecommendation(feedbackData?.feedbackForm?.recommended)
    }
  },[feedbackData])
 
  useEffect(()=>{
    if(interviewData && tabIndex===0)
    setFeedbackData(interviewData?.interviewDetL1);
  },[interviewData, tabIndex])
 
  useEffect(()=>{
    if(interview){
      setInterviewData(interview);
    }
    },[interview])
 
  useEffect(()=>{
    if(path){
      dispatch(getCandidateDetails(candidateId))
    }
  },[path,candidateId,dispatch])
 
  useEffect(()=>{
    if(path){
      if(candidateDetails){
        setInterviewData(candidateDetails?.interview)
      }
    }
  },[candidateDetails,path])
 
    return (
    <>
   
    <Box sx={{ bgcolor:'#F9FAFB', overflowY:'auto', width:"100vw" , height:'100vh'}}>
    <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center',  mt:'50px' }}>
      {!viewPath&&
      <KeyboardBackspaceRoundedIcon onClick={handleNavigate} sx={{position:'fixed', left:'30px', top:'30px', cursor:'pointer', transition: 'transform 0.2s ease-in-out', '&:hover': { transform: 'scale(1.1)'}}}/>
      }
      {!viewPath &&
      <Tooltip title={tooltipText} arrow>
      <Button
      sx={{ position:'fixed', right:'50px', top:'130px',zIndex:11, minWidth:'100px', bgcolor: '#1677FF', color: 'white', borderRadius: '8px', textTransform: 'none', display: 'flex', alignItems: 'center', gap: '8px', '&:hover': { bgcolor: '#1677FF' } }}
      onClick={handleClick}>
      <ShareOutlinedIcon sx={{ color: 'white', fontSize:'16px' }} />
      Share
      </Button>
      </Tooltip>
       }
      {/* Header Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
        <img src={InterviewEvaluationFormLogo} style={{ width: '40px' }} alt='InterviewEvaluationFormLogo' />
        <Typography sx={{ fontSize: '26px', fontWeight: 700, mt: '30px' }}>{positionName||candidateDetails?.mrfName||'N/A'}</Typography>
        <Typography sx={{ fontSize: '12px', color: '#919EAB', fontWeight: 500, mt: '12px' }}>Interview Evaluation Form</Typography>
      </Box>    
     
      <Box sx={{bgcolor:'#F9FAFB', width:'80%', zIndex:10, ml:'120px', mt:"20px"}}>
      <Paper elevation={0} sx={{ backgroundColor: 'transparent' }}>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        aria-label="Interview tabs"
        sx={{ ml:'100px' }} // Dynamic margin-left based on drawer status
        TabIndicatorProps={{
          style: {
            width: '20px',
            marginLeft: `${tabIndex === 0 ? '45px' : '36px'}`, // Adjusting indicator based on selected tab
          },
        }}// Adjust this as per your design needs
      >
        {tabsData.map((tab: any, index: number) => (
          <Tab
            key={index}
            label={tab.label}
            id={`tab-${index}`}
            aria-controls={`tabpanel-${index}`}
            sx={{ fontSize: '13px', textTransform: 'none' }}
          />
        ))}
      </Tabs>
      </Paper>
      </Box>
 
       <Box sx={{height:430, overflowY:'auto', '&::-webkit-scrollbar': {
      width: '0px', // Set the scrollbar width
      height: '0px', // Set the scrollbar height (if horizontal)
      }}}>
      {/* Candidate & Interview Details Section */}
      <Paper sx={{ width: '840px', mt: '20px', p: 3, mb: 4, boxShadow:'none' }}>
        <Box sx={{display:"flex", flexDirection:'row', justifyContent:'space-between'}}>
          <Box sx={{ display:"flex", flexDirection:'row' }}>
            <Box sx={{ ml:'30px', mr:'60px'}}>
              <Typography sx={{ color: '#919EAB', mb:'10px', textWrap:'nowrap' }}>Candidate Name</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px', textWrap:'nowrap' }}>Interviewer Name</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px', textWrap:'nowrap' }}>Job Title</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px', textWrap:'nowrap' }}>Interview Round</Typography>
            </Box>
 
            <Box>
            <Tooltip
              title={`${state?.firstName} ${state?.lastName}`}
              disableHoverListener={`${state?.firstName} ${state?.lastName}`.length <= 14}
            >
              <Typography sx={{ mb: '10px' }}>
                {`${state?.firstName} ${state?.lastName}`.length > 14
                  ? `${state?.firstName} ${state?.lastName}`.substring(0, 14) + '...'
                  : `${state?.firstName} ${state?.lastName}`}
              </Typography>
            </Tooltip>
 
            <Tooltip
              title={feedbackData?.interviewer?.userName || 'Not Available'}
              disableHoverListener={(feedbackData?.interviewer?.userName || '').length <= 14}
            >
              <Typography sx={{ mb: '10px' }}>
                {(feedbackData?.interviewer?.userName || 'Not Available').length > 14
                  ? (feedbackData?.interviewer?.userName || 'Not Available').substring(0, 14) + '...'
                  : feedbackData?.interviewer?.userName || 'Not Available'}
              </Typography>
            </Tooltip>
 
            <Tooltip
              title={positionName || candidateDetails?.mrfName || 'N/A'}
              disableHoverListener={(positionName || candidateDetails?.mrfName || 'N/A').length <= 14}
            >
              <Typography sx={{ mb: '10px' }}>
                {(positionName || candidateDetails?.mrfName || 'N/A').length > 14
                  ? (positionName || candidateDetails?.mrfName || 'N/A').substring(0, 14) + '...'
                  : positionName || candidateDetails?.mrfName || 'N/A'}
              </Typography>
            </Tooltip>
 
            <Tooltip
              title={feedbackData?.interviewRound || 'N/A'}
              disableHoverListener={(feedbackData?.interviewRound || 'N/A').length <= 14}
            >
              <Typography sx={{ mb: '10px' }}>
                {(feedbackData?.interviewRound || 'N/A').length > 14
                  ? (feedbackData?.interviewRound || 'N/A').substring(0, 14) + '...'
                  : feedbackData?.interviewRound || 'N/A'}
              </Typography>
            </Tooltip>
            </Box>          
          </Box>
 
          <Box sx={{ display:"flex", flexDirection:'row' }}>
            <Box sx={{ mr:'60px'}}>
              <Typography sx={{ color: '#919EAB', mb:'10px', textWrap:'nowrap' }}>Mode of Interview</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px', textWrap:'nowrap' }}>Candidate Email ID</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px', textWrap:'nowrap' }}>Interview Date</Typography>
              <Typography sx={{ color: '#919EAB', mb:'10px', textWrap:'nowrap' }}>Interview Time</Typography>
            </Box>
 
          <Box sx={{ mr: '30px' }}>
            <Tooltip
              title={feedbackData?.interviewMode || 'N/A'}
              disableHoverListener={(feedbackData?.interviewMode || 'N/A').length <= 14}
            >
              <Typography sx={{ mb: '10px' }}>
                {(feedbackData?.interviewMode || 'N/A').length > 14
                  ? (feedbackData?.interviewMode || 'N/A').substring(0, 14) + '...'
                  : feedbackData?.interviewMode || 'N/A'}
              </Typography>
            </Tooltip>
 
            <Tooltip
              title={state?.emailAddress || 'Null'}
              disableHoverListener={(state?.emailAddress || 'Null').length <= 14}
            >
              <Typography sx={{ mb: '10px' }}>
                {(state?.emailAddress || 'Null').length > 14
                  ? (state?.emailAddress || 'Null').substring(0, 14) + '...'
                  : state?.emailAddress || 'Null'}
              </Typography>
            </Tooltip>
 
            <Tooltip
              title={formatDate(feedbackData?.interviewDate) || 'N/A'}
              disableHoverListener={(formatDate(feedbackData?.interviewDate) || 'N/A').length <= 14}
            >
              <Typography sx={{ mb: '10px' }}>
                {(formatDate(feedbackData?.interviewDate) || 'N/A').length > 14
                  ? (formatDate(feedbackData?.interviewDate) || 'N/A').substring(0, 14) + '...'
                  : formatDate(feedbackData?.interviewDate) || 'N/A'}
              </Typography>
            </Tooltip>
 
            <Tooltip
              title={formatTimeRange(feedbackData?.from, feedbackData?.to) || 'N/A'}
              disableHoverListener={(formatTimeRange(feedbackData?.from, feedbackData?.to) || 'N/A').length <= 14}
            >
              <Typography sx={{ mb: '10px' }}>
                {(formatTimeRange(feedbackData?.from, feedbackData?.to) || 'N/A').length > 14
                  ? (formatTimeRange(feedbackData?.from, feedbackData?.to) || 'N/A').substring(0, 14) + '...'
                  : formatTimeRange(feedbackData?.from, feedbackData?.to) || 'N/A'}
              </Typography>
            </Tooltip>
          </Box>
 
          </Box>
        </Box>
      </Paper>
 
      {/* Technical Evaluation Section */}
      <Paper sx={{ width: '840px', p: 3, pb:0, boxShadow:'none' }}>
        <Box sx={{display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', mb:'40px', position:'sticky', top:0, backgroundColor: 'white', zIndex: 1}}>
          <Box>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '6px' }}>Technical Evaluation (Skills)</Typography>
           </Box>
        </Box>
 
        {/* Skill 1: Java API */}
        {feedbackData?.feedbackForm?.technicalEvalutionList?.map((skill:any, index:any) => (
        <Box sx={{ mb: 4, mt: 3 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 600, mb: "10px"}}>{skill?.technologyName}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', borderBottom:'1px dashed #DBDBDB', pb:'10px'}}>
          <Typography variant="body2" sx={{ textWrap: 'nowrap'}}>Rating</Typography>
          <Rating
            name={`${skill?.technologyName}-rating`}
            value={skill?.rating}
            precision={1}
            sx={{ ml: '120px' }}
            icon={<StarIcon />}
            readOnly
          />
          <Typography sx={{ ml: 1 }}>{skill?.rating}/5</Typography>
        </Box>
 
 
        {/* Criteria Evaluation */}
        <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%' }}>    
        <Box sx={{display:'flex', flexDirection:'row', }}>
        <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Experience</Typography>
        <Box sx={{ display: 'flex', gap: '10px', ml:'94.5px' }}>
          {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
            <Button
              key={label}
              variant="outlined"
              size="small"
              sx={{                      
                color: skill?.experience === label ? '#2F54EB' : '#919EAB',
                borderColor: skill?.experience === label ? '#2F54EB' : '#919EAB',
                textTransform:'none',
                ":hover":{ borderColor: skill?.experience === label ? '#2F54EB' : '#919EAB', color: skill?.experience === label ? '#2F54EB' : '#919EAB' }
              }}
            >
              {label}
            </Button>
          ))}
          </Box>
        </Box>
        <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
        <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill?.experienceEmoji}</Typography>
        </Box>
        </Box>
      </Box>
 
      {/* Technical Concepts */}
      <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Technical Concepts</Typography>
            <Box sx={{ display: 'flex', gap: '10px', ml:'39px' }}>
              {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
                <Button
                  key={label}
                  variant="outlined"
                  size="small"
                  sx={{                      
                    color: skill?.technicalConcepts=== label ? '#2F54EB' : '#919EAB',
                    borderColor: skill?.technicalConcepts === label ? '#2F54EB' : '#919EAB',
                    textTransform:'none',
                    ":hover": { borderColor: skill?.technicalConcepts === label ? '#2F54EB' : '#919EAB', color: skill?.technicalConcepts === label ? '#2F54EB' : '#919EAB' }
                  }}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
          <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill?.technicalConceptsEmoji}</Typography>
          </Box>
        </Box>
      </Box>
 
      {/* Logic */}
      <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Logic</Typography>
            <Box sx={{ display: 'flex', gap: '10px', ml:'129px' }}>
              {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
                <Button
                  key={label}
                  variant="outlined"
                  size="small"
                  sx={{                      
                    color: skill?.logics === label ? '#2F54EB' : '#919EAB',
                    borderColor: skill?.logics === label ? '#2F54EB' : '#919EAB',
                    textTransform:'none',
                    ":hover": { borderColor: skill?.logics === label ? '#2F54EB' : '#919EAB', color: skill?.logics === label ? '#2F54EB' : '#919EAB' }
                  }}
                >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
          <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill?.logicEmoji}</Typography>
          </Box>
        </Box>
      </Box>
 
      {/* Development Tools */}
      <Box sx={{display:'flex', borderBottom:'1px dashed #DBDBDB', pb:'10px', pt:'10px'}}>
        <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%'}}>
          <Box sx={{display:'flex', flexDirection:'row'}}>
            <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Development Tools</Typography>
            <Box sx={{ display: 'flex', gap: '10px', ml:'42px' }}>
              {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
                <Button
                  key={label}
                  variant="outlined"
                  size="small"
                  sx={{                      
                    color: skill?.developmentTools === label ? '#2F54EB' : '#919EAB',
                    borderColor: skill?.developmentTools === label ? '#2F54EB' : '#919EAB',
                    textTransform:'none',
                    ":hover": { borderColor: skill?.developmentTools === label ? '#2F54EB' : '#919EAB', color: skill?.developmentTools === label ? '#2F54EB' : '#919EAB' }
                  }}
                    >
                  {label}
                </Button>
              ))}
            </Box>
          </Box>
          <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
          <Typography sx={{ fontSize: '20px', ml: '20px' }}>{skill?.developmentToolsEmoji}</Typography>
          </Box>
        </Box>
      </Box>
 
      <Box sx={{display:'flex',flexDirection:'row'}}>
       <Typography variant="body2" sx={{ minWidth:'180px', textWrap: 'nowrap', pt:'10px', pb:'10px',mt:'14px'}}>
             Remarks 
           </Typography> 
       <TextField fullWidth value={skill?.remarks} sx={{mt:'10px'}}
       InputProps={{
        readOnly: true,
      }} />
       </Box>
        </Box>
        ))}
      </Paper>
 
 
     {/* Non-Technical */}
     <Paper sx={{ width: '840px', mt: '40px', p: 3, pb:0, mb: 4, boxShadow:'none' }}>
      <Box sx={{display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', mb:'40px', position:'sticky', top:0, backgroundColor: 'white', zIndex: 1}}>
          <Box>
            <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '6px' }}>Non-Technical Evaluation</Typography>
          </Box>
      </Box>
       
      {Object.keys(feedbackData?.feedbackForm?.nonTechnicalEvaluationList?.[0] || {}).map((skill: string, index: number) => {

if (skill.toLowerCase() === 'remarks') {
  return null; // Skip rendering this skill
}
  const apiValue = feedbackData?.feedbackForm?.nonTechnicalEvaluationList[0]?.[skill];

  if (skill === 'newSkills') {
    return feedbackData?.feedbackForm?.nonTechnicalEvaluationList[0]?.newSkills.map((newSkillObj: any, newSkillIndex: number) => {
      return Object.keys(newSkillObj).map((newSkill: string) => {
        const newSkillValue = newSkillObj[newSkill] === "string" ? 0 : newSkillObj[newSkill]; // Set to 0 if the value is "string"

        const formatSkillName = (skill: string) => {
          return skill
            .split(/(?=[A-Z])/)
            .join(' ') // Add space between camel case words
            .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize the first letter of each word
            .toLowerCase() // Convert all letters to lowercase before capitalizing the first one
            .replace(/^./, char => char.toUpperCase()); // Ensure the first character is capitalized
        };

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px dashed #DBDBDB', pb: '10px' }} key={`${index}-${newSkillIndex}`}>
            <Typography variant="body2" sx={{ minWidth: '180px', textWrap: 'nowrap', pt: '10px', pb: '10px' }}>
              {formatSkillName(newSkill)}
            </Typography>
            <Rating
              name={`${newSkill}-rating`}
              value={newSkillValue}
              precision={1}
              icon={<StarIcon />}
              readOnly
            />
            <Typography sx={{ ml: 1 }}>
              {newSkillValue}/5
            </Typography>
          </Box>
        );
      });
    });
  }

  const ratingValue = apiValue === "string" ? 0 : apiValue; // Set to 0 if the value is "string"

  const formatSkillName = (skill: string) => {
    return skill
      .split(/(?=[A-Z])/)
      .join(' ') // Add space between camel case words
      .replace(/\b\w/g, char => char.toUpperCase()) // Capitalize the first letter of each word
      .toLowerCase() // Convert all letters to lowercase before capitalizing the first one
      .replace(/^./, char => char.toUpperCase()); // Ensure the first character is capitalized
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px dashed #DBDBDB', pb: '10px' }} key={index}>
      <Typography variant="body2" sx={{ minWidth: '180px', textWrap: 'nowrap', pt: '10px', pb: '10px' }}>
        {formatSkillName(skill)}
      </Typography>
      <Rating
        name={`${skill}-rating`}
        value={ratingValue}
        precision={1}
        icon={<StarIcon />}
        readOnly
      />
      <Typography sx={{ ml: 1 }}>
        {ratingValue}/5
      </Typography>
    </Box>
  );
})}

     <Box sx={{display:'flex',flexDirection:'row'}}>
       <Typography variant="body2" sx={{ minWidth:'180px', textWrap: 'nowrap', pt:'10px', pb:'10px',mt:'14px'}}>
             Remarks 
           </Typography> 
       <TextField fullWidth value={feedbackData?.feedbackForm?.nonTechnicalEvaluationList[0]?.remarks} sx={{mt:'10px'}}
       InputProps={{
        readOnly: true,
      }} />
       </Box>
 
      </Paper>  
      <Paper sx={{ width: '840px', p: 3, boxShadow:'none' }}>
       
       <Box sx={{display:'flex', flexDirection:"row", justifyContent:'space-between', alignItems:'center', position:'sticky', top:0, backgroundColor: 'white', zIndex: 1}}>
       <Box>
       <Typography sx={{ fontSize: '18px', fontWeight: 600, mb: '6px' }}>Others </Typography>
       </Box>
       </Box>
 
       <Box sx={{mt:'20px'}}>
       <Box sx={{display:'flex', flexDirection:'row', alignItems:'center'}}>
       <Typography sx={{fontSize:'14px'}}>Window Violation</Typography>
       <Box>
       <Button variant='outlined' sx={{ml:'60px', textTransform:'none', height:'30px', color: selectedButton === 'yes' ? '#2F54EB' : '#919EAB', borderColor: selectedButton === 'yes' ? '#2F54EB' : '#919EAB', ":hover":{ color:'#2b67ba', border:'1px solid #2b67ba' }}}
      //  onClick={() =>{  if (isInteractionAllowed) handleClick('yes')}}
       >Yes</Button>
       <Button variant='outlined' sx={{ml:'10px', textTransform:'none', height:'30px', color: selectedButton === 'no' ? '#2F54EB' : '#919EAB', borderColor: selectedButton === 'no' ? '#2F54EB' : '#919EAB', ":hover":{ color:'#2b67ba', border:'1px solid #2b67ba' }}}
      //  onClick={() => {  if (isInteractionAllowed) handleClick('no')}}
       >No</Button>
       </Box>
       </Box>
       <Box sx={{display:'flex',flexDirection:'row'}}>
       <Typography variant="body2" sx={{ minWidth:'180px', textWrap: 'nowrap', pt:'10px', pb:'10px',mt:'14px'}}>
             Remarks 
           </Typography> 
       <TextField fullWidth value={feedbackData?.feedbackForm?.remarks} sx={{mt:'10px'}}
       InputProps={{
        readOnly: true,
      }} />
       </Box>
 
       <Box>
       <Box sx={{display:'flex', flexDirection:'row', mt:'20px'}}>
       <Typography sx={{fontSize:'14px',fontWeight:'500'}}>Final Recommendation</Typography>
       </Box>
       </Box>
 
       <Box sx={{display:'flex', flexDirection:'row', justifyContent:'space-between', width:'100%', mt:'20px' }}>    
       <Box sx={{display:'flex', flexDirection:'row', }}>
       <Typography variant="body2" sx={{textWrap:'nowrap', display:'flex', alignItems:'center'}}>Interviewer Comments</Typography>
       <Box sx={{ display: 'flex', gap: '10px', ml:'26.5px' }}>
         {['Low', 'Fair', 'Average', 'Good', 'Excellent'].map((label) => (
           <Button
             key={label}
             variant="outlined"
             size="small"
             sx={{                      
               color: javaApiEvaluation === label ? '#2F54EB' : '#919EAB',
               borderColor: javaApiEvaluation === label ? '#2F54EB' : '#919EAB',
               textTransform:'none',
               ":hover":{ borderColor: javaApiEvaluation === label ? '#2F54EB' : '#919EAB', color: javaApiEvaluation === label ? '#2F54EB' : '#919EAB' }
             }}
               >
             {label}
           </Button>
         ))}
         </Box>
       </Box>
       {/* <Box sx={{mr:'20px', display:'flex', alignItems:'center'}}>
       <Typography sx={{ fontSize: '20px', ml: '20px' }}>{javaApiEmoji}</Typography>
       </Box> */}
       </Box>
       </Box>  
 
       <Box sx={{display:'flex', flexDirection:'row', alignItems:'center', mt:'20px'}}>  
       <Typography sx={{fontSize:'14px'}}>Interview Score</Typography>  
       <Box sx={{ display: 'flex', gap: '10px', ml: '73px' }}>
       {[...Array(10).keys()].map((score) => (
         <Button
           key={score + 1}
           variant="outlined"
           size="small"
           sx={{
             width: '30px',    // Set width to 15px
             height: '30px',   // Set height to 15px
             p: 0,             // Remove padding
             minWidth: '35px', // Ensure the button maintains the width
             fontSize: '10px', // Set font size to fit the number inside the 15px size
             lineHeight: '1',  // Adjust line height to ensure the text is centered
             color: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB',  // Default to 0 if undefined
             borderColor: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB',
             bgcolor:  (interviewScore ?? 0) >= score + 1 ? '#D8DBBD' : '#none',              
             textTransform: 'none',
             ":hover": { borderColor: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB', color: (interviewScore ?? 0) >= score + 1 ? '#059212' : '#919EAB' }
           }}
          //  onClick={() => {  if (isInteractionAllowed) setInterviewScore(score + 1)}}
         >
           {score + 1}
         </Button>
       ))}
       </Box>
       </Box>
 
     {/* Recommendation */}
       <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: '20px' }}>
       <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>Recommended</Typography>
       <Box sx={{ display: 'flex', gap: '10px', ml: '74.5px' }}>
         {['Hire', 'Move to Next Round', 'Keep in Consideration', 'Needs Improvement', 'Do Not Hire'].map((label) => (
           <Button
             key={label}
             variant="outlined"
             size="small"
             sx={{
               color: recommendation === label ? '#2F54EB' : '#919EAB',
               borderColor: recommendation === label ? '#2F54EB' : '#919EAB',
               textTransform: 'none',
               ":hover": { borderColor: recommendation === label ? '#2F54EB' : '#919EAB', color: recommendation === label ? '#2F54EB' : '#919EAB' }
             }}
            //  onClick={() => { if (isInteractionAllowed)setRecommendation(label)}}
           >
             {label}
           </Button>
         ))}
       </Box>
       </Box>
       </Paper>
       </Box>
    </Box>
    </Box>
  </>  
  );
};
 