import * as React from "react";
import Button from "@mui/material/Button";
import { Tooltip, Typography } from "@mui/material";
 
 
export default function SelectData({
  // handleUpdate,
  // rowData,
  data,
  // options,
  // pageType,
  // fieldName,
}: any) {
  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const open = Boolean(anchorEl);
  const [selectedOption, setSelectedOption] = React.useState(data); // State for selected option
console.log("setSelectedOption",setSelectedOption)
 
const isTruncated = selectedOption?.length > 18;
  const displayText = isTruncated ? `${selectedOption.substring(0, 18)}...` : selectedOption;
 
  return (
    <>
    <Tooltip title={isTruncated ? selectedOption : ''} arrow>
      <Button
        sx={{
          color: '#1677FF',
          textTransform: 'none',
          backgroundColor: selectedOption ? '#1677FF1A' : '',
          fontSize: '12px',
          width: 'auto',
          height: '24px',
          '&:hover': {
           backgroundColor: selectedOption ? '#1677FF1A' : '', // Prevent hover effects
          },
        }}
      >
        <Typography noWrap sx={{fontSize:'12px'}}>{displayText}</Typography>
      </Button>
    </Tooltip>
    </>
  );
}