import { configureStore } from '@reduxjs/toolkit'
import authSlice from '../features/authSlice'
import userReducer from '../features/userSlice'
import profileReducer from '../features/profileSlice'
import passwordReducer from '../features/passwordSlice';
import jobReducer from '../features/jobcardSlice';
import cardReducer from '../features/cardSlice'
import recruiterReducer from '../features/recruiterSlice';
import fileUploadReducer from '../features/resumeUpload';
import allUserSlice from '../features/allUserSlice';
import resumeReducer from '../features/resumeSlice';
import teamHeadFilterReducer from '../features/teamHeadFilterSlice';
import overallRthSlice from '../features/overallRthSlice';
import roleSlice from '../features/roleSlice';
import businessSlice from '../features/businessSlice';
import wtMJobSlice from '../features/wTMJob';
import interviewSlice from '../features/interviewScheduleSlice';
import departmentSlice from '../features/departmentSlice';
import filterSlice from '../features/filterSlice';
import commonSlice from '../features/commonSlice';
import inteviewFilterSlice from '../features/interviewFilterSlice';
import interviewModuleSlice from '../features/interviewModuleSlice';
import resumeInfoFilterSlice from '../features/resumeInfoTabFilter';
import commentsSlice from '../features/commentsSlice';
import recruiterLeadReducer from '../features/recruiterLead'; 
import recruiterSlice from "../features/recruiter";
import positionTitleReducer from "../features/positionTitleSlice";
import businessReducer from "../features/businessDdSlice";
import locationReducer from "../features/locationSlice";
import postedInReducer from "../features/postedInSlice";
import rthStagesReducer from "../features/rthStagesSlice";
import fetchResumeSlice from "../features/fetchResumeSlice";

export const store = configureStore({
  reducer: {
   authLogin : authSlice,
   user: userReducer,
   profile: profileReducer,
   password: passwordReducer,
   teamHeadFilter: teamHeadFilterReducer,
   jobs: jobReducer,
   cards: cardReducer,
   recruiter: recruiterReducer,
   fileUpload:fileUploadReducer,
   allUserLogin:allUserSlice,
   resume:resumeReducer,
   interviewSchedule:interviewSlice,   
   overallRth:overallRthSlice,  
   role: roleSlice,
   wtMJob:wtMJobSlice,
   business: businessSlice,
   interviewDetailSchedule:interviewSlice,
   department: departmentSlice,
   common:commonSlice,
   interviewFilter:inteviewFilterSlice,
   interviewModule: interviewModuleSlice,
   filter:filterSlice,
   resumeInfo:resumeInfoFilterSlice,
   comments:commentsSlice,
   recruiterLead: recruiterLeadReducer,
   recruiterDd:recruiterSlice,
   positionTitle:positionTitleReducer,
   businessDd: businessReducer,
   location: locationReducer,   
   postedIn: postedInReducer,
   rthStages: rthStagesReducer,
   fetchResume:fetchResumeSlice,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch