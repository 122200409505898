import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Button, Paper, Tooltip, IconButton, Typography } from '@mui/material';
import { TemplateComponent } from './templateComponent';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { styled } from '@mui/material/styles';
import CrossIcon from '@mui/icons-material/Close';
import docIcon from '../../../../../assets/images/doc 1.svg';
import pdfIcon from '../../../../../assets/images/pdf 1.svg';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '../../../../../assets/images/del_Icon.svg'
import { useLocation, useNavigate } from 'react-router-dom';
// import mammoth from 'mammoth';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { toast } from 'react-toastify';
import mammoth from 'mammoth';
 
 
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
 
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
 
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}
 
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
 
export default function JDcreation() {
  const [value, setValue] = React.useState(0);
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [fileIcon, setFileIcon] = React.useState<string | null>(null);
  const [HideSAD, setHideSAD] = React.useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [hoveredIcon, setHoveredIcon] = React.useState(null);
  const[fileContent,setFileContent] = React.useState('');
  const[uploadContent,setUploadContent] = React.useState('');
  // const rthId=id;
  const {name} = location.state||{};
  console.log("fileContent",fileContent)
//  console.warn("state======>",name)
  const handleMouseEnter = (icon:any) => {
    setHoveredIcon(icon);
  };
 
  const handleMouseLeave = () => {
    setHoveredIcon(null);
  };
 
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
 
  const goToTemplateTab = () => {
    setValue((prevValue) => Math.max(0, prevValue - 1)); // Decrease the tab value by 1, ensuring it doesn't go below 0
  };
 
   
GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;
 
  const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    
    setHideSAD(false);
    const file = event.target.files?.[0];
    if (!file) {
      console.error("No file selected");
      return;
    }

    const maxFileSize = 2 * 1024 * 1024; // 2MB limit
    if (file.size > maxFileSize) {
      alert("File size must be less than 2MB");
      event.target.value = "";
      return;
    }
    setValue(0);
    setSelectedFile(file);

    const fileType = file.type;

    if (fileType === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      const reader = new FileReader();
      reader.onload = async function () {
        const arrayBuffer = reader.result;
        if (arrayBuffer instanceof ArrayBuffer) {
          try {
            const result = await mammoth.convertToHtml({ arrayBuffer });
            console.log("DOCX Content (HTML):", result.value);
            setFileContent(result.value);
            setUploadContent(result.value);
            toast.success("File submited Successfully");
          } catch (error) {
            console.error("Error processing DOCX to HTML:", error);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    } else if (fileType === "application/pdf") {
      const reader = new FileReader();
      reader.onload = function () {
        const typedArray = new Uint8Array(reader.result as ArrayBuffer);

        getDocument(typedArray)
        .promise.then(async (pdf: any) => {
          let textContent = "";
     
          for (let pageNum = 1; pageNum <= pdf.numPages; pageNum++) {
            const page = await pdf.getPage(pageNum);
            const text = await page.getTextContent();
     
            const pageText = text.items.map((item: any) => item.str).join(" ");
            textContent += pageText + " ";
          }
     
          console.log("PDF Content:", textContent);
        })
        .catch((error: any) => {
          console.error("Error processing PDF:", error);
        });
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert("Unsupported file type. Please upload a DOCX or PDF file.");
      event.target.value = "";
      setSelectedFile(null);
    }

    if (fileType === "application/pdf") {
      setFileIcon(pdfIcon); // Set PDF icon
    } else {
      setFileIcon(docIcon); // Set Word icon for .doc, .docx, etc.
    }
  };
 
  const handleBack = () => {    
    // navigate('/newRequestToHire')
    navigate(-1)
  }
 
  const truncateText = (text: string | null | undefined, maxLength: number) => {
    if (text == null) {
      return '';
    }
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };
 
  const clearFile = () => {
    setSelectedFile(null);
    setFileIcon(null); 
    setUploadContent('')
  };
 
  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  React.useEffect(() => {
    // Clear file selection when switching away from the first tab
    if (value !== 0) {
      setSelectedFile(null);
      setFileIcon(null);
}
}, [value]);

  return (
    <Box>
      {/* Title and Back, Edit and Delete  */}
      <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between'}}>
      <Box sx={{ display: "flex", flexDirection: 'row' }}>
        <KeyboardBackspaceIcon
          onClick={handleBack}
          onMouseEnter={() => handleMouseEnter('back')}
          onMouseLeave={handleMouseLeave}
          sx={{
            color: '#948F8F',
            pl: '26px',
            pr: "13px",
            fontSize: '24px',
            pt: '2px',
            cursor: 'pointer',
            transition: 'transform 0.5s ease-in-out',
            transform: hoveredIcon === 'back' ? 'scale(1.1)' : 'scale(1)',
          }}
        />
        <Typography sx={{ fontWeight: 700, fontSize: '20px' }}>
          Create a Job for <span style={{ color: '#1677FF', fontWeight: 700, fontSize: '20px' }}>{name}</span>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <EditIcon
          onMouseEnter={() => handleMouseEnter('edit')}
          onMouseLeave={handleMouseLeave}
          sx={{
            color: '#A5A1A1',
            fontSize: '20px',
            pr: '20px',
            cursor: 'pointer',
            transition: 'transform 0.5s ease-in-out',
            transform: hoveredIcon === 'edit' ? 'scale(1.1)' : 'scale(1)',
          }}
        />
        <img
          src={DeleteIcon}
          alt='Delete Icon'
          onMouseEnter={() => handleMouseEnter('delete')}
          onMouseLeave={handleMouseLeave}
          style={{
            width: '14px',
            paddingRight: '25px',
            cursor: 'pointer',
            transition: 'transform 0.5s ease-in-out',
            transform: hoveredIcon === 'delete' ? 'scale(1.1)' : 'scale(1)',
          }}
        />
      </Box>
      </Box>
 
    {/* Paper content */}  
    <Paper sx={{m: '20px', ml: '65px', borderRadius: 0, boxShadow:'none', '&::-webkit-scrollbar': { width: '0px', height: '0px' } }}>
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/*Tab component*/}
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
             
              TabIndicatorProps={{
                style: {
                  width: '24px', // Width of the indicator
                  border: '1px solid #1677FF',
                  borderRadius: '1px',
                  backgroundColor: '#1677FF',
                  marginLeft: '50px',
                  overflowX:'auto'
                },
              }}
            >
              <Tab
                label="Generate JD"
                {...a11yProps(0)}
                sx={{
                  color: value === 0 ? '#1677FF' : '#A9A9A9', // Active tab color
                  textTransform: 'none', // Disable uppercase transformation
                }}
              />
              <Tab
                label="Templates"
                {...a11yProps(1)}
                sx={{
                  color: value === 1 ? '#1677FF' : '#A9A9A9', // Active tab color
                  textTransform: 'none', // Disable uppercase transformation
                }}
              />
              <Tab
                label="Drafts"
                {...a11yProps(2)}
                sx={{
                  color: value === 2 ? '#1677FF' : '#A9A9A9', // Active tab color
                  textTransform: 'none', // Disable uppercase transformation
                }}
              />
            </Tabs>
          </Box>
 
          {/* Upload button and file display */}
          <Box sx={{ mt: 2, mr: 2 }}>
            {!selectedFile ? (
              <Button
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                sx={{
                  border: '1px solid #1677FF80',
                  color: '#1677FF',
                  textTransform: 'none',
                  fontWeight: 700,
                  '&:hover': {
                    border: '1px solid #1677FF80',
                    backgroundColor: 'none',
                  },
                  borderRadius: '8px'
                }}
                endIcon={
                  <Tooltip
                    title={
                      <React.Fragment>
                        <ul style={{ margin: 0, paddingLeft: '16px' }}>
                          <li style={{ marginBottom: '4px' }}>Allowed file type: .pdf, .docx</li>
                          <li>File must be less than: 2MB</li>
                        </ul>
                      </React.Fragment>
                    }
                  >
                    <ErrorOutlineIcon sx={{ fontSize: '10px' }} />
                  </Tooltip>
                }
              >
                Import
                <VisuallyHiddenInput
                  type="file"
                  accept=".doc,.docx"
                  onChange={handleFileUpload}
                />
              </Button>
            ) : (
              <Box sx={{ display: 'flex', alignItems: 'center', border: '1px solid #1677FF80', pl: '14px', borderRadius: '8px', bgcolor:"#f0f5ff",  height:'34px' }}>
                {fileIcon && <img src={fileIcon} alt=".doc/.docx or .pdf " style={{ marginLeft: '2px', marginRight: '5px', width:'16px' }} />}
                <Typography variant="body2" sx={{ color: '#878787' }}>{truncateText(selectedFile.name,6)}{fileIcon===pdfIcon?".pdf":".docx"}</Typography>
                <IconButton aria-label="delete" onClick={clearFile} sx={{'&:hover':{bgcolor: 'transparent'} }}>
                  <CrossIcon sx={{fontSize:'14px', color:'#9fa1a6'}} />
                </IconButton>
                <Button
                  component="label"
                  sx={{color: '#3087ff', textTransform: 'none', fontSize:'10px', fontWeight: 600, height:"10px", mr:'4px'}}
                >
                  Change File
                  <VisuallyHiddenInput
                    type="file"
                    accept=".doc, .docx"
                    onChange={handleFileUpload}
                  />
                </Button>
              </Box>
            )}
          </Box>
        </Box>
 
        <CustomTabPanel value={value} index={0}>
        <TemplateComponent pageType="Generate" buttonDisable={HideSAD} uploadContent={uploadContent} setUploadContent={setUploadContent}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TemplateComponent pageType="Template" goToTemplateTab={goToTemplateTab} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <TemplateComponent pageType="Draft" goToTemplateTab={goToTemplateTab}  />
        </CustomTabPanel>
      </Box>
    </Paper>
 
    </Box>
  );
}
 