import { Box, Button, Paper, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import pdfImage from '../../../../assets/images/pdf 1.svg';
import docImage from '../../../../assets/images/doc 1.svg';
import { getFileUrl } from '../../../../redux/features/resume';
import { useAppDispatch } from '../../../../redux/hooks';
// import '@react-pdf-viewer/core/lib/styles/index.css'; // Import styles


export default function ResumeReUpload({formValues,setFormValues}:any) {
  const [resumeFile,setResumeFile]=useState<File[]>([])
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const dispatch=useAppDispatch();

    const handleBrowseClick = () => {
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
      };
     
      const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
          setResumeFile(Array.from(event.target.files));
        }
      };

      const extractFileNameAndExtension = (url: string) => {
        
        const fileNameWithExtension = url.split('/').pop()?.split('?')[0] || '';
        
        
        const sanitizedFileName = fileNameWithExtension.replace(/^\d+_/, '');
      
        const fileParts = sanitizedFileName.split('.');
        const fileName = fileParts.slice(0, -1).join('.');
        const truncatedFileName = fileName.length > 7 ? fileName.slice(0, 9) + '...' : fileName; 
        const extension = fileParts[fileParts.length - 1]; 
      
        return { fileName, extension, truncatedFileName };
      };
      
      // Usage:
      const { extension, truncatedFileName, fileName } = formValues.resumeUrl
        ? extractFileNameAndExtension(formValues.resumeUrl)
        : { extension: '', truncatedFileName: '', fileName: '' };
      
             
    const getFileIcon=(extension:string)=>{

    switch(extension){
        case 'pdf':
            return <img src={pdfImage} alt="pdf Icon" style={{width:'16px',height:'16px'}}/>
        case 'doc':
         case 'docx':
             return <img src={docImage} alt='doc Icon' style={{width:'16px',height:'16px'}}/>
    }
 }

 const renderPreview = () => {
  if (extension === 'pdf') {
    // Use Google Drive viewer for PDFs
    const googleDriveViewerUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(formValues?.resumeUrl)}`;
    return (
      <iframe
        src={googleDriveViewerUrl}
        title="PDF Preview"
        style={{ width: '100%', height: '500px', border: 'none' }}
      ></iframe>
    );
  } 
  else if (extension === 'doc' || extension === 'docx') {
    // Use Google Docs Viewer to preview DOC/DOCX files
    const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(formValues?.resumeUrl)}&embedded=true`;
    return (
      <iframe
        src={googleDocsViewerUrl}
        title="DOC/DOCX Preview"
        style={{ width: '100%', height: '500px', border: 'none' }}
      ></iframe>
    );
  } else {
    return <p>No file available for preview.</p>;
  }
};

 useEffect(()=>{
   if(resumeFile.length > 0){
    const uploadFiles = async () => {
    const formData=new FormData();
    resumeFile.forEach((file)=>{
      formData.append("file",file);
    });

    try{
      const response= await dispatch(getFileUrl(formData));
      if(response?.payload){
        setFormValues((prevValues:any)=>({
          ...prevValues,
          resumeUrl:response?.payload?.data,
        }))
      }
    } catch(error){
    console.error("error in uploading file",error)
    }
  };
    uploadFiles();
   }

 },[resumeFile,setFormValues,dispatch])
      
  return (
    <>
    <Typography
          sx={{
              fontSize: '14px',
              mb: '17px',
              m: '14px',
              backgroundColor: '#1677FF1A',
              color: '#1677FF',
              width: '100px',
              p: '3px',
              borderRadius: '3px',
              textAlign: 'center',
              fontWeight: '500',
          }}
      >
          Resume
      </Typography>
      <Paper
  elevation={3} // Optional for shadow
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '500px', // Adjust height as needed
    padding: '16px', // Optional: Add padding for spacing
    border: '1px solid #ddd', // Optional: Border for framing
    borderRadius: '8px', // Optional: Add rounded corners
    backgroundColor: '#f5f5f5', // Optional: Light background
    margin: '20px auto', // Center on the page
    maxWidth: '90%', // Adjust width relative to the page
  }}
>
  {formValues?.resumeUrl ? renderPreview() : <p>No file available for preview.</p>}
        
</Paper>

      <Box sx={{m:'14px',mt:'40px',display:'flex',flexDirection:'row',gap:'10px'}}>
        
      <input
              type="file"
              accept=".pdf"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange} />
              <Button
                  onClick={handleBrowseClick}
                  sx={{
                      width: '220px',
                      height: '40px',
                      backgroundColor: '#f5f5f5',
                      color: '#666666',
                      border: '1px dashed #ccc',
                      boxShadow: 'none',
                      textTransform: 'none',
                      cursor: 'pointer',
                  }}
              >
              Re-Upload
          </Button>
          <Box sx={{ml:'20px'}}>
            <Tooltip title={`${fileName}.${extension}`} disableHoverListener={fileName?.length <= 7}>
          <Button endIcon={ <ClearIcon
      onClick={(e) => {
        e.stopPropagation(); // Prevent propagation to the Button
        setFormValues((prevValues: any) => ({
          ...prevValues,
          resumeUrl: '', // Clear the file name
        }));
        setResumeFile([]); // Clear the selected file
        handleBrowseClick(); // Trigger file browse
      }}
    />
          }
          sx={{
            width: '160px',
            height: '40px',
            // mt: '12px',
            color: '#888888',
            fontSize: '11px',
            border: '1px solid #DEDEDE',
            textTransform:'none'
          }}
          startIcon={getFileIcon(extension)}>
            {truncatedFileName}
            </Button>
            </Tooltip>
            </Box>
          </Box>
          </>
  )
}
