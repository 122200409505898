import { Box, Typography, Grid, CircularProgress } from '@mui/material';
import { PieChart } from '@mui/x-charts/PieChart';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BaseUrl } from '../../../../../constants/Crudurl';

const chartColors: { [key: string]: string } = {
  "L1 Pending": '#00A76F',
  "L2 Pending": '#FFAB00',
  "L2 Approved": '#00B8D9',
  "L1 Declined": '#05EEFF',
  "L2 Declined": '#FF5630',
};

function PieChartBoard1() {
  const data = useSelector((state: RootState) => state.profile.data);
  const userID = data?.data?.userId;
  const [statusReport, setStatusReport] = useState<{ label: string; value: number; statusText: string }[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStatusReport = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BaseUrl}/dashboard/mrfApprovalStatusForAssigned/${userID}`
        );
        const responseData = response?.data?.data;
        const updatedStatusReport = Object.entries(responseData).map(([label, value]) => ({
          label,
          value: Number(value),
          statusText: label === 'L1Pending' ? '(open)' : label === 'L2Pending' ? '(L1 Approved)' : '',
        }));
        setStatusReport(updatedStatusReport);
        setTotal(updatedStatusReport.reduce((acc, item) => acc + item.value, 0));
      } catch (error) {
        setLoading(false);
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    if (userID) {
      fetchStatusReport();
    }
  }, [userID]);

  return (
    <Grid container sx={{ borderRadius: '8px', height: '200px', backgroundColor: '#FFFFFF', padding: '8px', position: 'relative' }}>
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <Typography sx={{ fontWeight: '500', fontSize: '12px', marginTop: '8px' }}>
          MRF Approval Status
        </Typography>
        {loading ? (
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 10 }}>
            <CircularProgress size={50} />
          </Box>
        ) : (
        <Box sx={{ position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center', mt: -3.5, ml: 3 }}>
          <PieChart
            height={220}
            width={220}
            slotProps={{
              legend: { hidden: true },
            }}
            series={[
              {
                data: statusReport.map((item) => ({
                  ...item,
                  color: chartColors[item.label],
                })),
                innerRadius: 60,
                outerRadius: '65%',
                arcLabel: () => '',
                arcLabelMinAngle: 20,
                startAngle: -340,
                endAngle: 160,
              },
            ]}
          />
           {!loading && (
          <Grid item sx={{ position: 'absolute', top: '48%', left: '28%', transform: 'translate(-50%, -50%)', textAlign: 'center', pointerEvents: 'none' }}>
            <Typography component="span" sx={{ fontSize: '16px', fontWeight: 'bold', color: '#000' }}>{total}</Typography>
            <Typography component="span" sx={{ fontSize: '8px', display: 'block' }}>Overall MRF</Typography>
          </Grid>
           )}
        </Box>
        )}
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Box>
          {statusReport.map((item, index) => (
            <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
              <Box sx={{ width: 9, height: 9, borderRadius: '1px', backgroundColor: chartColors[item.label], marginRight: '8px' }}></Box>
              <Typography sx={{ color: '#6B7280', fontSize: '0.688rem', flex: 1, padding: '2px' }}>{item.label}</Typography>
              <Typography sx={{ color: '#6B7280', fontSize: '0.688rem', fontWeight: 'bold' }}>
                {item.value} {item.statusText}
              </Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
}

export default PieChartBoard1;
