import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUsers } from "../../../redux/features/userSlice";
import { RootState, AppDispatch } from "../../../redux/store";
import {Box} from "@mui/material";
import MasterTab from "../MasterTab";
import RolesTable from "./Roles/RolesTable";
import UsersTable from "./User/UserTable";
import { fetchRoles } from "../../../redux/features/roleSlice";
import { fetchBusiness } from "../../../redux/features/businessSlice";
import BusinessTable from "./Business/BusinessTable";
import DepartmentTable from "./Department/DepartmentTable";
import { fetchDepartment } from "../../../redux/features/departmentSlice";

const UserMasterTable: React.FC<{ setSubTitle: (subtitle: string) => void }> = ({ setSubTitle }) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();
  const { status, error } = useSelector((state: RootState) => state.user);

 
  const getTabIndex = (): number => {
    const tabs = ["users", "roles", "departments", "business"];

    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    return tabs.indexOf(tab || "users") !== -1 ? tabs.indexOf(tab || "users") : 0;
  };

  const [tabIndex, setTabIndex] = React.useState(getTabIndex);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchUsers());
      dispatch(fetchRoles());
      dispatch(fetchBusiness());
      dispatch(fetchDepartment());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const tabs = ["users", "roles", "departments", "business"];
    const subtitles = ["Users", "Roles", "Departments", "Business"];
    setSubTitle(subtitles[tabIndex]); 
    navigate(`?tab=${tabs[tabIndex]}`, { replace: true }); 
  }, [tabIndex, setSubTitle, navigate]);

  // if (status === "loading") {
  //   return <div>Loading...</div>;
  // }
  if (status === "failed") {
    console.error("Error:", error);
  }

  return (
    <Box sx={{ width: "auto", backgroundColor: "#f0f4ff", overflowX: "hidden" }} p={1}>
      <MasterTab tabIndex={tabIndex} onTabChange={(e, newIndex) => setTabIndex(newIndex)} />
      {tabIndex === 0 && <UsersTable />}
      {tabIndex === 1 && <RolesTable />}
       {/* {tabIndex === 2 && <OrganizationsTable />} */}
      {tabIndex === 2 && <DepartmentTable />}
      {tabIndex === 3 && <BusinessTable />}
    </Box>
  );
};

export default UserMasterTable;
