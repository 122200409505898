import axios from "axios";
import { COMMENTS_API_URL } from "../constants/Crudurl";

interface AddCommentPayload {
  rthId: string;
  commentedBy: string;
  commentedText: string;
}
// POST FOR CREATE COMMENTS
export const addComment = async (payload: AddCommentPayload) => {
  try {
    const response = await axios.post(`${COMMENTS_API_URL}/addComment`,payload);
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || "Failed to add the comment."
    );
  }
};

// GET FOR GET ALL COMMENTS BASED ON THE
export const getComment = async (id:string) => {
    try {
      const response = await axios.get(`${COMMENTS_API_URL}/getCommentsByRth/${id}`);
      return response.data;
    } catch (error: any) {
      throw new Error(
        error.response?.data?.message || "Failed to get the comments."
      );
    }
  };

// PUT FOR LIKE AND UNLIKE IN MAIN COMMENTS
export const likeAndUnlikeMainComment = async (commentId:string,userId:string,) => {
    try {
      const response = await axios.put(`${COMMENTS_API_URL}/like?commentId=${commentId}&likedBy=${userId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(
        error.response?.data?.message || "Failed to Like and Unlike the comments."
      );
    }
  };

//   PUT FOR MAIN REPLY COMMENTS
  export const mainReplyComment = async (commentId:string,reply:string,userId:string,) => {
    try {
      const response = await axios.put(`${COMMENTS_API_URL}/reply/${commentId}?reply=${reply}&repliedBy=${userId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(
        error.response?.data?.message || "Failed to Reply the comments."
      );
    }
  };

  
  // PUT FOR LIKE AND UNLIKE IN CHILD COMMENTS
export const likeAndUnlikeChild1Comment = async (commentId:string,replyId:string,userId:string,) => {
    try {
      const response = await axios.put(`${COMMENTS_API_URL}/likeToReplies?commentsId=${commentId}&replyId=${replyId}&likedBy=${userId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(
        error.response?.data?.message || "Failed to Like and Unlike the comments."
      );
    }
  };

   // PUT FOR LIKE AND UNLIKE IN CHILD COMMENTS
export const replyChild1Comment = async (comments:string,commentId:string,replyId:string,userId:string,) => {
    try {
      const response = await axios.put(`${COMMENTS_API_URL}/replyToReplies?text=${comments}&commentsId=${commentId}&replyId=${replyId}&repliedBy=${userId}`);
      return response.data;
    } catch (error: any) {
      throw new Error(
        error.response?.data?.message || "Failed to reply the comments."
      );
    }
  };
