import React, { useState } from 'react';
import { Grid } from '@mui/material';
import WelcomeSection from './WelcomeSection';
import StatisticsCard from './StatisticsCard';
import PieChartBoard1 from './PieChartBoard1';
import BarChartData from './BarChartData';
import PieChartBoard2 from './PieChartBoard2';
import PieChartBoard3 from './PieChartBoard3';
import PieChartBoard4 from './PieChartBoard4';
import BarChartData1 from './BarChartData1';
import GaugeChart from './GaugeChart';

const DashBoardTAH: React.FC = () => { 
    const [activePaper, setActivePaper] = useState<string>('total');

    return (
        <>
            <Grid container spacing={2} p={2} justifyContent="space-between">
                <Grid item xs={12} md={4} maxWidth="100%">
                    <WelcomeSection />
                </Grid>
                <Grid item xs={12} md={4} maxWidth="100%">
                    <StatisticsCard activePaper={activePaper} onPaperClick={setActivePaper} />
                </Grid>
                <Grid item xs={12} md={4} maxWidth="100%">
                    <PieChartBoard1 />
                </Grid>
            </Grid>
            <Grid container spacing={2} p={2} justifyContent="space-between">
                <Grid item xs={12} md={4} maxWidth="100%">
                    <BarChartData1 />
                </Grid>
                <Grid item xs={12} md={8} maxWidth="100%">
                    <BarChartData />
                </Grid>
            </Grid>
            <Grid container spacing={2} p={2} justifyContent="space-between" sx={{paddingBottom: '24px'}}>
                <Grid item xs={12} md={3} maxWidth="100%">
                    <PieChartBoard2 />
                </Grid>
                <Grid item xs={12} md={3} maxWidth="100%">
                    <PieChartBoard3 />
                </Grid>
                <Grid item xs={12} md={3} maxWidth="100%">
                    <GaugeChart />
                </Grid>
                <Grid item xs={12} md={3} maxWidth="100%">
                    <PieChartBoard4 />
                </Grid>
            </Grid>
        </>
    );
};

export default DashBoardTAH;
