import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  ListItemText,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import {
  setRthStagesId,
  setRthStagesName,
} from "../../../redux/features/rthStagesSlice";
import { useSelector } from "react-redux";
import { setFilterState } from "../../../redux/features/teamHeadFilterSlice";

const RthStagesDropDown = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  // const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  console.log('setLoading', setLoading)

  const isOpen = Boolean(anchorEl);

  const rthStagesName = useSelector(
    (state: RootState) => state.rthStages.rthStagesName
  );
  const rthStagesId = useSelector(
    (state: RootState) => state.rthStages.rthStagesId
  );

  const [rthStages, setRthStages] = useState<any[]>([
    { label: "Open", value: "0" },
    { label: "Move To Job", value: "1" },
    { label: "Job Post", value: "2" },
    { label: "Closed", value: "3" },
  ]);
  console.log('setRthStages', setRthStages)

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggle = (name: string, id: string) => {
    const updatedRthStagesName = rthStagesName.includes(name)
      ? rthStagesName.filter((title: any) => title !== name)
      : [...rthStagesName, name];
    const updatedRthStagesId = rthStagesId.includes(id)
      ? rthStagesId.filter((title: any) => title !== id)
      : [...rthStagesId, id];
    dispatch(setRthStagesName(updatedRthStagesName));
    dispatch(setRthStagesId(updatedRthStagesId));
  };

  useEffect(() => {
    const formattedRthStages = rthStagesName.map(
      (stage) =>
        stage
          .replace(/\s(.)/g, (match, group1) => group1.toUpperCase()) // Capitalize first letter of subsequent words
          .replace(/\s/g, "") // Remove any remaining spaces
    );

    dispatch(
      setFilterState({
        rthStages: formattedRthStages.length ? formattedRthStages : [],
      })
    );
  }, [rthStagesName, dispatch]);

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={handleOpen}
        sx={{
          width: "100%",
          justifyContent: "space-between",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          borderColor: "#B0B0B0",
          color: "#000",
          "&:hover": {
            borderColor: "#A0A0A0",
          },
        }}
      >
        {rthStagesName.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {rthStagesName.slice(0, 1).map((name: any) => (
              <Chip
                key={name}
                label={name}
                onDelete={() =>
                  handleToggle(
                    name,
                    rthStages.find((lead) => lead.label === name)?.value || ""
                  )
                }
                sx={{ fontSize: "10px", height: "20px" }}
              />
            ))}
            {rthStagesName.length > 1 && (
              <Chip
                label={`+${rthStagesName.length - 1}`}
                sx={{
                  fontSize: "10px",
                  height: "20px",
                  backgroundColor: "#f0f0f0",
                  color: "#8C8C8C",
                }}
              />
            )}
          </Box>
        ) : (
          <Typography sx={{ color: "grey" }}>Choose</Typography>
        )}
        <ArrowDropDownIcon sx={{ fontSize: 18, marginLeft: 1 }} />
      </Button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        PaperProps={{
          sx: {
            width: anchorEl ? anchorEl.offsetWidth : "auto",
            marginTop: "2px",
          },
        }}
      >
        <Box sx={{ padding: 1 }}>
          <TextField
            size="small"
            placeholder="Search..."
            fullWidth
            onChange={(e) => setSearchQuery(e.target.value)}
            value={searchQuery}
            sx={{
              marginBottom: 2,
              "& .MuiInputBase-input": { fontSize: "12px" },
            }}
          />
          <Box sx={{ maxHeight: "250px", overflowY: "auto" }}>
            {rthStages
              .filter((data) =>
                data.label.toLowerCase().includes(searchQuery.toLowerCase())
              )
              .map((lead) => (
                <MenuItem
                  key={lead.value}
                  onClick={() => handleToggle(lead.label, lead.value)}
                  sx={{ fontSize: "10px", padding: "4px 6px", gap: "10px" }}
                >
                  <Checkbox
                    checked={rthStagesName.includes(lead.label)}
                    size="small"
                    sx={{
                      padding: "2px",
                      "& .MuiSvgIcon-root": { fontSize: "14px" },
                    }}
                  />
                  <ListItemText
                    primary={lead.label}
                    sx={{ margin: 0, fontSize: "10px" }}
                  />
                </MenuItem>
              ))}
            {loading && (
              <Box sx={{ textAlign: "center", padding: 1 }}>
                <CircularProgress size={20} />
              </Box>
            )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default RthStagesDropDown;
