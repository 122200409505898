export const BaseUrl = "https://mytheron-qa-backend.citpl.info";
// export const BaseUrl = "https://mytheron-dev-backend.citpl.info";
export const BASE_COPY_URL = "https://mytheron-qa-portal.citpl.info";
export const LOGIN_API_URL = `${BaseUrl}/auth/login`;
export const REFRESH_API_URL = `${BaseUrl}/auth/refresh`;
export const LOGIN_AUTH_URL = `${BaseUrl}/auth`;
export const RTH_API_URL = `${BaseUrl}/master`;
export const PROFILE_API_URL = `${BaseUrl}/profile`;
export const FILE_API_URL = `${BaseUrl}/rth`;
export const CANDIDATE_API_URL = `${BaseUrl}/candidate`;
export const RTH_MASTER_API_URL = `${BaseUrl}/master`;
export const COMMENTS_API_URL = `${BaseUrl}/comments`;

//Local
// export const SSO_LOGIN = "https://globalauthdev.ckdigital.in/api/oauth2/Global/azure?state=MyTheron"
// export const SSO_LOGIN = "https://globalauthdev.ckdigital.in/api/oauth2/Global/azure?state=MyTheronDev"
export const SSO_LOGIN = "https://globalauthdev.ckdigital.in/api/oauth2/Global/azure?state=MyTheronQA"

export const SSO_LOGIN_API_URL = `${BaseUrl}/auth/socialLogin`
// export const SSO_LOGIN_API_URL = "https://mytheron-dev-backend.citpl.info/auth/socialLogin"
export const SSO_DEFAULT = "https://mytheron-dev-backend.citpl.info/auth/socialLogin?email=anandkumar.j@cavininfotech.com&name=anandkumar"
