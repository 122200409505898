import { createSlice, PayloadAction } from '@reduxjs/toolkit';
 
interface filterState {
  interviewName: string[];
  interviewStatus: string[];
  interviewStages:string[];
  source:string[];
  interviewMode:string[];
  dateRange:string[];
  positions:string[];
}
 
const initialState: filterState = {
  interviewName: [],
  interviewStatus: [],
  interviewStages:[],
  source:[],
  interviewMode:[],
  dateRange:[],
  positions:[],
};
 
const interviewFilterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setInterviewName: (state, action: PayloadAction<string[]>) => {
      state.interviewName = action.payload;
    },
    setInterviewStatus: (state, action: PayloadAction<string[]>) => {
      state.interviewStatus = action.payload;
    },
    setInterviewStages:(state,action:PayloadAction<string[]>)=>{
     state.interviewStages=action.payload;
    },
    setSource:(state,action:PayloadAction<string[]>)=>{
      state.source=action.payload;
    },
    setInterviewMode:(state,action:PayloadAction<string[]>)=>{
      state.interviewMode=action.payload;
    },
    setPositions:(state,action:PayloadAction<string[]>)=>{
     state.positions=action.payload;
    }
  },
});
 
export const { setInterviewName, setInterviewStatus,setInterviewStages,setSource,setInterviewMode,setPositions } = interviewFilterSlice.actions;
 
export default interviewFilterSlice.reducer;