import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useLocation } from 'react-router-dom';

 export const SubmissionSuccess = () => {
  const location=useLocation();
  const path=location.pathname.includes('SubmittedFeedback');

  const textToDisplay=path?'FeedBack Submitted Successfully':'Application Submitted Successfully';
  const shortText=path?'Thank you for your feedback':'Thank you for your submission, we’ll reach you if your profile gets shortlisted';

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bgcolor="#f5f5f5"
    >
      {/* Green Check Icon */}
      <CheckCircleIcon
        style={{ fontSize: 80, color: '#4caf50', marginBottom: '20px' }}
      />

      {/* Main Text */}
      <Typography
        variant="h5"
        fontWeight="bold"
        color="textPrimary"
        textAlign="center"
        gutterBottom
      >
        {textToDisplay}
      </Typography>

      {/* Subtext */}
      <Typography
        variant="body1"
        color="textSecondary"
        textAlign="center"
        maxWidth="400px"
      >
        {shortText}
      </Typography>
    </Box>
  );
};

