import { Card, Typography, Avatar, Button, Box, Tooltip, useMediaQuery } from '@mui/material';
import pdfImage from '../../assets/images/pdf 1.svg';
import docImage from '../../assets/images/doc 1.svg';
import phonecall from '../../assets/images/phone-call.svg'
import emailopen from '../../assets/images/e-mail.svg'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SelectData from './selectData';
import { SelectDataOptions } from '../../constants/dummyConst';
import { truncateText } from '../../helpers/truncateText';
// import SelectData from './selectData';
// import { SelectDataOptions } from '../../constants/dummyConst';

const getFileIcon = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase();
  if (extension === 'pdf') {
    return <img src={pdfImage} alt="PDF Icon" style={{ width: '12px', height: '12px' }} />;
  }
  if(extension==='doc'||extension==='docx'){
    return <img src ={docImage} alt="DOC Icon" style={{width:'16px',height:'16px'}}/>;
  }
  return null;
};
const getFileNameAndType = (url: string) => {
  const parts = url.split('/');
  const fileNameWithParams = parts[parts.length - 1].split('?')[0]; // Extract filename before the query params
  const fileName = fileNameWithParams.includes('_') 
    ? fileNameWithParams.split('_').slice(1).join('_') 
    : fileNameWithParams; // Remove prefix if exists
  const extension = fileName.split('.').pop()?.toLowerCase(); // Get file extension
  return { fileName, extension };
};

const getInitials = (name: string, lastName: string) => {
  const firstNameInitial = name?.trim()[0] || ''; // Get first letter of first name
  const lastNameInitial = lastName?.trim()[0] || ''; // Get first letter of last name
  return firstNameInitial + lastNameInitial; // Combine both initials
};


const truncateFileName = (fileName: string, extension: string, maxLength = 3) => {
  if (fileName.length > maxLength) {
    return `${fileName.slice(0, maxLength)}..${extension}`;
  }
  return `${fileName}.${extension}`;
};

const getStatusStyles = (status: string) => {
  if (status === 'Newly Arrived') {
    return { color: '#57DB57', backgroundColor: '#FBFBFB' };
  }
  return { color: '#7F7F7F', backgroundColor: '#FBFBFB' }; // Default styles if no conditions are met
};
  


const CandidateCard = ({
  name,
  status,
  daysAgo,
  phone,
  email,
  pdfLink,
  schedule,
  lastName,
  candidate,
  drawerOpen
}: any) => {
  const statusStyles = getStatusStyles(status);
  const navigate=useNavigate();
  const { fileName, extension } = getFileNameAndType(pdfLink); 
  const displayedFileName = truncateFileName(fileName, extension!, 3); 
  const {jobTitle}=useParams();
  const location=useLocation();
  const fullName = lastName ? `${name} ${lastName}` : name;
  const resumeRepoPath=location.pathname.includes("resumeRepo");
   
  const handleNavigate=()=>{
    if(resumeRepoPath){
      navigate(`/newRequestToHire/${candidate?.rthName}/${candidate?.candidateLogInList?.rthId}/candidate/${fullName}/${candidate.candidateLogInList?.candidateId}`)
    }
    else{
    navigate(`/newRequestToHire/${jobTitle}/${candidate.rthId}/candidate/${fullName}/${candidate.candidateId}`)
    }
  };
   
const Md = useMediaQuery("(max-width: 1504px)");

  return (
    <Card style={{margin: '5px', width: drawerOpen? (Md ? '298px' : '290px')  : (Md ? '376px' : '278px') , height: '160px',cursor:'pointer' }} onClick={handleNavigate}>
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: '10px' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
        <Avatar sx={{ width: '28px', height:'28px', bgcolor:'#e8f2ff', color:'#000', fontSize: '12px', mt:'2px' }}>{getInitials( name, lastName )}</Avatar>
        <Tooltip
      title={name} 
      disableHoverListener={name?.length <= 15} 
      arrow>
        <Typography sx={{ mt: '4px', fontSize: '16px' }}>{truncateText(name, 15)}
        </Typography>
        </Tooltip>
      </Box>
      <Typography
        variant="body2"
        sx={{
          color: statusStyles.color,
          backgroundColor: statusStyles.backgroundColor,
          padding: '4px 4px',
          borderRadius: '4px',
          display: 'inline-block',
          fontSize: '11px',
        }}  
      >
        {daysAgo ? `${status} : ${daysAgo}` : status}
      </Typography>
    </Box>

    <Box sx={{ p: '4px 10px' }}>
      <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', fontSize: '13px', ml:'5px' }}>
        <img src={phonecall} alt={'phone'} style={{  marginRight: '10px', width:'12px' }} /> {phone}
        </Typography>
      <Tooltip
      title={email} 
      disableHoverListener={email?.length <= 28} 
      arrow>
      <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mt: '10px', fontSize: '13px', ml:'5px'  }}>
        <img src={emailopen}  alt={'emailOpen'} style={{  marginRight: '10px', width:'12px' }} /> {truncateText(email, 28)}

      </Typography>
      </Tooltip>
    </Box>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        // width: '100%',          
        padding: '10px',
        pb:0,
        mt:1
      }}
    >
      {pdfLink ?
       <Tooltip 
       title={fileName} 
       disableHoverListener={fileName?.length <= 3} 
     >
      <Button
        variant="outlined"
        // href={pdfLink}
        // target="_blank"
        disabled
        startIcon={getFileIcon(fileName)}
        sx={{
          width: '100px',
          height: '24px',
          // mt: '12px',
          color: '#888888',
          fontSize: '11px',
          border: '1px solid #DEDEDE',
        }}
      >
        {displayedFileName}
      </Button>
      </Tooltip>
      :
      <Typography sx={{fontSize:'12px'}}>Resume not uploaded</Typography>
      }
      <Box>
      <SelectData
        type={'select'}
        fieldName={'priorityId'}
        data={schedule}
        options={SelectDataOptions}
        isEditable={'true'}
      />
      </Box>
    </Box>

  </Card>
  );
};

export default CandidateCard; 