import { Box, CircularProgress, Grid, InputAdornment, InputBase, Paper, Table, TableBody, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material"
import { StyledTableCell } from "../dashboard/Rth/rthtable/RthClosedTable"
import { useCallback, useEffect, useRef, useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAllInterviewsStatusData } from "../../redux/features/interviewModule";
 
export const InterviewStatusTable=({filterPayload}:any)=>{

    const [searchQuery, setSearchQuery] = useState<string>('');
    const dispatch=useAppDispatch();
    const [page,setPage]=useState(0);
    const scrollPosition = useRef(0);   
    const [interviewData, setInterviewData] = useState<any[]>([]); 
    const hasMore = true;
    const interviewStatusTableData=useAppSelector((state:any)=>state.interviewModule.interviewStatus)      
    const Navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false); // Loading state
    const scrollContainerRef = useRef<HTMLDivElement>(null); 
    const tableHead = [
        "SI.No",
        "Candidate Name",
        "Position",
        "Date",
        "Interview Status",
        "Action",
      ];
 const[searchState,setSearchState]=useState(false);
      const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
        setSearchState(true)
        setSearchQuery(event.target.value);
    };
 

    const handleNavigate=(candidateId:any, interviewId:any, positionName:any)=>{
       Navigate(`/interviewReviewForm/${candidateId}/${interviewId}/${positionName}`)   
    };


    const handleScroll = useCallback(() => {
      if (!scrollContainerRef.current) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollContainerRef.current;
        if (scrollTop > scrollPosition.current) {
            // Example: Detect when user reaches near the bottom of the container
          if (scrollTop + clientHeight >= scrollHeight - 100 && hasMore && page>=0) {
            setPage((prevPage) => prevPage + 1);
          }
        }
        scrollPosition.current = scrollTop;
      },[hasMore, page, scrollPosition, scrollContainerRef])

      const formatDate = (dateString: string) => {
        const date = new Date(dateString);
       
        // Get the day, month, and year
        const day = date.getDate().toString().padStart(2, '0'); 
        const month = date.toLocaleString('default', { month: 'short' }); 
        const year = date.getFullYear();
     
        return `${day} ${month}, ${year}`;
      };

      const Md = useMediaQuery("(max-width: 1504px)");

      useEffect(() => {
        const fetchInterviewStatusData = async () => {
          setLoading(true); // Set loading to true before the API call
          try {
            await dispatch(getAllInterviewsStatusData({ data: filterPayload, searchQuery: searchQuery, page: page }));
          } catch (error) {
            console.error("Error fetching interview status data", error);
          } finally {
            setLoading(false); // Set loading to false once the API call is done
          }
        };
        fetchInterviewStatusData();
      }, [searchQuery, filterPayload, dispatch, page]);
    

      useEffect(() => {
        const container = scrollContainerRef.current;
        if (container) {
          container.addEventListener('scroll', handleScroll);
        }
    
        return () => {
          if (container) {
            container.removeEventListener('scroll', handleScroll);
          }
        };
      }, [hasMore,handleScroll]);
      
      useEffect(()=>{
        if (interviewStatusTableData?.length > 0) {
       if(searchState){
        setInterviewData(interviewStatusTableData)
       }
       else{
        if(interviewStatusTableData){
            setInterviewData((prevData) => {
                const combinedData = [...prevData, ...interviewStatusTableData];
                const uniqueData = Array.from(
                  new Map(combinedData.map((item) => [item?.interview?.interviewId, item])).values()
                );
                return uniqueData;
              });
        }
      }
    }
      },[interviewStatusTableData,searchState,searchQuery])
     
      useEffect(()=>{
        if(searchQuery){
          setSearchState(true);
        }
        else{
          setSearchState(false);
        }
      },[searchQuery])

      useEffect(()=>{
        if(filterPayload){
        // const hasData = Object.values(filterPayload).some(value => value !== null);
        setPage(0);
        setSearchState(true);
        }
      },[filterPayload])

      
      
    return(
        <><Box sx={{ mt: '20px', ml: '40px' }}>
            <Grid item position="absolute">
                <InputBase
                    value={searchQuery}
                    onChange={handleSearchChange}
                    placeholder="Search Profile..."
                    startAdornment={<InputAdornment position="start">
                        <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
                    </InputAdornment>}
                    sx={{
                        border: '1px solid #ccc',
                        borderRadius: 1,
                        padding: 0,
                        backgroundColor: 'white',
                        minHeight: '3px',
                        fontSize: 14,
                        '.MuiInputBase-input': {
                            padding: '5px 5px',
                        },
                    }} />
            </Grid>
        </Box>
        <Box sx={{ width: "96%", overflowX: "auto", ml: '40px',mt:'70px' }}>
                <TableContainer 
                ref={scrollContainerRef}
                component={Paper} sx={{ maxHeight:Md? "63vh" : "70vh",overflow:'auto',
                 "&::-webkit-scrollbar": {
                    display: "none", // Hides the scrollbar in Webkit-based browsers (Chrome, Edge, Safari)
                    },
                    "-ms-overflow-style": "none", // Hides the scrollbar in Internet Explorer and Edge
                    "scrollbar-width": "none", // Hides the scrollbar in Firefox            
                    }}>
                    <Table sx={{ minWidth: 650 }} aria-label="MRF table">
                        <TableHead sx={{ backgroundColor: "#F9F9F9", position: 'sticky', top: 0 }}>
                            <TableRow>
                                {tableHead.map((data, index) => (
                                    <StyledTableCell key={index}>{data}</StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {interviewData?.map((item: any, index: any) => {
                                // Determine the styling based on the interview status
                                const statusStyles = (() => {
                                    if (["L1 Interview Scheduled", "L1 Interview Rescheduled", "L1 Interview Completed"].includes(item?.interview?.status)) {
                                        return { bgcolor: '#F7F1FF', color: '#5708B8' };
                                    }
                                    if (["L2 Interview Scheduled", "L2 Interview Rescheduled", "L2 Interview Completed"].includes(item?.interview?.status)) {
                                        return { bgcolor: '#CAF1D9', color: '#0E8B3E' };
                                    }
                                    if (["L3 Interview Scheduled", "L3 Interview Rescheduled", "L3 Interview Completed"].includes(item?.interview?.status)) {
                                        return { bgcolor: '#C2EEF6', color: '#0D6070' };
                                    }
                                    return {}; // Default styles if no match
                                })();

                                return (
                                    <TableRow key={index}>
                                        <StyledTableCell>{index + 1}</StyledTableCell>
                                        <StyledTableCell>{item?.interview?.candidateName}</StyledTableCell>
                                        <StyledTableCell>{item?.positionName}</StyledTableCell>
                                        <StyledTableCell>{formatDate(item?.interview.interviewDetL1.interviewDate)}</StyledTableCell>
                                        <StyledTableCell sx={{display:'flex'}}>
                                            <Box sx={{ display:'flex', justifyContent:'center', alignItems:'center', textAlign: 'center', ...statusStyles, width:'200px', p:'0 10px', height:'30px', borderRadius:'6px' }}> {item?.interview?.status}</Box>                                            
                                        </StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center' }}>
                                            <VisibilityIcon
                                                sx={{ color: '#8F8F8F', cursor: 'pointer' }}
                                                onClick={() => handleNavigate(item?.interview?.candidateId, item?.interview?.interviewId, item?.positionName)}
                                            />
                                        </StyledTableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {loading && (
        <Box sx={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: '50%', left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <CircularProgress size={24} />
        </Box>
      )}

            </Box>
            </>
    )
}