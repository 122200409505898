import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RecruiterDdState {
  recruiterId: string[];
  recruiterName: string[];
}

const initialState: RecruiterDdState = {
  recruiterId: [],
  recruiterName: [],
};

const recruiterSlice = createSlice({
  name: 'recruiterLead',
  initialState,
  reducers: {
    setRecruiterDdState: (
      state,
      action: PayloadAction<RecruiterDdState>
    ) => {
      state.recruiterId = action.payload.recruiterId;
      state.recruiterName = action.payload.recruiterName;
    },
    resetRecruiterDdState: (state) => {
      state.recruiterId = [];
      state.recruiterName = [];
    },
  },
});

export const { setRecruiterDdState, resetRecruiterDdState } =
  recruiterSlice.actions;

export default recruiterSlice.reducer;
