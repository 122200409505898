interface RowData {
  [key: string]: string | number | null | undefined;
}

interface ReportData {
  headerNames: string[];
  rows: RowData[];
}

const calculateAgeing = (creationDate: string): number => {
  const creation = new Date(creationDate); // Parse the date from the response
  const current = new Date(); // Current date
  const diffTime = Math.abs(current.getTime() - creation.getTime()); // Difference in milliseconds
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert to days
  return diffDays;
};

const formatDate = (dateString:string) => {
  if (dateString === "-") return "-";
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

function calculateSelectionPercentage(
  closedPosition: number,
  resumesSubmitted: number
): string {
  if (resumesSubmitted === 0) {
    return "-"; // Avoid division by zero
  }
  const percentage = (closedPosition / resumesSubmitted) * 100;
  return `${percentage.toFixed(2)}%`; // Return percentage with 2 decimal places
}

function getStatusMessage(l1Status: string, l2Status?: string): string {
  if (l1Status === "pending") {
    return "L1 Pending";
  }

  if (l1Status === "approved") {
    if (l2Status === "approved") {
      return "L2 Approved";
    } else if (l2Status === "declined") {
      return "L2 is Declined";
    }
    return "L2 Pending";
  }

  if (l1Status === "declined") {
    return "L1 is Declined";
  }

  return "Status Unknown"; // Default case for unhandled statuses
}

export const getReportData = (
  selectedTab: string,
  currentReportData: any[],
  roleName: string
): ReportData => {
  if (
    selectedTab === "MRF Approval Status" &&
    (roleName === "TEAMHEAD" || roleName === "APPROVERHEAD")
  ) {
    const headerNames = [
      "S no",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subcategory",
      "MRF Status",
      "L1 Approver",
      "L2 Approver",
    ];

    

    const rows = currentReportData?.map((data, index) => ({
      id: index + 1,
      seqId: data?.seqId || "-",
      positionTitle: data?.positionTitle || "-",
      typeOfRth: data?.typeOfRth || "-",
      modeOfRth: data?.modeOfRth || "-",
      mrfStatus: getStatusMessage(data?.l1Status, data?.l2Status),
      l1Approver: data?.l1Approval?.userName || "-",
      l2Approver: data?.l2Approval?.userName || "-",
    }));

    return { headerNames, rows };
  } else if (
    selectedTab === "MRF Stage Report" &&
    (roleName === "TEAMHEAD" || roleName === "APPROVERHEAD")
  ) {    
    const headerNames = [
      "S No",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subcategory",
      "MRF Status",
      "L1 Approver",
      "L2 Approver",
      "MRF Stage",
      "MRF Creation Date",
      "Ageing (In days)",
      "TA Head",
      "Recruiter Lead",
    ];

    const rows = currentReportData?.map((data, index) => ({
      s_no: index + 1,
      mrfId: data?.seqId || "-",
      mrfTitle: data?.positionTitle,
      mrfCategory: data?.typeOfRth || "-",
      mrfSubcategory: data?.modeOfRth || "-",
      mrfStatus: getStatusMessage(data?.l1Status, data?.l2Status),
      l1Approver: data?.l1Approval?.userName || "-",
      l2Approver: data?.l2Approval?.userName || "-",
      mrfStage: data?.rthProcess || "-",
      mrfCreationDate: formatDate(data.createdAt) || "-",
      ageingInDays: calculateAgeing(data?.createdAt),
      taHead: "-",
      recruiterLead:
        data?.assignedRecruiterLead &&
        Array.isArray(data?.assignedRecruiterLead) &&
        data?.assignedRecruiterLead.length > 0
          ? data.assignedRecruiterLead[0]?.userName || "-"
          : "-",
    }));

    return { headerNames, rows };
  } else if (
    selectedTab === "Approved MRF Report" &&
    (roleName === "TEAMHEAD" || roleName === "APPROVERHEAD")
  ) {
    console.log("current da",currentReportData);
    const headerNames = [
      "S no",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subcategory",
      "Department",
      "Created by",
      "MRF creation Date",
      "Position",
      "Recruiter Lead",
      "Recruiter",
      "Candidate",
      "MRF Stage",
      "Interview Stage",
      "Interview Status",
      "L1 Interviewer Name",
      "L2 Interviewer Name",
      "Ageing / Hiring Cycle(in days)",
      "Closed date",
    ];
    const rows = currentReportData?.map((data, index) => ({
      sNo: index + 1 || "-",
      mrfId: data?.rthRequest?.seqId || "-",
      mrfTitle: data?.rthRequest?.positionTitle,
      mrfCategory: data?.rthRequest?.typeOfRth || "-",
      mrfSubcategory: data?.rthRequest?.modeOfRth || "-",
      department: data?.rthRequest?.department?.departmentName || "-",
      createdBy: data?.rthRequest?.user?.userName || "-",
      mrfCreationDate: formatDate(data?.rthRequest?.createdAt) || "-",
      position: data?.rthRequest?.numOfOpenPosition || "-",
      recruiterLead:
        data?.rthRequest?.assignedRecruiterLead
          ?.map((recruiter: any) => recruiter?.userName)
          .join(",") || "-",
      recruiter:
        data?.rthRequest?.assignedRecruiter
          ?.map((recruiter: any) => recruiter?.userName)
          .join(", ") || "-",
      candidate: data?.candidates?.firstName || "-",
      mrfStage: data?.rthRequest?.rthProcess || "-",
      interviewStage: "-",
      interviewStatus: data?.interview?.status || "-",
      l1InterviewerName: data?.interview?.interviewDetL1?.interviewer?.userName || "-",
      l2InterviewerName: data?.interview?.interviewDetL2?.interviewer?.userName || "-",
      ageingHiringCycleInDays:
        calculateAgeing(data?.rthRequest?.createdAt) || "-",
      closedDate: "-",
    }));
    return { headerNames, rows };
  } else if (
    selectedTab === "Overall MRF Report" &&
    (roleName === "TEAMHEAD" || roleName === "APPROVERHEAD")
  ) {
    const headerNames = [
      "S no",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subcategory",
      "MRF creation Date",
      "Position",
      "Recruiter Lead",
      "Recruiter",
      "Candidate",
      "MRF Stage",
      "Interview Stage",
      "Interview Status",
      "L1 Interviewer Name",
      "L2 Interviewer Name",
      "Ageing / Hiring Cycle(in days)",
      "Closed date",
    ];

    const rows = currentReportData?.map((data, index) => ({
      sNo: index + 1 || "-",
      mrfId: data?.rthRequest?.seqId || "-",
      mrfTitle: data?.rthRequest?.positionTitle,
      mrfCategory: data?.rthRequest?.typeOfRth || "-",
      mrfSubcategory: data?.rthRequest?.modeOfRth || "-",
      mrfCreationDate: formatDate(data?.rthRequest?.createdAt) || "-",
      position: data?.rthRequest?.numOfOpenPosition || "-",
      recruiterLead:
        data?.rthRequest?.assignedRecruiterLead
          ?.map((recruiter: any) => recruiter?.userName)
          .join(",") || "-",
      recruiter:
        data?.rthRequest?.assignedRecruiter
          ?.map((recruiter: any) => recruiter?.userName)
          .join(", ") || "-",
      candidate: data?.candidates?.firstName || "-",
      mrfStage: "-",
      interviewStage: "-",
      interviewStatus: "-",
      l1InterviewerName: "-",
      l2InterviewerName: "-",
      ageingHiringCycleInDays:
        calculateAgeing(data?.rthRequest?.createdAt) || "-",
      closedDate: "-",
    }));
    return { headerNames, rows };
  } else if (
    selectedTab === "Source Report" &&
    (roleName === "TALENTACQUISITIONHEAD" ||
      roleName === "RECRUITER" ||
      roleName === "RECRUITERLEAD")
  ) {
    
    const headerNames = [
      "S. No",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subcategory",
      "Resume ID",
      "Resume Name",
      "Recruiter",
      "Source",
    ];

    const rows = currentReportData?.map((data, index) => ({
      sNo: index + 1 || "-",
      mrfId: data.mrfId || "-",
      mrfTitle: data.mrfTitle || "-",
      mrfCategory: data.mrfCategory || "-",
      mrfSubcategory: data.mrfSubCategory || "-",
      resumeId:data.resumeId ||"-",
      resumeName: data.resumeName||"-",
      recruiter: data.recruiter||"-",
      source: data.source||"-",
    }));

    return { headerNames, rows };
  } else if (
    (selectedTab === "MRF Stage Report" ||
      selectedTab === "Department wise MRF" ||
      selectedTab === "Client wise MRF") &&
    (roleName === "TALENTACQUISITIONHEAD" ||
      roleName === "RECRUITER" ||
      roleName === "RECRUITERLEAD")
  ) {
    const headerNames = [
      "S no",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subcategory",
      "MRF Stage",
      ...(selectedTab === "Department wise MRF" ? ["Department"] : []),
      ...(selectedTab === "Client wise MRF" ? ["Client"] : []),
      "MRF creation Date",
      "Ageing(in days)",
      "Recruiter Lead",
      "Recruiters",
    ];

    if (selectedTab === "MRF Stage Report") {
      const rows = currentReportData?.map((data, index) => ({
        sNo: index + 1 || "-",
        mrfId: data.seqId || "-",
        mrfTitle: data.positionTitle || "-",
        mrfCategory: data?.typeOfRth || "-",
        mrfSubcategory: data?.modeOfRth || "-",
        mrfStage: data?.rthProcess || "-",
        mrfCreationDate: formatDate(data.createdAt) || "-",
        ageingInDays: calculateAgeing(data?.createdAt),
        recruiterLead:
          data?.assignedRecruiterLead
            ?.map((recruiter: any) => recruiter?.userName)
            .join(",") || "-",
        recruiter:
          data?.assignedRecruiter
            ?.map((recruiter: any) => recruiter?.userName)
            .join(", ") || "-",
      }));
      return { headerNames, rows };
    } else if (selectedTab === "Department wise MRF") {
      const rows = currentReportData?.map((data, index) => ({
        sNo: index + 1 || "-",
        mrfId: data.seqId || "-",
        mrfTitle: data.positionTitle || "-",
        mrfCategory: data?.typeOfRth || "-",
        mrfSubcategory: data?.modeOfRth || "-",
        mrfStage: data?.rthProcess || "-",
        departmant: data?.department?.departmentName || "-",
        mrfCreationDate: formatDate(data.createdAt) || "-",
        ageingInDays: calculateAgeing(data?.createdAt),
        recruiterLead:
          data?.assignedRecruiterLead
            ?.map((recruiter: any) => recruiter?.userName)
            .join(",") || "-",
        recruiter:
          data?.assignedRecruiter
            ?.map((recruiter: any) => recruiter?.userName)
            .join(", ") || "-",
      }));
      return { headerNames, rows };
    } else if (selectedTab === "Client wise MRF") {
      const rows = currentReportData?.map((data, index) => ({
        sNo: index + 1 || "-",
        mrfId: data.seqId || "-",
        mrfTitle: data.positionTitle || "-",
        mrfCategory: data?.typeOfRth || "-",
        mrfSubcategory: data?.modeOfRth || "-",
        mrfStage: data?.rthProcess || "-",
        clientName: data?.clientName || "-",
        mrfCreationDate: formatDate(data.createdAt) || "-",
        ageingInDays: calculateAgeing(data?.createdAt),
        recruiterLead:
          data?.assignedRecruiterLead
            ?.map((recruiter: any) => recruiter?.userName)
            .join(",") || "-",
        recruiter:
          data?.assignedRecruiter
            ?.map((recruiter: any) => recruiter?.userName)
            .join(", ") || "-",
      }));
      return { headerNames, rows };
    }
  } else if (
    selectedTab === "Selection Rate" &&
    (roleName === "TALENTACQUISITIONHEAD" ||
      roleName === "RECRUITER" ||
      roleName === "RECRUITERLEAD")
  ) {
    const headerNames = [
      "S. No",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subcategory",
      "Position",
      "Resumes Submitted",
      "Closed Position",
      "Selection %",
    ];

    const rows = currentReportData?.map((data, index) => ({
      sNo: index + 1 || "-",
      mrfId: data.seqId || "-",
      mrfTitle: data.positionTitle || "-",
      mrfCategory: data?.typeOfRth || "-",
      mrfSubcategory: data?.modeOfRth || "-",
      position: data?.numOfOpenPosition || 0,
      resumesSubmitted: data?.resumesCount || 0,
      closedPosition: data?.numOfClosedPosition || 0,
      selectionPercentage: calculateSelectionPercentage(
        data?.numOfClosedPosition || 0,
        data?.resumesCount || 0
      ),
    }));
    return { headerNames, rows };
  } else if (
    selectedTab === "MRF Category Report" &&
    (roleName === "TALENTACQUISITIONHEAD" ||
      roleName === "RECRUITER" ||
      roleName === "RECRUITERLEAD")
  ) {
    const headerNames = [
      "S no",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subtype",
      "Position",
      "Recruiter",
      "Recruiter Lead",
    ];

    const rows = currentReportData?.map((data, index) => ({
      sNo: index + 1 || "-",
      mrfId: data.seqId || "-",
      mrfTitle: data.positionTitle || "-",
      mrfCategory: data?.typeOfRth || "-",
      mrfSubcategory: data?.modeOfRth || "-",
      position: data?.numOfOpenPosition || 0,
      recruiterLead:
        data?.assignedRecruiterLead
          ?.map((recruiter: any) => recruiter?.userName)
          .join(",") || "-",
      recruiter:
        data?.assignedRecruiter
          ?.map((recruiter: any) => recruiter?.userName)
          .join(", ") || "-",
    }));
    return { headerNames, rows };
  } else if (
    selectedTab === "Overall report" &&
    (roleName === "TALENTACQUISITIONHEAD" ||
      roleName === "RECRUITER" ||
      roleName === "RECRUITERLEAD")
  ) {
    const headerNames = [
      "S no",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subcategory",
      "MRF creation Date",
      "Position",
      "Recruiter Lead",
      "Recruiter",
      "Candidate",
      "MRF Stage",
      "Interview Stage",
      "Interview Status",
      "L1 Interviewer Name",
      "L2 Interviewer Name",
      "Ageing / Hiring Cycle(in days)",
      "Closed date",
    ];

    const rows = currentReportData?.map((data, index) => ({
      sNo: index + 1 || "-",
      mrfId: data?.rthRequest?.seqId || "-",
      mrfTitle: data?.rthRequest?.positionTitle,
      mrfCategory: data?.rthRequest?.typeOfRth || "-",
      mrfSubcategory: data?.rthRequest?.modeOfRth || "-",
      mrfCreationDate: formatDate(data?.rthRequest?.createdAt) || "-",
      position: data?.rthRequest?.numOfOpenPosition || "-",
      recruiterLead:
        data?.rthRequest?.assignedRecruiterLead
          ?.map((recruiter: any) => recruiter?.userName)
          .join(",") || "-",
      recruiter:
        data?.rthRequest?.assignedRecruiter
          ?.map((recruiter: any) => recruiter?.userName)
          .join(", ") || "-",
      candidate: data?.candidates?.firstName || "-",
      mrfStage: data?.rthRequest?.rthProcess || "-",
      interviewStage: "-",
      interviewStatus: data?.interview?.status || "-",
      l1InterviewerName: "-",
      l2InterviewerName: "-",
      ageingHiringCycleInDays:
        calculateAgeing(data?.rthRequest?.createdAt) || "-",
      closedDate: data?.candidates?.updatedAt || "-",
    }));
    return { headerNames, rows };
  } else if (
    selectedTab === "Candidate Interview Status" &&
    (roleName === "TALENTACQUISITIONHEAD" ||
      roleName === "RECRUITER" ||
      roleName === "RECRUITERLEAD")
  ) {
    const headerNames = [
      "S no",
      "MRF ID",
      "MRF Title",
      "MRF Category",
      "MRF Subtype",
      "Position",
      "Screening",
      "L1 Interview",
      "L2 Interview",
      "HR Round",
      "Other Status",
    ];
    const rows = currentReportData?.map((data, index) => ({
      sNo:index+1 || "N/A",
      mrfId:data?.rthRequest?.seqId || "N/A",
      mrfTitle: data?.rthRequest?.positionTitle|| "N/A",
      mrfCategory: data?.rthRequest?.typeOfRth||"N/A",
      mrfSubtype: data?.rthRequest?.modeOfRth||"N/A",
      position: data?.rthRequest?.numOfOpenPosition||"N/A",
      screening:data?.screeningCount || 0,
      l1Interview:data?.l1InterviewCount || 0,
      l2Interview:data?.l2InterviewCount || 0,
      hr:data?.hrCount || 0,
      other:data?.otherCount || 0,
    }));
    return { headerNames, rows };
  }

  return { headerNames: [], rows: [] };
};
