import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, InputAdornment, InputBase} from "@mui/material";
import MyRequestsCard from "./MyrequestsCard";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";
import { getMyRequestsAPIData } from "../../../../Crud/card";
import { useAppSelector } from "../../../../redux/hooks";
import SearchIcon from "@mui/icons-material/Search";
import { Job } from "../../commoncard/types";
import { createTheme, ThemeProvider } from '@mui/material/styles';


const customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,        // Extra-small: <600px
      sm: 600,      // Small: 600px to 960px
      md: 960,      // Medium: 960px to 1280px
      lg: 1380,     // Large: 1280px to 1920px
      xl: 1920,     // Extra-large: >1920px
    },
  },
});


interface MyRequestsCardProps {
  drawerOpen: boolean;
  setLowCount: Function;
  setMediumCount: Function;
  setHighCount: Function;
  setL1StatusPending: Function;
  setL1StatusDeclined: Function;
  setL2StatusPending: Function;
  setL2StatusApproved: Function;
  setL2StatusDeclined: Function;

}

const MyRequest: React.FC<MyRequestsCardProps> = ({
  drawerOpen,
  setLowCount,
  setHighCount,
  setMediumCount,
  setL1StatusPending,
  setL1StatusDeclined,
  setL2StatusPending,
  setL2StatusApproved,
  setL2StatusDeclined
}) => {
  const [data, setData] = useState<Job[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userId = localStorage.getItem("userId") ?? "";
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
console.log("loading",loading);
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    if (searchQuery.trim()) {
      setSearchLoading(true);
    } else {
      setSearchLoading(false);
    }

    const timeoutId = setTimeout(() => {
      setLoading(true);
      getMyRequestsAPIData(userId, teamHeadFilter, searchQuery)
        .then((res) => {
          setData(res?.data?.listOfRth);
          setLowCount(res?.data?.count?.MyRequestPriorityCount?.LowCount);
          setHighCount(res?.data?.count?.MyRequestPriorityCount?.HighCount);
          setMediumCount(res?.data?.count?.MyRequestPriorityCount?.MediumCount);
          setL1StatusPending(res?.data.count?.MyRequestL1L2COUNT?.L1StatusPending);
          setL1StatusDeclined(res?.data?.count?.MyRequestL1L2COUNT?.L1StatusDeclined);
          setL2StatusPending(res?.data?.count?.MyRequestL1L2COUNT?.L2StatusPending);
          setL2StatusApproved(res?.data?.count?.MyRequestL1L2COUNT?.L2StatusApproved);
          setL2StatusDeclined(res?.data?.count?.MyRequestL1L2COUNT?.L2StatusDeclined);
        })
        .catch(() => {
          setData([]);
        })
        .finally(() => {
          setLoading(false);
          setSearchLoading(false);
        });
    }, 300);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [userId, searchQuery, teamHeadFilter, setHighCount, setL1StatusDeclined, setL2StatusApproved, setL2StatusPending, setL1StatusPending, setL2StatusDeclined, setLowCount, setMediumCount]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "300px" }}>
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <>
    <ThemeProvider theme={customTheme}>
      <Grid
        item
        sx={{ marginTop: data.length > 0 ? "-54px" : "-58px", paddingLeft:"10px" }}
        position="absolute"
      >
        <InputBase
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search MRF..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
            </InputAdornment>
          }
          sx={{
            border: "1px solid #ccc",
            borderRadius: 1,
            padding: 0,
            backgroundColor: "white",
            minHeight: "3px",
            fontSize: 14,
            ".MuiInputBase-input": {
              padding: "5px 5px",
            },
          }}
        />
        {searchLoading && (
          <CircularProgress
            size={20}
            sx={{
              position: 'absolute',
              right: '10px',
              top: '25%',
              transform: 'translateY(-50%)',
              color: '#2F54EB',
            }}
          />
        )}
      </Grid>
      <Box>
        {(data.length > 0) ? (
          <Grid
            container
            spacing={1}
            // sx={{ display: "flex", flexWrap: "wrap" }}
            columns={12}
          >
            {data.map((job, index) => (
              // <Grid item xs={drawerOpen ? 3 : 2.4}
              
              //  key={index}>
              <Grid 
              item 
              // xs={12} 
              // sm={6}  
              // md={drawerOpen ? 6 : 4}  
              // lg={drawerOpen ? 3 : 2.4}  
              // xl={drawerOpen ? 3 : 2.4} 
              xs={12} 
              sm={6}  
              md={drawerOpen ? 4 : 3}  
              lg={drawerOpen ? 3 : 2.4}  
              xl={drawerOpen ? 3 : 2.4} 
              key={index}
            >
               
                <MyRequestsCard job={job} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "300px",
            }}
          >
            <img
              src={NoDataAvail}
              alt="No Data Available"
              style={{ maxWidth: "200px" }}
            />
          </Box>
        )}
      </Box>
      </ThemeProvider>
    </>
  );
};

export default MyRequest;