import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import BulkUploadForm from './forms';
import AdditionalInformation from './AdditionalInformation';
import Address from './AddressForm';
import Education from './EducationForm';
import Review from './ReviewForm';
import FileList from './resumeListGrid';
import CustomTabs from '../../../common/tabs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Experience from './ExperienceForm';
import { addCandidate, editCandidate, getAllResumes, getResumeUrlAndData } from '../../../../redux/features/resume';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { clearSelectedCity, clearSelectedCountry, clearSelectedFile, clearSelectedState, increment, setCompletedFile } from '../../../../redux/features/resumeSlice';
import { fetchAllResumes } from '../../../../redux/features/fetchResumeThunk';

 
const Forms = () => {
  const [tabIndex, setTabIndex] = useState(0); 
  const [formValues, setFormValues] = useState<any>({
    educations:[],
    experience:[],
  });
  
  const totalFileCount=useAppSelector((state)=>state.fetchResume.totalResumes);
  const hasRun = useRef(false); 
  const dispatch=useAppDispatch();
  const fileCount=useAppSelector((state)=>state.resume.count);
  const [selectedFileData, setSelectedFileData] = useState<any>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const[otherErrors,setOtherErrors]=useState<{[key:string]:string}>({});
  const [aadharError,setAadharError]=useState<string | null>(null);
  const [aadharFile,setAadharFile]=useState<File[]>([]);
  const navigate = useNavigate();
  const location=useLocation();
  const path=location.pathname.includes('BulkUpload');
  const{jobTitle,id}=useParams();
  const selectedFiles=useAppSelector((state)=>state.resume.selectedFiles);
  const [draftFilesCount, setDraftFilesCount] = useState(0);  
  const[selectedState,setSelectedState]=useState<any>([]);
  const[selectedCountry,setSelectedCountry]=useState<any>([]);
  const[selectedCity,setSelectedCity]=useState<any>([]);
    
  const drawerOpen = true;
  const [uploadedResumes, setUploadedResumes] = useState<any[]>([]);
  const [selectedFile, setSelectedFile] = useState<any>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [open, setOpen] = useState(false);
  // const { resumeUrl } = selectedFileData || {};
  const { resumeUrl } = path ? selectedFileData : formValues;
  const recId=localStorage.getItem('userId');

  
  
  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleClosePreviewDialog = () => {
    setOpen(false);
  };

  const handleReviewLaterClick = () => {
    setDialogOpen(true);
  };
 
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
 
  const handleReviewLater=()=>{
    dispatch(clearSelectedFile());
    navigate(-1);
  }
  
  const handleSubmit=async ()=>{
    let payload;
    if(path){
     payload={
     ...formValues,
     rthId:id,
     resumeUrl:selectedFileData.resumeUrl,
     workExperience:formValues.workExperience==='Yes',
     status:'Completed',
     candidateType:'Applied'
    }
  }
   if(!path){
     payload={
      ...formValues,
      rthId:id,
      workExperience:formValues.workExperience==='Yes',
      status:'Completed',
      candidateType:'Applied'
     }
   }   
    const resultAction = await dispatch(editCandidate({candidateId:selectedFileData.candidateId,data:payload}));
   
    if (editCandidate.fulfilled.match(resultAction)) {
      const { status } = resultAction.payload || {}; 
     
      if(status===true){
      dispatch(setCompletedFile(selectedFileData?.id));
     dispatch(increment());
     dispatch(clearSelectedCity());
     dispatch(clearSelectedCountry());
     dispatch(clearSelectedState());
      fetchResumes(id);
      handleSkipProfile();
      setFormValues([]);
      setAadharFile([]);
      toast.success('Candidate form submitted successfully');
      }
      else if(status===400){
        toast.error('Check the data');
      }
    } 
    else {
      toast.error('Failed to submit candidate form');
    } 
      
   };
   
   const handleSubmitCandidate=async()=>{
    const payload={
      ...formValues,
      rthId:id,
      recruiterId:recId,
      workExperience:formValues.workExperience==='Yes',
      status:'Completed',
      candidateType:'Applied'
    }
    const resultAction=await dispatch(addCandidate(payload));
     if(addCandidate.fulfilled.match(resultAction)){
      const{status,message}=resultAction.payload||{};
      if(status===true){
        toast.success('Candidate Added Successfully');
        setFormValues([]);
        setAadharFile([]);
        dispatch(clearSelectedCity());
        dispatch(clearSelectedCountry());
        dispatch(clearSelectedState());
        navigate(`/dashboard/MRH/${jobTitle}/${id}`)
      }
      else if(status===400){
        toast.error(message||'Failed to Submit the candidate details')
      }
     }
     else{
      toast.error("Failed to Submit Candidate")
     }
   }

  const tabsData = [
    { label: 'Personal Information' },
    { label: 'Additional Information' },
    { label: 'Address' },
    { label: 'Experience' },
    { label: 'Education' },
    { label: 'Review' },
  ];
 
  const handleSelectFile = (fileId: any) => {
    
    setSelectedFile(fileId); 
        
    const filteredFile = uploadedResumes.find((file:any) => file.id === fileId); 
    if (filteredFile) {
      setSelectedFileData(filteredFile);
      setFormValues((prevValues:any)=>({
        ...prevValues,
        firstName: selectedFileData.firstName === "Not Identified" ? "" : selectedFileData.firstName,
        emailAddress:selectedFileData.emailAddress=== "Not Identified" ? "" :selectedFileData.emailAddress,
        phoneNumber:selectedFileData.phoneNumber=== "Not Identified" ? "" :selectedFileData.phoneNumber,
      }))
    }
  };
 
  const handleDelete = (index: number) => {
    const newResumes = uploadedResumes.filter((_, i) => i !== index);
    setUploadedResumes(newResumes);
  };
 
  const handleNextPage = () => {
    const isValid = validateForm();
      const hasOtherErrors = Object.values(otherErrors).some((error) => {
      if (Array.isArray(error)) {
        return error.some((item) => Object.values(item).some((val) => val !== ""));
      } else if (typeof error === 'object') {
        return Object.values(error).some((val) => val !== "");
      }
      return error !== "";
    });

      if (isValid && !hasOtherErrors) {
      if (tabIndex < tabsData.length - 1) {
        setTabIndex((prevIndex) => prevIndex + 1);
      }
    }
  };
  
  
  
  const handlePreviousPage = () => {
    if (tabIndex > 0) {
      setTabIndex((prevIndex) => prevIndex - 1);
    }
  };
 
  const handleBack=()=>{
    dispatch(clearSelectedFile());
    navigate(`/dashboard/MRH/${jobTitle}/${id}`);
  }

  const handleSkipProfile = () => {
    const currentFileIndex = uploadedResumes.findIndex((file: any) => file.id === selectedFile);
    const nextFileIndex = currentFileIndex + 1;
  
    
    if (nextFileIndex < uploadedResumes.length) {
      setSelectedFile(uploadedResumes[nextFileIndex].id); 
      setSelectedFileData(uploadedResumes[nextFileIndex]);
      setTabIndex(0);
      // setFormValues([]);
    } else {
      console.log("No more files to skip.");
    }
  };

  
  const fetchResumes=(rthId:any)=>{
    getAllResumes(rthId).then((response:any)=>{
    //  setFiles(response.data);
     const filteredResumes = response.data.map((resume: any,index: number) => {
       const fileUrl = resume?.resumeUrl;
       const fileName = fileUrl?.substring(fileUrl?.lastIndexOf('/') + 1, fileUrl?.indexOf('?'));

       return {
         resumeUrl: fileUrl,
         status: resume?.status,
         id:index,
         name: fileName,
         candidateId:resume?.candidateId,
         firstName:resume?.firstName,
         emailAddress:resume?.emailAddress,
         phoneNumber:resume?.phoneNumber
       };
     });

     setUploadedResumes(filteredResumes);
     console.log((filteredResumes.filter((file: any) => file?.status === 'Completed').length),'completedFilesCount');
     

   });
 }

 const validateAddress = (address:any) => {
  return address?.trim() === '' ? 'Address cannot be empty or contain only spaces.' : '';
};

 const validateForm = () => {
  const newErrors: { [key: string]: string } = {};
  if (!formValues.howDidYouHeardAbtUs) {
    newErrors.howDidYouHeardAbtUs = 'This is required';
  }
  const namePattern = /^[A-Za-z]+$/;

if (!formValues.firstName) {
  newErrors.firstName = 'First Name is required';
} else if (formValues.firstName.length > 100) {
  newErrors.firstName = 'First Name cannot exceed 100 characters';
} else if (!namePattern.test(formValues.firstName)) {
  newErrors.firstName = 'First Name should contain only alphabets';
}

if (!formValues.lastName) {
  newErrors.lastName = 'Last Name is required';
} else if (formValues.lastName.length > 100) {
  newErrors.lastName = 'Last Name cannot exceed 100 characters';
} else if (!namePattern.test(formValues.lastName)) {
  newErrors.lastName = 'Last Name should contain only alphabets';
}
  
  if (!formValues.expectedCtc) {
    newErrors.expectedCtc = 'Expected CTC is required';
  } else if (!/^\d{1,10}$/.test(formValues.expectedCtc)) {
    newErrors.expectedCtc = 'Expected CTC must be a numeric value up to 10 digits';
  }
  
  if (!formValues.noticePeriods) {
    newErrors.noticePeriods = 'Required field';
  } else if (!/^\d{1,3}$/.test(formValues.noticePeriods)) {
    newErrors.noticePeriods = 'Notice period must be a numeric value up to 3 digits';
  }
  
  if (!formValues.currentCtc) {
    newErrors.currentCtc = 'Current CTC is required';
  } else if (!/^\d{1,10}$/.test(formValues.currentCtc)) {
    newErrors.currentCtc = 'Current CTC must be a numeric value up to 10 digits';
  }
   
  if (!formValues.emailAddress) {
    newErrors.emailAddress = 'Email Address is required';
  } else if (!/\S+@\S+\.\S+/.test(formValues.emailAddress)) {
    newErrors.emailAddress = 'Email Address is invalid';
  }
  if (!formValues.phoneNumber) {
    newErrors.phoneNumber = 'Phone Number is required';
  } else if (!/^\d{10}$/.test(formValues.phoneNumber)) {
    newErrors.phoneNumber = 'Phone Number must be 10 digits';
  }

  // if (!formValues.aadharNumber) {
  //   newErrors.aadharNumber = 'Aadhar Number is required';
  // } else if (!/^\d{12}$/.test(formValues.aadharNumber)) {
  //   newErrors.aadharNumber = 'Aadhar Number must be 12 digits';
  // }
  if (!formValues.dateOfBirth) {
    newErrors.dateOfBirth = 'Date of Birth is required';
  } else {
    // DOB validation to ensure it is not a future date
    const dob = new Date(formValues.dateOfBirth);
    const today = new Date();
    if (dob > today) {
      newErrors.dateOfBirth = 'Date of Birth cannot be a future date';
    }
  }

  if (!formValues.gender) {
    newErrors.gender = 'Gender is required';
  }
  // if(aadharFile.length <=0){
  //   setAadharError('Aadhar card is required');
  // }
  if (formValues.preEmployed === undefined || formValues.preEmployed === null) {
    newErrors.preEmployed = 'This field is required';
  }

  if(tabIndex===2){
    const addressLine1Error = validateAddress(formValues?.address?.addressLine1);
    const addressLine2Error = validateAddress(formValues?.address?.addressLine2);

    if (addressLine1Error || addressLine2Error) {
      setErrors({
        ...errors,
          addressLine1: addressLine1Error,
          addressLine2: addressLine2Error,
      });
      return; // Stop submission if there are errors
    }
   }  
  setErrors(newErrors);

  return Object.keys(newErrors).length === 0;
};

  useEffect(()=>{
  fetchResumes(id);
  },[id])
  
  useEffect(() => {
    if (id) {
      dispatch(fetchAllResumes(id));
    }
  }, [id, dispatch]);

  useEffect(()=>{
    if(totalFileCount ){
      setDraftFilesCount(totalFileCount?.filter((file: any) => file?.status === 'Draft').length);     
      hasRun.current = true;
    }
  },[totalFileCount]);


  useEffect(()=>{
    if(!path){

 const uploadFile=async()=>{
 if(selectedFiles.length > 0){
  const formdata=new FormData();
  selectedFiles.forEach((file)=>{
    formdata.append('resume',file);
  });

  try{
    const response=await dispatch(getResumeUrlAndData({data:formdata,rthId:id}));
    if(getResumeUrlAndData.fulfilled.match(response)){
      const {status,message}=response.payload||{};
      if(status===true){
        if (response?.payload) {
          setFormValues((prevValues:any) => ({
            ...prevValues,
            resumeUrl: response?.payload?.data?.resumeUrl,
            firstName:response?.payload?.data?.name,
            emailAddress:response?.payload?.data?.email,
            phoneNumber:response?.payload?.data?.mobileNumber
          }));
        }
      }
      else if(status===false){
        toast.error(message);
        navigate(`/dashboard/MRH/${jobTitle}/${id}`)
      }
    }
    
  } catch(error){
    console.error('Error in uploading the file',error);
  }
 }
    }
    uploadFile();
  }
  
  },[selectedFiles,dispatch,path,id,navigate,jobTitle])

  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return <BulkUploadForm formValues={formValues} setFormValues={setFormValues} handleNextPage={handleNextPage} validateForm={validateForm} 
        errors={errors} aadharError={aadharError} setAadharFile={setAadharFile} aadharFile={aadharFile} setErrors={setErrors} setAadharError={setAadharError} />;
      case 1:
        return <AdditionalInformation formValues={formValues} setFormValues={setFormValues} errors={otherErrors} setErrors={setOtherErrors} />;
      case 2:
        return <Address formValues={formValues} setFormValues={setFormValues} errors={otherErrors} setErrors={setOtherErrors} selectedState={selectedState} setSelectedState={setSelectedState}
        selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} selectedCity={selectedCity} setSelectedCity={setSelectedCity} />;
      case 3:
        return <Experience formValues={formValues} setFormValues={setFormValues} errors={otherErrors} setErrors={setOtherErrors} />;
      case 4:
        return <Education formValues={formValues} setFormValues={setFormValues} errors={otherErrors} setErrors={setOtherErrors}/>;
      case 5:
        return path ? (
        <Review formValues={formValues} setFormValues={setFormValues} selectedFile={selectedFileData} handlePreviousPage={handlePreviousPage} handleSubmit={handleSubmit} setSelectedFile={setSelectedFileData} handleDelete={handleDelete}/>
        ):(
          <Review formValues={formValues} setFormValues={setFormValues} selectedFile={formValues} handlePreviousPage={handlePreviousPage} handleSubmit={handleSubmit} setSelectedFile={setSelectedFileData} handleDelete={handleDelete}/>
        )
    }
  };

  const getFileExtension = (url:any) => {
    const urlWithoutQuery = url?.split('?')[0]; // Remove query parameters
    return urlWithoutQuery?.split('.').pop().toLowerCase();
  };


  const fileExtension = getFileExtension(resumeUrl);

  const renderPreview = () => {
    if (fileExtension === 'pdf') {
      // Use Google Drive viewer for PDFs
      const googleDriveViewerUrl = `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(resumeUrl)}`;
      return (
        <iframe
          src={googleDriveViewerUrl}
          title="PDF Preview"
          style={{ width: '100%', height: '500px', border: 'none' }}
        ></iframe>
      );
    } 
    else if (fileExtension === 'doc' || fileExtension === 'docx') {
      // Use Google Docs Viewer to preview DOC/DOCX files
      const googleDocsViewerUrl = `https://docs.google.com/gview?url=${encodeURIComponent(resumeUrl)}&embedded=true`;
      return (
        <iframe
          src={googleDocsViewerUrl}
          title="DOC/DOCX Preview"
          style={{ width: '100%', height: '500px', border: 'none' }}
        ></iframe>
      );
    } else {
      return <p>No file available for preview.</p>;
    }
  };


  
  const renderButtons = () => {
 
    if(path){
    if (tabIndex === 0) {
      return null; // No buttons will be displayed for the first tab
    }
 
    if (tabIndex === 5 || tabIndex === 6) {
      return (
        <Box sx={{display:'flex',justifyContent:'flex-end',mr:'10px'}}>
           <Button
          // variant="outlined"
          onClick={handleSkipProfile}
          sx={{border:'InactiveBorder',textTransform:'none'}}
        >
          skip this profile
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
          sx={{textTransform:'none'}}
        >
                
        Save 
        </Button>
        </Box>
      );
    }
    }

    if(!path){
      if (tabIndex === 0) {
        return null; 
      }
   
      if (tabIndex === 5 || tabIndex === 6) {
        return (
          <Box sx={{display:'flex',justifyContent:'flex-end',mr:'10px'}}>
             <Button
            // variant="outlined"
            onClick={handlePreviousPage}
            sx={{border:'InactiveBorder',textTransform:'none'}}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleSubmitCandidate}
            sx={{textTransform:'none'}}
          >
                  
          Save & Submit
          </Button>
          </Box>
        );
      }
    }
 
    return (
      <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, mb: 2,gap:'10px' }}>
        <Button
          // variant="outlined"
          onClick={handlePreviousPage}
          sx={{border:'InactiveBorder',color:'#848484',textTransform:'none'}}
          disabled={tabIndex === 0} // Disable "Previous" button on the first tab
        >
          Previous
        </Button>
        <Button variant="contained" color="primary" onClick={handleNextPage} sx={{mr:'10px',textTransform:'none'}}>
          Continue
        </Button>
      </Box>
      </Box>
    );
  };
  
  return (
 
  <>
  
  <Box sx={{display:'flex',flexDirection:'row',gap:'8px',mt:'4px',mb:'8px',justifyContent:'space-between'}} >
    <Box sx={{display:'flex',flexDirection:'row'}}>
    <ArrowBackIcon sx={{color:'grey',cursor:'pointer', ml:2}} onClick={handleBack}/>
    {path && (
      <Typography sx={{ml:'6px',fontWeight:'500'}}>Bulk Upload</Typography>
    )}
      </Box>
      { path && (
      <Box sx={{display:'flex',justifyContent:'flex-end'}}>
    <Button variant='contained' sx={{width:'270px',fontSize:'14px',textTransform:'none',backgroundColor:'#1677FF',color:'white',borderRadius:'4px',mr:'4px'}}
      onClick={handleReviewLaterClick}
    >Review Later & Upload All Profile</Button>
    </Box>
      )}
    </Box>
  
    <Paper sx={{ height:'82vh', display: 'flex',mr:'6px',m:1, overflow:'hidden'}}>
        <Box
          sx={{
            // width: '240px',
            flexShrink: 0,
            borderRight: '1px solid #E5E3E3',
            mr: '4px',
            // ml: '1px',
          }}
        >
          {path &&
          <Box sx={{ width: '16vw', height: '72vh', mt: '10px', overflowY: 'auto', overflowX: 'hidden' }}>
            <FileList files={uploadedResumes} selectedFile={selectedFile} onSelect={handleSelectFile} onRemove={handleDelete} />
          </Box>
           }
        </Box>
        <Box component="main" sx={{ flexGrow: 1, ml: '10px' }}>
          <Box sx={{justifyContent:'space-between',display:'flex'}}>
          <CustomTabs tabsData={tabsData} tabIndex={tabIndex} setTabIndex={setTabIndex} drawerOpen={drawerOpen} validateTabChange={validateForm}/>
          <Tooltip title={'View Resume'}>
            <Card sx={{mt:'16px',height:'30px',mr:'16px',width:'30px',cursor:'pointer'}} onClick={handleOpenDialog}>
          <VisibilityOutlinedIcon sx={{fontSize:'16px',mt:'7px',ml:'6px',cursor:'pointer'}}/>
          </Card>
          </Tooltip>
          </Box>
          <Box sx={{position:"relative"}}>
            <Paper elevation={0} sx={{height:'72vh',overflowY:'auto',pt:'1  0px', m:1}}>
          {renderTabContent()}
             </Paper>
         
          <Box sx={{position:'absolute',bottom:'20px',right:'20px'}}>
          {renderButtons()}
          </Box>
          </Box>
        </Box>
      </Paper>
 
      <Dialog
        open={dialogOpen}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        sx={{borderRadius:'20px'}}
      >
        <DialogTitle sx={{pb:'0'}}>
          <Typography sx={{fontSize:'20px',fontWeight:'600'}}>
          You've completed {fileCount} out of {draftFilesCount} candidate profiles.
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{display:'flex',justifyContent:'center', fontSize:'14px', mt:'4px'}}>
            Would you like to review later?
          </DialogContentText>
        </DialogContent>
        <Box sx={{display:'flex', justifyContent:'center'}}>
        <DialogActions sx={{mb:'32px'}}>
          <Button onClick={handleCloseDialog} variant="outlined" sx={{border:'InactiveBorder',color:'#848484',textTransform:'none'}}>
            Continue Editing
          </Button>
          <Button onClick={handleReviewLater} variant="contained" color="primary" sx={{color:'white',backgroundColor:'#1677FF',borderRadius:'8px',textTransform:'none'}}>
            Review Later
          </Button>
        </DialogActions>
        </Box>
      </Dialog>
      <Box sx={{width:'80vw'}}>
      <Dialog
        open={open}
        onClose={handleClosePreviewDialog}
        // TransitionComponent={Transition}
        sx={{
          '& .MuiDialog-paper': {
            width: '80%', // Adjust this value to increase the width
            maxWidth: 'none', // Ensures it doesn't use the default max-width
          },
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="h6">Resume Preview</Typography>
          <IconButton onClick={handleClosePreviewDialog}>
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Placeholder for PDF Review */}
        <Box
          sx={{
            height: '90%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f5f5f5', // Light grey background
            p: 2,
          }}
        >
         {resumeUrl ? renderPreview() : <p>No file available for preview.</p>}
        </Box>
      </Dialog>
      </Box>
      </>
  );
};
 
export default Forms;
