import React, { useCallback, useEffect, useState } from "react";
import {
  getComment,
  likeAndUnlikeChild1Comment,
  likeAndUnlikeMainComment,
} from "../../Crud/comments";
import { useLocation, useParams } from "react-router-dom";
import { Avatar, Box, CircularProgress, Typography } from "@mui/material";
import { stringAvatar } from "../../helpers/stringAvatar";
import { formattedDateAndTime } from "../../helpers/formattedDateAndTime";
import { toast } from "react-toastify";
import {
  getChild1ReplyComment,
  getMainReplyComment,
} from "../../redux/features/commentsSlice";
import { useDispatch } from "react-redux";
import { useUserId } from "../../hooks/useUserId";
import { useCommentsState } from "../../hooks/useCommentsState";

const CommentsView = () => {
  const { getDataRefresh }: any = useCommentsState();
  const userId = useUserId();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [likeUnlike, setLikeUnlike] = useState<any>([]);
  const location = useLocation();
  const dispatch = useDispatch();
  const getId = useParams();
  // const getId = getId.id;
  const { id } = location.state || {};

  const fetchComments = useCallback(async () => {
    if (getId.id||id) {
      setLoading(true);
      try {
        const res = await getComment(getId.id??id);
        setData(res.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  }, [getId.id, id]);

  useEffect(() => {
    fetchComments();
  }, [ fetchComments, getDataRefresh]);

  const handleLikeAndUnlike = useCallback(
    (item: any) => {
      likeAndUnlikeMainComment(item.commentId, userId)
        .then((res) => {
          toast.success(res.message);
          setLikeUnlike((prev:any) => {
            const existingItemIndex = prev.findIndex((item:any) => item.id === res.data.commentId);
            if (existingItemIndex !== -1) {
              const updatedArray = [...prev];
              updatedArray[existingItemIndex].count = res.data.likeCount;
              return updatedArray;
            } else {
              return [
                ...prev,
                { id: res.data.commentId, count: res.data.likeCount },
              ];
            }
          });
        })
        .catch(() => toast.error("Failed to add like or unlike"))
    },
    [userId]
  );

  const handleLikeAndUnlikeChild1 = useCallback(
    (replyId: string, parentId: string) => {
      likeAndUnlikeChild1Comment(parentId, replyId, userId)
        .then((res) => {
          toast.success(res.message);
          setLikeUnlike((prev:any) => {
            const existingItemIndex = prev.findIndex((item:any) => item.id === replyId);
            if (existingItemIndex !== -1) {
              const updatedArray = [...prev];
              updatedArray[existingItemIndex].count = res.data.likeCount;
              return updatedArray;
            } else {
              return [
                ...prev,
                { id: replyId, count: res.data.likeCount },
              ];
            }
          });
        })
        .catch(() => toast.error("Failed to add like or unlike"))
    },
    [userId]
  );

  const handleReply = (item: any, type: "main" | "child1") => {
    if (type === "main") {
      dispatch(getMainReplyComment(item));
      dispatch(getChild1ReplyComment({}));
    } else {
      dispatch(getChild1ReplyComment(item));
      dispatch(getMainReplyComment({}));
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "70vh",
        }}
      >
        <CircularProgress size="30px" />
      </Box>
    );
  }

  const renderReplies = (
    replies: any[],
    parentId: string,
    count?: number,
    id?: string
  ) => {
    
    return replies.map((reply: any) => {
      const countList = likeUnlike?.find((entry:any) => entry.id === reply.replyId);
return(
      <Box key={reply.replyId} ml={4}>
        <Box sx={{ display: "flex" }}>
          <Avatar
            src={reply.repliedBy.imageUrl}
            {...stringAvatar(reply.repliedBy.userName,"headerProfile")}
            sx={{ width: 30, height: 30 }}
          />
          <Box mx={1}>
            <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
              {reply.repliedBy.userName}
            </Typography>
            <Typography sx={{ fontSize: 12, color: "gray" }}>
              {reply.replyText}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            marginLeft: "40px",
            marginTop: "6px",
            marginBottom: "10px",
          }}
        >
            <Typography
              sx={{ fontSize: 10, color: "#1677FF", cursor: "pointer" }}
              onClick={() => handleLikeAndUnlikeChild1(reply.replyId, parentId)}
            >
              Like {countList?.count??reply.likeCount}
            </Typography>
            
          {id !== reply.replyId && (
            <Typography
              sx={{ fontSize: 10, color: "#1677FF", cursor: "pointer" }}
              onClick={() => {
                let data = { ...reply, parentId };
                handleReply(data, "child1");
              }}
            >
              Reply
            </Typography>
          )}

          <Typography sx={{ fontSize: 10, color: "gray" }}>
            {formattedDateAndTime(reply.repliedAt)}
          </Typography>
        </Box>
        {reply.replies &&
          reply.replies.length > 0 &&
          renderReplies(reply.replies, parentId,count ,id )}
      </Box>
    )});
  };

  const renderComment = (comment: any) => {
    const id = comment?.replies[0]?.replies[0]?.replies[0]?.replyId;
    const countList = likeUnlike?.find((entry:any) => entry.id === comment.commentId);

    return (
      <Box key={comment.commentId} mx={2}>
        <Box sx={{ display: "flex" }}>
          <Avatar
            src={comment.commentedBy.imageUrl}
            {...stringAvatar(comment.commentedBy.userName,"headerProfile")}
            sx={{ width: 30, height: 30 }}
          />
          <Box mx={1}>
            <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
              {comment.commentedBy.userName}
            </Typography>
            <Typography sx={{ fontSize: 12, color: "gray" }}>
              {comment.commentedText}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 3,
            marginLeft: "40px",
            marginTop: "6px",
            marginBottom: "10px",
          }}
        >
            <Typography
              sx={{ fontSize: 10, color: "#1677FF", cursor: "pointer" }}
              onClick={() => handleLikeAndUnlike(comment)}
            >
              Like {countList?.count??comment.likeCount}
            </Typography>
          <Typography
            sx={{ fontSize: 10, color: "#1677FF", cursor: "pointer" }}
            onClick={() => handleReply(comment, "main")}
          >
            Reply
          </Typography>
          <Typography sx={{ fontSize: 10, color: "gray" }}>
            {formattedDateAndTime(comment.commentedAt)}
          </Typography>
        </Box>
        {comment.replies &&
          comment.replies.length > 0 &&
          renderReplies(
            comment.replies,
            comment.commentId,
            comment.repliesCount,
            id
          )}
      </Box>
    );
  };

  return (
    <Box sx={{ height: "76vh", overflowY: "scroll", scrollbarWidth: "none" }}>
      {data.map((comment: any) => renderComment(comment))}
    </Box>
  );
};

export default CommentsView;
