import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RecruiterLeadState {
  recruiterLeadId: string[];
  recruiterLeadName: string[];
}

const initialState: RecruiterLeadState = {
  recruiterLeadId: [],
  recruiterLeadName: [],
};

const recruiterLeadSlice = createSlice({
  name: 'recruiterLead',
  initialState,
  reducers: {
    setRecruiterLeadState: (
      state,
      action: PayloadAction<RecruiterLeadState>
    ) => {
      state.recruiterLeadId = action.payload.recruiterLeadId;
      state.recruiterLeadName = action.payload.recruiterLeadName;
    },
    resetRecruiterLeadState: (state) => {
      state.recruiterLeadId = [];
      state.recruiterLeadName = [];
    },
  },
});

export const { setRecruiterLeadState, resetRecruiterLeadState } =
  recruiterLeadSlice.actions;

export default recruiterLeadSlice.reducer;
