import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import React, {  useRef, useState } from 'react'
import { getResumeUrlAndData } from '../../../../redux/features/resume';
import { useAppDispatch } from '../../../../redux/hooks';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
 
export default function UploadResume({selectedFile,setSelectedFile,handleNext,formValues,setFormValues}:any) {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch=useAppDispatch();
 const {id}=useParams();
  const handleBrowseClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
 
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const MAX_FILE_SIZE_MB = 2; 
    if (file) {
      // Check file size (in bytes)
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > MAX_FILE_SIZE_MB) {
        setError(`File size exceeds ${MAX_FILE_SIZE_MB} MB. Please upload a smaller file.`);
        setSelectedFile(null); // Clear any previously selected file
        return;
      }
      setError(null); 
      setSelectedFile(file);
    }
  };
 
const Md = useMediaQuery("(max-width: 1504px)");


  const handleContinue = async () => {
    if (selectedFile.length<=0) { 
      setError('Please upload a resume before proceeding');
      return;
    }
    setError(null);
    setLoading(true);
 
    const formData = new FormData();
    formData.append('resume', selectedFile); // Add the file to FormData
 
    try {
      const response = await dispatch(getResumeUrlAndData({data:formData,rthId:id})); // API call
      if(getResumeUrlAndData.fulfilled.match(response)){
        const {status,message}=response.payload||{};
        if(status===true){
          if (response?.payload) {
            setFormValues((prevValues: any) => ({
              ...prevValues,
              resumeUrl: response.payload.data?.resumeUrl,
              firstName:response?.payload?.data?.name=== "Not Identified" ? "" :response?.payload?.data?.name,
            emailAddress:response?.payload?.data?.email=== "Not Identified" ? "" :response?.payload?.data?.email,
            phoneNumber:
            response?.payload?.data?.mobileNumber === "Not Identified" || 
            response?.payload?.data?.mobileNumber === "0000000000" 
              ? "" 
              : response?.payload?.data?.mobileNumber
            }));
          }
          handleNext();
        }
        else if(status===false){
          toast.error(message);
          setSelectedFile(null);
        }
      }
       
    } catch (error) {
      console.error('Error in uploading file:', error);
    }
 
    setLoading(false);
    
  };
 
  
  return (
    <Box>
        <Box sx={{ml:'30px'}}>
            <Typography sx={{ fontSize: '20px', fontWeight: '500', mb: '27px',mt:'14px' }}>
        1.Upload Resume
        </Typography>
        </Box>
        <Box sx={{mt:'4px',ml:'30px'}}>
        <Typography sx={{color:'#cecccc'}}>Resume<span style={{color:'red'}}> *</span></Typography>
        </Box>
        <Box sx={{ml:'30px',mt:'6px'}}>
        <input
          type="file"
          accept=".pdf,.doc,.docx"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button
          onClick={handleBrowseClick}
          sx={{
            width:'260px',
            height:'40px',
            backgroundColor: '#f5f5f5',
            color: '#666666',
            border: '1px dashed #ccc',
            boxShadow: 'none',
            textTransform: 'none',
            cursor:'pointer',
           
          }}
        >
          Browse
        </Button>
        <Box sx={{display:'flex', flexDirection:'row'}}>
          <Box>
        {selectedFile && (
          <Typography sx={{ mt: '10px', color: '#1677FF' }}>
            Selected file: {selectedFile.name}
          </Typography>
        )}
        </Box>
        <Box sx={{ml:'5px'}}>
        {error && (
          <Typography sx={{ mt: '10px', color: 'red' }}>
            {error}
          </Typography>
        )}
        </Box>
        </Box>
 
      </Box>
      <Box sx={{ display: 'flex', alignItems:'flex-start', justifyContent:'flex-start', mt: Md? '50px' : '200px', ml: '30px' }}>
        <Button variant='contained' disabled={!selectedFile || loading} sx={{ textTransform: 'none', backgroundColor:'#1677FF', color:'#fff',  minWidth: '100px', }} onClick={handleContinue} >Continue</Button>
      </Box>
        </Box>
  )
}
 