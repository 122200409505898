// resumeSlice.ts

import { createSlice } from '@reduxjs/toolkit';
import { fetchAllResumes } from './fetchResumeThunk';


// Define the initial state
const initialState = {
  totalResumes: [],
  completedFilesCount: 0,
  error: {},
};

// Create the slice
const fetchResumeSlice = createSlice({
  name: 'resume',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllResumes.fulfilled, (state, action) => {
        state.totalResumes = action.payload;
        
      })
      .addCase(fetchAllResumes.rejected, (state, action) => {
        state.error = action.payload || 'An error occurred';
      });
  },
});

export default fetchResumeSlice.reducer;
