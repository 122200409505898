import { TableCell } from "@mui/material";
import React from "react";

interface CustomTableCellProps {
  children: React.ReactNode;
  hasBorder?: boolean;
  isHeader?: boolean;
}

const CustomTableCell: React.FC<CustomTableCellProps> = ({ children, hasBorder = true, isHeader = false }) => {
  return (
    <TableCell
      sx={{
        borderRight: hasBorder ? "1px solid #B5B5B5" : "none",
        backgroundColor: isHeader ? "#F9F9F9" : "#FFFFFF",
        fontSize: isHeader ? "0.75rem" : "0.75rem",
        fontWeight: isHeader ? "500" : "400",
      }}
    >
      {children}
    </TableCell>
  );
};

export default CustomTableCell;
