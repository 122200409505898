//   export const calculateDays = (createdAt: string): string => {
//     const createdDate: Date = new Date(createdAt);
//     const now: Date = new Date();
//     const differenceInMilliseconds: number = now.getTime() - createdDate.getTime();

//     const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
//     if (differenceInMinutes < 60) {
//         return differenceInMinutes === 0 ? 'Just now' : `${differenceInMinutes} minute${differenceInMinutes > 1 ? 's' : ''} ago`;
//     }

//     const differenceInHours = Math.floor(differenceInMinutes / 60);
//     if (differenceInHours < 24) {
//         return `${differenceInHours} hour${differenceInHours > 1 ? 's' : ''} ago`;
//     }

//     const differenceInDays = Math.floor(differenceInHours / 24);
//     return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
// };
export const calculateDays = (createdAt: string): string => {
    const createdDate: Date = new Date(createdAt);
    const now: Date = new Date();
    const differenceInMilliseconds: number = now.getTime() - createdDate.getTime();
  
    if (differenceInMilliseconds <= 0) {
      return 'Just now';
    }
  
    const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    if (differenceInMinutes < 60) {
      return differenceInMinutes === 0
        ? 'Just now'
        : `${differenceInMinutes} minute${differenceInMinutes > 1 ? 's' : ''} ago`;
    }
  
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    if (differenceInHours < 24) {
      return `${differenceInHours} hour${differenceInHours > 1 ? 's' : ''} ago`;
    }
  
    const differenceInDays = Math.floor(differenceInHours / 24);
    return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
  };
  
  export const calculateDaysForCandidateCard = (createdAt: string): string => {
    const createdDate = new Date(createdAt);
    const today = new Date();
  
    // Normalize the dates to ignore time
    createdDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
  
    const differenceInMilliseconds: number = today.getTime() - createdDate.getTime();
    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  
    if (differenceInDays === 0) {
      return 'Newly Arrived';
    }
  
    return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
  };
  