import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  SelectChangeEvent,
  MenuProps,
} from "@mui/material";

interface CommonSelectProps {
  labelId: string;
  label: string;
  name: string;
  value: string;
  onChange: (event: SelectChangeEvent) => void;
  options: { value: any; label: string }[];
  error?: boolean;
  helperText?: string;
}

const CommonSelect: React.FC<CommonSelectProps> = ({
  
  labelId,
  label,
  name,
  value,
  onChange,
  options,
  error,
  helperText,
}) => {

  const customMenuProps: Partial<MenuProps> = {
      PaperProps: {
        style: {
          width:'150px',
          maxHeight: 200, // Set the maximum height for the dropdown
          overflowY: 'auto', // Enable vertical scrolling
          overflowX:'auto'
        },
      },
    };
  
  return (
    <FormControl
      fullWidth
      size="small"
      error={error}
      sx={{ borderColor: "divider" }}
    >
      <InputLabel id={labelId} sx={{ fontSize: "0.8rem", fontWeight: 410, color: "gray"}}>
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        label={label}
        value={value}
        onChange={onChange}
        name={name}
        MenuProps={customMenuProps}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{`${helperText} field is required`}</FormHelperText>}
    </FormControl>
  );
};

export default CommonSelect;
