import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import ClosedIcon from '../../assets/images/closedIconRTH.svg';

interface Recruiter {
    userName: string;
    designation: string;
    imageUrl: string | null;
}

interface ClosedRecruiterProps {
    assignedRecruiters: Recruiter[];
}

const ClosedRecruiterList: React.FC<ClosedRecruiterProps> = ({ assignedRecruiters }) => {
    const visibleRecruiters = assignedRecruiters?.slice(0, 2);
    const extraCount = assignedRecruiters?.length - 2;

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', pt: '5px' }} gap={1}>
            {visibleRecruiters?.map((recruiter, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: '8px' }} >
                    <Avatar src={recruiter.imageUrl || ClosedIcon} alt={recruiter.userName} sx={{ width: 20, height: 20 }} />
                    <Box>
                        <Typography sx={{ fontSize: '12px', color: '#8C8C8C', fontWeight: 'bold' }}>{recruiter.userName}</Typography>
                        <Typography sx={{ fontSize: '8px', color: '#B0B0B0' }}>{recruiter.designation}</Typography>
                    </Box>
                </Box>
            ))}
            {extraCount > 0 && (
                <Typography sx={{ fontSize: '12px', color: '#8C8C8C', fontWeight: 'bold', ml: -0.5 }}> +{extraCount} </Typography>
            )}
        </Box>
    );
};

export default ClosedRecruiterList;
