import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BusinessState {
  businessName: string[];
  businessId: string[];
}

const initialState: BusinessState = {
  businessName: [],
  businessId: [],
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setBusinessName(state, action: PayloadAction<string[]>) {
      state.businessName = action.payload;
    },
    setBusinessId(state, action: PayloadAction<string[]>) {
      state.businessId = action.payload;
    },
  },
});

export const { setBusinessName, setBusinessId } = businessSlice.actions;
export default businessSlice.reducer;
