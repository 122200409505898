import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  InputAdornment,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getMrfHubData } from "../../../../Crud/card";
import { StyledTableCell } from "./RthClosedTable";
import { useAppSelector } from "../../../../redux/hooks";
import SearchIcon from "@mui/icons-material/Search";
import NoDataAvail from "../../../../assets/images/NoDataAvail.svg";

interface prop {
  setLowCount: Function;
  setMediumCount: Function;
  setHighCount: Function;
}

const MrfHub: React.FC<prop> = ({
  setLowCount,
  setHighCount,
  setMediumCount,
}) => {
  // const userId = localStorage.getItem("userId") ?? "";
  const teamHeadFilter = useAppSelector((state) => state.teamHeadFilter);
  const [tableData, setTableData] = useState<any[]>([]);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  console.log("setSearchLoading",setSearchLoading);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const tableHead = [
    "MRF ID",
    "MRF Name",
    "Total Positions",
    "MRF Stage",
    "Recruiter",
    "Assigned Positions",
    "Rectr. MRF Stage",
    "Closed Position",
    "Open Position",
  ];
  const [loading, setLoading] = useState<boolean>(false);
  console.log("loading", loading);
  useEffect(() => {
    setLoading(true);
    getMrfHubData(teamHeadFilter, searchQuery)
      .then((res) => {
        setTableData(() => res?.data?.data.MrfHub);
        setLowCount(res?.data?.data.OverAllRthPriorityCount.LowCount);
        setMediumCount(res?.data?.data.OverAllRthPriorityCount.MediumCount);
        setHighCount(res?.data?.data.OverAllRthPriorityCount.HighCount);
        console.log("mrfHUbData",res.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [teamHeadFilter, searchQuery,setHighCount,setLowCount,setMediumCount]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    console.log("search qqq",event.target.value);
  };

  return (
    <Box>
      <Box sx={{ width: "auto", backgroundColor: "#f0f4ff" }}>
        <Grid
          item
          sx={{ marginTop: tableData.length > 0 ? "-60px" : "-58px" }}
          position="absolute"
        >
          <InputBase
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search MRF..."
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ fontSize: 18, marginLeft: "10px" }} />
              </InputAdornment>
            }
            sx={{
              border: "1px solid #ccc",
              borderRadius: 1,
              padding: 0,
              backgroundColor: "white",
              minHeight: "3px",
              fontSize: 14,
              ".MuiInputBase-input": {
                padding: "5px 5px",
              },
            }}
          />
          {searchLoading && (
            <CircularProgress
              size={20}
              sx={{
                position: "absolute",
                right: "10px",
                top: "25%",
                transform: "translateY(-50%)",
                color: "#2F54EB",
              }}
            />
          )}
        </Grid>
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "300px",
              }}
            >
              <CircularProgress size={100} sx={{ color: "#2F54EB" }} />
            </Box>
          ) : (
            tableData.length === 0 ? (
              <Box
              sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "300px",
              }}
          >
              <img src={NoDataAvail} alt="No Data Available" style={{ maxWidth: "200px" }} />
          </Box>
            ):(

              <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="MRF table">
                <TableHead sx={{ backgroundColor: "#F9F9F9" }}>
                  <TableRow
                    sx={{
                      "& th": {
                        padding: "4px 8px", // Adjust padding for header cells here
                        fontSize: "14px", // Optional: Adjust font size
                      },
                    }}
                  >
                    {tableHead.map((data) => (
                      <StyledTableCell>{data}</StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData.map((data, index) => (
                    <TableRow
                      key={data.index}
                      sx={{
                        "& td, & th": {
                          padding: "4px 8px", // Adjust padding here
                        },
                      }}
                    >
                      <StyledTableCell>{index + 1}</StyledTableCell>
                      <StyledTableCell>{data?.mrfName}</StyledTableCell>
                      <StyledTableCell>{data?.totalPosition}</StyledTableCell>
                      <StyledTableCell>
                        <Chip
                          label={data?.mrfStage}
                          size="small"
                          sx={{
                            fontSize: "12px",
                            padding: "3px 6px 0px 6px",
                            height: "px",
                            borderRadius: "1px",
                            backgroundColor: "#389E0D1A",
                            color: "#389E0D",
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{data?.recruiter?.userName}</StyledTableCell>
                      <StyledTableCell>
                        {data?.assignedPosition}
                      </StyledTableCell>
                      <StyledTableCell>{data?.rctrMrfStage}</StyledTableCell>
                      <StyledTableCell>{data?.closedPosition}</StyledTableCell>
                      <StyledTableCell>{data?.openPosition}</StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            )                        
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MrfHub;
