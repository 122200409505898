import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import AjHeader from "./AjHeader";
import AjBody from "./AjBody";
import { getAllPostedJobs } from "../../redux/features/allUserSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import ProfilePage from "../../pages/profile/profilepage";

const AjMain: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [clickedIndex, setClickedIndex] = useState(0);

  const allPostedJobs = useSelector(
    (state: RootState) => state.allUserLogin.allViewersLoginData
  );

  const [profileView, setProfileView] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getAllPostedJobs({ industry: "", location: "" }));
  }, [dispatch]);

  console.log(allPostedJobs);

  return (
    <Box display={"flex"} flexDirection={"column"} gap={3}>
      <Box position="fixed" top={0} left={0} right={0} zIndex={1000}>
        <AjHeader
          clickedIndex={clickedIndex}
          setClickedIndex={setClickedIndex}
          allPostedJobs={allPostedJobs}
          setProfileView={setProfileView}
        />
      </Box>
      <Box marginTop={15} marginBottom={2}>
      {profileView ? (
        <ProfilePage setProfileView={setProfileView} />
      ) : (
        <AjBody
          allPostedJobs={allPostedJobs}
          clickedIndex={clickedIndex}
          setClickedIndex={setClickedIndex}
        />
      )}
      </Box>
    </Box>
  );
};

export default AjMain;
