import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RolesData } from '../../components/admin/adminTabs/Roles/AddRole';
import { BaseUrl } from '../../constants/Crudurl';
import { toast } from 'react-toastify';

interface UserState {
  users: {
    data: RolesData[];
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: UserState = {
  users: { data: [] },
  status: 'idle',
  error: null,
};

export const fetchRoles = createAsyncThunk('role/fetchRoles', async (search?:string) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`${BaseUrl}/master/getActiveRole${search?`?search=${search}`:''}`, {
    headers: { Authorization: `Bearer ${token}` }
  });
  return response.data;
});

export const addRole = createAsyncThunk('role/addUser', async (RolesData: Partial<RolesData>) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`${BaseUrl}/master/addRole`, RolesData, {
    headers: { Authorization: `Bearer ${token}` }
  });
  if (response.data.message === "Role added") {
    toast.success("Role added successfully")
  } else {
    toast.error("Role aleady exists")
  }
  return response.data;
});

export const deleteRole = createAsyncThunk('role/deleteRole', async (roleId: string) => {
  const token = localStorage.getItem("token");
  await axios.delete(`${BaseUrl}/master/removeRoleById/${roleId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
  return roleId;
});

export const editRole = createAsyncThunk('role/updateUser', async (RolesData: any) => {
  const token = localStorage.getItem("token");
  const response = await axios.put(`${BaseUrl}/master/editRoleById/${RolesData.roleId}`, RolesData, {
    headers: { Authorization: `Bearer ${token}` }
  });
  if (response.data.message === "role updated") {
    toast.success("Role updated successfully")
  } else {
    toast.error("Role aleady exists")
  }
  return response.data;
});

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || null;
      })
      .addCase(addRole.fulfilled, (state, action: PayloadAction<RolesData>) => {
        if (state.users.data) {
          state.users.data.push(action.payload);
        } else {
          state.users.data = [action.payload];
        }
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.users.data = state.users.data.filter((user: RolesData) => user.id !== action.payload);
      })
      .addCase(editRole.fulfilled, (state, action: PayloadAction<RolesData>) => {
        const index = state.users.data.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.users.data[index] = action.payload;
        }
      })
  },
});

export default roleSlice.reducer;