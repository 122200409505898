import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface filterState {
  interviewName: string[];
  interviewStatus: string[];
  interviewStages:string[];
  source:string[];
  interviewMode:string[];
  dateRange:string[];
  positions:string[];
  resumePositionTitle:string[];
  resumeInterviewStages:string[];
  resumeSource:string[];
  resumeExpectedCtc:string;
  resumeNoticePeriod:string[];
  noticePeriod:string[];
  resumeExperience:string[];
  resumeskillSets:string[];
  resumeEducation:string[];
  resumeLanguage:string[];
  resumeInterviewStatus:string[];
  resumeExpectedSalary:string;
  resumeCurrentSalary:string;
}

const initialState: filterState = {
  interviewName: [],
  interviewStatus: [],
  interviewStages:[],
  source:[],
  interviewMode:[],
  dateRange:[],
  positions:[],
  resumePositionTitle:[],
  resumeInterviewStages:[],
  resumeSource:[],
  resumeExpectedCtc:'',
  resumeNoticePeriod:[],
  noticePeriod:[],
  resumeExperience:[],
  resumeskillSets:[],
  resumeEducation:[],
  resumeLanguage:[],
  resumeInterviewStatus:[],
  resumeExpectedSalary:'',
  resumeCurrentSalary:'',
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setInterviewName: (state, action: PayloadAction<string[]>) => {
      state.interviewName = action.payload;
    },
    setInterviewStatus: (state, action: PayloadAction<string[]>) => {
      state.interviewStatus = action.payload;
    },
    setInterviewStages:(state,action:PayloadAction<string[]>)=>{
     state.interviewStages=action.payload;
    },
    setSource:(state,action:PayloadAction<string[]>)=>{
      state.source=action.payload;
    },
    setInterviewMode:(state,action:PayloadAction<string[]>)=>{
      state.interviewMode=action.payload;
    },
    setPositions:(state,action:PayloadAction<string[]>)=>{
     state.positions=action.payload;
    },
    setResumePositionTitle:(state,action:PayloadAction<string[]>)=>{
      state.resumePositionTitle=action.payload;
    },
    setResumeInterviewStages:(state,action:PayloadAction<string[]>)=>{
      state.resumeInterviewStages=action.payload;
    },
    setResumeSource:(state,action:PayloadAction<string[]>)=>{
      state.resumeSource=action.payload;
    },
    setResumeExpectedCtc:(state,action:PayloadAction<string>)=>{
      state.resumeExpectedCtc=action.payload;
    },
    setNoticePeriods:(state,action:PayloadAction<string[]>)=>{
      state.resumeNoticePeriod=action.payload;
    },
    setResumeExperience:(state,action:PayloadAction<string[]>)=>{
      state.resumeExperience=action.payload;
    },
    setResumeSkillSets:(state,action:PayloadAction<string[]>)=>{
     state.resumeskillSets=action.payload;
    },
    setResumeEducation:(state,action:PayloadAction<string[]>)=>{
    state.resumeEducation=action.payload;
    },
    setResumeLanguage:(state,action:PayloadAction<string[]>)=>{
      state.resumeLanguage=action.payload;
    },
    setResumeInterviewStatus:(state,action:PayloadAction<string[]>)=>{
      state.resumeInterviewStatus=action?.payload;
    },
    setResumeExpectedSalary:(state,action:PayloadAction<string>)=>{
      state.resumeExpectedSalary=action?.payload;
    },
    setResumeCurrentSalary:(state,action:PayloadAction<string>)=>{
      state.resumeCurrentSalary=action?.payload;
    },
  },
});

export const { setInterviewName, setInterviewStatus,setInterviewStages,setSource,setInterviewMode,setPositions,setResumeInterviewStages,
  setResumeSource,setNoticePeriods, setResumeExperience,setResumeSkillSets,setResumeEducation,setResumeLanguage,setResumeExpectedCtc,
  setResumeInterviewStatus,setResumeCurrentSalary,setResumeExpectedSalary,setResumePositionTitle
 } = filterSlice.actions;

export default filterSlice.reducer;
