import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PostedInState {
  names: string[];
  ids: string[];
}

const initialState: PostedInState = {
  names: [],
  ids: [],
};

const postedInSlice = createSlice({
  name: "postedIn",
  initialState,
  reducers: {
    setPostedInName(state, action: PayloadAction<string[]>) {
      state.names = action.payload;
    },
    setPostedInId(state, action: PayloadAction<string[]>) {
      state.ids = action.payload;
    },
  },
});

export const { setPostedInName, setPostedInId } = postedInSlice.actions;
export default postedInSlice.reducer;
